import {
  type TableColumn,
  CheckTypeDisplay,
  type CheckType,
  UtilizationUnitsDisplay,
  type UtilizationUnits,
  EditableInputCellWithLabelWithLabel,
  InputType,
  EditableSelectCellWithLabel,
  type ISelectOption,
} from '@flyward/platform'
import { type FieldValues, type Control, type Path } from 'react-hook-form'
import { type ChecksDisplay } from './ChecksReadOnlyTable'

export const existingTableColumns = <TFormData extends FieldValues>(
  formControl: Control<TFormData, unknown>,
  isArrayPath: boolean,
): Array<TableColumn<ChecksDisplay>> => [
  {
    accessor: 'checkType',
    isIdField: true,
    title: 'Event Type',
    valueGetter: (value) => {
      return CheckTypeDisplay(value as CheckType)
    },
    cellClassName: 'basis-6/24',
    headerClassName: 'basis-6/24',
  },
  {
    accessor: 'utilizationUnit',
    title: 'Unit',
    valueGetter: (value) => {
      return UtilizationUnitsDisplay(value as UtilizationUnits)
    },
    cellClassName: 'basis-6/24',
    headerClassName: 'basis-6/24',
  },
  {
    accessor: 'utilization',
    title: 'Limit',
    customCell: ({ rowIndex: index }) => {
      const filedName = `${isArrayPath ? `checks.${index}` : 'check'}.matureLimit` as Path<TFormData>
      return (
        <div className="w-full min-w-full text-xs">
          <EditableInputCellWithLabelWithLabel inputType={InputType.NaturalNumber} formControl={formControl} fieldName={filedName} />
        </div>
      )
    },
    cellClassName: 'basis-6/24',
    headerClassName: 'basis-6/24',
  },
  {
    accessor: 'baseCost',
    title: 'Cost',
    customCell: ({ rowIndex: index }) => {
      const filedName = `${isArrayPath ? `checks.${index}` : 'check'}.baseCost` as Path<TFormData>
      return (
        <div className="w-full min-w-full text-xs">
          <EditableInputCellWithLabelWithLabel inputType={InputType.Currency} formControl={formControl} fieldName={filedName} />
        </div>
      )
    },
    cellClassName: 'basis-6/24',
    headerClassName: 'basis-6/24',
  },
]

export const newTableColumns = <TFormData extends FieldValues>(
  formControl: Control<TFormData, unknown>,
  isArrayPath: boolean,
  checkTypesOptions: ISelectOption[],
): Array<TableColumn<ChecksDisplay>> => [
  {
    accessor: 'checkType',
    isIdField: true,
    title: 'Event Type',
    customCell: ({ rowIndex: index }) => {
      const fieldName = `${isArrayPath ? `checkAddRemoveItems.addedItems.${index}` : 'checkAddRemoveItem.addedItem'}.checkType` as Path<TFormData>

      return (
        <div className="w-full min-w-full text-xs">
          <EditableSelectCellWithLabel
            className="min-w-full basis-3/6 whitespace-pre"
            options={checkTypesOptions}
            formControl={formControl}
            fieldName={fieldName}
          />
        </div>
      )
    },
    cellClassName: 'basis-6/24',
    headerClassName: 'basis-6/24',
  },
  {
    accessor: 'utilizationUnit',
    title: 'Unit',
    valueGetter: (value) => {
      return UtilizationUnitsDisplay(value as UtilizationUnits)
    },
    cellClassName: 'basis-6/24',
    headerClassName: 'basis-6/24',
  },
  {
    accessor: 'utilization',
    title: 'Limit',
    customCell: ({ rowIndex: index }) => {
      const filedName = `${isArrayPath ? `checkAddRemoveItems.addedItems.${index}` : 'checkAddRemoveItem.addedItem'}.matureLimit` as Path<TFormData>
      return (
        <div className="w-full min-w-full text-xs">
          <EditableInputCellWithLabelWithLabel inputType={InputType.NaturalNumber} formControl={formControl} fieldName={filedName} />
        </div>
      )
    },
    cellClassName: 'basis-6/24',
    headerClassName: 'basis-6/24',
  },
  {
    accessor: 'baseCost',
    title: 'Cost',
    customCell: ({ rowIndex: index }) => {
      const filedName = `${isArrayPath ? `checkAddRemoveItems.addedItems.${index}` : 'checkAddRemoveItem.addedItem'}.baseCost` as Path<TFormData>
      return (
        <div className="w-full min-w-full text-xs">
          <EditableInputCellWithLabelWithLabel inputType={InputType.Currency} formControl={formControl} fieldName={filedName} />
        </div>
      )
    },
    cellClassName: 'basis-6/24',
    headerClassName: 'basis-6/24',
  },
]
