import { dateToIsoString } from '@flyward/platform/helpers/dateHelpers'
import { AirframeCheckType, AirframeCheckTypeDisplay, AirframeCheckTypeOptions } from '@flyward/platform/models'
import DeleteIcon from '@mui/icons-material/Delete'
import { IconButton, Link, MenuItem, Select, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker/DatePicker'
import { isEmpty, isNil } from 'lodash'
import { useState } from 'react'
import { NumericFormat } from 'react-number-format'
import { TableRowData } from './Airframe'

const duplicateStyle = {
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'red',
    },
    '&:hover fieldset': {
      borderColor: 'red',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'red',
    },
  },
}

interface ChecksTableProps {
  rows: TableRowData[]
  onRowChange: (_id: number, _field: keyof TableRowData, _value: any) => void
  onAddRow: () => void
  onDeleteRow: (_id: number) => void
  onValidationChange?: (_isValid: boolean) => void
  isAiPopulated?: boolean
}

const MIN_VALUE = 0
const MAX_VALUE = 1000000000

const TABLE_CELL_STYLES = {
  width: '30%',
  whiteSpace: 'nowrap',
  minWidth: '12rem',
  alignContent: 'end',
}

const DELETE_CELL_STYLES = {
  width: '10%',
}

const ChecksTable = ({ rows, onRowChange, onAddRow, onDeleteRow }: ChecksTableProps) => {
  const checkDuplicates = (currentId: number, checkType: AirframeCheckType): boolean => {
    if (!checkType) return false
    return rows.some((row) => row.id !== currentId && row.checkType === checkType)
  }

  const handleInputChange = (id: number, field: keyof TableRowData, value: any) => {
    onRowChange(id, field, value)
  }

  return (
    <>
      <Table size={'small'} sx={{ width: '100%' }}>
        <TableHead>
          <TableRow sx={{ '& th': { borderBottom: '1px solid rgba(0, 0, 0, 0.12)', backgroundColor: 'transparent' } }}>
            <TableCell sx={{ ...TABLE_CELL_STYLES }}>
              <span className="flex pl-1">Event Type *</span>
            </TableCell>
            <TableCell sx={{ ...TABLE_CELL_STYLES }}>
              <span className="flex pl-1">Date @ last check</span>
            </TableCell>
            <TableCell sx={{ ...TABLE_CELL_STYLES }}>
              <span className="flex pl-1">
                Months since check <br /> at contract delivery
              </span>
            </TableCell>
            <TableCell sx={DELETE_CELL_STYLES} />
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => {
            const isDuplicate = checkDuplicates(row.id, row.checkType)

            return (
              <TableRow key={row.id} sx={{ '& td': { border: 'none' } }}>
                <TableCell sx={TABLE_CELL_STYLES}>
                  <Select
                    fullWidth
                    value={row.checkType}
                    onChange={(e) => handleInputChange(row.id, 'checkType', Number(e.target.value))}
                    error={isDuplicate}
                    sx={isDuplicate ? { ...duplicateStyle } : undefined}
                    displayEmpty
                    renderValue={(selectedValue) => {
                      if (!selectedValue) {
                        return <em>Select an event</em>
                      }
                      return AirframeCheckTypeDisplay(Number(selectedValue))
                    }}
                  >
                    {AirframeCheckTypeOptions.map(({ value, label }) => (
                      <MenuItem key={value} value={value}>
                        {label}
                      </MenuItem>
                    ))}
                  </Select>
                </TableCell>

                <TableCell sx={TABLE_CELL_STYLES}>
                  <DatePicker
                    value={!isNil(row.dateAtLastCheck) && !isEmpty(row.dateAtLastCheck) ? new Date(row.dateAtLastCheck) : undefined}
                    onChange={(newValue) => {
                      const isoFormat = isNil(newValue) ? undefined : dateToIsoString(newValue)
                      onRowChange(row.id, 'dateAtLastCheck', isoFormat)
                    }}
                    slotProps={{
                      field: {
                        clearable: true,
                        onClear: () => {
                          onRowChange(row.id, 'dateAtLastCheck', undefined)
                        },
                      },
                      textField: {
                        sx: { width: '100%' },
                      },
                    }}
                  />
                </TableCell>
                <TableCell sx={TABLE_CELL_STYLES}>
                  <NumericFormat
                    fullWidth
                    customInput={TextField}
                    value={row.monthsSinceEventAtContractDelivery ?? ''}
                    thousandSeparator
                    decimalScale={0}
                    allowNegative={false}
                    isAllowed={({ floatValue }) => floatValue === undefined || (floatValue >= MIN_VALUE && floatValue <= MAX_VALUE)}
                    onValueChange={({ floatValue }) => {
                      return onRowChange(row.id, 'monthsSinceEventAtContractDelivery', floatValue)
                    }}
                  />
                </TableCell>
                <TableCell sx={DELETE_CELL_STYLES}>
                  {rows.length > 0 && (
                    <IconButton size="small" onClick={() => onDeleteRow(row.id)} sx={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  )}
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
      <Link
        component="button"
        variant="body2"
        onClick={onAddRow}
        sx={{
          mt: 2,
          textDecoration: 'none',
          '&:hover': {
            textDecoration: 'none',
          },
        }}
      >
        + Add another row
      </Link>
    </>
  )
}

export { ChecksTable }
