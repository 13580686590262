import React from 'react'
import { Outlet } from 'react-router-dom'
import { useZitadelAuthenticated, ZitadelAuthenticatedProvider } from '@flyward/appIdentity/context'
import { Skeleton } from '@mui/material'

const LayoutSkeleton = () => {
  return (
    <div className="flex h-full w-full flex-col bg-background-light">
      {/* Header Skeleton */}
      <div className="flex h-20">
        <div className="flex w-full gap-x-4 pb-4 pl-6 pr-6 pt-6">
          <Skeleton variant="rectangular" width={200} height={32} />
        </div>
      </div>

      <div className="flex flex-grow">
        {/* Main Content Skeleton */}
        <div className="h-full w-full p-6">
          <div className="space-y-4">
            <Skeleton variant="rectangular" width="100%" height={200} />
            <div className="grid grid-cols-3 gap-4">
              {[1, 2, 3].map((i) => (
                <Skeleton key={i} variant="rectangular" width="100%" height={120} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export const ProtectedRouteOutlet: React.FC = () => {
  const { isUserAuthenticated, logout } = useZitadelAuthenticated()

  if (isUserAuthenticated === false) {
    logout()
  }

  if (isUserAuthenticated === null) {
    return <LayoutSkeleton />
  }
  return <ZitadelAuthenticatedProvider>{<Outlet />}</ZitadelAuthenticatedProvider>
}
