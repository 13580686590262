export enum AlignmentStatus {
  MissingInAsset = 0, // Empty Icon & No Action (empty row, can be dragged only)
  Success = 1, // Green Icon + Align Arrow, becomes Blue if moved to MissingInKb or ToAlign if moved in KB area
  MissingInKb = 2, // Blue Icon + Bin, becomes ToAlign if moved in KB area
  SuggestedAlignment = 3, // Blue Icon + Bin, becomes ToAlign if moved in KB area
  ToAlignManually = 9, // Blue Icon + Align Arrow, becomes Green Icon after aligning
}

export const AlignmentStatusDisplay = (status: AlignmentStatus) => {
  switch (status) {
    case AlignmentStatus.MissingInAsset:
      return 'Missing In Asset'
    case AlignmentStatus.Success:
      return 'Aligned'
    case AlignmentStatus.MissingInKb:
      return 'Missing in Programme'
    case AlignmentStatus.SuggestedAlignment:
      return 'Suggested Alignment'
    case AlignmentStatus.ToAlignManually:
      return 'To Align'
  }
}
