import { ROUTES } from '@flyward/main-app/providers/routes'
import { ComponentType } from '@flyward/platform'

export const BREADCRUMBS = {
  [ComponentType.EngineUnit]: [
    {
      title: 'Maintenance Programme',
      url: ROUTES.MAINTENANCE_PROGRAMS.EMP,
    },
  ],
  [ComponentType.Airframe]: [
    {
      title: 'Maintenance Programme',
      url: ROUTES.MAINTENANCE_PROGRAMS.AMP,
    },
  ],
  [ComponentType.LandingGear]: [
    {
      title: 'Maintenance Programme',
      url: ROUTES.MAINTENANCE_PROGRAMS.LGMP,
    },
  ],
  [ComponentType.AuxiliaryPowerUnit]: [
    {
      title: 'Maintenance Programme',
      url: ROUTES.MAINTENANCE_PROGRAMS.APUMP,
    },
  ],
}
