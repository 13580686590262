import { type AircraftEngineDto } from '@flyward/assets/models'
import { type EngineLessorContributionsDto } from '@flyward/assets/models/aircraftComponents/aircraftEngine/EngineLessorContributionsDto'
import { CheckType, CheckTypeDisplay, NumberDisplay, NumberDisplayType, SimpleHeader } from '@flyward/platform'
import { GenericTable } from '@flyward/platform/components/Table/GenericTable'
import { type ColumnDef, getCoreRowModel, useReactTable } from '@tanstack/react-table'
import { isNil } from 'lodash'

interface IEPRLessorContributionsReadonlyTabProps {
  component: AircraftEngineDto
}

const generateGridColumns = (): Array<ColumnDef<EngineLessorContributionsDto>> => {
  const contractualReserveColumns: Array<ColumnDef<EngineLessorContributionsDto>> = [
    {
      id: 'checkType',
      accessorKey: 'checkType',
      header: () => <SimpleHeader title="Check Type" className="text-semibold m-0 w-full p-1 text-center text-xs text-black-0" />,
      cell: () => <div className="text-center text-xs">{CheckTypeDisplay(CheckType.Epr)}</div>,
      meta: {
        className: 'basis-6/24',
      },
    },
    {
      id: 'isActive',
      accessorKey: 'isActive',
      header: () => <SimpleHeader title="Applicable" className="text-semibold m-0 w-full p-1 text-center text-xs text-black-0" />,
      cell: ({ row }) => <div className="text-center text-xs">{row.original.eprLessorContribution?.isActive === true ? 'Yes' : 'No'}</div>,
      meta: {
        className: 'basis-6/24',
      },
    },
    {
      id: 'isUnlimited',
      accessorKey: 'isUnlimited',
      header: () => <SimpleHeader title="Unlimited" className="text-semibold m-0 w-full p-1 text-center text-xs text-black-0" />,
      cell: ({ row }) => {
        if (row.original.eprLessorContribution?.isActive === true) {
          return <div className="text-center text-xs">{row.original.eprLessorContribution?.isUnlimited ? 'Yes' : 'No'}</div>
        } else {
          return <div className="h-5 bg-black-20 text-center"></div>
        }
      },
      meta: {
        className: 'basis-6/24',
      },
    },
    {
      id: 'amount',
      accessorKey: 'amount',
      header: () => <SimpleHeader title="Total Amount" className="text-semibold m-0 w-full p-1 text-center text-xs text-black-0" />,
      cell: ({ row }) => {
        if (row.original.eprLessorContribution?.isActive === true && !row.original.eprLessorContribution?.isUnlimited) {
          return (
            <div className="text-center text-xs">
              <NumberDisplay displayType={NumberDisplayType.Currency} value={row.original.eprLessorContribution?.amount ?? 0} />
            </div>
          )
        } else {
          return <div className="flex h-5 bg-black-20 text-center"></div>
        }
      },
      meta: {
        className: 'basis-6/24',
      },
    },
  ]

  return contractualReserveColumns
}

export const EPRLessorContributionsReadonlyTab = ({ component }: IEPRLessorContributionsReadonlyTabProps) => {
  const lessorContributions = !isNil(component.lessorContributions) ? [component.lessorContributions] : new Array<EngineLessorContributionsDto>()

  const contractualReserveColumns = generateGridColumns()

  const contractualReserveTable = useReactTable<EngineLessorContributionsDto>({
    data: lessorContributions,
    columns: contractualReserveColumns,
    getRowCanExpand: () => true,
    getCoreRowModel: getCoreRowModel(),
    enableMultiRowSelection: false,
  })

  return (
    <div className="w-100">
      <GenericTable tableInstance={contractualReserveTable} />
    </div>
  )
}
