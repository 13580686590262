/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable prettier/prettier */
import React, { useState } from 'react'
import { axiosInstance } from '@flyward/platform/services/API'
import { isEmpty, isNil } from 'lodash'
import { Button, ButtonVariant } from '@flyward/platform'
import axios from 'axios'

const TestStatusCodes: React.FC = () => {
  const [expectedStatusCode, setExpectedStatusCode] = useState<number>(200)
  const [response, setResponse] = useState<string | null>(null)
  const [error, setError] = useState<string | null>(null)

  const _baseApiUrl: string = isEmpty(import.meta.env.VITE_API_URL) ? '/api' : import.meta.env.VITE_API_URL

  const handleSubmit = async () => {
    setResponse(null)
    setError(null)

    try {
      const result = await axiosInstance.post(`${_baseApiUrl}/forecasts/status-codes`, null, {
        params: { expectedStatusCode },
      })

      setResponse(JSON.stringify(result.data, null, 2))
    } catch (err) {
      const displayError =
        axios.isAxiosError(err) && !isNil(err.response)
          ? JSON.stringify(err.response.data, null, 2)
          : err instanceof Error
            ? err.message
            : 'An unknown error occurred'
      setError(displayError)
    }
  }

  return (
    <div>
      <h2>Test Status Codes</h2>
      <label>
        Expected Status Code:
        <input
          type="number"
          value={expectedStatusCode}
          onChange={(e) => {
            setExpectedStatusCode(Number(e.target.value))
          }}
        />
      </label>
      <br />

      <br />

      <Button variant={ButtonVariant.ModalConfirm} label="Test API" onClick={handleSubmit} />

      {isNil(response) && (
        <div>
          <h3>Response:</h3>
          <pre>{response}</pre>
        </div>
      )}
      {!isNil(error) && (
        <div style={{ color: 'red' }}>
          <h3>Error:</h3>
          <pre>{error}</pre>
        </div>
      )}
    </div>
  )
}

export { TestStatusCodes }
