import React, { useMemo } from 'react'
import { withSuspenseAndErrorBoundary } from './withSuspenseAndErrorBoundary'

interface ILoadingComponentWithSuspenseAndErrorBoundary {
  children: React.ReactNode
  hasSuspense?: boolean
}

/**
 @deprecated
 */
const AsyncErrorBoundary = ({ children, hasSuspense = true }: ILoadingComponentWithSuspenseAndErrorBoundary): React.ReactElement | null => {
  const WrappedChildren = useMemo(
    () =>
      React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          const WrappedComponent = withSuspenseAndErrorBoundary(() => child, { hasSuspense })
          return <WrappedComponent />
        }
        return child
      }),
    [children, hasSuspense],
  )

  return <>{WrappedChildren}</>
}
export { AsyncErrorBoundary }
