import { AuthorizedElement, useUserAuthenticated } from '@flyward/appIdentity'
import type { AircraftMaintenancePrograms } from '@flyward/assets/models'
import { ComponentContainer } from '@flyward/platform'
import { ReadOnlyMaintenanceProgram } from './ReadOnlyAircratMaintenancePrograms'
import { EditAircraftMaintenancePrograms } from './EditAircraftMaintenancePrograms/EditAircraftMaintenancePrograms'
import { isNil } from 'lodash'

export const AircraftMaintenanceProgram = ({
  assetId,
  assignedAirframeProgramId,
  assignedAPUProgramId,
  assignedEngineProgramId,
  assignedLandingGearProgramId,
  availablePrograms,
  changePrograms,
  airframesIds,
  enginesIds,
  apusIds,
  lgIds,
}: AircraftMaintenancePrograms) => {
  const { loggedUser } = useUserAuthenticated()
  return (
    <ComponentContainer className="p-6">
      {!isNil(loggedUser) && (
        <div className="flex w-full gap-x-6">
          <AuthorizedElement>
            <ReadOnlyMaintenanceProgram
              assetId={assetId}
              data-permission-element-id="read-only-asset-view"
              airframesIds={airframesIds}
              enginesIds={enginesIds}
              apusIds={apusIds}
              lgIds={lgIds}
              availablePrograms={availablePrograms}
              changePrograms={changePrograms}
              assignedAirframeProgramId={assignedAirframeProgramId}
              assignedEngineProgramId={assignedEngineProgramId}
              assignedAPUProgramId={assignedAPUProgramId}
              assignedLandingGearProgramId={assignedLandingGearProgramId}
            />
          </AuthorizedElement>
          <AuthorizedElement>
            <EditAircraftMaintenancePrograms
              assetId={assetId}
              data-permission-element-id="edit-asset"
              airframesIds={airframesIds}
              enginesIds={enginesIds}
              apusIds={apusIds}
              lgIds={lgIds}
              availablePrograms={availablePrograms}
              changePrograms={changePrograms}
              assignedAirframeProgramId={assignedAirframeProgramId}
              assignedEngineProgramId={assignedEngineProgramId}
              assignedAPUProgramId={assignedAPUProgramId}
              assignedLandingGearProgramId={assignedLandingGearProgramId}
            />
          </AuthorizedElement>
        </div>
      )}
    </ComponentContainer>
  )
}
