import { useSelector, useDispatch } from 'react-redux'
import type { TRootState } from '@flyward/platform/store/configureReducers'
import { Box, Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import { NumberDisplay, NumberDisplayType } from '@flyward/platform'
import { ComponentType } from '@flyward/platform/models/enums/ComponentType'
import { Label } from '../common/Label'
import { useCreateLandingGearMaintenanceProgramMutation } from '@flyward/knowledgeBase/store'
import { type CreateLandingGearProgramDto } from '@flyward/knowledgeBase/models/DTOs/Create/LandingGear'
import { isNil } from 'lodash'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '@flyward/main-app/providers/routes'
import { showError, showSuccess } from '@flyward/platform/services'
import { formatAxiosErrorMessage } from '@flyward/platform/helpers/ErrorHelpers'
import { resetWizard } from '../../store/wizardSlice'

interface ReviewAndPublishProps {
  onNext: () => void
  onBack: () => void
  stepTitle: string
}

export const ReviewAndPublish: React.FC<ReviewAndPublishProps> = ({ onBack, stepTitle }) => {
  const wizardState = useSelector((state: TRootState) => state.wizard[ComponentType.LandingGear])
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [createLandingGearMaintenanceProgram] = useCreateLandingGearMaintenanceProgramMutation()

  const onPublish = () => {
    const programToCreate: CreateLandingGearProgramDto = {
      programName: wizardState.general.name,
      manufacturer: wizardState.general.manufacturer,
      componentModel: wizardState.general.model,
      baseYear: wizardState.general.baseYear,
      basicAssumptions: {
        downtimeMonths: wizardState.checksEscalationsAssumptions.checkDowntime,
        monthsDelayInPaymentOnClaims:
          isNil(wizardState.checksEscalationsAssumptions.delayInPayment) || wizardState.checksEscalationsAssumptions.delayInPayment === ''
            ? 0
            : wizardState.checksEscalationsAssumptions.delayInPayment,
      },
      overhaulCheck: {
        limit: wizardState.checksEscalationsAssumptions.checks[0].limit,
        cost: wizardState.checksEscalationsAssumptions.checks[0].cost,
      },
      escalationPct: wizardState.checksEscalationsAssumptions.escalation,
    }

    createLandingGearMaintenanceProgram({
      program: programToCreate,
    })
      .then(() => {
        dispatch(resetWizard())
        navigate(ROUTES.MAINTENANCE_PROGRAMS.LGMP)
        showSuccess(<span>The maintenance programme was successfully added.</span>)
      })
      .catch((error) => {
        showError(formatAxiosErrorMessage(JSON.stringify(error?.response)))
      })
  }

  const flexBoxStyles = {
    display: 'flex',
    flexDirection: 'row',
    gap: 2,
    flexWrap: 'wrap',
    '& > *': {
      flex: '1 1 calc(25% - 16px)',
      minWidth: '200px',
    },
  }

  return (
    <div className="shadow-sm rounded-lg bg-white p-6">
      <Typography variant="h6" sx={{ mb: 3 }}>
        {stepTitle}
      </Typography>

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, mb: 4 }}>
        <div className="mb-2 text-lg font-semibold">1. General</div>
        <Box sx={flexBoxStyles}>
          <Label label="Programme Name" value={wizardState.general.name} />
          <Label label="Manufacturer" value={wizardState.general.manufacturer} />
          <Label label="Model" value={wizardState.general.model} />
          <Label label="Base Year" value={wizardState.general.baseYear?.toString() ?? 'N/A'} />
        </Box>
      </Box>

      {wizardState.checksEscalationsAssumptions.checks.length > 0 && (
        <>
          <div className="mb-2 mt-4 text-lg font-semibold">2. Checks - Escalations and Basic Assumptions</div>
          <Typography variant="subtitle2" sx={{ mb: 1 }}>
            Checks
          </Typography>
          <TableContainer component={Paper} sx={{ mb: 4 }}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: 600 }}>Event Type</TableCell>
                  <TableCell sx={{ fontWeight: 600 }}>Unit</TableCell>
                  <TableCell sx={{ fontWeight: 600, textAlign: 'right' }}>Limit</TableCell>
                  <TableCell sx={{ fontWeight: 600, textAlign: 'right' }}>Cost</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {wizardState.checksEscalationsAssumptions.checks.map(({ limit, cost }: { limit: number; cost: number }, index: number) => (
                  <TableRow key={index}>
                    <TableCell>Overhaul</TableCell>
                    <TableCell>Months</TableCell>
                    <TableCell sx={{ textAlign: 'right' }}>
                      <NumberDisplay value={limit} displayType={NumberDisplayType.Integer} />
                    </TableCell>
                    <TableCell sx={{ textAlign: 'right' }}>
                      <NumberDisplay value={cost} displayType={NumberDisplayType.CurrencyRounded} />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}

      <Typography variant="subtitle2" sx={{ mb: 1 }}>
        Escalation
      </Typography>
      <Box sx={flexBoxStyles} className="mb-4">
        <Label label="Percentage" value={`${wizardState.checksEscalationsAssumptions.escalation ?? 0}%`} />
      </Box>

      <Typography variant="subtitle2" sx={{ mb: 1 }}>
        Basic Assumptions
      </Typography>
      <Box sx={flexBoxStyles}>
        <Label label="Check Downtime (months)" value={wizardState.checksEscalationsAssumptions.checkDowntime} />
        <Label label="Delay in Payment (months)" value={wizardState.checksEscalationsAssumptions.delayInPayment || '0'} />
        <span></span>
        <span></span>
      </Box>

      <Box sx={{ mt: 4 }}>
        <Button
          variant="contained"
          onClick={() => {
            onPublish()
          }}
          sx={{ mr: 1 }}
        >
          Publish
        </Button>
        <Button variant="text" onClick={onBack}>
          Back
        </Button>
      </Box>
    </div>
  )
}
