/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { useExportReportItemQuery } from '@flyward/forecasts/store'
import { isNil } from 'lodash'
import { useEffect, useState } from 'react'
import { IconVariant } from '@flyward/platform/components'
import { useSpinnerState } from '@flyward/platform/contexts'
import { showError, showSuccess } from '@flyward/platform/services'
import { errorMessages } from '@flyward/platform'
import { cn } from '@flyward/platform'
import { Button } from '@mui/material'
import { ExcelExtract } from '@flyward/platform/components/Icon/svg/ExcelExtract'

interface IReportItemExcelExtractProps {
  reportId: string
  reportItems: Array<{ reportItemId: string; assetSerialNumber: string }>
  className?: string
  disabled?: boolean
}

export const ReportItemExcelExtract = ({ reportId, reportItems, className, disabled = false }: IReportItemExcelExtractProps) => {
  const [buttonPressed, setButtonPressed] = useState(false)
  const { showSpinner, hideSpinner } = useSpinnerState()

  const [reportItemIds, setReportItemIds] = useState<string[]>([])
  const { data, error } = useExportReportItemQuery({ reportId, reportItemIds }, { skip: !buttonPressed })

  useEffect(() => {
    if (!isNil(data) && data.length > 0) {
      for (const d of data) {
        if (d.data instanceof Blob) {
          const url = window.URL.createObjectURL(d.data)
          const link = document.createElement('a')
          link.href = url
          link.download = `${getMSN(d.reportItemId)}.xlsx`
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
          window.URL.revokeObjectURL(url)
        } else {
          throw new Error('MediaSource handling is not supported in this method.')
        }
      }
      showSuccess(
        <span>
          {data.length} Excel {data.length > 1 ? 'files' : 'file'} downloaded
        </span>,
      )
      setButtonPressed(false)
      hideSpinner()
    }
    if (!isNil(error)) {
      showError(errorMessages.reports.generateExcelError)
      setButtonPressed(false)
      hideSpinner()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, error])

  const handleDownload = () => {
    const ids = reportItems.map((asset) => asset.reportItemId).filter((id): id is string => id !== undefined)
    if (ids.length > 0) {
      setReportItemIds(ids)
      setButtonPressed(true)
      showSpinner()
    }
  }

  const getMSN = (reportItemId: string) => {
    const item = reportItems.find((obj) => obj.reportItemId === reportItemId)
    return !isNil(item) ? item.assetSerialNumber : 'report'
  }

  return (
    <Button
      key={`excel-extract-${reportId}`}
      variant="outlined"
      startIcon={
        <div className="flex h-5 w-5 items-center justify-center">
          <ExcelExtract variant={IconVariant.ExcelExtract} className="h-full w-full" />
        </div>
      }
      className={cn('mr-1 h-9 px-3', className)}
      onClick={handleDownload}
      disabled={disabled || buttonPressed}
      sx={{
        '&.MuiButton-outlined': {
          borderColor: 'rgba(0, 0, 0, 0.23)',
          '&:hover': {
            borderColor: 'primary.main',
          },
        },
        '&.Mui-disabled': {
          backgroundColor: 'var(--black-5)',
          borderColor: 'var(--black-20)',
          '& .MuiSvgIcon-root, & svg': {
            color: 'var(--black-40)',
            opacity: 0.7,
          },
        },
        '& .MuiButton-startIcon': {
          marginRight: 0,
          marginLeft: 0,
        },
      }}
    />
  )
}
