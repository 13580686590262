import { getCoreRowModel, useReactTable } from '@tanstack/react-table'
import { useState } from 'react'
import { type UseFormSetValue, type Control } from 'react-hook-form'
import { type DraggableAirframeCheck, type DraggableAirframeChecksStack } from '../../../../../../../models/aircraftComponents'
import { type VerifyAirframeCheckResultDto } from '../../../../../../../models/aircraftComponents/airframe/verify/VerifyAirframeCheckResultDto'
import { AssetAirframeChecksHeader } from './AssetAirframeChecksHeader'
import { editableColumns } from './columns'
import { AssetAirframeChecksRows } from './AssetAirframeChecksRows'
import { type RowSizeMap } from '@flyward/platform/components'

interface IAssetAlignmentWizardProps {
  verifyAirframeChecksResult: VerifyAirframeCheckResultDto[]
  componentFormValues: DraggableAirframeChecksStack
  setComponentFormValues: UseFormSetValue<DraggableAirframeChecksStack>
  formControl: Control<DraggableAirframeChecksStack, unknown>
  onDeleteExistingAirframeCheck: (AirframeCheckId: string) => void
  onExistingAirframeCheckCopyFromKb: (positionalIndex: number, AirframeCheck: DraggableAirframeCheck) => void
  persistAssetAirframeChecksStackInForm: (AirframeCheckStack: DraggableAirframeCheck[]) => void
  kbTotalCount: number
}

export const AssetAirframeChecksTable = ({
  verifyAirframeChecksResult,
  componentFormValues,
  formControl,
  setComponentFormValues,
  onDeleteExistingAirframeCheck,
  onExistingAirframeCheckCopyFromKb,
  persistAssetAirframeChecksStackInForm,
  kbTotalCount,
}: IAssetAlignmentWizardProps) => {
  const assetAirframeChecks: DraggableAirframeCheck[] = componentFormValues.airframeChecks

  const [columnSizes, setColumnSizes] = useState<RowSizeMap>({
    airframeCheck_checkType: { defaultSize: 80, minSize: 50, maxSize: 200, currentSize: 80 },
    airframeCheck_dateAtLastQualifyingEvent: { defaultSize: 150, minSize: 140, maxSize: 200, currentSize: 150 },
    airframeCheck_mrRateAmount: { defaultSize: 80, minSize: 50, maxSize: 200, currentSize: 80 },
    airframeCheck_mrRateType: { defaultSize: 90, minSize: 50, maxSize: 200, currentSize: 90 },
    airframeCheck_mrRateYear: { defaultSize: 90, minSize: 50, maxSize: 150, currentSize: 90 },
    airframeCheck_mrCollectionType: { defaultSize: 120, minSize: 50, maxSize: 200, currentSize: 120 },
    airframeCheck_mrCurrentFund: { defaultSize: 80, minSize: 50, maxSize: 200, currentSize: 80 },
    airframeCheck_monthsSinceEventAtContractDelivery: { defaultSize: 150, minSize: 50, maxSize: 260, currentSize: 150 },
    alignmentStatus: { defaultSize: 20, minSize: 20, maxSize: 220, currentSize: 20 },
    order: { defaultSize: 20, minSize: 20, maxSize: 20, currentSize: 20 },
    align: { defaultSize: 20, minSize: 20, maxSize: 20, currentSize: 20 },
  })

  const table = useReactTable<DraggableAirframeCheck>({
    data: assetAirframeChecks,
    columns: editableColumns(columnSizes),
    getCoreRowModel: getCoreRowModel(),
    columnResizeMode: 'onChange',
  })

  return (
    <table className="max-w-fit basis-8/12 border-collapse border border-black-20">
      <AssetAirframeChecksHeader headerGroups={table.getHeaderGroups()} columnSizes={columnSizes} setColumnSizes={setColumnSizes} />
      <AssetAirframeChecksRows
        componentFormValues={componentFormValues}
        setComponentFormValues={setComponentFormValues}
        verifyAirframeChecksResult={verifyAirframeChecksResult}
        kbTotalCount={kbTotalCount}
        formControl={formControl}
        onDeleteExistingAirframeCheck={onDeleteExistingAirframeCheck}
        onExistingAirframeCheckCopyFromKb={onExistingAirframeCheckCopyFromKb}
        persistAssetLllStackInForm={persistAssetAirframeChecksStackInForm}
        columnSizes={columnSizes}
      />
    </table>
  )
}
