import { getLabelContent } from '@flyward/assets/helpers/assetHelpers'
import { RedeliveryConditionsEditable } from '@flyward/assets/components/AircraftComponents/common'
import { type AircraftEngineDto } from '@flyward/assets/models'
import {
  CheckTypeDisplay,
  EditableBooleanCellWithLabelWithLabel,
  EditableInputCellWithLabelWithLabel,
  InputType,
  TextCellWithLabel,
  UtilizationUnitsDisplay,
} from '@flyward/platform'
import { useState, useEffect } from 'react'
import { type Control } from 'react-hook-form'

export interface IContractualEditableTabProps {
  component: AircraftEngineDto
  formControl: Control<AircraftEngineDto, unknown>
}

export const ContractualEditableTab = ({ component, formControl }: IContractualEditableTabProps) => {
  const maintenanceReserve = component.engineContract.engineUnitMaintenanceReserveContract
  const contractDeliverySnapshot = component.engineContract.contractDeliverySnapshot
  const [dynamicLabel, setDynamicLabel] = useState<string>('')

  useEffect(() => {
    setDynamicLabel(getLabelContent(maintenanceReserve.isEolActive, maintenanceReserve.isMaintenanceReserveActive))
  }, [maintenanceReserve.isEolActive, maintenanceReserve.isMaintenanceReserveActive])

  return (
    <div className="flex flex-col gap-y-2">
      <div className="flex gap-x-6">
        <EditableInputCellWithLabelWithLabel
          inputType={InputType.NaturalNumber}
          className="basis-1/6"
          label="Build Standard (FC)"
          formControl={formControl}
          fieldName={`engineContract.llpsContractualBuildStandard`}
        />
        <EditableBooleanCellWithLabelWithLabel
          className="basis-1/6"
          label="MR Active"
          formControl={formControl}
          fieldName={`engineContract.engineUnitMaintenanceReserveContract.isMaintenanceReserveActive`}
        />
        <EditableBooleanCellWithLabelWithLabel
          className="basis-1/6"
          label="EOL Applicable"
          formControl={formControl}
          fieldName={`engineContract.engineUnitMaintenanceReserveContract.isEolActive`}
        />
        <EditableInputCellWithLabelWithLabel
          inputType={InputType.Year}
          className="basis-1/6"
          label={`${dynamicLabel} rates Year (PR & LLP)`}
          formControl={formControl}
          fieldName={`engineContract.engineUnitMaintenanceReserveContract.enginePerformanceRestorationMaintenanceRate.ratesYear`}
          preventInfo={!maintenanceReserve.isMaintenanceReserveActive && !maintenanceReserve.isEolActive}
        />
        <EditableInputCellWithLabelWithLabel
          inputType={InputType.NaturalNumber}
          className="basis-1/6"
          label={`Hours since ${CheckTypeDisplay(contractDeliverySnapshot.checkType)} at contract delivery`}
          formControl={formControl}
          fieldName={`engineContract.contractDeliverySnapshot.hoursSinceEventAtContractDelivery`}
        />
        <div className="basis-1/6" />
      </div>
      <div className="flex gap-x-6">
        <EditableInputCellWithLabelWithLabel
          inputType={InputType.Percentage}
          className="basis-1/6"
          label={`LLP ${dynamicLabel} Agreed Escalation`}
          formControl={formControl}
          fieldName={`engineContract.escalations.llPsEscalation.maintenanceReserveAgreedEscalationPercentage`}
          preventInfo={!maintenanceReserve.isMaintenanceReserveActive && !maintenanceReserve.isEolActive}
        />
        <EditableInputCellWithLabelWithLabel
          inputType={InputType.Currency}
          className="basis-1/6"
          label={`LLP ${dynamicLabel} rate`}
          formControl={formControl}
          fieldName={`engineContract.engineUnitMaintenanceReserveContract.engineLLPsTotalMaintenanceReserveRate.rateAmount`}
          preventInfo={!maintenanceReserve.isMaintenanceReserveActive && !maintenanceReserve.isEolActive}
        />
        <TextCellWithLabel
          className="basis-1/6"
          label={`LLP ${dynamicLabel} rate type`}
          info={UtilizationUnitsDisplay(component.engineContract.engineUnitMaintenanceReserveContract.engineLLPsTotalMaintenanceReserveRate?.utilizationUnit)}
          preventInfo={!maintenanceReserve.isMaintenanceReserveActive && !maintenanceReserve.isEolActive}
        />
        <EditableInputCellWithLabelWithLabel
          inputType={InputType.IntegerCurrency}
          className="basis-1/6"
          label={`Current LLP ${dynamicLabel} fund`}
          formControl={formControl}
          fieldName={`engineContract.engineUnitMaintenanceReserveContract.engineLLPsTotalMaintenanceReserveRate.currentFund`}
          isReadOnly={!maintenanceReserve.isMaintenanceReserveActive && !maintenanceReserve.isEolActive}
        />
        <div className="mr-6 basis-2/6" />
      </div>
      <div className="flex gap-x-6">
        <RedeliveryConditionsEditable
          label="Return Conditions PR"
          fieldPath="engineContract.minimumReDeliveryConditions.eprMinimumReDeliveryConditions"
          formControl={formControl}
          conditions={component.engineContract.minimumReDeliveryConditions.eprMinimumReDeliveryConditions}
          className="mr-6"
        />
        <RedeliveryConditionsEditable
          label="Return Conditions LLP"
          fieldPath="engineContract.minimumReDeliveryConditions.llpMinimumReDeliveryConditions"
          formControl={formControl}
          conditions={component.engineContract.minimumReDeliveryConditions.llpMinimumReDeliveryConditions}
          className="mr-6"
        />
        <div className="ml-6 basis-2/6" />
      </div>
    </div>
  )
}
