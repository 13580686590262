import { AircraftEngineDtoSchema, type AircraftEngineDto } from '@flyward/assets/models'
import { CheckType, CnForm, ComponentContainer } from '@flyward/platform'
import { isNil, maxBy } from 'lodash'
import { EngineDetailsTabs } from './EngineDetailsTabs'
import { type ComponentUtilizationAtEventDto } from '@flyward/platform/models/entities/Utilization'
import { EngineDetailsGeneral } from './EngineDetailsTabs/EngineDetailsGeneral'
import { useEffect, useRef } from 'react'
import { useAssetComponentsForms } from '@flyward/assets/context'
import { useUpdateEngineMutation } from '@flyward/assets/store'
import { type SubmitHandler, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { formatAxiosErrorMessage } from '@flyward/platform/helpers/ErrorHelpers'
import { showError } from '@flyward/platform/services'

interface IEngineDetailsProps {
  engine: AircraftEngineDto
  engineIndexDisplay?: number
}

const EngineDetails = ({ engine, engineIndexDisplay }: IEngineDetailsProps) => {
  const { registerFormRef } = useAssetComponentsForms()
  const submitRef = useRef<HTMLInputElement>(null)
  const resetRef = useRef<HTMLInputElement>(null)
  const [updateEngine] = useUpdateEngineMutation()

  const form = useForm<AircraftEngineDto>({
    defaultValues: engine,
    values: engine,
    resolver: zodResolver(AircraftEngineDtoSchema),
    mode: 'all',
    reValidateMode: 'onChange',
  })

  const {
    register: registerComponentFormField,
    unregister: unregisterComponentFormField,
    handleSubmit: handleComponentUpdate,
    formState,
    getValues: getComponentFormValues,
    setValue: setComponentFormValue,
    control: componentFormControl,
    trigger: triggerComponentValidation,
  } = form

  const componentFormValues: AircraftEngineDto = getComponentFormValues()

  const { isDirty, isValid } = formState

  if (isDirty && !isValid) {
    console.log('Engine formState.errors', formState.errors)
  }

  useEffect(() => {
    triggerComponentValidation()
  }, [isValid, triggerComponentValidation])

  useEffect(() => {
    registerFormRef({ id: [componentFormValues.assetId, componentFormValues.componentId], submitRef, resetRef, hasChanges: isDirty, isValid })
  }, [registerFormRef, componentFormValues, isDirty, isValid])

  const onUpdateComponent: SubmitHandler<AircraftEngineDto> = async (data) => {
    const result = await updateEngine({ assetId: componentFormValues.assetId, engine: data })
    if (!isNil(result.error)) {
      showError(formatAxiosErrorMessage(result.error?.message))
    }
  }

  let lastPerformanceRestoration: ComponentUtilizationAtEventDto | null = null
  const performanceRestorationReserves = componentFormValues.engineContract.contractualFlightHoursToFlightCyclesPerformanceRestorationReserves

  const eprs = componentFormValues.assetComponentUtilizationSnapshot.componentUtilizationAtEvents.filter((e) => e.eventType === CheckType.Epr)
  const lastEpr = maxBy(eprs, (e) => e.totalHoursSinceNewAtLastQualifyingEvent)
  if (!isNil(lastEpr)) {
    lastPerformanceRestoration = lastEpr
  }

  return (
    <CnForm {...form}>
      <form onSubmit={handleComponentUpdate(onUpdateComponent)}>
        <ComponentContainer className="p-6">
          <div className="flex w-full flex-col">
            <p className="mb-4 w-full border-b border-primary-light-2 pb-2 text-base font-semibold text-text-1">Engine {engineIndexDisplay}</p>
            <div className="flex w-full flex-col gap-2">
              <EngineDetailsGeneral engine={componentFormValues} setFormValue={setComponentFormValue} formControl={componentFormControl} />
              <EngineDetailsTabs
                lastPerformanceRestoration={lastPerformanceRestoration}
                performanceRestorationReserves={performanceRestorationReserves}
                registerFormField={registerComponentFormField}
                unRegisterFormField={unregisterComponentFormField}
                setFormValue={setComponentFormValue}
                formControl={componentFormControl}
                formState={formState}
                componentFormValues={componentFormValues}
                triggerComponentValidation={triggerComponentValidation}
              />
            </div>
          </div>
        </ComponentContainer>
        <input type="submit" className="hidden" ref={submitRef} />
        <input type="reset" className="hidden" ref={resetRef} onClick={() => form.reset()} />
      </form>
    </CnForm>
  )
}

export { EngineDetails }
export type { IEngineDetailsProps }
