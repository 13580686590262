import { type AssetSearchDTO } from '@flyward/assets'
import { ComponentContainer, ErrorBoundary, ReportDisplayType } from '@flyward/platform'
import { setSelectedAssetId, setSuccessfulReportId, useFlyForwardDefaultParametersPrefetch } from '@flyward/platform/store'
import { useAppDispatch, useAppSelector } from '@flyward/platform/store/configureHooks'
import {
  selectUnsortedReportAssets,
  selectSelectedAssetId,
  selectFilteredAssetsData,
  selectReportDisplayType,
} from '@flyward/platform/store/slices/features/flyForward/flyForwardSelectors'
import React, { useEffect } from 'react'
import { FlyForwardIndividualAssetReport } from './IndividualAssetReport'
import { FlyForwardAssetsSelection } from './FlyForwardAssetsSelection'
import { useSearchParams } from 'react-router-dom'
import { isNil } from 'lodash'
import { FlyForwardPortfolioReport } from './PortfolioReport/FlyForwardPortfolioReport'

const FlyForwardPageBody = () => {
  const dispatch = useAppDispatch()

  const filteredAssetsData = useAppSelector(selectFilteredAssetsData)
  const reportAssets = useAppSelector(selectUnsortedReportAssets)
  const selectedAssetId = useAppSelector(selectSelectedAssetId)
  const reportDisplayType = useAppSelector(selectReportDisplayType)

  const prefetchGetAllAssetDefaultFlyForwardParameters = useFlyForwardDefaultParametersPrefetch('getFlyForwardDefaultParameters')

  const [searchParams] = useSearchParams()

  const initialParams: string[] = searchParams.getAll('assetIds')

  const selectedAsset = filteredAssetsData?.items.find((asset: AssetSearchDTO) => asset.assetId === selectedAssetId?.id)

  useEffect(() => {
    dispatch(setSelectedAssetId({ assetId: { id: initialParams?.[0], index: 0 } }))
    dispatch(setSuccessfulReportId({ reportId: '' }))

    for (const assetId of initialParams) {
      prefetchGetAllAssetDefaultFlyForwardParameters({ assetId })
    }
    // intended for [] so that we 100% run it only once
  }, [])

  const currentReportAsset = reportAssets.find(
    (reportAsset) => !isNil(selectedAsset) && selectedAssetId?.id === reportAsset.assetId.id && selectedAssetId.index === reportAsset.assetId.index,
  )

  return (
    <>
      <ComponentContainer className="p-6">
        <FlyForwardAssetsSelection />
      </ComponentContainer>
      {reportDisplayType === ReportDisplayType.AssetReport && !isNil(selectedAsset) && !isNil(currentReportAsset) && (
        <ErrorBoundary>
          <FlyForwardIndividualAssetReport currentAssetData={currentReportAsset} selectedAsset={selectedAsset} />
        </ErrorBoundary>
      )}
      {reportDisplayType === ReportDisplayType.PortfolioReport && (
        <ErrorBoundary>
          <FlyForwardPortfolioReport />
        </ErrorBoundary>
      )}
    </>
  )
}

export { FlyForwardPageBody }
