import { useEffect, useState } from 'react'
import { Box, TextField, Typography } from '@mui/material'
import { NumericFormat } from 'react-number-format'
import { isEmpty, isNil } from 'lodash'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { dateToIsoString } from '@flyward/platform/helpers/dateHelpers'
import { LLPTable } from '../common/LLPTable'
import { LLPTableRowData } from '../common/types'
import { EngineFormData } from './Engines'
import { UseFormRegister } from 'react-hook-form'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'

const MIN_VALUE = 0
const MAX_VALUE = 1000000000
const MAX_VALUE_PERCENTAGE = 99

interface EngineProps {
  engineIndex: number
  engineData: EngineFormData
  register: UseFormRegister<any>
  setValue: (_field: string, _value: any) => void
  touchedFields: any
  errors: any
  isAiPopulated?: boolean
  areRequiredFieldsMissing?: boolean
}

export const EngineComponent = ({ engineIndex, engineData, setValue, register, touchedFields, errors, isAiPopulated, areRequiredFieldsMissing }: EngineProps) => {
  const maxIndex = Math.max(...engineData.llps.map((row) => row.id), 0)

  const alwaysShowErrors = isAiPopulated || areRequiredFieldsMissing

  const EmptyRow: LLPTableRowData = {
    id: maxIndex + 1,
    description: '',
  } as const

  const [formValues, setFormValues] = useState(engineData)

  const setIsLLPTableValid = (isValid: boolean) => {
    if (engineData.isLLPTableValid !== isValid) {
      setValue('isLLPTableValid', isValid)
    }
  }

  useEffect(() => {
    setFormValues(engineData)
  }, [engineData])

  const handleAddRow = () => {
    setValue('llps', [...formValues.llps, EmptyRow])
  }

  const handleRowChange = (id: number, field: keyof LLPTableRowData, value: string) => {
    const llpsUpdatedValues = formValues.llps.map((row) => {
      if (row.id === id) {
        return { ...row, [field]: value }
      }
      return row
    })

    setValue('llps', llpsUpdatedValues)
  }

  const handleDeleteRow = (id: number) => {
    const llpsUpdatedValues = formValues.llps.filter((row) => row.id !== id)

    setValue('llps', llpsUpdatedValues)
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
      <div>
        <Typography variant="subtitle1">General</Typography>
      </div>

      <Box sx={{ display: 'flex', gap: 2 }}>
        <TextField
          {...register(`items.${engineIndex}.general.sn`)}
          label="ESN *"
          value={formValues?.general?.sn ?? ''}
          onChange={(e) => setValue('general.sn', e.target.value)}
          error={(touchedFields?.general?.sn || alwaysShowErrors) && !!errors?.general?.sn}
          helperText={(touchedFields?.general?.sn || alwaysShowErrors) && errors?.general?.sn?.message}
          sx={{ flex: 1 }}
        />

        <DatePicker
          label="DOM"
          value={!isNil(formValues?.general?.dom) && !isEmpty(formValues?.general?.dom) ? new Date(formValues?.general?.dom) : undefined}
          onChange={(newValue) => {
            const isoFormat = isNil(newValue) ? undefined : dateToIsoString(newValue)
            setValue('general.dom', isoFormat)
          }}
          slotProps={{
            field: {
              clearable: true,
              onClear: () => {
                setValue('general.dom', undefined)
              },
            },
            textField: {
              sx: { flex: 1 },
              error: (touchedFields?.general?.dom || alwaysShowErrors) && !!errors?.general?.dom,
            },
          }}
        />
      </Box>

      <Box sx={{ display: 'flex', gap: 2 }}>
        <TextField
          {...register(`items.${engineIndex}.general.manufacturer`)}
          label="Manufacturer"
          value={formValues?.general?.manufacturer ?? ''}
          error={(touchedFields?.general?.manufacturer || alwaysShowErrors) && !!errors?.general?.manufacturer}
          helperText={(touchedFields?.general?.manufacturer || alwaysShowErrors) && errors?.general?.manufacturer}
          sx={{ flex: 1 }}
        />
        <TextField
          {...register(`items.${engineIndex}.general.model`)}
          label="Model"
          value={formValues?.general?.model ?? ''}
          error={(touchedFields?.general?.model || alwaysShowErrors) && !!errors?.general?.model}
          helperText={(touchedFields?.general?.model || alwaysShowErrors) && errors?.general?.model}
          sx={{ flex: 1 }}
        />
      </Box>
      <Box sx={{ display: 'flex', gap: 2 }}>
        <NumericFormat
          customInput={TextField}
          InputProps={{ startAdornment: '%' }}
          label="Derate"
          value={formValues?.general?.derate}
          error={(touchedFields?.general?.derate || alwaysShowErrors) && !!errors?.general?.derate}
          helperText={(touchedFields?.general?.derate || alwaysShowErrors) && errors?.general?.derate}
          thousandSeparator
          decimalScale={2}
          allowNegative={false}
          isAllowed={({ floatValue }) => floatValue === undefined || (floatValue >= MIN_VALUE && floatValue <= MAX_VALUE_PERCENTAGE)}
          onValueChange={({ floatValue }) => {
            setValue('general.derate', floatValue!)
          }}
          onBlur={() => {
            if (!touchedFields?.general?.derate) {
              setValue('general.derate', formValues?.general?.derate)
            }
          }}
          sx={{ flex: 1 }}
        />
        <Box sx={{ flex: 1 }} />
      </Box>

      <Typography variant="subtitle1">PR Technical</Typography>

      <Box sx={{ display: 'flex', gap: 2 }}>
        <NumericFormat
          customInput={TextField}
          label="TSN"
          value={formValues?.pr?.tsn ?? ''}
          error={(touchedFields?.pr?.tsn || alwaysShowErrors) && !!errors?.pr?.tsn}
          helperText={(touchedFields?.pr?.tsn || alwaysShowErrors) && errors?.pr?.tsn}
          thousandSeparator
          decimalScale={0}
          allowNegative={false}
          isAllowed={({ floatValue }) => floatValue === undefined || (floatValue >= MIN_VALUE && floatValue <= MAX_VALUE)}
          onValueChange={({ floatValue }) => {
            setValue('pr.tsn', floatValue!)
          }}
          sx={{ flex: 1 }}
        />

        <NumericFormat
          customInput={TextField}
          label="CSN"
          value={formValues?.pr?.csn ?? ''}
          error={(touchedFields?.pr?.csn || alwaysShowErrors) && !!errors?.pr?.csn}
          helperText={(touchedFields?.pr?.csn || alwaysShowErrors) && errors?.pr?.csn}
          thousandSeparator
          decimalScale={0}
          allowNegative={false}
          isAllowed={({ floatValue }) => floatValue === undefined || (floatValue >= MIN_VALUE && floatValue <= MAX_VALUE)}
          onValueChange={({ floatValue }) => {
            setValue('pr.csn', floatValue!)
          }}
          sx={{ flex: 1 }}
        />
      </Box>

      <Box sx={{ display: 'flex', gap: 2 }}>
        <NumericFormat
          customInput={TextField}
          label="TSN @ Last PR"
          value={formValues?.pr?.tsnLastPr ?? ''}
          error={(touchedFields?.pr?.tsnLastPr || alwaysShowErrors) && !!errors?.pr?.tsnLastPr}
          helperText={(touchedFields?.pr?.tsnLastPr || alwaysShowErrors) && errors?.pr?.tsnLastPr}
          thousandSeparator
          decimalScale={0}
          allowNegative={false}
          isAllowed={({ floatValue }) => floatValue === undefined || (floatValue >= MIN_VALUE && floatValue <= MAX_VALUE)}
          onValueChange={({ floatValue }) => {
            setValue('pr.tsnLastPr', floatValue!)
          }}
          sx={{ flex: 1 }}
        />

        <NumericFormat
          customInput={TextField}
          label="CSN @ Last PR"
          value={formValues?.pr?.csnLastPr ?? ''}
          error={(touchedFields?.pr?.csnLastPr || alwaysShowErrors) && !!errors?.pr?.csnLastPr}
          helperText={(touchedFields?.pr?.csnLastPr || alwaysShowErrors) && errors?.pr?.csnLastPr}
          thousandSeparator
          decimalScale={0}
          allowNegative={false}
          isAllowed={({ floatValue }) => floatValue === undefined || (floatValue >= MIN_VALUE && floatValue <= MAX_VALUE)}
          onValueChange={({ floatValue }) => {
            setValue('pr.csnLastPr', floatValue!)
          }}
          sx={{ flex: 1 }}
        />
      </Box>

      <Box sx={{ display: 'flex', gap: 2 }}>
        <DatePicker
          label="Date @ Last PR"
          value={!isNil(formValues?.pr?.dateLastPr) && !isEmpty(formValues?.pr?.dateLastPr) ? new Date(formValues?.pr?.dateLastPr) : undefined}
          onChange={(newValue) => {
            const isoFormat = isNil(newValue) ? undefined : dateToIsoString(newValue)
            setValue('pr.dateLastPr', isoFormat)
          }}
          slotProps={{
            field: {
              clearable: true,
              onClear: () => {
                setValue('pr.dateLastPr', undefined)
              },
            },
            textField: {
              sx: { flex: 1 },
              error: (touchedFields?.pr?.dateLastPr || alwaysShowErrors) && !!errors?.pr?.dateLastPr,
            },
          }}
        />
        <NumericFormat
          customInput={TextField}
          label="Hours since PR at Contract Delivery"
          value={formValues?.pr?.hoursSinceEventAtContractDelivery ?? ''}
          error={(touchedFields?.pr?.hoursSinceEventAtContractDelivery || alwaysShowErrors) && !!errors?.pr?.hoursSinceEventAtContractDelivery}
          helperText={(touchedFields?.pr?.hoursSinceEventAtContractDelivery || alwaysShowErrors) && errors?.pr?.hoursSinceEventAtContractDelivery}
          thousandSeparator
          decimalScale={0}
          allowNegative={false}
          isAllowed={({ floatValue }) => floatValue === undefined || (floatValue >= MIN_VALUE && floatValue <= MAX_VALUE)}
          onValueChange={({ floatValue }) => {
            setValue('pr.hoursSinceEventAtContractDelivery', floatValue!)
          }}
          sx={{ flex: 1 }}
        />{' '}
      </Box>

      <div>
        <Typography variant="subtitle1">LLP Technical</Typography>

        {formValues?.isLLPTableValid === false && (
          <Typography color="error">
            <ErrorOutlineIcon fontSize="small" color="error" />
            Please fix the LLP table errors
          </Typography>
        )}
      </div>

      <LLPTable
        rows={formValues.llps}
        onRowChange={handleRowChange}
        onAddRow={handleAddRow}
        onDeleteRow={handleDeleteRow}
        onValidationChange={setIsLLPTableValid}
        isAiPopulated={isAiPopulated}
      />
    </Box>
  )
}
