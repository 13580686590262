import { Box, Button, TextField, Typography } from '@mui/material'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import { validateRequiredNaturalNumber, validateNullableNaturalNumber } from '@flyward/platform/helpers/inputValidators'

const basicAssumptionsSchema = z.object({
  checkDowntime: validateRequiredNaturalNumber('Check Downtime', 1),
  delayInPayment: validateNullableNaturalNumber,
})

type BasicAssumptionsFormData = z.infer<typeof basicAssumptionsSchema>

interface BasicAssumptionsProps {
  onNext: (_data: BasicAssumptionsFormData) => void
  onBack: () => void | undefined
  stepTitle: string
  initialData?: {
    checkDowntime: number
    delayInPayment?: number
  }
}

const BasicAssumptions = ({ onNext, onBack, stepTitle, initialData }: BasicAssumptionsProps) => {
  const {
    register,
    formState: { errors, touchedFields },
    trigger,
    getValues,
  } = useForm<BasicAssumptionsFormData>({
    resolver: zodResolver(basicAssumptionsSchema),
    mode: 'all',
    defaultValues: {
      checkDowntime: initialData?.checkDowntime ?? 1,
      delayInPayment: initialData?.delayInPayment,
    },
  })

  const hasValidationErrors = !!errors.checkDowntime || !!errors.delayInPayment
  const isButtonDisabled = hasValidationErrors && (touchedFields.checkDowntime || touchedFields.delayInPayment)

  const handleContinue = async () => {
    const isValidForm = await trigger()
    if (isValidForm) {
      const values = getValues()
      onNext(values)
    }
  }

  return (
    <div className="shadow-sm w-150 rounded-lg bg-white p-6">
      <Typography variant="h6" sx={{ mb: 3 }}>
        {stepTitle}
      </Typography>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 3,
          mb: 4,
        }}
      >
        <TextField
          {...register('checkDowntime')}
          error={touchedFields.checkDowntime && !!errors.checkDowntime}
          label="Check Downtime (Months)"
          helperText={touchedFields.checkDowntime && errors.checkDowntime?.message}
          inputProps={{ inputMode: 'numeric', min: 1 }}
          defaultValue={1}
        />
        <TextField
          {...register('delayInPayment')}
          error={touchedFields.delayInPayment && !!errors.delayInPayment}
          label="Delay in Payment on Claims (Months)"
          helperText={touchedFields.delayInPayment && errors.delayInPayment?.message}
          inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
        />
      </Box>

      <Box>
        <Button variant="contained" onClick={handleContinue} disabled={isButtonDisabled} sx={{ mr: 1 }}>
          Continue
        </Button>
        <Button variant="text" onClick={onBack}>
          Back
        </Button>
      </Box>
    </div>
  )
}

export { BasicAssumptions }
