import { isArray, isEmpty, isNil } from 'lodash'
import { z } from 'zod'

const refineNaturalNumber = {
  check: (value: string | number | null): boolean => {
    const stringValue = (value as string) ?? ''
    if (isEmpty(stringValue)) {
      return true
    }
    return /^\d+$/.test(stringValue)
  },
  message: 'Field should contain only natural numbers',
}

const refineIntegerNumber = {
  check: (value: string | number | null): boolean => {
    const stringValue = (value as string) ?? ''
    if (isEmpty(stringValue)) {
      return true
    }
    return /^-?\d+$/.test(stringValue) // Allows positive and negative integers
  },
  message: 'Field should contain only integer numbers',
}

const refineDecimalNumber = {
  check: (value: string | number | null): boolean => {
    const stringValue = (value as string) ?? ''
    if (isEmpty(stringValue)) {
      return true
    }
    return /^-?\d+(\.\d+)?$/.test(stringValue) // Allows positive and negative decimal numbers
  },
  message: 'Field should contain only decimal numbers',
}

const refinePositiveDecimalNumber = {
  check: (value: string | number | null): boolean => {
    const stringValue = (value as string) ?? ''
    if (isEmpty(stringValue)) {
      return true
    }
    return /^\d+(\.\d+)?$/.test(stringValue) // Allows only positive decimal numbers
  },
  message: 'Field should contain only positive decimal numbers',
}

const refineRequired = {
  check: (value: string | number | null): boolean => {
    const stringValue: string = value?.toString() ?? ''
    return !isEmpty(stringValue)
  },
  message: (field: string) => `${field} is required`,
}

const transformToNumber = (value: string | null): number | undefined => {
  if (isNil(value) || value === '') {
    return undefined
  }
  const parsedValue = parseFloat(value ?? '')
  return isNaN(parsedValue) ? 0 : parsedValue
}

export const transformCommaSeparatedStringToArray = (value: string): string[] => {
  if (isArray(value)) {
    return value
  }

  if (isNil(value) || isEmpty(value)) {
    return []
  }
  return value.split(',').map((item) => item.trim())
}

export const validateRequiredNaturalNumber = (field: string, minValue: number = 0) =>
  z
    .any()
    .refine(refineRequired.check, {
      message: refineRequired.message(field),
    })
    .refine(refineNaturalNumber.check, {
      message: refineNaturalNumber.message,
    })
    .transform(transformToNumber)
    .refine(
      (value: number | undefined): boolean => {
        const parsedValue: number = value ?? 0
        return parsedValue >= minValue
      },
      { message: `${field} should be greater or equal to ${minValue}` },
    )

export const validateNullableNaturalNumber = z
  .any()
  .optional()
  .nullable()
  .refine(refineNaturalNumber.check, {
    message: refineNaturalNumber.message,
  })
  .transform(transformToNumber)

export const validateRequiredPositiveDecimalNumber = (field: string) =>
  z
    .any()
    .refine(refineRequired.check, {
      message: refineRequired.message(field),
    })
    .refine(refinePositiveDecimalNumber.check, {
      message: refinePositiveDecimalNumber.message,
    })
    .transform(transformToNumber)

export const validateRequiredDecimalNumber = (field: string) =>
  z
    .any()
    .refine(refineRequired.check, {
      message: refineRequired.message(field),
    })
    .refine(refineDecimalNumber.check, {
      message: refineDecimalNumber.message,
    })
    .transform(transformToNumber)

export const validateRequiredIntegerNumber = (field: string) =>
  z
    .any()
    .refine(refineRequired.check, {
      message: refineRequired.message(field),
    })
    .refine(refineIntegerNumber.check, {
      message: refineIntegerNumber.message,
    })
    .transform(transformToNumber)

export const validateNullableDecimalNumber = z
  .any()
  .optional()
  .nullable()
  .refine(refineDecimalNumber.check, {
    message: refineDecimalNumber.message,
  })
  .transform(transformToNumber)

export const validateNullablePositiveDecimalNumber = z
  .any()
  .optional()
  .nullable()
  .refine(refinePositiveDecimalNumber.check, {
    message: refinePositiveDecimalNumber.message,
  })
  .transform(transformToNumber)

export const validateRequiredString = (field: string) =>
  z.any().refine(refineRequired.check, {
    message: refineRequired.message(field),
  })

export const validateNullableDate = z
  .any()
  .optional()
  .nullable()
  .transform((value) => (isEmpty(value?.toString()) ? undefined : value))

export const validateRequiredDate = (field: string) =>
  z
    .string()
    .optional()
    .nullable()
    .refine(refineRequired.check, {
      message: refineRequired.message(field),
    })
    .transform((value) => (isEmpty(value?.toString()) ? undefined : value))
