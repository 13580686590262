import { IconButton, Link, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import { useState } from 'react'
import { NumericFormat } from '../common/NumericFormat'
import { TableRowData } from './types'

interface ChecksTableProps {
  rows: TableRowData[]
  onRowChange: (_id: number, _field: keyof TableRowData, _value: string) => void
  onAddRow: () => void
  onDeleteRow: (_id: number) => void
}

const errorStyle = {
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'red',
    },
    '&:hover fieldset': {
      borderColor: 'red',
    },
  },
}

export const ChecksTable = ({ rows, onRowChange, onDeleteRow }: ChecksTableProps) => {
  const [touchedFields, setTouchedFields] = useState<Record<number, Record<string, boolean>>>({})

  const handleBlur = (rowId: number, field: string) => {
    setTouchedFields((prev) => ({
      ...prev,
      [rowId]: {
        ...(prev[rowId] || {}),
        [field]: true,
      },
    }))
  }

  const checkDuplicateLimit = (currentId: number, limit: string): boolean => {
    if (!limit.trim()) return false
    const normalizedLimit = parseFloat(limit).toString()
    return rows.some((row) => row.id !== currentId && parseFloat(row.limit).toString() === normalizedLimit)
  }

  const hasError = (rowId: number, field: string, value: string): boolean => {
    if (!touchedFields[rowId]?.[field]) return false
    if (field === 'limit') {
      return !value || checkDuplicateLimit(rowId, value)
    }
    return !value
  }

  return (
    <>
      <Table size={'small'}>
        <TableHead>
          <TableRow sx={{ '& th': { borderBottom: '1px solid rgba(0, 0, 0, 0.12)', backgroundColor: 'transparent' } }}>
            <TableCell sx={{ whiteSpace: 'nowrap' }}>Event Type</TableCell>
            <TableCell sx={{ whiteSpace: 'nowrap' }}>Unit</TableCell>
            <TableCell sx={{ whiteSpace: 'nowrap' }}>Limit [APU Hours] *</TableCell>
            <TableCell sx={{ whiteSpace: 'nowrap' }}>Cost *</TableCell>
            <TableCell width={50} />
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.id} className="[&>td]:border-none">
              <TableCell>
                <Typography sx={{ whiteSpace: 'nowrap' }}>PR</Typography>
              </TableCell>
              <TableCell>
                <Typography sx={{ whiteSpace: 'nowrap' }}>APU Hours</Typography>
              </TableCell>
              <TableCell>
                <TextField
                  fullWidth
                  value={row.limit}
                  onBlur={() => handleBlur(row.id, 'limit')}
                  error={hasError(row.id, 'limit', row.limit)}
                  sx={hasError(row.id, 'limit', row.limit) ? errorStyle : {}}
                  InputProps={{
                    inputComponent: NumericFormat as any,
                  }}
                  onChange={(e) => onRowChange(row.id, 'limit', e.target.value)}
                />
              </TableCell>
              <TableCell>
                <TextField
                  fullWidth
                  value={row.cost}
                  onBlur={() => handleBlur(row.id, 'cost')}
                  error={hasError(row.id, 'cost', row.cost)}
                  sx={hasError(row.id, 'cost', row.cost) ? errorStyle : {}}
                  InputProps={{
                    inputComponent: NumericFormat as any,
                  }}
                  onChange={(e) => onRowChange(row.id, 'cost', e.target.value)}
                />
              </TableCell>
              <TableCell width={50}>
                {rows.length > 1 && (
                  <IconButton onClick={() => onDeleteRow(row.id)} size="small">
                    <DeleteIcon />
                  </IconButton>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {/* <Link
        component="button"
        variant="body2"
        onClick={onAddRow}
        sx={{
          mt: 2,
          textDecoration: 'none',
          '&:hover': {
            textDecoration: 'none',
          },
        }}
      >
        + Add another row
      </Link> */}
    </>
  )
}
