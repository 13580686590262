import { type ManualEventDto, type IMaintenanceScheduleDisplay } from '@flyward/forecasts/models'
import { useReScheduleWithManualEventMutation } from '@flyward/forecasts/store'
import { type CheckType, type AssetType, isEngineCheck, getEngineChecks, CheckTypeTableDisplay, YearMonth } from '@flyward/platform/models'
import { ActionDialog, type IYearMonth, YearMonthSelector } from '@flyward/platform/components'
import { type Row } from '@tanstack/react-table'
import { useEffect, useMemo, useState } from 'react'
import { useAppDispatch } from '@flyward/platform/store/configureHooks'
import { setAssetFlyResults } from '@flyward/platform/store'
import { isNil } from 'lodash'
import { useSpinnerState } from '@flyward/platform/contexts'
import { showError } from '@flyward/platform/services'
import { formatAxiosErrorMessage } from '@flyward/platform/helpers/ErrorHelpers'
import { errorMessages } from '@flyward/platform'

interface ISetManualEventProps {
  assetType: AssetType
  reportItemId: string
  index: number
  assetSchedules: IMaintenanceScheduleDisplay[]
  startYearMonth: IYearMonth
  endYearMonth: IYearMonth
  row: Row<IMaintenanceScheduleDisplay>
  isEditConfirm: boolean
  setIsEditConfirm: (_value: boolean) => void
}

const MoveManualEvent = ({ assetType, reportItemId, index, assetSchedules, startYearMonth, endYearMonth, row, isEditConfirm, setIsEditConfirm }: ISetManualEventProps) => {
  const { showSpinner, hideSpinner } = useSpinnerState()

  const dispatch = useAppDispatch()
  const [reScheduleWithManualEvent] = useReScheduleWithManualEventMutation()

  const startDateDisplay = `(${YearMonth.fromYearMonthOrIYearMonth(startYearMonth).toShortString()})`
  const endDateDisplay = `(${YearMonth.fromYearMonthOrIYearMonth(endYearMonth).toShortString()})`

  const editedMasterComponentId = row.original.forecastedCheck.masterComponentId ?? row.original.forecastedCheck.componentId
  const currentCheckType = row.original.forecastedCheck.checkType
  const originalYearMonth = row.original.yearMonth as IYearMonth
  const originalEventMonth = row.original.eventMonth
  const kbDowntime = row.original.forecastedCheck.kbDowntime

  const [checkTypesToConsider, setCheckTypesToConsider] = useState<CheckType[]>([currentCheckType])

  const [manualEvent, setManualEvent] = useState<ManualEventDto>({
    masterComponentId: editedMasterComponentId,
    affectedCheckTypes: checkTypesToConsider,
    originalYearMonth,
    changedYearMonth: originalYearMonth,
    originalEventMonth,
  })

  const componentEventsBeforeModifiedEvent: IMaintenanceScheduleDisplay[] = assetSchedules.filter((t) => {
    const eventYearMonth = t.yearMonth as IYearMonth
    return (
      (t.forecastedCheck.masterComponentId === editedMasterComponentId || t.forecastedCheck.componentId === editedMasterComponentId) &&
      (eventYearMonth.year < originalYearMonth.year || (eventYearMonth.year === originalYearMonth.year && eventYearMonth.month < originalYearMonth.month))
    )
  })

  const lastComponentEventBeforeModifiedEvent = componentEventsBeforeModifiedEvent[componentEventsBeforeModifiedEvent.length - 1]

  // eslint-disable-next-line max-len
  const lastComponentEventBeforeModifiedEventDisplay = `(${!isNil(lastComponentEventBeforeModifiedEvent) && YearMonth.fromYearMonthOrIYearMonth(lastComponentEventBeforeModifiedEvent.yearMonth).toShortString()})`

  const componentCheckTypesForModifiedMonth = assetSchedules
    .filter((t) => {
      const eventYearMonth = t.yearMonth as IYearMonth
      return (
        eventYearMonth.year === originalYearMonth.year &&
        eventYearMonth.month === originalYearMonth.month &&
        (t.forecastedCheck.masterComponentId === editedMasterComponentId || t.forecastedCheck.componentId === editedMasterComponentId)
      )
    })
    .map((t) => t.forecastedCheck.checkType)

  const isFullEngineCheck = componentCheckTypesForModifiedMonth.filter((checkType) => isEngineCheck(checkType)).length === getEngineChecks().length

  // for now, we support only the case when all engine events are moved
  // so user doesn't need to confirm
  const [mustConfirmFullEngineCheck, setMustConfirmFullEngineCheck] = useState<boolean>(false)
  // force to move all engine checks
  const [moveAllEngineEvents, setMoveAllEngineEvents] = useState<boolean>(true)

  useEffect(() => {
    if (moveAllEngineEvents && isFullEngineCheck) {
      setCheckTypesToConsider(componentCheckTypesForModifiedMonth)
    }
  }, [componentCheckTypesForModifiedMonth, isFullEngineCheck, moveAllEngineEvents])

  useEffect(() => {
    setManualEvent((prevState) => ({ ...prevState, affectedCheckTypes: checkTypesToConsider }))
  }, [checkTypesToConsider])

  const isManualEventModifiedDateAfterLastEvent = useMemo(() => {
    const isAfter = isEngineCheck(currentCheckType)
      ? componentEventsBeforeModifiedEvent.filter(
          (t: IMaintenanceScheduleDisplay) => YearMonth.fromObject(t.yearMonth as IYearMonth).compareTo(YearMonth.fromObject(manualEvent.changedYearMonth)) > 0,
        )
      : componentEventsBeforeModifiedEvent.filter(
          (t: IMaintenanceScheduleDisplay) =>
            // only engine can have multiple related check types for the same component
            t.forecastedCheck.checkType === currentCheckType && YearMonth.fromObject(t.yearMonth as IYearMonth).compareTo(YearMonth.fromObject(manualEvent.changedYearMonth)) > 0,
        )

    return isAfter.length === 0
  }, [componentEventsBeforeModifiedEvent, currentCheckType, manualEvent.changedYearMonth])

  const isManualEventAfterStartDate = useMemo(() => {
    return YearMonth.fromObject(startYearMonth).addMonths(1).compareTo(YearMonth.fromObject(manualEvent.changedYearMonth)) <= 0
  }, [startYearMonth, manualEvent])

  const isManualEventBeforeEndDatePlusDowntime = useMemo(() => {
    return YearMonth.fromObject(endYearMonth).addMonths(-kbDowntime).compareTo(YearMonth.fromObject(manualEvent.changedYearMonth)) >= 0
  }, [endYearMonth, kbDowntime, manualEvent.changedYearMonth])

  const isManualEventDifferentFromOriginal = useMemo(() => {
    return manualEvent.originalYearMonth.year !== manualEvent.changedYearMonth.year || manualEvent.originalYearMonth.month !== manualEvent.changedYearMonth.month
  }, [manualEvent])

  return (
    <>
      {mustConfirmFullEngineCheck && (
        <ActionDialog
          isValid={true}
          confirmBtnLabel="Yes, move both"
          cancelBtnLabel={`No, move just ${CheckTypeTableDisplay(currentCheckType)}`}
          key={`conform-full-engine-${editedMasterComponentId}-${currentCheckType}-${originalYearMonth.year}-${originalYearMonth.month}`}
          onConfirm={async () => {
            setMoveAllEngineEvents(true)
          }}
          isOpen={mustConfirmFullEngineCheck}
          setIsOpen={setMustConfirmFullEngineCheck}
          dialogContent={
            <p className="font-semibold">
              This event includes both PR and LLPs replacement.
              <br />
              Do you want to move both events?
            </p>
          }
        />
      )}
      {!mustConfirmFullEngineCheck && (
        <ActionDialog
          isValid={isManualEventModifiedDateAfterLastEvent && isManualEventAfterStartDate && isManualEventBeforeEndDatePlusDowntime && isManualEventDifferentFromOriginal}
          confirmBtnLabel="Update"
          key={`set-event-${editedMasterComponentId}-${currentCheckType}-${originalYearMonth.year}-${originalYearMonth.month}`}
          onConfirm={async () => {
            showSpinner(0)
            const newSchedule = await reScheduleWithManualEvent({ manualEvent, reportItemId, assetType })

            if (!isNil(newSchedule.error)) {
              hideSpinner(0)
              showError(formatAxiosErrorMessage(newSchedule.error.message, errorMessages.forecasting.manualEventError))
            }

            hideSpinner(2)
            if (!isNil(newSchedule.data)) {
              dispatch(
                setAssetFlyResults({
                  assetId: { id: newSchedule.data.assetId, index },
                  assetComponentsMonthlyStatistics: newSchedule.data.assetComponentsMonthlyStatistics,
                  eventSchedule: newSchedule.data.outputSchedules,
                  reportItemId: newSchedule.data.id,
                  reportItemStatus: newSchedule.data.status,
                }),
              )
            }
          }}
          isOpen={isEditConfirm}
          setIsOpen={setIsEditConfirm}
          dialogContent={
            <div className="flex flex-col gap-2 pt-2">
              <p className="pb-2 !text-sm font-medium">
                {isEngineCheck(currentCheckType) && !isFullEngineCheck && `${CheckTypeTableDisplay(currentCheckType)} only Event`}
                {isFullEngineCheck &&
                  `${getEngineChecks()
                    .map((checkType) => CheckTypeTableDisplay(checkType))
                    .join(' and ')} Event`}
              </p>
              <YearMonthSelector
                value={row.original.yearMonth as IYearMonth}
                setValue={(newValue: IYearMonth) => {
                  setManualEvent((prevState) => ({ ...prevState, changedYearMonth: newValue }))
                }}
                yearsInterval={{ startYear: startYearMonth.year, endYear: endYearMonth.year }}
              />
              {!isManualEventModifiedDateAfterLastEvent && (
                <p className="pt-2 !text-sm font-medium text-error">Can&apos;t be before a previous event for the same component {lastComponentEventBeforeModifiedEventDisplay}</p>
              )}
              {!isManualEventAfterStartDate && <p className="pt-2 !text-sm font-medium text-error">Must be after the start date {startDateDisplay}</p>}
              {!isManualEventBeforeEndDatePlusDowntime && (
                <>
                  <p className="pt-2 !text-sm font-medium text-error">Must be before the end date {endDateDisplay}</p>
                  {kbDowntime > 0 && (
                    <p className="pt-0 !text-sm font-medium text-error">
                      And must allow {kbDowntime} {kbDowntime > 1 ? 'months' : 'month'} downtime
                    </p>
                  )}
                </>
              )}
              {!isManualEventDifferentFromOriginal && <p className="pt-2 !text-sm font-medium text-error">Can&apos;t be the same as the original event</p>}
            </div>
          }
        />
      )}
    </>
  )
}

export { MoveManualEvent }
