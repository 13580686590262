import { type ZitadelTokenResponse } from '@flyward/platform'
import { persistZitadelUserAccessToken, ZitadelInstance } from '@flyward/platform/services/AUTH'
import { setZitadelUserInformation } from '@flyward/platform/store'
import { useAppDispatch } from '@flyward/platform/store/configureHooks'
import { type ZitadelAuth } from '@zitadel/react'
import { type User } from 'oidc-client-ts'
import { useLayoutEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '@flyward/main-app/providers/routes'

export const AuthDone = () => {
  // TODO: this has to go away. no component should handle auth logic
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null)
  const [userInfo, setUserInfo] = useState<User | null>(null)
  const zitadel = useRef<ZitadelAuth>(ZitadelInstance.getInstance())

  useLayoutEffect(() => {
    if (isAuthenticated === null) {
      // TODO: without the following logic, login and redirect to /fleet doesn't work
      zitadel.current.userManager.signinRedirectCallback().then((user: User) => {
        if (user !== null) {
          setIsAuthenticated(true)
          setUserInfo(user)
          const tokenResponse: ZitadelTokenResponse = {
            accessToken: user.access_token,
          }
          dispatch(
            setZitadelUserInformation({
              user: {
                token: tokenResponse,
              },
            }),
          )
          persistZitadelUserAccessToken(tokenResponse)
          navigate(ROUTES.FLEET.ROOT)
        } else {
          setIsAuthenticated(false)
          navigate(ROUTES.AUTH.FAILED)
        }
      })
    } else if (isAuthenticated && userInfo === null) {
      zitadel.current.userManager.getUser().then((user) => {
        if (user !== null) {
          setIsAuthenticated(true)
          setUserInfo(user)
        } else {
          setIsAuthenticated(false)
        }
      })
    }
  }, [dispatch, isAuthenticated, setIsAuthenticated, userInfo])
  return <></>
}
