import { AircraftTechSpec } from '@flyward/assets'
import { AssetVerification } from '@flyward/assets/components/AircraftComponents/AircraftDetails/Verify'
import { cn, OutletTabs, useNavigationState, useSpinnerState } from '@flyward/platform'
import { formatAxiosErrorMessage } from '@flyward/platform/helpers/ErrorHelpers'
import { showError } from '@flyward/platform/services'
import {
  useGetAircraftDetailsQuery,
  useGetAllAirframesByAssetIdQuery,
  useGetAllAuxiliaryPowerUnitsByAssetIdQuery,
  useGetAllEnginesByAssetIdQuery,
  useGetAllLandingGearsByAssetIdQuery,
} from '@flyward/platform/store'
import { isEmpty, isNil } from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { pageContentStyles } from '../../../layout'
import { AircraftMaintenanceProgramsSection } from './AircraftMaintenanceProgramsSection'
import { isArrayEmptyOrNull } from '@flyward/platform/helpers/arrayHelpers'
import { ROUTES } from '@flyward/main-app/providers/routes.ts'
import queryString from 'query-string'
import { AuthorizedElement } from '@flyward/appIdentity'
import { useAssetComponentsForms } from '@flyward/assets/context'
import { AssetComponentsFormsProvider } from '@flyward/assets/context'
import { FWBreadcrumbs } from '@flyward/main-app/layout/MasterLayout/Header/FWBreadcrumbs'
import { Button as MuiButton } from '@mui/material'
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff'
import { EditIcon, SaveIcon } from 'lucide-react'

export const AircraftPage = () => (
  <AssetComponentsFormsProvider>
    <AircraftPageBody />
  </AssetComponentsFormsProvider>
)

const AircraftPageBody = () => {
  const [currentTab, setCurrentTab] = useState('Tech Spec')

  const onTabChanged = useCallback((activeTab: string) => {
    setCurrentTab(activeTab)
  }, [])

  const navigate = useNavigate()
  const { hasAnyChanges, isAllValid, isInEditMode, setIsInEditMode, getAllFormRefs, setHasErrors, hasErrors } = useAssetComponentsForms()

  // Memoize form refs to prevent unnecessary re-creation
  const allFormRefs = useMemo(() => getAllFormRefs(), [getAllFormRefs])

  // Memoize submit handler
  const submitAllForms = useCallback(() => {
    allFormRefs.forEach((formRef) => {
      if (formRef.submitRef?.current !== null) {
        formRef.submitRef.current.click()
      }
    })
  }, [allFormRefs])

  const resetAllForms = useCallback(() => {
    allFormRefs.forEach((formRef) => {
      if (formRef.resetRef?.current !== null) {
        formRef.resetRef.current.click()
      }
    })
  }, [allFormRefs])

  const { id } = useParams()
  const { toggleVisibility } = useNavigationState()
  const { showSpinner, hideSpinner } = useSpinnerState()

  const assetId: string = id ?? ''

  useEffect(() => {
    toggleVisibility()
    return () => {
      toggleVisibility()
    }
  }, [toggleVisibility])

  const { data: aircraftDetails, isLoading: isLoadingAircrafts, isFetching: isFetchingAircrafts, error: getAircraftError } = useGetAircraftDetailsQuery({ assetId })
  const { data: engines = [], isLoading: isLoadingEngines, isFetching: isFetchingEngines } = useGetAllEnginesByAssetIdQuery({ assetId, includeLLPs: true })
  const {
    data: airframes = [],
    isLoading: isLoadingAirframes,
    isFetching: isFetchingAirframes,
  } = useGetAllAirframesByAssetIdQuery({
    assetId,
  })
  const {
    data: auxiliaryPowerUnits = [],
    isLoading: isLoadingAuxiliaryPowerUnits,
    isFetching: isFetchingAuxiliaryPowerUnits,
  } = useGetAllAuxiliaryPowerUnitsByAssetIdQuery({ assetId }, { skip: isEmpty(assetId) })
  const {
    data: landingGears = [],
    isLoading: isLoadingLandingGears,
    isFetching: isFetchingLandingGears,
  } = useGetAllLandingGearsByAssetIdQuery({
    assetId,
  })

  useEffect(() => {
    if (!isNil(getAircraftError)) {
      showError(formatAxiosErrorMessage(getAircraftError?.message))
    }
  }, [getAircraftError])

  const areComponentsLoading =
    isLoadingAircrafts ||
    isFetchingAircrafts ||
    isLoadingEngines ||
    isFetchingEngines ||
    isLoadingAirframes ||
    isFetchingAirframes ||
    isLoadingAuxiliaryPowerUnits ||
    isFetchingAuxiliaryPowerUnits ||
    isLoadingLandingGears ||
    isFetchingLandingGears

  const airframeKBProgramId = airframes?.[0]?.assignedKBProgramId ?? ''
  const engineAssignedKBProgramId = engines?.[0]?.assignedKBProgramId ?? ''
  const auxiliaryPowerUnitKBProgramId = auxiliaryPowerUnits?.[0]?.assignedKBProgramId ?? ''
  const landingGearKBProgramId = landingGears?.[0]?.assignedKBProgramId ?? ''

  // Memoize tabs object to prevent re-creation on every render
  const tabs = useMemo(
    () => ({
      'Tech Spec': {
        content: (
          <AircraftTechSpec
            assetId={assetId}
            aircraft={aircraftDetails}
            airframes={airframes}
            auxiliaryPowerUnits={auxiliaryPowerUnits}
            engines={engines}
            landingGears={landingGears}
          />
        ),
      },
      'Maintenance Programme': {
        content: (
          <AircraftMaintenanceProgramsSection
            assetId={assetId}
            airframesIds={!isArrayEmptyOrNull(airframes) ? airframes.map((airframe) => airframe.componentId) : []}
            enginesIds={!isArrayEmptyOrNull(engines) ? engines.map((engine) => engine.componentId) : []}
            lgIds={!isArrayEmptyOrNull(landingGears) ? landingGears?.map((lg) => lg.componentId) : []}
            apusIds={!isArrayEmptyOrNull(auxiliaryPowerUnits) ? auxiliaryPowerUnits?.map((apu) => apu.componentId) : []}
            airframeKBProgramId={airframeKBProgramId}
            engineKBProgramId={engineAssignedKBProgramId}
            auxiliaryPowerUnitKBProgramId={auxiliaryPowerUnitKBProgramId}
            landingGearKBProgramId={landingGearKBProgramId}
            airframes={airframes}
            auxiliaryPowerUnits={auxiliaryPowerUnits}
            engines={engines}
            landingGears={landingGears}
          />
        ),
      },
    }),
    [assetId, aircraftDetails, airframes, auxiliaryPowerUnits, engines, landingGears],
  )

  areComponentsLoading ? showSpinner() : hideSpinner()

  return (
    <>
      {/* Header */}
      <div className="flex h-20 w-full items-center justify-between px-6">
        <FWBreadcrumbs breadcrumbs={[{ title: 'Fleet Summary', url: ROUTES.FLEET.ROOT }]} currentTitle="View Aircraft" />

        <div className="flex items-center gap-4 px-3">
          <AuthorizedElement>
            <div data-permission-element-id="read-only-asset-view">
              <AssetVerification
                msn={aircraftDetails?.serialNumber}
                assetId={assetId}
                isLoading={areComponentsLoading}
                selectedAirframeProgramId={airframeKBProgramId}
                selectedEngineProgramId={engineAssignedKBProgramId}
                readOnly
              />
            </div>
          </AuthorizedElement>
          <AuthorizedElement>
            <div data-permission-element-id="edit-asset" className={'flex items-center  gap-4'}>
              <AssetVerification
                msn={aircraftDetails?.serialNumber}
                assetId={assetId}
                isLoading={areComponentsLoading}
                selectedAirframeProgramId={airframeKBProgramId}
                selectedEngineProgramId={engineAssignedKBProgramId}
              />
              <MuiButton
                variant={isInEditMode ? 'outlined' : 'contained'}
                color="primary"
                startIcon={isInEditMode ? null : <EditIcon />}
                disabled={currentTab === 'Maintenance Programme'}
                onClick={() => {
                  if (isInEditMode) {
                    resetAllForms()
                    setIsInEditMode(false)
                    setHasErrors(false)
                  } else {
                    setIsInEditMode(true)
                  }
                }}
                style={{ minWidth: '5.4rem' }}
              >
                {isInEditMode ? 'Cancel' : 'Edit'}
              </MuiButton>
              <MuiButton
                variant="contained"
                color="primary"
                disabled={!isAllValid || !hasAnyChanges || hasErrors}
                startIcon={<SaveIcon />}
                onClick={() => {
                  submitAllForms()
                  setIsInEditMode(false)
                  setHasErrors(false)
                }}
                style={{ minWidth: '5.4rem' }}
              >
                Save
              </MuiButton>
            </div>
          </AuthorizedElement>

          <MuiButton
            variant="contained"
            color="primary"
            disabled={isInEditMode}
            startIcon={<FlightTakeoffIcon />}
            onClick={() => {
              navigate(`${ROUTES.FLY_FORWARD}/?${queryString.stringify({ assetIds: [id] })}`)
            }}
            style={{ minWidth: '5.4rem' }}
          >
            Fly
          </MuiButton>
        </div>
      </div>

      {/* Main Pane */}
      <main className={cn('h-full flex-1', pageContentStyles)}>
        <OutletTabs tabs={tabs} activeTab={currentTab} onTabChanged={onTabChanged} />
      </main>
    </>
  )
}
