import { NumberDisplay, type NumberDisplayType } from '@flyward/platform/components/NumberDisplay'
import { cn } from '@flyward/platform/components/utils'
import { formatNullable } from '@flyward/platform/utils'
import { isEmpty } from 'lodash'

interface INumberCellWithLabel {
  displayType: NumberDisplayType
  label: string
  className?: string
  currencyDecimals?: number
  info?: number | null
  infoClassName?: string
  labelClassName?: string
  preventInfo?: boolean
  isDisabled?: boolean
}

const NumberCellWithLabel = ({
  displayType,
  label,
  className = '',
  currencyDecimals = 0,
  info = undefined,
  infoClassName = '',
  labelClassName = '',
  preventInfo = false,
  isDisabled = false,
}: INumberCellWithLabel) => {
  return (
    <div className={(cn('flex flex-col justify-center gap-y-1'), className)}>
      {!isEmpty(label) && <em className={cn('block text-xs font-semibold capitalize not-italic text-text-3', labelClassName)}>{label}</em>}
      <span className={cn('block text-sm text-text-1', infoClassName)}>
        {!preventInfo ? (
          <NumberDisplay displayType={displayType} currencyDecimals={currencyDecimals} value={formatNullable(info)} isDisabled={isDisabled} />
        ) : (
          'N/A'
        )}
      </span>
    </div>
  )
}

export { NumberCellWithLabel }
export type { INumberCellWithLabel }
