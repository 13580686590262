import { useSortable } from '@dnd-kit/sortable'
import {
  AlignmentStatus,
  AlignmentStatusDisplay,
  Button,
  ButtonVariant,
  cn,
  EditableInputCellWithLabelWithLabel,
  IconVariant,
  InputType,
  type RowSizeMap,
  Size,
  Tooltip,
} from '@flyward/platform'
import { type Control, type Path } from 'react-hook-form'
import { type DraggableLlp, type DraggableLlpStack } from '../../../../../../../models'

interface IAssetLLpRowProps {
  llpStatus: AlignmentStatus
  formLlp: DraggableLlp
  positionalIndex: number
  kbTotalCount: number
  formControl: Control<DraggableLlpStack, unknown>
  onDeleteExistingLlp: (llpId: string) => void
  onExistingLlpCopyFromKb: (positionalIndex: number, llp: DraggableLlp) => void
  pathPrefix: string
  columnSizes: RowSizeMap
  draggedIndex?: number | null
  overIndex?: number | null
}

const AssetLlpRow = ({
  formLlp,
  llpStatus,
  positionalIndex,
  kbTotalCount,
  formControl,
  onDeleteExistingLlp,
  onExistingLlpCopyFromKb,
  pathPrefix,
  columnSizes,
  draggedIndex = null,
  overIndex = null,
}: IAssetLLpRowProps) => {
  const isEmptyLlp = llpStatus === AlignmentStatus.MissingInAsset

  const isInDeletePosition = positionalIndex >= kbTotalCount

  const canDeleteLLp = ![AlignmentStatus.MissingInAsset].includes(llpStatus) && isInDeletePosition

  const canAlignLlp =
    !canDeleteLLp && [AlignmentStatus.MissingInKb, AlignmentStatus.ToAlignManually, AlignmentStatus.Success, AlignmentStatus.SuggestedAlignment].includes(llpStatus)

  const suggestAlignment = llpStatus === AlignmentStatus.MissingInKb || llpStatus === AlignmentStatus.ToAlignManually || llpStatus === AlignmentStatus.SuggestedAlignment

  const isSwappedTo = positionalIndex === overIndex
  const isSwappedFrom = positionalIndex === draggedIndex

  const { attributes, listeners, setNodeRef, setActivatorNodeRef } = useSortable({ id: positionalIndex })

  const isNewLlpEditable = false

  const cellOpacity = isSwappedTo && 'opacity-25 !bg-blue-500'

  return (
    <tr
      ref={setNodeRef}
      className={cn('box-border  border bg-header-table', isSwappedFrom && ' box-border border border-gray-500 opacity-50', isSwappedTo && 'box-border border border-blue-500 ')}
      {...attributes}
    >
      <td
        className={cn(cellOpacity, ' h-6  truncate border border-black-20 bg-header-table')}
        style={{
          width: `${columnSizes.llp_componentModule.currentSize}px`,
          maxWidth: `${columnSizes.llp_componentModule.currentSize}px`,
        }}
      >
        <div className="  text-xs">
          {!isEmptyLlp || isNewLlpEditable ? (
            <EditableInputCellWithLabelWithLabel
              showErrorAsTooltip
              inputClassName="p-0 py-0 pl-0 pr-0"
              formControl={formControl}
              fieldName={`${pathPrefix}.componentModule` as Path<DraggableLlpStack>}
            />
          ) : (
            <></>
          )}
        </div>
      </td>
      <td
        className={cn(cellOpacity, ' h-6  truncate border border-black-20 bg-header-table')}
        style={{
          width: `${columnSizes.llp_componentModel.currentSize}px`,
          maxWidth: `${columnSizes.llp_componentModel.currentSize}px`,
        }}
      >
        <div className="  text-xs">
          {!isEmptyLlp || isNewLlpEditable ? (
            <EditableInputCellWithLabelWithLabel
              showErrorAsTooltip
              inputClassName="p-0 py-0 pl-0 pr-0"
              formControl={formControl}
              fieldName={`${pathPrefix}.componentModel` as Path<DraggableLlpStack>}
            />
          ) : (
            <></>
          )}
        </div>
      </td>
      <td
        className={cn(cellOpacity, ' h-6  truncate border border-black-20 bg-header-table')}
        style={{
          width: `${columnSizes.llp_partNumber.currentSize}px`,
          maxWidth: `${columnSizes.llp_partNumber.currentSize}px`,
        }}
      >
        <div className="  text-xs">
          {!isEmptyLlp || isNewLlpEditable ? (
            <EditableInputCellWithLabelWithLabel
              showErrorAsTooltip
              inputClassName="p-0 py-0 pl-0 pr-0"
              formControl={formControl}
              fieldName={`${pathPrefix}.partNumber` as Path<DraggableLlpStack>}
            />
          ) : (
            <></>
          )}
        </div>
      </td>
      <td
        className={cn(cellOpacity, ' h-6  truncate border border-black-20 bg-header-table')}
        style={{
          width: `${columnSizes.llp_componentSerialNumber.currentSize}px`,
          maxWidth: `${columnSizes.llp_componentSerialNumber.currentSize}px`,
        }}
      >
        <div className="   text-xs">
          {!isEmptyLlp || isNewLlpEditable ? (
            <EditableInputCellWithLabelWithLabel
              showErrorAsTooltip
              inputClassName="p-0 py-0 pl-0 pr-0"
              formControl={formControl}
              fieldName={`${pathPrefix}.componentSerialNumber` as Path<DraggableLlpStack>}
            />
          ) : (
            <></>
          )}
        </div>
      </td>
      <td
        className={cn(cellOpacity, ' h-6  truncate border border-black-20 bg-header-table')}
        style={{
          width: `${columnSizes.llp_technicalLimitAmount.currentSize}px`,
          maxWidth: `${columnSizes.llp_technicalLimitAmount.currentSize}px`,
        }}
      >
        <div className="  text-xs">
          {!isEmptyLlp || isNewLlpEditable ? (
            <EditableInputCellWithLabelWithLabel
              showErrorAsTooltip
              inputClassName="p-0 py-0 pl-0 pr-0"
              inputType={InputType.NaturalNumber}
              formControl={formControl}
              fieldName={`${pathPrefix}.technicalLimitAmount` as Path<DraggableLlpStack>}
            />
          ) : (
            <></>
          )}
        </div>
      </td>
      <td
        className={cn(cellOpacity, ' h-6  truncate border border-black-20 bg-header-table')}
        style={{
          width: `${columnSizes['llp_assetComponentUtilizationSnapshot.cyclesSinceNew'].currentSize}px`,
          maxWidth: `${columnSizes['llp_assetComponentUtilizationSnapshot.cyclesSinceNew'].currentSize}px`,
        }}
      >
        <div className="  text-xs">
          {!isEmptyLlp || isNewLlpEditable ? (
            <EditableInputCellWithLabelWithLabel
              showErrorAsTooltip
              inputClassName="p-0 py-0 pl-0 pr-0"
              inputType={InputType.NaturalNumber}
              formControl={formControl}
              fieldName={`${pathPrefix}.assetComponentUtilizationSnapshot.cyclesSinceNew` as Path<DraggableLlpStack>}
            />
          ) : (
            <></>
          )}
        </div>
      </td>
      <td
        className={cn(cellOpacity, ' h-6  truncate border border-black-20 bg-header-table')}
        style={{
          width: `${columnSizes['llp_assetComponentUtilizationSnapshot.remainingCycles'].currentSize}px`,
          maxWidth: `${columnSizes['llp_assetComponentUtilizationSnapshot.remainingCycles'].currentSize}px`,
        }}
      >
        <div className="  text-xs">
          {!isEmptyLlp || isNewLlpEditable ? (
            <EditableInputCellWithLabelWithLabel
              showErrorAsTooltip
              inputClassName="p-0 py-0 pl-0 pr-0"
              inputType={InputType.NaturalNumber}
              formControl={formControl}
              fieldName={`${pathPrefix}.assetComponentUtilizationSnapshot.remainingCycles` as Path<DraggableLlpStack>}
            />
          ) : (
            <></>
          )}
        </div>
      </td>
      <td
        className={cn(cellOpacity, ' h-6  truncate border border-black-20 bg-header-table')}
        style={{
          width: `${columnSizes.llp_cyclesSinceNewAtContractDelivery.currentSize}px`,
          maxWidth: `${columnSizes.llp_cyclesSinceNewAtContractDelivery.currentSize}px`,
        }}
      >
        <div className="  text-xs">
          {!isEmptyLlp || isNewLlpEditable ? (
            <EditableInputCellWithLabelWithLabel
              showErrorAsTooltip
              inputClassName="p-0 py-0 pl-0 pr-0"
              inputType={InputType.NaturalNumber}
              formControl={formControl}
              fieldName={`${pathPrefix}.cyclesSinceNewAtContractDelivery` as Path<DraggableLlpStack>}
            />
          ) : (
            <></>
          )}
        </div>
      </td>
      <td
        className={cn('box-border  h-6 truncate border border-black-20 bg-header-table')}
        style={{
          width: `${columnSizes.alignmentStatus.currentSize}px`,
          maxWidth: `${columnSizes.alignmentStatus.currentSize}px`,
          minWidth: `${columnSizes.order.currentSize}px`,
        }}
      >
        <div className="flex items-center justify-center">
          <Tooltip
            tooltipContent={AlignmentStatusDisplay(llpStatus)}
            content={
              <span className="m-auto text-xs">
                {llpStatus === AlignmentStatus.Success && '✅'}
                {llpStatus === AlignmentStatus.MissingInKb && '🔵'}
                {llpStatus === AlignmentStatus.ToAlignManually && '🔵'}
                {llpStatus === AlignmentStatus.SuggestedAlignment && '🔵'}
                {llpStatus === AlignmentStatus.MissingInAsset && ''}
              </span>
            }
          />
        </div>
      </td>
      <td
        className={cn(cellOpacity, 'h-6 truncate border border-black-20 bg-header-table')}
        style={{
          width: `${columnSizes.order.currentSize}px`,
          maxWidth: `${columnSizes.order.currentSize}px`,
          minWidth: `${columnSizes.order.currentSize}px`,
        }}
      >
        <div ref={setActivatorNodeRef} {...listeners} className=" flex items-center justify-center text-xs">
          <Button className="m-auto h-6 cursor-grab p-0 text-xs" leftIcon={IconVariant.Apps} variant={ButtonVariant.Ghost} size={Size.Small} onClick={() => {}} />
        </div>
      </td>
      <td
        className={cn(cellOpacity, ' h-6 truncate border border-black-20 bg-black-0')}
        style={{
          width: `${columnSizes.align.currentSize}px`,
          maxWidth: `${columnSizes.align.currentSize}px`,
          minWidth: `${columnSizes.order.currentSize}px`,
        }}
      >
        <div className=" text-xs">
          {canAlignLlp && (
            <Tooltip
              tooltipContent="Align with KB"
              content={
                <Button
                  className={cn('m-auto mx-auto h-6 p-0 text-xs', suggestAlignment && 'text-text-red-500')}
                  leftIcon={IconVariant.KeyboardArrowLeft}
                  variant={ButtonVariant.Ghost}
                  size={Size.Small}
                  onClick={() => {
                    if (!isEmptyLlp) {
                      onExistingLlpCopyFromKb(positionalIndex, formLlp)
                    }
                  }}
                />
              }
            />
          )}

          {canDeleteLLp && (
            <Button
              className="m-auto mx-auto h-6 p-0 text-xs"
              leftIcon={IconVariant.Delete}
              variant={ButtonVariant.Ghost}
              size={Size.Small}
              onClick={() => {
                onDeleteExistingLlp(formLlp?.llp?.componentId ?? '')
              }}
            />
          )}
        </div>
      </td>
    </tr>
  )
}

export { AssetLlpRow }
