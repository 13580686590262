import React, { useEffect, useRef } from 'react'
import { type AssetSearchDTO } from '@flyward/assets'
import { EventSchedule, formatAssetSchedule, ResponsiveForecastingSummaryChart, Statistics, type IMaintenanceScheduleDisplay } from '@flyward/forecasts'
import { CollapsibleContainer, ComponentContainer, type ComponentMonthlyStatistics, ErrorBoundary, ReportDisplayType } from '@flyward/platform'
import { useGetFlyForwardDefaultParametersQuery, type IReportAsset } from '@flyward/platform/store/slices'
import { isNil } from 'lodash'
import { FlyForwardParameters } from './FlyForwardParameters'

interface IFlyForwardIndividualAssetReportProps {
  currentAssetData: IReportAsset
  selectedAsset: AssetSearchDTO
}

const FlyForwardIndividualAssetReport = ({ currentAssetData, selectedAsset }: IFlyForwardIndividualAssetReportProps) => {
  const componentsMonthlyStatistics: ComponentMonthlyStatistics[] = []
  const chartRef = useRef<HTMLDivElement>(null)

  const { data: flyForwardDefaultParameters, isLoading: isLoadingFlyForwardDefaultParameters } = useGetFlyForwardDefaultParametersQuery(
    {
      assetId: selectedAsset.assetId,
    },
    { skip: isNil(selectedAsset?.assetId) },
  )

  useEffect(() => {
    if (currentAssetData?.assetComponentsMonthlyStatistics?.assetMonthlyStatistics.length > 0) {
      setTimeout(() => {
        chartRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
      }, 100)
    }
  }, [currentAssetData?.assetComponentsMonthlyStatistics])

  if (isLoadingFlyForwardDefaultParameters || isNil(flyForwardDefaultParameters) || isNil(selectedAsset)) {
    return <div data-testid="fly-forward-parameters-loading"></div>
  }

  currentAssetData?.assetComponentsMonthlyStatistics?.groupedComponentStatistics.forEach((component) => {
    if (component.subComponents !== undefined && component.subComponents.length > 0) {
      componentsMonthlyStatistics.push(...component.subComponents)
    } else {
      componentsMonthlyStatistics.push(component)
    }
  })

  const assetScheduleData: IMaintenanceScheduleDisplay[] = formatAssetSchedule(
    currentAssetData.eventSchedule,
    currentAssetData.flyForwardParameters.endDate,
    currentAssetData.assetSerialNumber,
    currentAssetData.reportItemId,
  )

  const assetScheduleEvents: IMaintenanceScheduleDisplay[] = formatAssetSchedule(
    currentAssetData.eventSchedule,
    currentAssetData.flyForwardParameters.endDate,
    currentAssetData.assetId.id,
    currentAssetData.reportItemId,
    true,
  )

  return (
    <>
      {!isNil(flyForwardDefaultParameters) && (
        <ComponentContainer className="p-6">
          <CollapsibleContainer title="Fly Forward Parameters" containerClassName="w-full">
            <FlyForwardParameters assetId={{ id: selectedAsset.assetId, index: currentAssetData.assetId.index }} assetType={selectedAsset.assetType} />
          </CollapsibleContainer>
        </ComponentContainer>
      )}
      <>
        {!isNil(currentAssetData.assetComponentsMonthlyStatistics) && currentAssetData.assetComponentsMonthlyStatistics.assetMonthlyStatistics.length > 0 && (
          <div ref={chartRef}>
            <ComponentContainer className="p-6">
              <CollapsibleContainer title="Chart" containerClassName="w-full gap-y-0">
                <div className="h-96 w-full">
                  <ErrorBoundary>
                    <ResponsiveForecastingSummaryChart assetMonthlyStatistics={currentAssetData.assetComponentsMonthlyStatistics.assetMonthlyStatistics} />
                  </ErrorBoundary>
                </div>
              </CollapsibleContainer>
            </ComponentContainer>
          </div>
        )}
        {assetScheduleData.length > 0 && (
          <EventSchedule
            reportDisplayType={ReportDisplayType.AssetReport}
            reportItemsSchedules={[
              {
                flyForwardParameters: currentAssetData.flyForwardParameters,
                reportItemId: currentAssetData.reportItemId ?? '',
                assetIdWithIndex: {
                  id: currentAssetData.assetId.id,
                  index: currentAssetData.assetId.index,
                },
                schedules: assetScheduleData,
              },
            ]}
            uniqueSchedules={assetScheduleData}
            allSchedules={assetScheduleEvents}
            componentsMonthlyStatistics={componentsMonthlyStatistics}
            componentTotalStatisticsAtEvent={currentAssetData.assetComponentsMonthlyStatistics?.componentTotalStatisticsAtEvent ?? []}
          />
        )}
        {!isNil(currentAssetData.assetComponentsMonthlyStatistics) && currentAssetData.assetComponentsMonthlyStatistics.assetMonthlyStatistics.length > 0 && (
          <Statistics
            reportDisplayType={ReportDisplayType.AssetReport}
            allEvents={assetScheduleEvents}
            assetMonthlyStatisticsSummary={currentAssetData.assetComponentsMonthlyStatistics.assetMonthlyStatistics}
            allReportComponentsMonthlyStatistics={currentAssetData.assetComponentsMonthlyStatistics}
            key={currentAssetData.assetId.id}
            assetType={selectedAsset.assetType}
            isoStartDate={selectedAsset.dateOfLastTechSpec ?? ''}
            isoEndDate={currentAssetData.flyForwardParameters.endDate}
          />
        )}
      </>
    </>
  )
}

export { FlyForwardIndividualAssetReport }
