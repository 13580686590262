import { Button as MuiButton } from '@mui/material'
import { Add } from '@mui/icons-material'
import { useState } from 'react'

const CreateEventWizard = (isEditable: boolean) => {
  const [userConfirmedRisk, setUserConfirmedRisk] = useState<boolean>(false)

  return (
    <>
      <>
        {isEditable && (
          <MuiButton sx={{ display: 'none' }} variant="contained" color="primary" onClick={() => {}}>
            <Add />
            Create Event
          </MuiButton>
        )}
      </>

      {!userConfirmedRisk && <></>}

      {userConfirmedRisk && <></>}
    </>
  )
}

export { CreateEventWizard }
