import { ReportDisplayType, type IEventViewDetailDto } from '@flyward/platform/models'
import { cn } from '@flyward/platform/components'
import { Tooltip as MuiTooltip } from '@mui/material'

export interface MasterComponentWithEvents {
  masterComponentId: string
  eventsOnComponent: IEventViewDetailDto[]
}

interface IComponentsEventsTableHeaderProps {
  reportDisplayType: ReportDisplayType
  thStyle: string
}

const ComponentsEventsTableHeader = ({ reportDisplayType, thStyle }: IComponentsEventsTableHeaderProps) => {
  return (
    <thead className="sticky top-0 w-full bg-header-table text-sm font-semibold leading-tight text-zinc-700">
      <tr className="flex w-full">
        <th className={cn('basis-2/36 text-start', thStyle, reportDisplayType === ReportDisplayType.AssetReport && 'hidden')}>SN</th>
        <th className={cn('basis-2/36 text-start', thStyle)}>Component</th>
        <th className={cn('text-start', thStyle, reportDisplayType === ReportDisplayType.AssetReport ? 'basis-5/36' : 'basis-4/36')}>Event Type</th>
        <th className={cn('basis-3/36 text-start', thStyle, reportDisplayType === ReportDisplayType.AssetReport ? 'basis-4/36' : 'basis-3/36')}>
          Event Reason
        </th>
        <th className={cn('basis-2/36 text-start', thStyle)}>TSN</th>
        <th className={cn('basis-2/36 text-start', thStyle)}>CSN</th>
        <th className={cn('basis-2/36 text-start', thStyle)}>Limit</th>
        <th className={cn('basis-2/36 text-start', thStyle)}>
          <MuiTooltip title="Values turn negative if the component is used beyond the limit." arrow placement="top">
            <span className="w-full">Remaining</span>
          </MuiTooltip>
        </th>
        <th className={cn('basis-2/36 text-start', thStyle)}>TSLPR</th>
        <th className={cn('basis-2/36 text-start', thStyle)}>CSLPR</th>
        <th className={cn('basis-3/36 text-end', thStyle)}>MR Balance</th>
        <th className={cn('basis-3/36 text-end', thStyle)}>Event Cost</th>
        <th className={cn('basis-3/36 text-end', thStyle)}>CF Out</th>
        <th className={cn('basis-3/36 text-end', thStyle)}>Lessor Contribution</th>
        <th className={cn('basis-3/36 text-end', thStyle)}>Event Shortfall</th>
      </tr>
    </thead>
  )
}

export { ComponentsEventsTableHeader }
