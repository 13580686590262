import { useSelector, useDispatch } from 'react-redux'
import type { TRootState } from '@flyward/platform/store/configureReducers'
import { Box, Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import { NumberDisplay, NumberDisplayType } from '@flyward/platform'
import { ComponentType } from '@flyward/platform/models/enums/ComponentType'
import { Label } from '../common/Label'
import { useCreateAuxiliaryPowerUnitMaintenanceProgramMutation } from '@flyward/knowledgeBase/store'
import { type CreateApuProgramDto } from '@flyward/knowledgeBase/models/DTOs/Create/Apu'
import { isNil } from 'lodash'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '@flyward/main-app/providers/routes'
import { showError, showSuccess } from '@flyward/platform/services'
import { formatAxiosErrorMessage } from '@flyward/platform/helpers/ErrorHelpers'
import { resetWizard } from '../../store/wizardSlice'

interface ReviewAndPublishProps {
  onNext: () => void
  onBack: () => void
  stepTitle: string
}

export const ReviewAndPublish: React.FC<ReviewAndPublishProps> = ({ onBack, stepTitle }) => {
  const wizardState = useSelector((state: TRootState) => state.wizard[ComponentType.AuxiliaryPowerUnit])
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [createAuxiliaryPowerUnitMaintenanceProgram] = useCreateAuxiliaryPowerUnitMaintenanceProgramMutation()

  const onPublish = () => {
    const programToCreate: CreateApuProgramDto = {
      programName: wizardState.general.name,
      manufacturer: wizardState.general.manufacturer,
      componentModel: wizardState.general.model,
      baseYear: wizardState.general.baseYear,
      basicAssumptions: {
        downtimeMonths: wizardState.checksEscalationsAssumptions.checkDowntime,
        monthsDelayInPaymentOnClaims:
          isNil(wizardState.checksEscalationsAssumptions.delayInPayment) || wizardState.checksEscalationsAssumptions.delayInPayment === ''
            ? 0
            : wizardState.checksEscalationsAssumptions.delayInPayment,
      },
      apuPrCheck: {
        limit: wizardState.checksEscalationsAssumptions.checks[0].limit,
        cost: wizardState.checksEscalationsAssumptions.checks[0].cost,
      },
      escalationPct: wizardState.checksEscalationsAssumptions.escalation,
    }

    createAuxiliaryPowerUnitMaintenanceProgram({
      program: programToCreate,
    })
      .then(() => {
        dispatch(resetWizard())
        navigate(ROUTES.MAINTENANCE_PROGRAMS.APUMP)
        showSuccess(<span>The maintenance programme was successfully added.</span>)
      })
      .catch((error) => {
        showError(formatAxiosErrorMessage(error?.message))
      })
  }

  const flexBoxStyles = {
    display: 'flex',
    flexDirection: 'row',
    gap: 2,
    flexWrap: 'wrap',
    '& > *': {
      flex: '1 1 calc(25% - 16px)',
      minWidth: '200px',
    },
  }

  return (
    <div className="shadow-sm rounded-lg bg-white p-6">
      <Typography variant="h6" sx={{ mb: 3 }}>
        {stepTitle}
      </Typography>

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, mb: 4 }}>
        <div className="mb-2 text-lg font-semibold">1. General</div>
        <Box sx={flexBoxStyles}>
          <Label label="Programme Name" value={wizardState.general.name} />
          <Label label="Manufacturer" value={wizardState.general.manufacturer} />
          <Label label="Model" value={wizardState.general.model} />
          <Label label="Base Year" value={wizardState.general.baseYear?.toString() ?? 'N/A'} />
        </Box>
      </Box>

      {wizardState.checksEscalationsAssumptions.checks.length > 0 && (
        <>
          <div className="mb-2 mt-4 text-lg font-semibold">2. Checks - Escalations and Basic Assumptions</div>
          <Typography variant="subtitle2" sx={{ mb: 1 }}>
            Checks
          </Typography>
          <TableContainer component={Paper} variant="outlined" sx={{ mb: 3 }}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Event Type</TableCell>
                  <TableCell>Unit</TableCell>
                  <TableCell sx={{ textAlign: 'right' }}>Limit</TableCell>
                  <TableCell sx={{ textAlign: 'right' }}>Cost</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {wizardState.checksEscalationsAssumptions.checks.map((check, index) => (
                  <TableRow key={index}>
                    <TableCell>PR</TableCell>
                    <TableCell>APU Hours</TableCell>
                    <TableCell sx={{ textAlign: 'right' }}>
                      <NumberDisplay value={check.limit} displayType={NumberDisplayType.Integer} />
                    </TableCell>
                    <TableCell sx={{ textAlign: 'right' }}>
                      <NumberDisplay value={check.cost} displayType={NumberDisplayType.Currency} />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Typography variant="subtitle2" sx={{ mb: 1 }}>
            Basic Assumptions
          </Typography>
          <Box sx={flexBoxStyles}>
            <Label label="Check Downtime (Months)" value={wizardState.checksEscalationsAssumptions.checkDowntime} />
            <Label label="Delay in Payment on Claims (Months)" value={wizardState.checksEscalationsAssumptions.delayInPayment || 0} />
            <Label label="Escalation (%)" value={wizardState.checksEscalationsAssumptions.escalation ? `${wizardState.checksEscalationsAssumptions.escalation}%` : '0%'} />
          </Box>
        </>
      )}

      <Box sx={{ display: 'flex', gap: 1, mt: 4 }}>
        <Button variant="contained" onClick={onPublish}>
          Publish
        </Button>
        <Button variant="text" onClick={onBack}>
          Back
        </Button>
      </Box>
    </div>
  )
}
