import { MaintenanceProgramsGrid } from '@flyward/knowledgeBase'
import { useChangeStateMaintenanceProgramMutation, useDeleteMaintenanceProgramMutation } from '@flyward/knowledgeBase/store/api/common'
import { ComponentType, ComponentContainer, ErrorBoundary, useSpinnerState } from '@flyward/platform'
import { OutletTabs } from '@flyward/platform/components/Tabs/OutletTabs/OutletTabs'
import {
  useGetAllAirframeMaintenanceProgramsQuery,
  useGetAllAuxiliaryPowerUnitMaintenanceProgramsQuery,
  useGetAllEngineMaintenanceProgramsQuery,
  useGetAllLandingGearMaintenanceProgramsQuery,
} from '@flyward/platform/store'
import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { ROUTES } from '@flyward/main-app/providers/routes'
import { Typography } from '@mui/material'

type AvailableTabs = 'Engine' | 'Airframe' | 'Landing Gear' | 'APU'

const ActiveTabToPath: Record<AvailableTabs, string> = {
  Engine: 'engine-maintenance-program',
  Airframe: 'airframe-maintenance-program',
  APU: 'auxiliary-power-unit-maintenance-program',
  'Landing Gear': 'landing-gears-maintenance-program',
}

const PathToActiveTab: Record<string, AvailableTabs> = {
  'engine-maintenance-program': 'Engine',
  'airframe-maintenance-program': 'Airframe',
  'auxiliary-power-unit-maintenance-program': 'APU',
  'landing-gears-maintenance-program': 'Landing Gear',
}

export const KnowledgeBasePage = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const { showSpinner, hideSpinner } = useSpinnerState()
  const [currentTab, setCurrentTab] = useState<AvailableTabs>(PathToActiveTab[location.pathname.substring(location.pathname.lastIndexOf('/') + 1)])

  const [deleteMaintenanceProgram] = useDeleteMaintenanceProgramMutation()
  const [changeStateMaintenanceProgram] = useChangeStateMaintenanceProgramMutation()
  const { data: enginePrograms, isLoading: isLoadingEnginePrograms } = useGetAllEngineMaintenanceProgramsQuery(true)
  const { data: airframePrograms, isLoading: isLoadingAirframePrograms } = useGetAllAirframeMaintenanceProgramsQuery(true)
  const { data: landingGearPrograms, isLoading: isLoadingLandingGearPrograms } = useGetAllLandingGearMaintenanceProgramsQuery(true)
  const { data: auxiliaryPowerUnitMaintenancePrograms, isLoading: isLoadingAuxiliaryPowerUnitMaintenancePrograms } = useGetAllAuxiliaryPowerUnitMaintenanceProgramsQuery(true)

  const onTabChanged = (activeTab: string) => {
    navigate(`${ROUTES.MAINTENANCE_PROGRAMS.ROOT}/${ActiveTabToPath[activeTab as AvailableTabs]}`)
    setCurrentTab(activeTab as AvailableTabs)
  }

  const onDelete = async (maintenanceId: string): Promise<string | undefined> => {
    const result = await deleteMaintenanceProgram({ maintenanceId })
    return result.error?.message
  }

  const onChangeState = async (maintenanceId: string): Promise<string | undefined> => {
    const result = await changeStateMaintenanceProgram({ maintenanceId })
    return result.error?.message
  }

  const tabs = {
    Engine: {
      content: (
        <ErrorBoundary>
          <MaintenanceProgramsGrid
            programs={enginePrograms ?? []}
            componentType={ComponentType.EngineUnit}
            detailsRoute={location.pathname}
            onDelete={onDelete}
            onChangeState={onChangeState}
          />
        </ErrorBoundary>
      ),
    },
    Airframe: {
      content: (
        <ErrorBoundary>
          <MaintenanceProgramsGrid
            programs={airframePrograms ?? []}
            componentType={ComponentType.Airframe}
            detailsRoute={location.pathname}
            onDelete={onDelete}
            onChangeState={onChangeState}
          />
        </ErrorBoundary>
      ),
    },
    'Landing Gear': {
      content: (
        <ErrorBoundary>
          <MaintenanceProgramsGrid
            programs={landingGearPrograms ?? []}
            componentType={ComponentType.LandingGear}
            detailsRoute={location.pathname}
            onDelete={onDelete}
            onChangeState={onChangeState}
          />
        </ErrorBoundary>
      ),
    },
    APU: {
      content: (
        <ErrorBoundary>
          <MaintenanceProgramsGrid
            programs={auxiliaryPowerUnitMaintenancePrograms ?? []}
            componentType={ComponentType.AuxiliaryPowerUnit}
            detailsRoute={location.pathname}
            onDelete={onDelete}
            onChangeState={onChangeState}
          />
        </ErrorBoundary>
      ),
    },
  }

  isLoadingAirframePrograms || isLoadingAuxiliaryPowerUnitMaintenancePrograms || isLoadingEnginePrograms || isLoadingLandingGearPrograms ? showSpinner() : hideSpinner()

  return (
    <>
      {/* Header */}
      <div className="flex h-20 w-full items-center px-2 py-9">
        <Typography sx={{ fontSize: '1.25rem', fontWeight: 600, color: 'var(--text-1)' }}>Maintenance Programmes</Typography>
      </div>
      {/* Main Pane */}
      <ComponentContainer className="h-full w-full !items-start !justify-start py-6">
        <OutletTabs tabs={tabs} activeTab={currentTab} onTabChanged={onTabChanged} />
      </ComponentContainer>
    </>
  )
}
