import { errorMessages, type GetAllUsersDto, Role, RoleDisplay, UserState } from '@flyward/platform'
import { showSuccess, showError } from '@flyward/platform/services'
import { type IAddEditUserQueryRequest, useAddUserMutation, useEditUserMutation } from '@flyward/platform/store'
import { isNil } from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Select, MenuItem, FormControl, InputLabel, Box, InputAdornment } from '@mui/material'
import { Email as EmailIcon } from '@flyward/platform/components/Icon/svg/Email'
import { IconVariant } from '@flyward/platform/components/Icon/IconVariant'
import { validateEmail } from '@flyward/platform/components/Input/inputValidations'
import { Person as PersonIcon, People as PeopleIcon } from '@mui/icons-material'

interface IAddEditUserProps {
  user: GetAllUsersDto | null
  isEditMode: boolean
  isOpen: boolean
  setIsOpen: (_isOpen: boolean) => void
}

const INITIAL_FORM_STATE = {
  firstName: false,
  lastName: false,
  email: false,
}

const newUser: GetAllUsersDto = {
  userId: '',
  firstName: '',
  lastName: '',
  email: '',
  role: Role.None,
  state: UserState.None,
  createdAt: new Date(),
  isEmailVerified: false,
}

const roleStates = [
  { label: RoleDisplay(Role.NormalUser), value: Role.NormalUser },
  { label: RoleDisplay(Role.SuperUser), value: Role.SuperUser },
  { label: RoleDisplay(Role.Admin), value: Role.Admin },
]

const useFormManagement = (user: GetAllUsersDto | null, isOpen: boolean, isEditMode: boolean) => {
  const [userForm, setUserForm] = useState<GetAllUsersDto>(user ?? newUser)
  const [formErrors, setFormErrors] = useState(INITIAL_FORM_STATE)
  const [touchedFields, setTouchedFields] = useState(INITIAL_FORM_STATE)

  useEffect(() => {
    setUserForm(user ?? newUser)
    setTouchedFields(INITIAL_FORM_STATE)
    setFormErrors(INITIAL_FORM_STATE)
  }, [user, isOpen, isEditMode])

  return { userForm, setUserForm, formErrors, setFormErrors, touchedFields, setTouchedFields }
}

const validateUserForm = (form: GetAllUsersDto) => {
  const errors = {
    firstName: !form.firstName.trim(),
    lastName: !form.lastName.trim(),
    email: !validateEmail(form.email).isValid,
  }
  return { errors, isValid: !Object.values(errors).some(Boolean) && form.role !== Role.None }
}

const prepareUserData = (userForm: GetAllUsersDto): IAddEditUserQueryRequest => ({
  userId: userForm?.userId ?? '',
  firstName: userForm?.firstName.trim() ?? '',
  lastName: userForm?.lastName.trim() ?? '',
  email: userForm?.email.trim() ?? '',
  role: Number(userForm?.role),
})

export const AddEditUser = ({ isOpen, isEditMode, user, setIsOpen }: IAddEditUserProps) => {
  const { userForm, setUserForm, formErrors, setFormErrors, touchedFields, setTouchedFields } = useFormManagement(user, isOpen, isEditMode)
  const [addUser] = useAddUserMutation()
  const [editUser] = useEditUserMutation()

  const handleInputChange = useCallback(
    (field: keyof GetAllUsersDto, value: string | number) => {
      const updatedForm = { ...userForm, [field]: value }
      setUserForm(updatedForm)
      setTouchedFields((prev) => ({ ...prev, [field]: true }))
      const { errors } = validateUserForm(updatedForm)
      setFormErrors(errors)
    },
    [userForm, setUserForm, setTouchedFields, setFormErrors],
  )

  const handleBlur = useCallback(
    (field: keyof typeof touchedFields) => {
      setTouchedFields((prev) => ({ ...prev, [field]: true }))
      const { errors } = validateUserForm(userForm)
      setFormErrors(errors)
    },
    [userForm, setTouchedFields, setFormErrors],
  )

  const handleSave = useCallback(async () => {
    const { isValid } = validateUserForm(userForm)
    if (!isValid) return

    const userData = prepareUserData(userForm)
    const { error } = await (isEditMode ? editUser(userData) : addUser(userData))

    if (isNil(error)) {
      showSuccess('Your user has been successfully saved!')
      setIsOpen(false)
    } else {
      showError(errorMessages.userManagement.saveError)
    }
  }, [userForm, isEditMode, editUser, addUser, setIsOpen])

  const handleClose = useCallback(() => {
    setUserForm(user ?? newUser)
    setIsOpen(false)
  }, [user, setIsOpen, setUserForm])

  const renderTextField = useCallback(
    (label: string, field: keyof GetAllUsersDto, type = 'text') => (
      <TextField
        label={label}
        fullWidth
        type={type}
        value={userForm[field]}
        onChange={(e) => handleInputChange(field, e.target.value)}
        onBlur={() => handleBlur(field as keyof typeof touchedFields)}
        error={touchedFields[field as keyof typeof touchedFields] && formErrors[field as keyof typeof formErrors]}
        helperText={touchedFields[field as keyof typeof touchedFields] && formErrors[field as keyof typeof formErrors] ? `${label} is required` : ''}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              {field === 'firstName' && <PersonIcon className="text-primary" />}
              {field === 'lastName' && <PeopleIcon className="text-primary" />}
              {field === 'email' && <EmailIcon className="h-5 w-5 fill-current text-primary" variant={IconVariant.Email} />}
            </InputAdornment>
          ),
        }}
      />
    ),
    [userForm, touchedFields, formErrors, handleInputChange, handleBlur],
  )

  const { isValid } = validateUserForm(userForm)

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="xs" fullWidth>
      <DialogTitle>{isEditMode ? `Edit user ${user?.firstName}` : 'Add user'}</DialogTitle>
      <DialogContent>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, pt: 2 }}>
          {renderTextField('First Name', 'firstName')}
          {renderTextField('Last Name', 'lastName')}
          {renderTextField('Email', 'email', 'email')}
          <FormControl fullWidth>
            <InputLabel shrink>Role</InputLabel>
            <Select
              value={userForm.role || ''}
              label="Role"
              notched
              onChange={(e) => handleInputChange('role', Number(e.target.value))}
              displayEmpty
              renderValue={(selected) => (!selected ? 'Please select a role' : RoleDisplay(selected as Role))}
            >
              {roleStates.map((role) => (
                <MenuItem key={role.value} value={role.value}>
                  {role.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions sx={{ marginRight: '1rem', marginBottom: '0.5rem' }}>
        <Button onClick={handleClose} variant="outlined">
          Cancel
        </Button>
        <Button
          onClick={handleSave}
          variant="contained"
          disabled={!isValid}
          sx={{
            backgroundColor: 'var(--primary)',
            '&:hover': {
              backgroundColor: 'var(--primary-light-1)',
            },
          }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}
