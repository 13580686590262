import { type VerifyAssetResultDto } from '../../../../../models'
import { Box, Tabs, Tab } from '@mui/material'
import { isEmpty, isNil } from 'lodash'
import { AirframeAlignment } from './AirframeAlignment'
import { EngineAlignment } from './EngineAlignment'
import { AssetVerificationBadge } from '../AssetVerificationBadge'
import { Suspense, useState } from 'react'
import { FWBreadcrumbs } from '@flyward/main-app/layout/MasterLayout/Header/FWBreadcrumbs'
import { ROUTES } from '@flyward/main-app/providers/routes'
import { LoadingFallback } from '@flyward/platform/hoc'

interface IAssetAlignmentWizardProps {
  msn: string
  assetId: string
  alignmentData: VerifyAssetResultDto
  setIsAssetVerificationModalOpen: (_isOpen: boolean) => void
  selectedAirframeProgramId?: string
  selectedEngineProgramId?: string
}

const TabContent = ({ content, isSelected }: { content: React.ReactNode; isSelected: boolean }) => {
  if (!isSelected) return null

  return <Suspense fallback={<LoadingFallback />}>{content}</Suspense>
}

const AssetAlignmentWizard = ({ msn, assetId, alignmentData, setIsAssetVerificationModalOpen, selectedAirframeProgramId, selectedEngineProgramId }: IAssetAlignmentWizardProps) => {
  const [selectedTab, setSelectedTab] = useState(0)

  const tabPanels: Array<{
    label: React.ReactNode
    content: React.ReactNode
  }> = []

  if (!isEmpty(selectedAirframeProgramId) && !isNil(alignmentData.airframeResult)) {
    tabPanels.push({
      label: (
        <div className="flex items-center gap-x-2">
          <AssetVerificationBadge isSuccess={alignmentData?.airframeResult?.isSuccess ?? false} />
          Airframe
        </div>
      ),
      content: <AirframeAlignment assetId={assetId} airframeResult={alignmentData.airframeResult} setIsAssetVerificationModalOpen={setIsAssetVerificationModalOpen} />,
    })
  }

  if (!isEmpty(selectedEngineProgramId)) {
    alignmentData.engineResults.forEach((engineResult) => {
      tabPanels.push({
        label: (
          <div className="flex items-center gap-x-2">
            <AssetVerificationBadge isSuccess={engineResult?.isSuccess ?? false} />
            Engine {engineResult.engineSerialNumber}
          </div>
        ),
        content: <EngineAlignment assetId={assetId} engineResult={engineResult} setIsAssetVerificationModalOpen={setIsAssetVerificationModalOpen} />,
      })
    })
  }

  return (
    <div className="h-full max-h-screen w-[calc(100vw-3rem)]">
      <FWBreadcrumbs
        breadcrumbs={[
          { title: 'Fleet Summary', url: ROUTES.FLEET.ROOT },
          {
            title: msn,
            onBreadcrumbClick: () => {
              setIsAssetVerificationModalOpen(false)
            },
          },
        ]}
        currentTitle="Align Asset"
      />

      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={selectedTab} variant="scrollable" scrollButtons="auto">
          {tabPanels.map((panel, index) => (
            <Tab key={index} label={panel.label} onClick={() => setSelectedTab(index)} />
          ))}
        </Tabs>
      </Box>

      {tabPanels.map((panel, index) => (
        <Box key={index} role="tabpanel" hidden={selectedTab !== index} sx={{ p: 3 }}>
          <TabContent content={panel.content} isSelected={selectedTab === index} />
        </Box>
      ))}
    </div>
  )
}

export { AssetAlignmentWizard }
