import { EngineTechSpec } from '@flyward/assets'
import { AssetVerification } from '@flyward/assets/components/AircraftComponents/AircraftDetails/Verify'
import { cn, OutletTabs, useNavigationState } from '@flyward/platform'
import { formatAxiosErrorMessage } from '@flyward/platform/helpers/ErrorHelpers'
import { showError } from '@flyward/platform/services'
import { useGetAllEnginesByAssetIdQuery, useGetAllStandaloneEnginesByAssetIdQuery } from '@flyward/platform/store'
import { isNil } from 'lodash'
import { useCallback, useEffect, useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { StandaloneEngineMaintenanceProgramSelection } from './StandaloneEngineMaintenanceProgramSelection'
import { AssetComponentsFormsProvider, useAssetComponentsForms } from '@flyward/assets/context'
import { FWBreadcrumbs } from '@flyward/main-app/layout/MasterLayout/Header/FWBreadcrumbs.tsx'
import { ROUTES } from '@flyward/main-app/providers/routes.ts'
import { Button as MuiButton } from '@mui/material'
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff'
import queryString from 'query-string'
import { AuthorizedElement } from '@flyward/appIdentity'
import { EditIcon, SaveIcon } from 'lucide-react'
import { pageContentStyles } from '@flyward/main-app/layout'

export const StandaloneEnginePage = () => (
  <AssetComponentsFormsProvider>
    <StandaloneEnginePageBody />
  </AssetComponentsFormsProvider>
)

const StandaloneEnginePageBody = () => {
  const currentTab = useRef<string>('Tech Spec')

  const onTabChanged = (activeTab: string) => {
    currentTab.current = activeTab
  }

  const { id } = useParams()
  const { toggleVisibility } = useNavigationState()

  const navigate = useNavigate()

  const { hasAnyChanges, isAllValid, isInEditMode, setIsInEditMode, getAllFormRefs } = useAssetComponentsForms()

  const allFormRefs = getAllFormRefs()

  // Memoize submit handler
  const submitAllForms = useCallback(() => {
    allFormRefs.forEach((formRef) => {
      if (formRef.submitRef?.current !== null) {
        formRef.submitRef.current.click()
      }
    })
  }, [allFormRefs])

  const resetAllForms = useCallback(() => {
    allFormRefs.forEach((formRef) => {
      if (formRef.resetRef?.current !== null) {
        formRef.resetRef.current.click()
      }
    })
  }, [allFormRefs])

  const assetId: string = id ?? ''

  const { data: engines = [], isFetching: isFetchingEngines, isLoading: isLoadingEngines } = useGetAllEnginesByAssetIdQuery({ assetId, includeLLPs: true })
  const { data: standaloneEngines, error: getStandaloneEngineError } = useGetAllStandaloneEnginesByAssetIdQuery({ assetId })

  useEffect(() => {
    if (!isNil(getStandaloneEngineError)) {
      showError(formatAxiosErrorMessage(getStandaloneEngineError?.message))
    }
  }, [getStandaloneEngineError])

  const isLoading = isFetchingEngines || isLoadingEngines

  useEffect(() => {
    toggleVisibility()

    return () => {
      toggleVisibility()
    }
  }, [toggleVisibility, assetId])

  const engineAssignedKBProgramId = engines?.[0]?.assignedKBProgramId ?? ''

  const tabs = {
    'Tech Spec': {
      content: <EngineTechSpec assetId={assetId} engine={engines?.[0]} standAloneEngine={standaloneEngines} />,
    },
    'Maintenance Programme': {
      content: <StandaloneEngineMaintenanceProgramSelection assetId={assetId} engines={engines} />,
    },
  }

  return (
    <>
      {/* Header */}
      <div className="flex h-20 w-full items-center justify-between px-6">
        <FWBreadcrumbs breadcrumbs={[{ title: 'Fleet Summary', url: ROUTES.FLEET.ROOT }]} currentTitle="View Engine" />

        <div className="flex items-center gap-4 px-3">
          <AuthorizedElement>
            <div data-permission-element-id="read-only-asset-view">
              <AssetVerification msn={engines?.[0]?.componentSerialNumber} isLoading={isLoading} assetId={assetId} selectedEngineProgramId={engineAssignedKBProgramId} readOnly />
            </div>
          </AuthorizedElement>

          <AuthorizedElement>
            <div data-permission-element-id="edit-asset" className={'flex items-center  gap-4'}>
              <AssetVerification msn={engines?.[0]?.componentSerialNumber} isLoading={isLoading} assetId={assetId} selectedEngineProgramId={engineAssignedKBProgramId} />
              <MuiButton
                variant={isInEditMode ? 'outlined' : 'contained'}
                color="primary"
                startIcon={isInEditMode ? null : <EditIcon />}
                disabled={currentTab.current === 'Maintenance Programme'}
                onClick={() => {
                  if (isInEditMode) {
                    resetAllForms()
                    setIsInEditMode(false)
                  } else {
                    setIsInEditMode(true)
                  }
                }}
                style={{ minWidth: '5.4rem' }}
              >
                {isInEditMode ? 'Cancel' : 'Edit'}
              </MuiButton>
              <MuiButton
                variant="contained"
                color="primary"
                disabled={!isAllValid || !hasAnyChanges}
                startIcon={<SaveIcon />}
                onClick={() => {
                  submitAllForms()
                  setIsInEditMode(false)
                }}
              >
                Save
              </MuiButton>
            </div>
          </AuthorizedElement>

          <MuiButton
            variant="contained"
            color="primary"
            disabled={isInEditMode}
            startIcon={<FlightTakeoffIcon />}
            onClick={() => {
              navigate(`${ROUTES.FLY_FORWARD}/?${queryString.stringify({ assetIds: [id] })}`)
            }}
          >
            Fly
          </MuiButton>
        </div>
      </div>
      {/* Main Pane */}
      <main className={cn('h-full flex-1', pageContentStyles)}>
        <OutletTabs tabs={tabs} activeTab={currentTab.current} onTabChanged={onTabChanged} />
      </main>
    </>
  )
}
