import { AirframeCheckTypeOptions, type AirframeCheckType } from '@flyward/platform/models'
import DeleteIcon from '@mui/icons-material/Delete'
import { IconButton, Link, MenuItem, Select, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@mui/material'
import { useState } from 'react'
import { NumericFormat } from '../common/NumericFormat'

const duplicateStyle = {
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'red',
    },
    '&:hover fieldset': {
      borderColor: 'red',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'red',
    },
  },
}

interface TableRowData {
  id: number
  checkType: AirframeCheckType
  limit: string
  cost: string
}

interface ChecksTableProps {
  rows: TableRowData[]
  onRowChange: (_id: number, _field: keyof TableRowData, _value: any) => void
  onAddRow: () => void
  onDeleteRow: (_id: number) => void
}

const ChecksTable = ({ rows, onRowChange, onAddRow, onDeleteRow }: ChecksTableProps) => {
  const [touchedFields, setTouchedFields] = useState<Record<number, Record<string, boolean>>>({})

  const handleBlur = (rowId: number, field: string) => {
    setTouchedFields((prev) => ({
      ...prev,
      [rowId]: {
        ...(prev[rowId] || {}),
        [field]: true,
      },
    }))
  }

  const checkDuplicateImpactType = (currentId: number, checkType: AirframeCheckType): boolean => {
    if (!checkType) return false
    return rows.some((row) => row.id !== currentId && row.checkType === checkType)
  }

  const handleInputChange = (id: number, field: keyof TableRowData, value: any) => {
    onRowChange(id, field, value)
  }

  const hasError = (rowId: number, field: string, value: string) => {
    const isTouched = touchedFields[rowId]?.[field]
    const isRequired = field === 'description' || field === 'limit' || field === 'cost'
    return isTouched && isRequired && !value.trim()
  }

  return (
    <>
      <Table size={'small'}>
        <TableHead>
          <TableRow sx={{ '& th': { borderBottom: '1px solid rgba(0, 0, 0, 0.12)', backgroundColor: 'transparent' } }}>
            <TableCell sx={{ whiteSpace: 'nowrap' }}>Event Type *</TableCell>
            <TableCell sx={{ whiteSpace: 'nowrap' }}>Limit [Months] *</TableCell>
            <TableCell sx={{ whiteSpace: 'nowrap' }}>Cost *</TableCell>
            <TableCell width={50} />
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => {
            const isDuplicate = checkDuplicateImpactType(row.id, row.checkType)

            return (
              <TableRow key={row.id} sx={{ '& td': { border: 'none' } }}>
                <TableCell>
                  <Select
                    fullWidth
                    value={row.checkType}
                    onChange={(e) => handleInputChange(row.id, 'checkType', Number(e.target.value))}
                    error={isDuplicate}
                    sx={isDuplicate ? { ...duplicateStyle, minWidth: 200 } : { minWidth: 200 }}
                  >
                    {AirframeCheckTypeOptions.map(({ value, label }) => (
                      <MenuItem key={value} value={value}>
                        {label}
                      </MenuItem>
                    ))}
                  </Select>
                </TableCell>

                <TableCell>
                  <TextField
                    fullWidth
                    value={row.limit}
                    onBlur={() => handleBlur(row.id, 'limit')}
                    error={!!hasError(row.id, 'limit', row.limit)}
                    InputProps={{
                      inputComponent: NumericFormat,
                    }}
                    onChange={(e) => onRowChange(row.id, 'limit', e.target.value)}
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    fullWidth
                    value={row.cost}
                    onBlur={() => handleBlur(row.id, 'cost')}
                    error={!!hasError(row.id, 'cost', row.cost)}
                    InputProps={{
                      inputComponent: NumericFormat,
                      startAdornment: '$',
                    }}
                    onChange={(e) => onRowChange(row.id, 'cost', e.target.value)}
                  />
                </TableCell>
                <TableCell width={50}>
                  {rows.length > 1 && (
                    <IconButton size="small" onClick={() => onDeleteRow(row.id)} sx={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  )}
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
      <Link
        component="button"
        variant="body2"
        onClick={onAddRow}
        sx={{
          mt: 2,
          textDecoration: 'none',
          '&:hover': {
            textDecoration: 'none',
          },
        }}
      >
        + Add another row
      </Link>
    </>
  )
}

export { ChecksTable }
