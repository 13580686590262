import { ElementForSupportUser } from '@flyward/appIdentity'
import { MUISelect } from '@flyward/platform/components/MUISelect'
import { useTenantSelector } from '../hooks/useTenantSelector'

interface TenantSelectorProps {
  isCollapsed: boolean
}

export const TenantSelector = ({ isCollapsed }: TenantSelectorProps) => {
  const { tenantsList, supportFilteredTenantId, handleTenantChange, hasSelectedTenant } = useTenantSelector()

  if (isCollapsed) {
    return null
  }

  return (
    <MUISelect
      options={tenantsList}
      value={hasSelectedTenant ? supportFilteredTenantId : ''}
      onChange={(value) => handleTenantChange([value])}
      label="Tenant"
      id="tenantSelector"
    />
  )
}
