import { RedeliveryConditionsEditable } from '@flyward/assets/components/AircraftComponents/common'
import { getLabelContent } from '@flyward/assets/helpers/assetHelpers'
import { type ComponentMaintenanceReserveRate, type LandingGearDto } from '@flyward/assets/models'
import {
  CheckTypeDisplay,
  EditableBooleanCellWithLabelWithLabel,
  EditableInputCellWithLabelWithLabel,
  InputType,
  MaintenanceReserveCollectionTypeDisplay,
  TextCellWithLabel,
  UtilizationUnitsDisplay,
} from '@flyward/platform'
import { isNil } from 'lodash'
import { useState, useEffect } from 'react'
import { type Control } from 'react-hook-form'

interface ILGContractualEditableTabProps {
  component: LandingGearDto
  formControl: Control<LandingGearDto, unknown>
}

export const LGContractualEditableTab = ({ component, formControl }: ILGContractualEditableTabProps) => {
  const maintenanceReserve = component.landingGearContract.landingGearMaintenanceReserveContract
  const rates = [maintenanceReserve.landingGearMaintenanceReserveRate]
  const contractDeliverySnapshot = component.landingGearContract.contractDeliverySnapshot
  const minimumRedeliveryConditions = component.landingGearContract.minimumReDeliveryConditions
  const [dynamicLabel, setDynamicLabel] = useState<string>('')

  useEffect(() => {
    setDynamicLabel(getLabelContent(maintenanceReserve.isEolActive, maintenanceReserve.isMaintenanceReserveActive))
  }, [maintenanceReserve.isEolActive, maintenanceReserve.isMaintenanceReserveActive])

  const Rates = rates.map((rate: ComponentMaintenanceReserveRate) => {
    if (isNil(rate)) return null
    return (
      <div key={rate.checkType} className="flex w-full gap-x-6 gap-y-4">
        <EditableBooleanCellWithLabelWithLabel
          className="basis-1/6"
          label="MR Active"
          formControl={formControl}
          fieldName={`landingGearContract.landingGearMaintenanceReserveContract.isMaintenanceReserveActive`}
        />
        <EditableInputCellWithLabelWithLabel
          inputType={InputType.Currency}
          className="basis-1/6"
          label={`${CheckTypeDisplay(rate.checkType)} ${dynamicLabel} rate`}
          formControl={formControl}
          fieldName={`landingGearContract.landingGearMaintenanceReserveContract.landingGearMaintenanceReserveRate.rateAmount`}
          preventInfo={!maintenanceReserve.isMaintenanceReserveActive && !maintenanceReserve.isEolActive}
        />
        <TextCellWithLabel
          className="basis-1/6"
          label={`${CheckTypeDisplay(rate.checkType)} ${dynamicLabel} rate type`}
          info={UtilizationUnitsDisplay(rate.utilizationUnit)}
          preventInfo={!maintenanceReserve.isMaintenanceReserveActive && !maintenanceReserve.isEolActive}
        />
        <EditableInputCellWithLabelWithLabel
          inputType={InputType.Year}
          className="basis-1/6"
          label={`${CheckTypeDisplay(rate.checkType)} ${dynamicLabel} rate year`}
          formControl={formControl}
          fieldName={`landingGearContract.landingGearMaintenanceReserveContract.landingGearMaintenanceReserveRate.ratesYear`}
          preventInfo={!maintenanceReserve.isMaintenanceReserveActive && !maintenanceReserve.isEolActive}
        />
        <TextCellWithLabel
          className="basis-1/6"
          label={`${CheckTypeDisplay(rate.checkType)} MR collection type`}
          info={MaintenanceReserveCollectionTypeDisplay(rate.maintenanceReserveCollectionType)}
          preventInfo={!maintenanceReserve.isMaintenanceReserveActive}
        />
        <EditableInputCellWithLabelWithLabel
          inputType={InputType.IntegerCurrency}
          className="basis-1/6"
          label={`Current ${CheckTypeDisplay(rate.checkType)} ${dynamicLabel} fund`}
          formControl={formControl}
          fieldName={`landingGearContract.landingGearMaintenanceReserveContract.landingGearMaintenanceReserveRate.currentFund`}
          isReadOnly={!maintenanceReserve.isMaintenanceReserveActive && !maintenanceReserve.isEolActive}
        />
      </div>
    )
  })

  return (
    <div className="flex w-full flex-col gap-y-2">
      <div className="flex w-full flex-col gap-x-6 gap-y-2">{Rates}</div>
      <div className="flex w-full gap-x-1">
        <EditableBooleanCellWithLabelWithLabel
          className="basis-1/6"
          label="EOL Applicable"
          formControl={formControl}
          fieldName={`landingGearContract.landingGearMaintenanceReserveContract.isEolActive`}
        />
        <EditableInputCellWithLabelWithLabel
          inputType={InputType.Percentage}
          className="basis-1/6"
          label={`${dynamicLabel} Escalation`}
          formControl={formControl}
          fieldName={`landingGearContract.escalations.maintenanceReserveAgreedEscalationPercentage`}
          preventInfo={!maintenanceReserve.isMaintenanceReserveActive && !maintenanceReserve.isEolActive}
        />
        <EditableInputCellWithLabelWithLabel
          inputType={InputType.NaturalNumber}
          className="basis-1/6"
          label={`Months since ${CheckTypeDisplay(contractDeliverySnapshot.checkType)} at contract delivery`}
          formControl={formControl}
          fieldName={`landingGearContract.contractDeliverySnapshot.monthsSinceEventAtContractDelivery`}
        />
        <div className="-mr-6 basis-3/6" />
      </div>
      <div className="flex gap-x-6">
        <RedeliveryConditionsEditable
          label="Redelivery Conditions"
          fieldPath="landingGearContract.minimumReDeliveryConditions"
          formControl={formControl}
          conditions={minimumRedeliveryConditions}
        />
        <div className="mr-12 basis-2/6" />
      </div>
    </div>
  )
}
