import { type ReportDisplayType, type IEventViewDetailDto } from '@flyward/platform/models'
import { type ComponentTotalStatisticsAtEvent } from '@flyward/platform/models/entities/ComponentTotalStatisticsAtEvent'
import { MasterComponentEventsRows } from './MasterComponentEventsRows'

export interface MasterComponentWithEvents {
  masterComponentId: string
  eventsOnComponent: IEventViewDetailDto[]
}

interface IComponentsEventsTableContentProps {
  reportDisplayType: ReportDisplayType

  componentsWithEvents: MasterComponentWithEvents[]
  componentTotalStatisticsAtEvent: ComponentTotalStatisticsAtEvent[]
  tdStyle: string
  borderAndTextStyle: string
}

const ComponentsEventsTableContent = ({
  reportDisplayType,
  componentsWithEvents,
  componentTotalStatisticsAtEvent,
  tdStyle,
  borderAndTextStyle,
}: IComponentsEventsTableContentProps) => {
  return (
    <tbody>
      {componentsWithEvents.map((componentEvents) => {
        return (
          <MasterComponentEventsRows
            key={`${componentEvents.masterComponentId}`}
            reportDisplayType={reportDisplayType}
            componentEvents={componentEvents}
            componentTotalStatisticsAtEvent={componentTotalStatisticsAtEvent}
            tdStyle={tdStyle}
            borderAndTextStyle={borderAndTextStyle}
          />
        )
      })}
    </tbody>
  )
}

export { ComponentsEventsTableContent }
