import { APIRoutes } from '@flyward/platform/constants'
import { flywardApi } from '@flyward/platform/store/slices/api/flywardApi/flywardApiSlice'
import {
  type IGetAllAuxiliaryPowerUnitChecksWithDetailsByAssetIdQueryRequest,
  type IGetAllAuxiliaryPowerUnitChecksWithDetailsByAssetIdQueryResponse,
  type IGetAllAuxiliaryPowerUnitsByAssetIdQueryRequest,
  type IGetAllAuxiliaryPowerUnitsByAssetIdQueryResponse,
  type IPutApuMutationRequest,
  type IPutAuxiliaryPowerUnitsMaintenanceProgramMutationRequest,
} from './types'
import { COMPONENT_TYPES, ComponentType } from '@flyward/platform'
import { type AxiosError } from 'axios'

const auxiliaryPowerUnitsApi = flywardApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllAuxiliaryPowerUnitsByAssetId: builder.query<
      IGetAllAuxiliaryPowerUnitsByAssetIdQueryResponse,
      IGetAllAuxiliaryPowerUnitsByAssetIdQueryRequest
    >({
      query: ({ assetId }) => ({
        url: APIRoutes.AssetsModule.Components.AuxiliaryPowerUnitsController.GetAll(assetId),
        method: 'GET',
      }),
      providesTags: (_, __, request) => ['apiData', { type: 'auxiliaryPowerUnits', id: request.assetId }],
    }),
    getAllAuxiliaryPowerUnitChecksWithDetailsByAssetId: builder.query<
      IGetAllAuxiliaryPowerUnitChecksWithDetailsByAssetIdQueryResponse,
      IGetAllAuxiliaryPowerUnitChecksWithDetailsByAssetIdQueryRequest
    >({
      query: ({ assetId }) => ({
        url: APIRoutes.AssetsModule.Components.AuxiliaryPowerUnitsController.GetExpandedAuxiliaryPowerUnits(assetId),
        method: 'GET',
      }),
      providesTags: (_, __, request) => [
        'apiData',
        { type: 'componentChecks', id: `${request.assetId}-${COMPONENT_TYPES[ComponentType.AuxiliaryPowerUnit].id}` },
      ],
    }),
    updateAuxiliaryPowerUnitsMaintenanceProgram: builder.mutation<void, IPutAuxiliaryPowerUnitsMaintenanceProgramMutationRequest>({
      queryFn: async (
        { assetId, kbProgramId, auxiliaryPowerUnits },
        _queryApi,
        _extraOptions,
        baseQuery,
      ): Promise<{ data: void } | { error: AxiosError<unknown, unknown> }> => {
        if (auxiliaryPowerUnits == null || auxiliaryPowerUnits.length === 0) {
          return { data: undefined }
        }

        // Iterate over the auxiliaryPowerUnits and update each one
        for (const apu of auxiliaryPowerUnits) {
          const apuUpdateResponse = await baseQuery({
            url: APIRoutes.AssetsModule.Components.AuxiliaryPowerUnitsController.Update(assetId),
            method: 'PUT',
            data: { ...apu, assignedKBProgramId: kbProgramId },
          })

          // If an error occurs while updating auxiliaryPowerUnits, return early
          if (apuUpdateResponse.error != null) {
            return { error: apuUpdateResponse.error }
          }
        }

        return { data: undefined }
      },
      invalidatesTags: (_, __, request) => [{ type: 'auxiliaryPowerUnits', id: request.assetId }],
    }),
    updateApu: builder.mutation<string, IPutApuMutationRequest>({
      query: ({ assetId, apu }) => ({
        url: APIRoutes.AssetsModule.Components.AuxiliaryPowerUnitsController.Update(assetId),
        method: 'PUT',
        data: apu,
      }),
      invalidatesTags: (_, __, request) => [{ type: 'auxiliaryPowerUnits', id: request.assetId }, { type: 'assets' }],
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetAllAuxiliaryPowerUnitsByAssetIdQuery,
  useGetAllAuxiliaryPowerUnitChecksWithDetailsByAssetIdQuery,
  useUpdateAuxiliaryPowerUnitsMaintenanceProgramMutation,
  useUpdateApuMutation,
  util: auxiliaryPowerUnitsApiSliceUtil,
} = auxiliaryPowerUnitsApi

type Endpoints = typeof auxiliaryPowerUnitsApi.endpoints
type InitiateActions = {
  [K in keyof Endpoints]: ReturnType<ReturnType<Endpoints[K]['initiate']>>
}
export type TAuxiliaryPowerUnitsApiActions = InitiateActions[keyof InitiateActions]
