import { Combobox } from '@flyward/platform/components'
import { type FlyForwardParametersDTO } from '@flyward/platform/models/DTOs/FlyForwardParametersDTO'
import { type RedeliveryConditionSettings } from '@flyward/platform/models/DTOs/RedeliveryConditionSettings'
import { Tooltip as MuiTooltip } from '@mui/material'

interface IEditableAircraftComponentRedeliveryConditionsProps {
  flyForwardParameters: FlyForwardParametersDTO
  componentId: string
  onAircraftComponentWaiveRedeliveryConditionsToggle: (_flyForwardParameters: FlyForwardParametersDTO, _componentId: string, value: boolean) => void
}

const EditableAircraftComponentRedeliveryConditions = ({
  flyForwardParameters,
  componentId,
  onAircraftComponentWaiveRedeliveryConditionsToggle,
}: IEditableAircraftComponentRedeliveryConditionsProps) => {
  const componentRedeliveryConditions: RedeliveryConditionSettings[] | undefined = flyForwardParameters?.nonEngineComponents?.find(
    (t) => t.componentId === componentId,
  )?.redeliveryConditions

  const hasRedeliveryConditions: boolean = (componentRedeliveryConditions ?? []).length > 0

  const waiveRedeliveryConditions: boolean = (componentRedeliveryConditions ?? []).filter((t) => t.waiveCondition).length > 0

  const options = [
    { value: 'true', label: 'Yes' },
    { value: 'false', label: 'No' },
  ]

  const comboBox = (
    <div>
      <Combobox
        items={options}
        itemLabel="label"
        disabled={!hasRedeliveryConditions}
        label="Waive redelivery conditions"
        selectedValues={waiveRedeliveryConditions ? ['true'] : ['false']}
        setSelectedValues={(values: string[]) => {
          onAircraftComponentWaiveRedeliveryConditionsToggle(flyForwardParameters, componentId, values[0] === 'true')
        }}
        className="!w-65"
        hasSearchInput={false}
      />
    </div>
  )

  return (
    <div className="w-fit pt-4">
      {hasRedeliveryConditions ? (
        comboBox
      ) : (
        <MuiTooltip title="No redelivery conditions defined" arrow placement="right">
          {comboBox}
        </MuiTooltip>
      )}
    </div>
  )
}

export { EditableAircraftComponentRedeliveryConditions }
