import { Breadcrumbs, Typography, styled } from '@mui/material'
import { Link } from 'react-router-dom'

const StyledBreadcrumbsBase = styled(Breadcrumbs)({
  '& .MuiBreadcrumbs-li': {
    fontSize: '1.25rem',
  },
  '& a': {
    color: 'var(--text-2)',
    textDecoration: 'none',
    fontWeight: 500,
    fontSize: '1.25rem',
    transition: 'color 0.2s',
    '&:hover': {
      color: 'var(--primary)',
    },
  },
  '& .MuiBreadcrumbs-separator': {
    marginLeft: '0.5rem',
    marginRight: '0.5rem',
  },
  '& .MuiTypography-root': {
    fontSize: '1.25rem',
    fontWeight: 600,
    color: 'var(--text-1)',
  },
})

export const FWBreadcrumbs = ({ breadcrumbs, currentTitle }) => {
  return (
    <StyledBreadcrumbsBase separator={<Typography>›</Typography>} aria-label="breadcrumb">
      {breadcrumbs.map((breadcrumb, index) => (
        <Link
          key={index}
          to={breadcrumb.url}
          onClick={
            breadcrumb.onBreadcrumbClick
              ? (e) => {
                  e.preventDefault()
                  breadcrumb.onBreadcrumbClick()
                }
              : undefined
          }
        >
          {breadcrumb.title}
        </Link>
      ))}
      <Typography className="text-base font-semibold text-text-1">{currentTitle}</Typography>
    </StyledBreadcrumbsBase>
  )
}
