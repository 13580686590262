import React, { useEffect, useState } from 'react'
import { Box, Dialog, DialogContent, DialogTitle, LinearProgress, Typography, Button } from '@mui/material'

export interface ProgressStep {
  message: string
  progressStart: number
  duration: number // in seconds
}

interface FullPageProgressBarProps {
  isOpen: boolean
  onComplete?: () => void
  onCancel?: () => void
  title: string
  subtitle?: string
  steps: ProgressStep[]
}

export const FullPageProgressBar: React.FC<FullPageProgressBarProps> = ({ isOpen, onComplete, onCancel, title, subtitle, steps }) => {
  const [progress, setProgress] = useState(0)
  const [currentMessage, setCurrentMessage] = useState(steps[0]?.message ?? '')
  const [showExtendedTimeMessage, setShowExtendedTimeMessage] = useState(false)

  useEffect(() => {
    if (!isOpen || steps.length === 0) {
      setProgress(0)
      setCurrentMessage(steps[0]?.message ?? '')
      setShowExtendedTimeMessage(false)
      return
    }

    const getCurrentStepIndex = (currentProgress: number): number =>
      steps.findIndex((_step, index) => (index == steps.length - 1 ? true : currentProgress < steps[index + 1].progressStart))

    const intervalFrequency = 1.5 // seconds

    const interval = setInterval(() => {
      setProgress((currentProgress) => {
        const stepIndex = getCurrentStepIndex(currentProgress)
        const currentStep = steps[stepIndex]

        const nextStepProgress = stepIndex === steps.length - 1 ? 100 : steps[stepIndex + 1].progressStart

        setCurrentMessage(currentStep.message)

        // Calculate progress
        const progressIncrease = intervalFrequency * ((nextStepProgress - currentStep.progressStart) / currentStep.duration)
        const finalProgress = currentProgress + progressIncrease

        // Cap at 95% and show extended time message
        if (finalProgress > 95) {
          setShowExtendedTimeMessage(true)
        }

        return Math.min(finalProgress, 95)
      })
    }, intervalFrequency * 1000)

    return () => clearInterval(interval)
  }, [isOpen, onComplete, steps])

  return (
    <Dialog
      open={isOpen}
      fullWidth
      maxWidth="sm"
      PaperProps={{
        sx: {
          borderRadius: 2,
          p: 2,
        },
      }}
    >
      <DialogTitle>
        {title}
        {subtitle && (
          <Typography
            variant="subtitle1"
            color="text.secondary"
            sx={{
              mt: 1,
              fontSize: '0.875rem',
              fontWeight: 'normal',
            }}
          >
            {subtitle}
          </Typography>
        )}
      </DialogTitle>
      <DialogContent>
        <Box sx={{ width: '100%', p: 3 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
            <Box sx={{ width: '100%', mr: 1 }}>
              <LinearProgress
                value={progress}
                sx={{
                  height: 8,
                  borderRadius: 4,
                  backgroundColor: 'var(--primary-light-2)',
                  '& .MuiLinearProgress-bar': {
                    backgroundColor: 'var(--primary-dark-1)',
                    borderRadius: 4,
                    animationDuration: '6s',
                  },
                }}
              />
            </Box>
          </Box>
          <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
            {currentMessage}
          </Typography>
          {showExtendedTimeMessage && (
            <Box sx={{ mt: 2 }}>
              <Typography variant="body2" color="warning.main" sx={{ mb: 2 }}>
                This is taking longer than usual. Please wait while we complete the analysis...
              </Typography>
              {/*<Button variant="outlined" color="warning" onClick={onCancel} sx={{ mt: 1 }}>
                Cancel Process
              </Button>*/}
            </Box>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  )
}
