import { useEffect, useMemo, useState } from 'react'
import { useReactTable, getCoreRowModel, getExpandedRowModel, type ColumnDef, type Row } from '@tanstack/react-table'
import { format } from 'date-fns'
import { type AssetSearchDTO } from '../../models'
import {
  AssetType,
  CellWithExpander,
  ExpandableRow,
  getAssetAge,
  cn,
  Pagination,
  getAssetTypeDisplayName,
  TextCellWithLabel,
  PageSizes,
  type PaginatedResult,
  ButtonWithDialog,
  IconVariant,
} from '@flyward/platform'
import { type NavigateFunction, useNavigate } from 'react-router-dom'
import { isEmpty } from 'lodash'
import { AssetIdentificationCell } from './SelectionCell'
import { AircraftExpandedDataGridWrapper } from './AircraftExpandedDataGridWrapper'
import { StandaloneEngineExpandedDataGridWrapper } from './StandaloneEngineExpandedDataGridWrapper'
import { useAppDispatch } from '@flyward/platform/store/configureHooks'
import { setSelectedAssetIds } from '@flyward/platform/store'
import { IntermediateCheckbox } from './SelectionCell/IntermediateCheckbox/IntermediateCheckbox'
import { AuthorizedElement } from '@flyward/appIdentity'
import { isArrayEmptyOrNull } from '@flyward/platform/helpers/arrayHelpers'
import { longDateFormat } from '@flyward/platform/helpers/dateHelpers'
import { ROUTES } from '@flyward/main-app/providers/routes'
import { Box } from '@mui/material'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'

const getFormattedValue = (getValue: () => unknown) => {
  const value = getValue()
  return value as string
}

const getFormattedDate = (getValue: () => unknown) => {
  const value = getFormattedValue(getValue)
  if (value === undefined) {
    return '-'
  }
  const date = new Date(value)
  return format(date, longDateFormat)
}

const getAge = (getValue: () => unknown) => {
  const value = getFormattedValue(getValue)
  return getAssetAge(value)
}

const actionCell = (row: Row<AssetSearchDTO>, onDelete: (reportId: string) => Promise<string | undefined>) => {
  return (
    <AuthorizedElement>
      <ButtonWithDialog
        data-permission-element-id="delete-any-asset"
        isExpanded={row.getIsExpanded()}
        id={row.original.assetId}
        name={row.original.serialNumber}
        onConfirm={onDelete}
        type="asset"
        dataPermissionId="delete-any-asset"
        actionLabel="Delete"
        icon={IconVariant.Delete}
      />
    </AuthorizedElement>
  )
}

const generateColumns = (navigate: NavigateFunction, onDelete: (assetId: string) => Promise<string | undefined>): Array<ColumnDef<AssetSearchDTO>> => [
  {
    id: 'select',
    accessorKey: 'serialNumber',
    header: () => null,
    meta: {
      colClassName: 'min-w-10',
      isClickable: false,
    },
    cell: ({ row }) => (
      <div className="flex">
        <IntermediateCheckbox
          {...{
            checked: row.getIsSelected(),
            disabled: !row.getCanSelect(),
            indeterminate: row.getIsSomeSelected(),
            onChange: (e) => {
              e.stopPropagation()
              row.getToggleSelectedHandler()(e)
            },
          }}
        />
      </div>
    ),
  },
  {
    id: 'assetIdentification',
    accessorKey: 'serialNumber',
    header: () => null,
    meta: {
      colClassName: 'basis-2/24 min-w-42',
      isClickable: true,
    },
    cell: ({ getValue, row }) => <AssetIdentificationCell serialNumber={getFormattedValue(getValue)} assetType={row.original.assetType} isExpanded={row.getIsExpanded()} />,
  },
  {
    accessorKey: 'lessee',
    header: () => null,
    meta: {
      colClassName: 'basis-4/24',
      isClickable: true,
    },
    cell: ({ getValue, row }) => (
      <Box className="cursor-pointer">
        <TextCellWithLabel
          label="Lessee"
          info={getFormattedValue(getValue)}
          labelClassName={`truncate ${row.getIsExpanded() && 'text-black-20'}`}
          infoClassName={`truncate ${row.getIsExpanded() && 'text-text-4'}`}
        />
      </Box>
    ),
  },
  {
    accessorKey: 'region',
    header: () => null,
    meta: {
      colClassName: 'basis-3/24',
      isClickable: true,
    },
    cell: ({ getValue, row }) => (
      <Box className="cursor-pointer">
        <TextCellWithLabel
          label="Region"
          info={getFormattedValue(getValue)}
          labelClassName={`truncate ${row.getIsExpanded() && 'text-black-20'}`}
          infoClassName={`truncate ${row.getIsExpanded() && 'text-text-4'}`}
        />
      </Box>
    ),
  },
  {
    accessorKey: 'aircraftModel',
    header: () => null,
    meta: {
      colClassName: 'basis-3/24',
      isClickable: true,
    },
    cell: ({ getValue, row }) => {
      const value = getFormattedValue(getValue)
      return (
        <Box className="cursor-pointer">
          <TextCellWithLabel
            label={'Aircraft Model'}
            info={value}
            labelClassName={cn(row.getIsExpanded() && 'text-black-20', isEmpty(value) && 'select-none text-transparent	')}
            infoClassName={`truncate ${row.getIsExpanded() && 'text-text-4'}`}
          />
        </Box>
      )
    },
  },
  {
    accessorKey: 'engineModel',
    header: () => null,
    meta: {
      colClassName: 'basis-3/24',
      isClickable: true,
    },
    cell: ({ getValue, row }) => (
      <Box className="cursor-pointer">
        <TextCellWithLabel
          label="Engine Model"
          info={getFormattedValue(getValue)}
          labelClassName={`truncate ${row.getIsExpanded() && 'text-black-20'}`}
          infoClassName={`truncate ${row.getIsExpanded() && 'text-text-4'}`}
        />
      </Box>
    ),
  },
  {
    id: 'assetAge',
    accessorKey: 'dom',
    meta: {
      colClassName: 'basis-2/24',
    },
    header: () => null,
    cell: ({ getValue, row }) => (
      <TextCellWithLabel
        label={`${getAssetTypeDisplayName(row.original.assetType)} Age`}
        info={getAge(getValue)}
        labelClassName={`truncate ${row.getIsExpanded() && 'text-black-20'}`}
        infoClassName={`truncate ${row.getIsExpanded() && 'text-text-4'}`}
      />
    ),
  },
  {
    accessorKey: 'redeliveryDate',
    header: () => null,
    meta: {
      colClassName: 'basis-4/24',
      isClickable: true,
    },
    cell: ({ getValue, row }) => (
      <Box className="cursor-pointer">
        <TextCellWithLabel
          label="Redelivery Date"
          info={getFormattedDate(getValue)}
          labelClassName={`truncate ${row.getIsExpanded() && 'text-black-20'}`}
          infoClassName={`truncate ${row.getIsExpanded() && 'text-text-4'}`}
        />
      </Box>
    ),
  },
  {
    accessorKey: 'assetId',
    header: () => null,
    meta: {
      colClassName: 'basis-1/24',
    },
    cell: ({ row }) => actionCell(row, onDelete),
  },
  {
    id: 'expander',
    meta: {
      colClassName: 'basis-1/24',
    },
    header: () => null,
    cell: ({ row }) => <CellWithExpander<AssetSearchDTO> row={row} />,
  },
]

const getExpandedComponent = (assetType: AssetType, assetId: string) => {
  if (isEmpty(assetId)) return undefined
  return assetType === AssetType.Aircraft ? AircraftExpandedDataGridWrapper : StandaloneEngineExpandedDataGridWrapper
}

interface IAssetsDataGridProps {
  fleet: PaginatedResult<AssetSearchDTO>
  setCurrentPage: (page: number) => void
  setPageSize: (size: PageSizes) => void
  currentPage: number
  pageSize: PageSizes
  onDelete: (assetId: string) => Promise<string | undefined>
}

export const AssetsDataGrid = ({ fleet, setCurrentPage, setPageSize, currentPage, pageSize, onDelete }: IAssetsDataGridProps) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const columns = useMemo(() => generateColumns(navigate, onDelete), [navigate, onDelete])

  const [rowSelection, setRowSelection] = useState({})

  const table = useReactTable<AssetSearchDTO>({
    data: fleet.items,
    columns,
    getRowCanExpand: () => true,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    enableRowSelection: true,
    state: {
      rowSelection,
    },
    onRowSelectionChange: (newRowSelection) => {
      setRowSelection(newRowSelection)
    },
  })

  useEffect(() => {
    const selectedIds = table.getSelectedRowModel().flatRows.map((row) => row.original.assetId)
    dispatch(
      setSelectedAssetIds({
        selectedAssetIds: selectedIds,
      }),
    )
  }, [rowSelection])

  const totalPages = pageSize === PageSizes.All ? 1 : Math.ceil(fleet.totalCount / pageSize)

  return isArrayEmptyOrNull(fleet.items) ? (
    <div className="flex h-[calc(100vh-14rem)] w-full items-center justify-center">
      <p className="mt-6 text-sm text-text-1">No assets found</p>
    </div>
  ) : (
    <div className="flex h-full w-full flex-col justify-between font-normal">
      <div className="block h-[calc(100vh-15.5rem)] w-full overflow-x-auto">
        <table className="w-full">
          <tbody className="w-full">
            {table.getRowModel().rows.map((row) => (
              <ExpandableRow
                key={row.original.assetId}
                row={row}
                expandedComponent={getExpandedComponent(row.original.assetType, row.original.assetId)}
                onRowClick={() => {
                  if (row.original.assetType === AssetType.Aircraft) {
                    navigate(`${ROUTES.FLEET.AIRCRAFTS}/${row.original.assetId}`)
                  } else {
                    navigate(`${ROUTES.FLEET.ENGINES}/${row.original.assetId}`)
                  }
                }}
              />
            ))}
          </tbody>
        </table>
      </div>
      <Pagination
        entityLabel="Assets"
        currentPage={currentPage}
        totalPages={totalPages}
        pageSize={pageSize}
        isLoading={false}
        totalCount={fleet.totalCount}
        setCurrentPage={setCurrentPage}
        setPageSize={setPageSize}
      />
    </div>
  )
}

export default AssetsDataGrid
