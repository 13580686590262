import { flexRender, type Row } from '@tanstack/react-table'
import { cn } from '../../utils'
import { Fragment } from 'react'

export interface ExpandableRowProps<T> {
  row: Row<T>
  expandedComponent?: ({ data }: { data: T }) => JSX.Element
  onRowClick?: () => void
}

export const ExpandableRow = <T,>({ row, expandedComponent: ExpandedComponent, onRowClick }: ExpandableRowProps<T>) => {
  return (
    <Fragment key={row.id}>
      <tr>
        <td colSpan={row.getVisibleCells().length} className="p-0">
          <div className={`border-t border-primary-light-2 p-0 ${row.getIsExpanded() ? 'mx-0' : 'mx-4'}`} />
        </td>
      </tr>
      <tr className={cn('flex w-full items-center justify-between px-4 py-2', row.getIsExpanded() && 'bg-primary-dark-1 text-text-4', !row.getIsExpanded() && 'hover:bg-black-2')}>
        {row.getVisibleCells().map((cell, index, cells) => {
          const isFirstColumn = index === 0
          const isSecondColumn = index === 1
          const isLastTwoColumns = index >= cells.length - 2
          const isClickableColumn = !isFirstColumn && !isLastTwoColumns

          return (
            <td
              key={cell.id}
              className={cn(
                'overflow-hidden truncate',
                // Remove gap only between first and second column
                !isFirstColumn && !isSecondColumn && !isLastTwoColumns && 'pl-10',
                isClickableColumn && onRowClick && 'cursor-pointer',
                (cell.column.columnDef.meta as { colClassName?: string })?.colClassName,
              )}
              onClick={(e) => {
                if (isClickableColumn && onRowClick) {
                  e.stopPropagation()
                  onRowClick()
                }
              }}
            >
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </td>
          )
        })}
      </tr>
      {row.getIsExpanded() && (
        <tr className="w-full">
          <td colSpan={row.getVisibleCells().length} className="w-full p-0">
            {ExpandedComponent !== undefined ? <ExpandedComponent data={row.original} /> : <div>Loading...</div>}
          </td>
        </tr>
      )}
    </Fragment>
  )
}
