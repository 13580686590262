import { useState } from 'react'
import { type IReportAsset } from '@flyward/platform/store'
import { ReportItemCustomExcelExtractConfigDialog } from './ReportItemCustomExcelExtractConfigDialog'
import { Button } from '@mui/material'
import TuneIcon from '@mui/icons-material/Tune'

interface IReportItemCustomExcelExtractBtnProps {
  reportId: string
  reportItems: IReportAsset[]
  disabled?: boolean
}

export const ReportItemCustomExcelExtractBtn = ({ reportId, reportItems, disabled = false }: IReportItemCustomExcelExtractBtnProps) => {
  const [isCustomExportConfigModalOpen, setIsCustomExportConfigModalOpen] = useState(false)

  return (
    <>
      <Button
        key={`excel-custom-extract-${reportId}`}
        variant="outlined"
        startIcon={<TuneIcon className="h-5 w-5" />}
        onClick={() => {
          setIsCustomExportConfigModalOpen(true)
        }}
        disabled={disabled}
        className="mr-1 h-9"
        sx={{
          borderColor: 'rgba(0, 0, 0, 0.23)',
          '&:hover': {
            borderColor: 'primary.main',
          },
          '&.Mui-disabled': {
            backgroundColor: 'var(--black-5)',
            borderColor: 'var(--black-20)',
            '& .MuiSvgIcon-root, & svg': {
              color: 'var(--black-40)',
            },
          },
          '& .MuiButton-startIcon': {
            marginRight: 0,
            marginLeft: 0,
          },
        }}
      />
      <ReportItemCustomExcelExtractConfigDialog
        key={`custom-extract-${reportId}`}
        isCustomExportConfigModalOpen={isCustomExportConfigModalOpen}
        setIsCustomExportConfigModalOpen={setIsCustomExportConfigModalOpen}
        reportId={reportId}
        reportItems={reportItems}
      />
    </>
  )
}
