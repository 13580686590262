import { cn, errorMessages, LoadingFallback, ReportDisplayType, ReportDisplayTypeOptions, useHeaderContent, useNavigationState } from '@flyward/platform'
import { useGetReportDetailsQuery } from '@flyward/platform/store/slices'
import { isEmpty, isNil, uniqBy } from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { pageContentStyles } from '../../../layout'
import { ReportItemAssets } from './ReportItemAssets'
import { IndividualReportItemBody, PortfolioReportBody } from './ReportBody'
import { ReportExcelExtract, ReportItemExcelExtract } from '@flyward/forecasts/components'
import { showError } from '@flyward/platform/services'
import { type IndexedAssetWithReportItem } from '@flyward/forecasts/models'
import { ROUTES } from '@flyward/main-app/providers/routes.ts'
import { FWBreadcrumbs } from '@flyward/main-app/layout/MasterLayout/Header/FWBreadcrumbs.tsx'
import { MUISelect } from '@flyward/platform/components/MUISelect'

const breadcrumbs = [{ title: 'Reports', url: ROUTES.REPORTS }]

const ReportPage = () => {
  const { id: reportId } = useParams()
  const { setTitle, setHasBackButton } = useHeaderContent()
  const { toggleVisibility } = useNavigationState()

  const [reportDisplayType, setReportDisplayType] = useState(ReportDisplayType.AssetReport)

  const onGetReportError = useCallback(() => {
    showError(errorMessages.reports.loadingError)
  }, [])

  const {
    data: report,
    isError: isReportError,
    isLoading: isReportLoading,
    error: reportError,
  } = useGetReportDetailsQuery(
    { reportId: reportId! },
    {
      skip: isNil(reportId) || isEmpty(reportId),
    },
  )

  const [selectedReportItemId, setSelectedReportItemId] = useState<string | null>(report?.assetWithReportItemList?.[0]?.reportItemId ?? null)
  const [selectedIndex, setSelectedIndex] = useState<number>(0)
  const assetWithReportItemListWithIndexZero: IndexedAssetWithReportItem[] =
    report?.assetWithReportItemList?.map((reportItem) => {
      return {
        ...reportItem,
        index: 0,
      }
    }) ?? []

  const maxIndexOnReportItemArrayForAssetId = (array: IndexedAssetWithReportItem[]) => {
    array.forEach((a, index) => {
      if (index === 0) {
        a.index = 0
      } else if (a.assetId === array[index - 1].assetId) {
        a.index = array[index - 1].index + 1
      } else {
        a.index = 0
      }
    })

    return array
  }

  const IndexedReportItems: IndexedAssetWithReportItem[] = maxIndexOnReportItemArrayForAssetId(assetWithReportItemListWithIndexZero)

  const handleAssetSelection = (reportId: string, index: number) => {
    setSelectedReportItemId(reportId)
    setSelectedIndex(index)
  }

  useEffect(() => {
    if (isReportError) {
      onGetReportError()
    }
  }, [isReportError, onGetReportError])

  const getReportItemIds = () => {
    if (!isNil(report) && !isEmpty(report)) {
      return report.assetWithReportItemList
        .map((asset) => ({
          reportItemId: asset.reportItemId,
          assetSerialNumber: asset.assetSerialNumber,
        }))
        .filter((item): item is { reportItemId: string; assetSerialNumber: string } => item.reportItemId !== undefined)
    }
    return []
  }

  useEffect(() => {
    setHasBackButton(true)
    toggleVisibility()

    return () => {
      toggleVisibility()
    }
  }, [setHasBackButton, toggleVisibility])

  useEffect(() => {
    setTitle(`Report ${report?.reportName ?? ''}`)
    setSelectedReportItemId(report?.assetWithReportItemList?.[0]?.reportItemId ?? null)

    return () => {
      setTitle('')
    }
  }, [setTitle, report])

  if (isNil(report) || !isNil(reportError) || isReportLoading) {
    return (
      <span data-testid="loading-report">
        <LoadingFallback />
      </span>
    )
  }

  const distinctAssets = uniqBy(IndexedReportItems, 'assetSerialNumber')
  const hasOnlyDistinctAssets = distinctAssets.length === IndexedReportItems.length
  const showReportDisplayTypeSwitch = !isEmpty(reportId) && !isNil(reportId) && IndexedReportItems.length > 1 && hasOnlyDistinctAssets

  return (
    <>
      {/* Header */}
      <div className="flex h-20 w-full items-center justify-between px-6">
        <FWBreadcrumbs breadcrumbs={breadcrumbs} currentTitle={`Report ${report?.reportName ?? ''}`} />
        <div className="flex items-center gap-4">
          {showReportDisplayTypeSwitch && (
            <MUISelect
              options={ReportDisplayTypeOptions.map((option) => ({
                label: option.label,
                value: option.value.toString(),
              }))}
              value={reportDisplayType.toString()}
              onChange={(value) => setReportDisplayType(Number(value))}
              label="Report Type"
              id="reportTypeSelector"
              className="!w-44"
            />
          )}

          {!isEmpty(reportId) && !isNil(reportId) && (
            <>
              {reportDisplayType === ReportDisplayType.AssetReport ? (
                <ReportItemExcelExtract key={`extract-${reportId}`} reportId={reportId} reportItems={getReportItemIds()} className="h-9" />
              ) : (
                <ReportExcelExtract key={`extract-portfolio-${reportId}`} reportId={reportId} reportName={report?.reportName ?? ''} className="h-9" />
              )}
            </>
          )}
        </div>
      </div>

      {/* Content */}
      <main className={cn('h-full flex-1', pageContentStyles)}>
        <ReportItemAssets
          reportItemAssetBase={IndexedReportItems}
          selectedAsset={selectedReportItemId}
          onAssetSelect={handleAssetSelection}
          isDisabled={reportDisplayType !== ReportDisplayType.AssetReport}
        />

        {!isNil(selectedReportItemId) && reportDisplayType === ReportDisplayType.AssetReport && (
          <IndividualReportItemBody
            reportAsset={{
              id: selectedReportItemId,
              index: selectedIndex,
            }}
          />
        )}

        {!isNil(reportId) && reportDisplayType === ReportDisplayType.PortfolioReport && <PortfolioReportBody reportId={reportId} />}
      </main>
    </>
  )
}

export { ReportPage }
