import { useDispatch, useSelector } from 'react-redux'
import type { TRootState } from '@flyward/platform/store/configureReducers'
import { Box, Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import { Label } from '../common/Label'
import { CreateAssetDto } from '@flyward/assets/models/CreateAssetDto'
import { useCreateAssetMutation } from '@flyward/assets/store/api'
import { AirframeCheckType, AirframeCheckTypeDisplay, AssetType, NumberDisplay, NumberDisplayType } from '@flyward/platform'
import { resetWizard } from '../../store/assetWizardSlice'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '@flyward/main-app/providers/routes'
import { showError, showSuccess } from '@flyward/platform/services/Notifications'
import { formatAxiosErrorMessage } from '@flyward/platform/helpers/ErrorHelpers'
import { isEmpty, isNil } from 'lodash'
import { EnginesReadOnly } from './EnginesReadOnly'
import { longDateFormat, stringToLongDateFormat } from '@flyward/platform/helpers/dateHelpers'

interface ReviewAndPublishProps {
  onNext: () => void
  onBack: () => void
  stepTitle: string
}

export const AircraftReviewAndPublish: React.FC<ReviewAndPublishProps> = ({ onBack, stepTitle }) => {
  const wizardState = useSelector((state: TRootState) => state.assetWizard.steps[AssetType.Aircraft])
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [createAsset] = useCreateAssetMutation()

  const onPublish = () => {
    const assetToCreate: CreateAssetDto = {
      dateOfLastTechSpec: wizardState.general.dateOfLastTechSpec,
      leaseStartDate: wizardState.general.leaseStartDate,
      leaseEndDate: wizardState.general.leaseEndDate,
      lessee: wizardState.general.lessee,
      lesseeHabitualBase: wizardState.general.lesseeHabitualBase,
      region: wizardState.general.region,
      portfolio: wizardState.general.portfolio,
      averageMonthlyFlightHours: wizardState.general.averageMonthlyFlightHours,
      averageMonthlyFlightCycles: wizardState.general.averageMonthlyFlightCycles,
      averageMonthlyApuHours: wizardState.general.averageMonthlyAPUHours,
      aircraftType: wizardState.general.model,
      registrationNo: wizardState.general.registrationNo,
      sourceUrl: wizardState.general.sourceUrl,
      airframe: {
        serialNumber: wizardState.general.sn,
        dom: wizardState.general.dom,
        manufacturer: wizardState.general.manufacturer,
        totalHoursSinceNew: Number(wizardState.airframe.technical.tsn),
        cyclesSinceNew: Number(wizardState.airframe.technical.csn),
        checks: wizardState.airframe.checks.map((check) => ({
          checkType: Number(check.checkType),
          eventDate: check.dateAtLastCheck,
          monthsSinceEventAtContractDelivery: check.monthsSinceEventAtContractDelivery,
        })),
      },
      engines: wizardState.engines.items?.map((engine) => ({
        serialNumber: engine?.general?.sn,
        dom: engine?.general?.dom,
        manufacturer: engine?.general?.manufacturer,
        model: engine?.general?.model,
        totalHoursSinceNew: Number(engine?.pr?.tsn),
        cyclesSinceNew: Number(engine?.pr?.csn),
        totalHoursSinceNewAtLastEvent: engine?.pr?.tsnLastPr,
        cyclesSinceNewAtLastEvent: engine?.pr?.csnLastPr,
        lastEventDate: engine?.pr?.dateLastPr,
        deratePct: engine?.general?.derate,
        hoursSinceEventAtContractDelivery: engine?.pr?.hoursSinceEventAtContractDelivery,
        engineLifeLimitedParts: engine?.llps?.map((llp) => {
          return {
            model: llp.description,
            module: llp.module,
            partNumber: llp.partNumber,
            serialNumber: llp.serialNumber,
            technicalLimitAmount: llp.limit,
            cyclesSinceNew: llp.csn,
            cyclesRemaining: llp.cyclesRemaining,
            cyclesSinceNewAtContractDelivery: llp.csnAtContractDelivery,
          }
        }),
      })),
      landingGear:
        isEmpty(wizardState.landingGear.technical?.sn) || isNil(wizardState.landingGear.technical?.dom)
          ? undefined
          : {
              serialNumber: wizardState.landingGear.technical?.sn,
              model: wizardState.landingGear.technical?.model,
              manufacturer: wizardState.landingGear.technical?.manufacturer,
              partNumber: wizardState.landingGear.technical?.partNumber,
              dom: wizardState.landingGear.technical?.dom,
              totalHoursSinceNew: Number(wizardState.landingGear.technical?.tsn),
              cyclesSinceNew: Number(wizardState.landingGear.technical?.csn),
              totalHoursSinceNewAtLastEvent: wizardState.landingGear.technical?.tsnLastPr,
              cyclesSinceNewAtLastEvent: wizardState.landingGear.technical?.csnLastPr,
              lastEventDate: wizardState.landingGear.technical?.dateLastPr,
              monthsSinceEventAtContractDelivery: wizardState.landingGear.technical?.monthsSinceOverhaulAtContractDelivery,
            },
      apu: isEmpty(wizardState.apu.prTechnical?.sn)
        ? undefined
        : {
            serialNumber: wizardState.apu.prTechnical?.sn,
            model: wizardState.apu.prTechnical?.model,
            manufacturer: wizardState.apu.prTechnical?.manufacturer,
            dom: wizardState.apu.prTechnical?.dom,
            totalHoursSinceNew: Number(wizardState.apu.prTechnical?.tsn),
            cyclesSinceNew: Number(wizardState.apu.prTechnical?.csn),
            totalHoursSinceNewAtLastEvent: wizardState.apu.prTechnical?.tsnLastPr,
            cyclesSinceNewAtLastEvent: wizardState.apu.prTechnical?.csnLastPr,
            lastEventDate: wizardState.apu.prTechnical?.dateLastPr,
            apuHoursSinceEventAtContractDelivery: wizardState.apu.prTechnical?.apuHoursSinceEventAtContractDelivery,
          },
    }

    createAsset({
      assetType: AssetType.Aircraft,
      createAssetDto: assetToCreate,
    })
      .then((data) => {
        if (data.error) {
          showError(formatAxiosErrorMessage(data.error.message))
        } else {
          showSuccess(<span>The Aircraft {wizardState.general.sn} was successfully added.</span>)
          dispatch(resetWizard())
          navigate(ROUTES.FLEET.ROOT)
        }
      })
      .catch((error) => {
        showError(formatAxiosErrorMessage(JSON.stringify(error?.response)))
      })
  }

  const flexBoxStyles = {
    display: 'flex',
    flexDirection: 'row',
    gap: 2,
    flexWrap: 'wrap',
    '& > *': {
      flex: '1 1 calc(33.333% - 16px)', // Takes up 1/3 of the space minus the gap
      minWidth: '200px',
      maxWidth: 'calc(33.333% - 16px)', // Ensures it doesn't grow beyond 1/3
    },
  }

  return (
    <div className="shadow-sm max-w-368 rounded-lg bg-white p-6">
      <Typography variant="h6" sx={{ mb: 3 }}>
        {stepTitle}
      </Typography>

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, mb: 4 }}>
        <div className="mb-2 text-lg font-semibold">1. General</div>
        <Box sx={flexBoxStyles}>
          <Label label="MSN" value={wizardState.general.sn} />
          <Label label="DOM" value={stringToLongDateFormat(wizardState.general.dom) ?? '-'} />
          <Label label="Tech Spec Date" value={stringToLongDateFormat(wizardState.general.dateOfLastTechSpec) ?? '-'} />
          <Label label="Registration" value={isEmpty(wizardState.general.registrationNo) ? '-' : wizardState.general.registrationNo} />
          <Label label="Manufacturer" value={isEmpty(wizardState.general.manufacturer) ? '-' : wizardState.general.manufacturer} />
          <Label label="Aircraft Type" value={isEmpty(wizardState.general.model) ? '-' : wizardState.general.model} />
          <Label label="Lease Start Date" value={stringToLongDateFormat(wizardState.general.leaseStartDate) ?? '-'} />
          <Label label="Lease End Date" value={stringToLongDateFormat(wizardState.general.leaseEndDate) ?? '-'} />
          <div />
          <Label label="Operator" value={isEmpty(wizardState.general.lessee) ? '-' : wizardState.general.lessee} />
          <Label label="Lessee Habitual Base" value={isEmpty(wizardState.general.lesseeHabitualBase) ? '-' : wizardState.general.lesseeHabitualBase} />
          <Label label="Region" value={isEmpty(wizardState.general.region) ? '-' : wizardState.general.region} />
          <Label label="Portfolio" value={isEmpty(wizardState.general.portfolio) ? '-' : wizardState.general.portfolio} />
          <div />
          <div />
          <Label label="Average Monthly Flight Hours" value={`${wizardState.general.averageMonthlyFlightHours ?? '0'}`} />
          <Label label="Average Monthly Flight Cycles" value={`${wizardState.general.averageMonthlyFlightCycles ?? '0'}`} />
          <Label label="Average Monthly APU Hours" value={`${wizardState.general.averageMonthlyAPUHours ?? '0'}`} />
          <div />
          <div />
          <div />
        </Box>
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, mb: 4 }}>
        <div className="mb-2 text-lg font-semibold">2. Airframe</div>

        <Typography variant="subtitle1" sx={{ mb: 1 }}>
          Technical
        </Typography>
        <Box sx={flexBoxStyles}>
          <Label label="TSN" value={wizardState.airframe.technical.tsn ?? '-'} />
          <Label label="CSN" value={wizardState.airframe.technical.csn ?? '-'} />
        </Box>

        {wizardState.airframe.checks.length > 0 && (
          <>
            <Typography variant="subtitle2" sx={{ mb: 1 }}>
              Check history
            </Typography>
            <TableContainer component={Paper} sx={{ mb: 4 }} className="max-w-200">
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: 600 }}>Event Type</TableCell>
                    <TableCell sx={{ fontWeight: 600 }}>Date @ last check</TableCell>
                    <TableCell sx={{ fontWeight: 600, textAlign: 'right' }}>
                      Months since check <br />
                      at contract delivery
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {wizardState.airframe.checks.map(
                    (
                      {
                        checkType,
                        dateAtLastCheck,
                        monthsSinceEventAtContractDelivery,
                      }: {
                        checkType: AirframeCheckType
                        dateAtLastCheck: string
                        monthsSinceEventAtContractDelivery: number
                      },
                      index: number,
                    ) => (
                      <TableRow key={index}>
                        <TableCell>{AirframeCheckTypeDisplay(Number(checkType))}</TableCell>
                        <TableCell sx={{ textAlign: 'left' }}>{stringToLongDateFormat(dateAtLastCheck) ?? '-'}</TableCell>
                        <TableCell sx={{ textAlign: 'right' }}>
                          <NumberDisplay value={monthsSinceEventAtContractDelivery} displayType={NumberDisplayType.Integer} />
                        </TableCell>
                      </TableRow>
                    ),
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, mb: 4 }}>
        <div className="mb-2 text-lg font-semibold">3. Engines</div>

        {isEmpty(wizardState.engines.items) ? (
          <Typography variant="subtitle1" sx={{ mb: 1 }}>
            -
          </Typography>
        ) : (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            <EnginesReadOnly engines={wizardState.engines} />
          </Box>
        )}
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, mb: 4 }}>
        <div className="mb-2 text-lg font-semibold">4. Landing Gear</div>

        {isEmpty(wizardState.landingGear.technical.sn) ? (
          <Typography variant="subtitle1" sx={{ mb: 1 }}>
            -
          </Typography>
        ) : (
          <Box sx={flexBoxStyles}>
            <Label label="Serial Number" value={wizardState.landingGear.technical?.sn ?? '-'} />
            <Label label="DOM" value={stringToLongDateFormat(wizardState.landingGear.technical?.dom) ?? '-'} />
            <Label label="Manufacturer" value={isEmpty(wizardState.landingGear.technical?.manufacturer) ? '-' : wizardState.landingGear.technical?.manufacturer} />
            <Label label="Model" value={isEmpty(wizardState.landingGear.technical?.model) ? '-' : wizardState.landingGear.technical?.model} />
            <Label label="Part Number" value={isEmpty(wizardState.landingGear.technical?.partNumber) ? '-' : wizardState.landingGear.technical?.partNumber} />
            <div />
            <Label label="TSN" value={wizardState.landingGear.technical?.tsn ?? '-'} />
            <Label label="CSN" value={wizardState.landingGear.technical?.csn ?? '-'} />
            <div />
            <Label label="TSN @ Last Overhaul" value={wizardState.landingGear.technical?.tsnLastPr ?? '-'} />
            <Label label="CSN @ Last Overhaul" value={wizardState.landingGear.technical?.csnLastPr ?? '-'} />
            <Label label="Date @ Last Overhaul" value={stringToLongDateFormat(wizardState.landingGear.technical?.dateLastPr) ?? '-'} />
            <Label label="Months Since Overhaul @ Contract Delivery" value={wizardState.landingGear.technical?.monthsSinceOverhaulAtContractDelivery?.toString() ?? '-'} />
          </Box>
        )}
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, mb: 4 }}>
        <div className="mb-2 text-lg font-semibold">5. APU</div>

        {isEmpty(wizardState.apu.prTechnical.sn) ? (
          <Typography variant="subtitle1" sx={{ mb: 1 }}>
            -
          </Typography>
        ) : (
          <Box sx={flexBoxStyles}>
            <Label label="Serial Number" value={wizardState.apu.prTechnical?.sn ?? '-'} />
            <Label label="DOM" value={stringToLongDateFormat(wizardState.apu.prTechnical?.dom) ?? '-'} />
            <div />
            <Label label="Manufacturer" value={isEmpty(wizardState.apu.prTechnical?.manufacturer) ? '-' : wizardState.apu.prTechnical?.manufacturer} />
            <Label label="Model" value={isEmpty(wizardState.apu.prTechnical?.model) ? '-' : wizardState.apu.prTechnical?.model} />
            <div />
            <Label label="TSN" value={wizardState.apu.prTechnical?.tsn ?? '-'} />
            <Label label="CSN" value={wizardState.apu.prTechnical?.csn ?? '-'} />
            <div />
            <Label label="TSN @ Last PR" value={wizardState.apu.prTechnical?.tsnLastPr ?? '-'} />
            <Label label="CSN @ Last PR" value={wizardState.apu.prTechnical?.csnLastPr ?? '-'} />
            <Label label="Date @ Last PR" value={stringToLongDateFormat(wizardState.apu.prTechnical?.dateLastPr) ?? '-'} />
            <Label label="CSN @ Contract Delivery" value={wizardState.apu.prTechnical?.apuHoursSinceEventAtContractDelivery?.toString() ?? '-'} />
          </Box>
        )}
      </Box>

      <Box sx={{ mt: 4 }}>
        <Button
          variant="contained"
          onClick={() => {
            onPublish()
          }}
          sx={{ mr: 1 }}
        >
          Publish
        </Button>
        <Button variant="text" onClick={onBack}>
          Back
        </Button>
      </Box>
    </div>
  )
}
