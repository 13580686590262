import { zodResolver } from '@hookform/resolvers/zod'
import { Button, ButtonVariant, cn, CnForm } from '@flyward/platform/components'
import { isNil } from 'lodash'
import { Suspense, useEffect, useRef, useState } from 'react'
import { type SubmitHandler, useForm } from 'react-hook-form'
import { type VerifyEngineResultDto } from '../../../../../../models/aircraftComponents/aircraftEngine/verify/VerifyEngineResultDto'
import { type DraggableLlp, type DraggableLlpStack, DraggableLlpStackSchema } from '../../../../../../models'
import { type UpdateLlpStackInput } from '../../../../../../models/aircraftComponents/aircraftEngine/UpdateLlpStackInput'
import { formatAxiosErrorMessage } from '@flyward/platform/helpers/ErrorHelpers'
import { showError, showSuccess } from '@flyward/platform/services'
import { useUpdateLlpStackMutation } from '@flyward/assets/store'
import { VerificationStatusToAlignmentStatus, AlignmentStatus, VerificationStatus, LoadingFallback } from '@flyward/platform'
import { AssetLlpsTable } from './asset/AssetLlpsTable'
import { KbLlpsTable } from './kb/KbLlpsTable'

interface IEngineAlignmentProps {
  assetId: string
  engineResult: VerifyEngineResultDto
  setIsAssetVerificationModalOpen: (_isOpen: boolean) => void
}

const EngineAlignment = ({ assetId, engineResult, setIsAssetVerificationModalOpen }: IEngineAlignmentProps) => {
  const [updateLlpStack] = useUpdateLlpStackMutation()
  const [isContentMounted, setIsContentMounted] = useState(false)
  const updateLlpsSubmitRef = useRef<HTMLInputElement | null>(null)

  // Mount content with a slight delay to ensure smooth transitions
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsContentMounted(true)
    }, 50)
    return () => {
      clearTimeout(timer)
    }
  }, [])

  const values: DraggableLlpStack = {
    llps: engineResult.llpStackResults.map((l) => ({
      llp: l.llp!,
      alignmentStatus: VerificationStatusToAlignmentStatus(l.status),
    })),
    removedItemIds: [],
  }

  const form = useForm<DraggableLlpStack>({
    defaultValues: values,
    values,
    resolver: zodResolver(DraggableLlpStackSchema),
    mode: 'all',
    reValidateMode: 'onChange',
  })

  const {
    handleSubmit: handleComponentUpdate,
    formState,
    getValues: getComponentFormValues,
    setValue: setComponentFormValue,
    control: componentFormControl,
    trigger: triggerComponentValidation,
  } = form

  const assetLlpsFormValues: DraggableLlpStack = getComponentFormValues()
  const removedLlpsIds: string[] = assetLlpsFormValues?.removedItemIds ?? []
  const { isDirty, isValid } = formState

  useEffect(() => {
    triggerComponentValidation()
  }, [isValid, triggerComponentValidation])

  const onUpdateComponent: SubmitHandler<DraggableLlpStack> = async (llpStack: DraggableLlpStack) => {
    try {
      const notNullLlps = llpStack.llps.filter((l) => !isNil(l.llp)).map((l) => l.llp)

      const apiData: UpdateLlpStackInput = {
        engineComponentId: engineResult.engineComponentId,
        llps: notNullLlps,
        addRemoveLlps: {
          addedItems: [], // we don't support adding new LLPS in the alignment screen
          removedItemIds: llpStack.removedItemIds,
        },
      }

      const result = await updateLlpStack({ assetId, data: apiData })
      if (!isNil(result.error)) {
        showError(formatAxiosErrorMessage(result.error?.message))
      } else {
        showSuccess('LLP stack updated successfully')
        setIsAssetVerificationModalOpen(false)
      }
    } catch (error) {
      showError(formatAxiosErrorMessage(error))
    }
  }

  const onDeleteExistingLlp = (llpId: string) => {
    const remainingItems: DraggableLlp[] = assetLlpsFormValues.llps.filter((data) => data?.llp?.componentId !== llpId)
    setComponentFormValue('llps', remainingItems, { shouldDirty: true })
    const removedIds = [...removedLlpsIds, llpId]
    setComponentFormValue('removedItemIds', removedIds, { shouldDirty: true })
    triggerComponentValidation()
  }

  const onExistingLlpCopyFromKb = (positionalIndex: number, llp: DraggableLlp) => {
    const kbLlpByPosition = engineResult.llpStackResults[positionalIndex].llpProgram!
    const llpToSave: DraggableLlp = {
      ...llp,
      llp: {
        ...llp.llp,
        componentModel: kbLlpByPosition.model,
      },
      alignmentStatus: AlignmentStatus.Success,
    }

    setComponentFormValue(`llps.${positionalIndex}`, llpToSave, { shouldDirty: true })
    triggerComponentValidation()
  }

  const persistAssetLllStackInForm = (llpStack: DraggableLlp[]) => {
    setComponentFormValue(`llps`, llpStack, { shouldDirty: true })
    triggerComponentValidation()
  }

  const handleCancel = () => {
    setIsAssetVerificationModalOpen(false)
  }

  // Calculate statistics
  const stats = {
    matchedLlpsCount: engineResult.llpStackResults.filter((llp) => llp.status === VerificationStatus.Success).length,
    missingInAssetCount: engineResult.llpStackResults.filter((llp) => llp.status === VerificationStatus.MissingInAsset).length,
    missingInKbCount: engineResult.llpStackResults.filter((llp) => llp.status === VerificationStatus.MissingInKb).length,
    suggestedAlignmentsCount: engineResult.llpStackResults.filter((llp) => llp.status === VerificationStatus.SuggestedAlignment).length,
  }

  const assetsTotalCount = stats.matchedLlpsCount + stats.missingInKbCount + stats.suggestedAlignmentsCount
  const kbTotalCount = stats.matchedLlpsCount + stats.missingInAssetCount + stats.suggestedAlignmentsCount
  const differentLlpsCount = assetsTotalCount !== kbTotalCount

  if (!isContentMounted) {
    return <LoadingFallback />
  }

  return (
    <div className="h-full w-full">
      <div className={cn('flex justify-between')}>
        <p className={cn('text-left')}>
          Engine Model:{' '}
          <strong className="font-semibold">
            {engineResult.engineManufacturer} - {engineResult.engineModel}
          </strong>
        </p>
        <p className={cn('text-right')}>
          Assigned Maintenance Programme: <strong className="font-semibold">{engineResult.kbProgramName}</strong>
        </p>
      </div>
      <CnForm {...form}>
        <form onSubmit={handleComponentUpdate(onUpdateComponent)}>
          <div className={cn('flex justify-between', differentLlpsCount && 'text-blue-500')}>
            <p title={`${stats.matchedLlpsCount} matched, ${stats.missingInKbCount} missing in program, ${stats.suggestedAlignmentsCount} suggested alignments`}>
              Total Asset LLPs: {assetsTotalCount}
            </p>
            <p title={`${stats.matchedLlpsCount} matched, ${stats.missingInAssetCount} missing in asset, ${stats.suggestedAlignmentsCount} suggested alignments`}>
              Total Programme LLPs: {kbTotalCount}
            </p>
          </div>
          <div className="flex max-h-[calc(100vh-17rem)] flex-row overflow-y-auto">
            <Suspense fallback={<LoadingFallback />}>
              <AssetLlpsTable
                componentFormValues={assetLlpsFormValues}
                verifyLLPsResult={engineResult.llpStackResults}
                kbTotalCount={kbTotalCount}
                formControl={componentFormControl}
                onDeleteExistingLlp={onDeleteExistingLlp}
                onExistingLlpCopyFromKb={onExistingLlpCopyFromKb}
                persistAssetLllStackInForm={persistAssetLllStackInForm}
              />
            </Suspense>
            <KbLlpsTable llpResults={engineResult.llpStackResults} />
          </div>

          <input ref={updateLlpsSubmitRef} type="submit" className="hidden" />
        </form>
      </CnForm>
      <div className="mt-4 flex justify-end gap-2">
        <Button label="Update" disabled={isDirty && !isValid} variant={ButtonVariant.ModalConfirm} onClick={() => updateLlpsSubmitRef.current?.click()} />
        <Button label={isDirty ? 'Cancel' : 'Close'} variant={ButtonVariant.ModalCancel} onClick={handleCancel} />
      </div>
    </div>
  )
}

export { EngineAlignment }
