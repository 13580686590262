import React, { useEffect, useState } from 'react'
import { Box, Button, TextField, Typography } from '@mui/material'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { NumericFormat } from 'react-number-format'
import { validateNullableDate } from '@flyward/platform/helpers/inputValidators'
import { isEmpty, isNil } from 'lodash'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { dateToIsoString } from '@flyward/platform/helpers/dateHelpers'
import { LLPTable } from '../common/LLPTable'
import { LLPTableRowData } from '../common/types'

const MIN_VALUE = 0
const MAX_VALUE = 1000000000

const prSchema = z.object({
  tsn: z.number().optional().nullable(),
  csn: z.number().optional().nullable(),
  tsnLastPr: z.number().optional().nullable(),
  csnLastPr: z.number().optional().nullable(),
  dateLastPr: validateNullableDate,
  hoursSinceEventAtContractDelivery: z.number().optional().nullable(),
})

const engineSchema = z.object({
  pr: prSchema,
  llps: z.array(z.any()).nullable().optional(),
})

export type EngineFormData = z.infer<typeof engineSchema>

interface GeneralProps {
  onNext: (_data: EngineFormData) => void
  onBack?: () => void
  stepTitle: string
  initialData?: EngineFormData
  isAiPopulated?: boolean
}

const EmptyLlpRow: LLPTableRowData = {
  id: 0,
  description: '',
} as const

const EngineComponent = ({ onNext, onBack, stepTitle, initialData, isAiPopulated }: GeneralProps) => {
  const [tableRows, setTableRows] = useState<LLPTableRowData[]>([EmptyLlpRow])
  const [isLLPTableValid, setIsLLPTableValid] = useState(true)

  const {
    formState: { errors, touchedFields },
    watch,
    trigger,
    setValue,
  } = useForm<EngineFormData>({
    resolver: zodResolver(engineSchema),
    mode: 'all',
    defaultValues: {
      pr: {
        tsn: initialData?.pr?.tsn,
        csn: initialData?.pr?.csn,
        tsnLastPr: initialData?.pr?.tsnLastPr ?? null,
        csnLastPr: initialData?.pr?.csnLastPr ?? null,
        dateLastPr: initialData?.pr?.dateLastPr ?? null,
        hoursSinceEventAtContractDelivery: initialData?.pr?.hoursSinceEventAtContractDelivery ?? null,
      },
      llps: initialData.llps,
    },
  })

  useEffect(() => {
    if (isAiPopulated) {
      trigger()
    }
  }, [isAiPopulated])

  // Populate LLP replacements from initialData
  useEffect(() => {
    if (initialData?.llps.length) {
      const populatedRows = initialData.llps.map((llp, index) => ({
        id: index + 1,
        module: llp.module,
        description: llp.description,
        partNumber: llp.partNumber,
        serialNumber: llp.serialNumber,
        limit: llp.limit,
        csn: llp.csn,
        cyclesRemaining: llp.cyclesRemaining,
        csnAtContractDelivery: llp.csnAtContractDelivery,
      }))
      setTableRows(populatedRows)
    }
  }, [initialData])

  const values = watch()

  const handleContinue = async () => {
    const isValidForm = await trigger()
    if (isValidForm) {
      const formattedLLPReplacements = tableRows
        ?.filter((row) => row.description)
        .map((row) => ({
          module: row.module,
          description: row.description,
          partNumber: row.partNumber,
          serialNumber: row.serialNumber,
          limit: Number(row.limit),
          csn: Number(row.csn),
          cyclesRemaining: Number(row.cyclesRemaining),
          csnAtContractDelivery: Number(row.csnAtContractDelivery),
        }))

      onNext({
        pr: {
          tsn: values?.pr?.tsn,
          csn: values?.pr?.csn,
          tsnLastPr: values?.pr?.tsnLastPr,
          csnLastPr: values?.pr?.csnLastPr,
          dateLastPr: values?.pr?.dateLastPr,
          hoursSinceEventAtContractDelivery: values?.pr?.hoursSinceEventAtContractDelivery,
        },
        llps: formattedLLPReplacements,
      })
    }
  }

  const handleAddRow = () => {
    const maxIndex = Math.max(...tableRows.map((row) => row.id), 0)
    setTableRows([...tableRows, { ...EmptyLlpRow, id: maxIndex + 1 }])
  }

  const handleRowChange = (id: number, field: keyof LLPTableRowData, value: string) => {
    setTableRows(
      tableRows.map((row) => {
        if (row.id === id) {
          return { ...row, [field]: value }
        }
        return row
      }),
    )
  }

  const handleDeleteRow = (id: number) => {
    setTableRows(tableRows.filter((row) => row.id !== id))
  }

  const hasValidationErrors = Object.keys(errors).length > 0
  const isButtonDisabled = hasValidationErrors || !isLLPTableValid

  const getPRErrorMessage = (fieldName) => {
    if (!touchedFields?.pr?.[fieldName]) return ''
    return errors.pr?.[fieldName]?.message ?? ''
  }

  return (
    <div className="shadow-sm rounded-lg bg-white p-6" style={{ minWidth: '32rem' }}>
      <Typography variant="h6" sx={{ mb: 3 }}>
        {stepTitle}
      </Typography>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 3,
          mb: 4,
        }}
      >
        <Typography variant="subtitle1" sx={{ mb: 1 }}>
          PR Technical
        </Typography>

        <Box sx={{ display: 'flex', gap: 2 }}>
          <NumericFormat
            customInput={TextField}
            label="TSN"
            value={values.pr.tsn ?? ''}
            error={touchedFields?.pr?.tsn && !!errors?.pr?.tsn}
            helperText={getPRErrorMessage('tsn')}
            thousandSeparator
            decimalScale={0}
            allowNegative={false}
            isAllowed={({ floatValue }) => floatValue === undefined || (floatValue >= MIN_VALUE && floatValue <= MAX_VALUE)}
            onValueChange={({ floatValue }) => {
              setValue('pr.tsn', floatValue!, {
                shouldValidate: true,
                shouldTouch: true,
              })
            }}
            onBlur={() => {
              if (!touchedFields?.pr?.tsn) {
                setValue('pr.tsn', values.pr.tsn, {
                  shouldTouch: true,
                  shouldValidate: true,
                })
              }
            }}
            sx={{ flex: 1 }}
          />

          <NumericFormat
            customInput={TextField}
            label="CSN"
            value={values.pr.csn ?? ''}
            error={touchedFields?.pr?.csn && !!errors?.pr?.csn}
            helperText={getPRErrorMessage('csn')}
            thousandSeparator
            decimalScale={0}
            allowNegative={false}
            isAllowed={({ floatValue }) => floatValue === undefined || (floatValue >= MIN_VALUE && floatValue <= MAX_VALUE)}
            onValueChange={({ floatValue }) => {
              setValue('pr.csn', floatValue!, {
                shouldValidate: true,
                shouldTouch: true,
              })
            }}
            onBlur={() => {
              if (!touchedFields?.pr?.csn) {
                setValue('pr.csn', values.pr.csn, {
                  shouldTouch: true,
                  shouldValidate: true,
                })
              }
            }}
            sx={{ flex: 1 }}
          />

          <Box sx={{ flex: 1 }} />
        </Box>

        <Box sx={{ display: 'flex', gap: 2 }}>
          <NumericFormat
            customInput={TextField}
            label="TSN @ Last PR"
            value={values.pr.tsnLastPr ?? ''}
            error={touchedFields?.pr?.tsnLastPr && !!errors?.pr?.tsnLastPr}
            helperText={getPRErrorMessage('pr.tsnLastPr')}
            thousandSeparator
            decimalScale={0}
            allowNegative={false}
            isAllowed={({ floatValue }) => floatValue === undefined || (floatValue >= MIN_VALUE && floatValue <= MAX_VALUE)}
            onValueChange={({ floatValue }) => {
              setValue('pr.tsnLastPr', floatValue!, {
                shouldValidate: true,
                shouldTouch: true,
              })
            }}
            onBlur={() => {
              if (!touchedFields?.pr?.tsnLastPr) {
                setValue('pr.tsnLastPr', values.pr.tsnLastPr, {
                  shouldTouch: true,
                  shouldValidate: true,
                })
              }
            }}
            sx={{ flex: 1 }}
          />

          <NumericFormat
            customInput={TextField}
            label="CSN @ Last PR"
            value={values.pr.csnLastPr ?? ''}
            error={touchedFields?.pr?.csnLastPr && !!errors?.pr?.csnLastPr}
            helperText={getPRErrorMessage('pr.csnLastPr')}
            thousandSeparator
            decimalScale={0}
            allowNegative={false}
            isAllowed={({ floatValue }) => floatValue === undefined || (floatValue >= MIN_VALUE && floatValue <= MAX_VALUE)}
            onValueChange={({ floatValue }) => {
              setValue('pr.csnLastPr', floatValue!, {
                shouldValidate: true,
                shouldTouch: true,
              })
            }}
            onBlur={() => {
              if (!touchedFields?.pr?.csnLastPr) {
                setValue('pr.csnLastPr', values.pr.csnLastPr, {
                  shouldTouch: true,
                  shouldValidate: true,
                })
              }
            }}
            sx={{ flex: 1 }}
          />

          <DatePicker
            label="Date @ Last PR"
            value={!isNil(values.pr.dateLastPr) && !isEmpty(values.pr.dateLastPr) ? new Date(values.pr.dateLastPr) : undefined}
            onChange={(newValue) => {
              const isoFormat = isNil(newValue) ? undefined : dateToIsoString(newValue)
              setValue('pr.dateLastPr', isoFormat)
              trigger('pr.dateLastPr')
            }}
            slotProps={{
              field: {
                clearable: true,
                onClear: () => {
                  setValue('pr.dateLastPr', undefined)
                  trigger('pr.dateLastPr')
                },
              },
            }}
            sx={{ flex: 1 }}
          />
        </Box>

        <Box sx={{ display: 'flex', gap: 2 }}>
          <NumericFormat
            customInput={TextField}
            label="Hours since PR at Contract Delivery"
            value={values.pr.hoursSinceEventAtContractDelivery ?? ''}
            error={touchedFields?.pr?.hoursSinceEventAtContractDelivery && !!errors?.pr?.hoursSinceEventAtContractDelivery}
            helperText={getPRErrorMessage('pr.hoursSinceEventAtContractDelivery')}
            thousandSeparator
            decimalScale={0}
            allowNegative={false}
            isAllowed={({ floatValue }) => floatValue === undefined || (floatValue >= MIN_VALUE && floatValue <= MAX_VALUE)}
            onValueChange={({ floatValue }) => {
              setValue('pr.hoursSinceEventAtContractDelivery', floatValue!, {
                shouldValidate: true,
                shouldTouch: true,
              })
            }}
            onBlur={() => {
              if (!touchedFields?.pr?.hoursSinceEventAtContractDelivery) {
                setValue('pr.hoursSinceEventAtContractDelivery', values.pr.hoursSinceEventAtContractDelivery, {
                  shouldTouch: true,
                  shouldValidate: true,
                })
              }
            }}
          />
          <Box sx={{ flex: 1 }} />
          <Box sx={{ flex: 1 }} />
        </Box>

        <Typography variant="subtitle1" sx={{ mb: 1 }}>
          LLP Technical
        </Typography>

        <LLPTable
          rows={tableRows}
          onRowChange={handleRowChange}
          onAddRow={handleAddRow}
          onDeleteRow={handleDeleteRow}
          onValidationChange={setIsLLPTableValid}
          isAiPopulated={isAiPopulated}
        />
      </Box>

      <Box>
        <Button variant="contained" onClick={handleContinue} disabled={isButtonDisabled}>
          Continue
        </Button>
        <Button variant="text" onClick={onBack}>
          Back
        </Button>
      </Box>
    </div>
  )
}

export { EngineComponent }
