import { type DraftAssetDto } from '@flyward/assets/models/DraftAsset/DraftAssetDto'

export const mapDraftAssetToEngineState = (draftAsset: DraftAssetDto) => {
  const engine = draftAsset.engines?.[0]

  return {
    general: {
      sn: engine?.serialNumber ?? '',
      derate: undefined,
      manufacturer: engine?.manufacturer,
      model: engine?.model,
      dom: engine?.dom,
      dateOfLastTechSpec: draftAsset.dateOfLastTechSpec,
      leaseStartDate: draftAsset.leaseStartDate,
      leaseEndDate: draftAsset.leaseEndDate,
      lessee: draftAsset.lessee,
      lesseeHabitualBase: undefined,
      region: undefined,
      portfolio: undefined,
      averageMonthlyFlightHours: undefined,
      averageMonthlyFlightCycles: undefined,
      sourceUrl: draftAsset.sourceUrl,
    },
    engine: {
      pr: {
        tsn: engine?.totalHoursSinceNew,
        csn: engine?.cyclesSinceNew,
        tsnLastPr: engine?.totalHoursSinceNewAtLastEvent,
        csnLastPr: engine?.cyclesSinceNewAtLastEvent,
        dateLastPr: engine?.lastEventDate,
        hoursSinceEventAtContractDelivery: undefined,
      },
      isLLPTableValid: true,
      llps:
        engine?.engineLifeLimitedParts?.map((llp, index) => ({
          id: index,
          description: llp.model,
          module: llp.module,
          partNumber: llp.partNumber,
          serialNumber: llp.serialNumber,
          limit: llp.technicalLimitAmount,
          csn: llp.cyclesSinceNew,
          cyclesRemaining: llp.cyclesRemaining,
          csnAtContractDelivery: undefined,
        })) ?? [],
    },
  }
}

export const mapDraftAssetToAircraftState = (draftAsset: DraftAssetDto) => {
  const airframe = draftAsset.airframe
  const landingGear = draftAsset.landingGear
  const apu = draftAsset.apu

  return {
    general: {
      sn: airframe?.serialNumber ?? '',
      manufacturer: airframe?.manufacturer,
      registrationNo: draftAsset.registrationNo,
      model: draftAsset.aircraftType,
      dom: airframe?.dom,
      dateOfLastTechSpec: draftAsset.dateOfLastTechSpec,
      leaseStartDate: draftAsset.leaseStartDate,
      leaseEndDate: draftAsset.leaseEndDate,
      lessee: draftAsset.lessee,
      lesseeHabitualBase: undefined,
      region: undefined,
      portfolio: undefined,
      averageMonthlyFlightHours: undefined,
      averageMonthlyFlightCycles: undefined,
      averageMonthlyAPUHours: undefined,
      sourceUrl: draftAsset.sourceUrl,
    },
    airframe: {
      technical: {
        tsn: airframe?.totalHoursSinceNew,
        csn: airframe?.cyclesSinceNew,
      },
      checks:
        airframe?.checks?.map((check) => ({
          checkType: check.checkType,
          dateAtLastCheck: check.eventDate,
          monthsSinceEventAtContractDelivery: undefined,
        })) ?? [],
    },
    engines: {
      items:
        draftAsset.engines?.map((engine) => ({
          general: {
            sn: engine.serialNumber,
            dom: engine.dom,
            manufacturer: engine.manufacturer,
            model: engine.model,
            derate: undefined,
          },
          pr: {
            sn: engine.serialNumber,
            manufacturer: engine.manufacturer,
            model: engine.model,
            dom: engine.dom,
            tsn: engine.totalHoursSinceNew,
            csn: engine.cyclesSinceNew,
            tsnLastPr: engine.totalHoursSinceNewAtLastEvent,
            csnLastPr: engine.cyclesSinceNewAtLastEvent,
            dateLastPr: engine.lastEventDate,
            hoursSinceEventAtContractDelivery: undefined,
          },
          isLLPTableValid: true,
          llps:
            engine.engineLifeLimitedParts?.map((llp, index) => ({
              id: index,
              description: llp.model,
              module: llp.module,
              partNumber: llp.partNumber,
              serialNumber: llp.serialNumber,
              limit: llp.technicalLimitAmount,
              csn: llp.cyclesSinceNew,
              cyclesRemaining: llp.cyclesRemaining,
              csnAtContractDelivery: undefined,
            })) ?? [],
        })) ?? [],
    },
    landingGear: {
      technical: {
        sn: landingGear?.serialNumber,
        manufacturer: landingGear?.manufacturer,
        model: landingGear?.model,
        partNumber: landingGear?.partNumber,
        dom: landingGear?.dom,
        tsn: landingGear?.totalHoursSinceNew,
        csn: landingGear?.cyclesSinceNew,
        tsnLastPr: landingGear?.totalHoursSinceNewAtLastEvent,
        csnLastPr: landingGear?.cyclesSinceNewAtLastEvent,
        dateLastPr: landingGear?.lastEventDate,
        monthsSinceOverhaulAtContractDelivery: undefined,
      },
    },
    apu: {
      prTechnical: {
        sn: apu?.serialNumber,
        manufacturer: apu?.manufacturer,
        model: apu?.model,
        dom: apu?.dom,
        tsn: apu?.totalHoursSinceNew,
        csn: apu?.cyclesSinceNew,
        tsnLastPr: apu?.totalHoursSinceNewAtLastEvent,
        csnLastPr: apu?.cyclesSinceNewAtLastEvent,
        dateLastPr: apu?.lastEventDate,
        apuHoursSinceEventAtContractDelivery: undefined,
      },
    },
  }
}
