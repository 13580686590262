import { useEffect } from 'react'
import { Box, IconButton, MenuItem, Select, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography, Link } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import { type EnvironmentalImpactType, EnvironmentalImpactTypeMap } from '@flyward/platform/models/enums'

interface TableRowData {
  id: number
  impactType: EnvironmentalImpactType
  limitAdjustmentPct: string
}

interface EnvironmentImpactProps {
  rows: TableRowData[]
  onRowChange: (_id: number, _field: keyof TableRowData, _value: number) => void
  onAddRow: () => void
  onDeleteRow: (_id: number) => void
  onValidationChange: (_isValid: boolean) => void
}

const duplicateStyle = {
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'red',
    },
    '&:hover fieldset': {
      borderColor: 'red',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'red',
    },
  },
}

export const EnvironmentImpact = ({ rows, onRowChange, onAddRow, onDeleteRow, onValidationChange }: EnvironmentImpactProps) => {
  const validateRow = (row: TableRowData): boolean => {
    const hasImpactType = Boolean(row.impactType)
    const hasLimitAdjustment = Boolean(row.limitAdjustmentPct.trim())
    return (hasImpactType && hasLimitAdjustment) || (!hasImpactType && !hasLimitAdjustment)
  }

  const checkDuplicateImpactType = (currentId: number, impactType: EnvironmentalImpactType): boolean => {
    if (!impactType) return false
    return rows.some((row) => row.id !== currentId && row.impactType === impactType && Boolean(row.impactType))
  }

  const hasValidationError = (row: TableRowData): boolean => {
    const hasImpactType = Boolean(row.impactType)
    const hasLimitAdjustment = Boolean(row.limitAdjustmentPct.trim())
    return (hasLimitAdjustment && !hasImpactType) || (hasImpactType && !hasLimitAdjustment)
  }

  const handleInputChange = (id: number, field: keyof TableRowData, value) => {
    if (field === 'limitAdjustmentPct' && value && !/^-?\d*\.?\d*$/.test(value)) return

    onRowChange(id, field, value)
  }

  useEffect(() => {
    const isValid = rows.every((row) => {
      const isRowValid = validateRow(row)
      const hasDuplicate = checkDuplicateImpactType(row.id, row.impactType)
      return isRowValid && !hasDuplicate
    })

    onValidationChange(isValid)
  }, [rows, onValidationChange, validateRow])

  return (
    <Box>
      <Typography variant="subtitle1">Environmental Impact</Typography>
      <Table
        sx={{
          mt: 2,
          '& .MuiTableCell-body': {
            borderBottom: 'none',
          },
        }}
        size={'small'}
      >
        <TableHead>
          <TableRow sx={{ '& th': { borderBottom: '1px solid rgba(0, 0, 0, 0.12)', backgroundColor: 'transparent' } }}>
            <TableCell width={200}>Impact Type</TableCell>
            <TableCell width={200}>EPR Limit Adjustment</TableCell>
            <TableCell width={48} />
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => {
            const isDuplicate = checkDuplicateImpactType(row.id, row.impactType)
            const hasError = hasValidationError(row)

            return (
              <TableRow key={row.id}>
                <TableCell>
                  <Select
                    fullWidth
                    value={row.impactType}
                    onChange={(e) => handleInputChange(row.id, 'impactType', e.target.value)}
                    error={isDuplicate || hasError}
                    sx={isDuplicate ? duplicateStyle : {}}
                  >
                    {Array.from(EnvironmentalImpactTypeMap.entries()).map(([value, label]) => (
                      <MenuItem key={value} value={value}>
                        {label}
                      </MenuItem>
                    ))}
                  </Select>
                </TableCell>
                <TableCell>
                  <TextField
                    fullWidth
                    value={row.limitAdjustmentPct}
                    onChange={(e) => handleInputChange(row.id, 'limitAdjustmentPct', e.target.value)}
                    error={hasError}
                    InputProps={{
                      endAdornment: '%',
                    }}
                    inputProps={{
                      inputMode: 'decimal',
                    }}
                  />
                </TableCell>
                <TableCell>
                  <IconButton size="small" onClick={() => onDeleteRow(row.id)} sx={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
      <Link
        component="button"
        variant="body2"
        onClick={onAddRow}
        sx={{
          mt: 2,
          textDecoration: 'none',
          '&:hover': {
            textDecoration: 'none',
          },
        }}
      >
        + Add another row
      </Link>
    </Box>
  )
}
