import React, { useState } from 'react'
import { isNil } from 'lodash'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Paper, Typography } from '@mui/material'
import { Close as CloseIcon, CloudUpload as CloudUploadIcon, ReportProblemOutlined as ReportProblemOutlinedIcon } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'

interface IAddModalProps {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  title: string
  onConfirm: (file: File) => Promise<void>
  onSuccess?: () => void
  onCancel?: () => void
  acceptedFileTypes?: string
  confirmButtonLabel?: string
  cancelButtonLabel?: string
  isLoading?: boolean
  wizardUri?: string
}

export const AddAssetModal: React.FC<IAddModalProps> = ({
  isOpen,
  setIsOpen,
  title,
  onConfirm,
  onSuccess = () => {},
  onCancel = () => {},
  acceptedFileTypes = '',
  confirmButtonLabel = 'Upload',
  cancelButtonLabel = 'Cancel',
  isLoading = false,
  wizardUri,
}) => {
  const [file, setFile] = useState<File | null>(null)
  const [dragging, setDragging] = useState(false)
  const [fileName, setFileName] = useState<string | null>(null)
  const [fileError, setFileError] = useState<string | null>(null)
  const navigate = useNavigate()

  const handleClose = () => {
    setFile(null)
    setFileName(null)
    setFileError(null)
    onCancel()
    setIsOpen(false)
  }

  const handleConfirm = async () => {
    try {
      await onConfirm(file!)
      onSuccess()
      setIsOpen(false)
      setFile(null)
      setFileName(null)
      setFileError(null)
    } catch (error) {
      console.error('Error during confirmation:', error)
    }
  }

  const validateFile = (file: File): boolean => {
    if (!acceptedFileTypes) return true

    const fileExtension = '.' + file.name.split('.').pop()?.toLowerCase()
    const acceptedTypes = acceptedFileTypes.split(',')

    if (!acceptedTypes.includes(fileExtension)) {
      setFileError(`File type ${fileExtension} is not supported. Please use: ${acceptedFileTypes}`)
      return false
    }

    setFileError(null)
    return true
  }

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!isNil(event.target.files) && event.target.files.length > 0) {
      const selectedFile = event.target.files[0]

      if (validateFile(selectedFile)) {
        setFile(selectedFile)
        setFileName(selectedFile.name)
      } else {
        setFile(null)
        setFileName(null)
      }
    }
  }

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    setDragging(true)
  }

  const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    setDragging(false)
  }

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    setDragging(false)
    const files = event.dataTransfer.files
    if (files.length > 0) {
      const selectedFile = files[0]

      if (validateFile(selectedFile)) {
        setFile(selectedFile)
        setFileName(selectedFile.name)
      } else {
        setFile(null)
        setFileName(null)
      }
    }
  }
  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="sm" fullWidth data-testid="add-modal">
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">{title}</Typography>
          <IconButton onClick={handleClose} size="small">
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>

      <DialogContent>
        <Paper
          variant="outlined"
          sx={{
            p: 3,
            mt: 2,
            border: '2px dashed',
            borderColor: fileError ? 'error.main' : dragging ? 'primary.main' : 'grey.300',
            backgroundColor: dragging ? 'rgba(0, 0, 0, 0.04)' : 'transparent',
            cursor: 'pointer',
            transition: 'all 0.2s ease',
          }}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
          onClick={() => document.getElementById('file-upload-input')?.click()}
          role="button"
          tabIndex={0}
        >
          <Box display="flex" flexDirection="column" alignItems="center" gap={2}>
            {fileError ? <ReportProblemOutlinedIcon color="error" sx={{ fontSize: 48 }} /> : <CloudUploadIcon color="primary" sx={{ fontSize: 48 }} />}
            <Typography align="center" color={fileError ? 'error' : 'inherit'}>
              {fileError ?? 'Drag and drop a Tech Spec file here, or click to select one'}
            </Typography>
            <input id="file-upload-input" type="file" accept={acceptedFileTypes} onChange={handleFileChange} style={{ display: 'none' }} data-testid="file-browser-input" />
            {!isNil(fileName) && (
              <Box mt={2} textAlign="center">
                <Typography variant="body2" color="textSecondary">
                  Selected File:{' '}
                  <Box component="span" fontWeight="medium">
                    {fileName}
                  </Box>
                </Typography>
              </Box>
            )}
          </Box>
        </Paper>
      </DialogContent>

      <DialogActions sx={{ px: 3, pb: 3, display: 'flex', justifyContent: 'space-between' }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            navigate(wizardUri ?? '')
          }}
          disabled={!wizardUri}
        >
          Launch Wizard
        </Button>
        <Box>
          <Button onClick={handleClose} color="inherit" sx={{ mr: 1 }}>
            {cancelButtonLabel}
          </Button>
          <Button onClick={handleConfirm} variant="contained" color="primary" disabled={isNil(file) || isLoading}>
            {isLoading ? 'Processing...' : confirmButtonLabel}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  )
}
