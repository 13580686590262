import { Command as CmdkCommand } from 'cmdk'
import React, { useRef, useState, useCallback, useMemo } from 'react'
import { debounce } from 'lodash'
import { ButtonVariant, cn, Command as CnCommand, CommandGroup as CnCommandGroup, CommandItem as CnCommandItem, CommandList as CnCommandList, Label as CnLabel } from '../_shadcn'
import { Icon, IconVariant } from '../Icon'
import { Button } from '../Button'

interface MultiSelectValue {
  value: string
  label: string | React.ReactNode
}

interface IMultiSelectProps {
  placeholder: string
  onValueSelected: (_value: MultiSelectValue) => void
  values: MultiSelectValue[]
  disabled?: boolean
  selectClassName?: string
  labelClassName?: string
  label?: string
  listContainerClassName?: string
  controlledSearchTerm?: string
}

const MultiSelect = ({
  controlledSearchTerm,
  placeholder,
  onValueSelected,
  values,
  disabled = false,
  selectClassName,
  labelClassName,
  label,
  listContainerClassName = 'max-h-[calc(100vh-12rem)]',
}: IMultiSelectProps) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const [open, setOpen] = useState<boolean>(false)
  const [inputValue, setInputValue] = useState<string>(controlledSearchTerm ?? '')

  const allValues = [...values]

  const distinctValue = allValues.filter((value, index, self) => self.findIndex((t) => t.value === value.value) === index)

  const labelColor = disabled ? 'text-text-3' : 'text-text-1'
  const selectListRef = useRef<HTMLDivElement | null>(null)

  const handleInputChange = useCallback((value: string) => {
    setInputValue(value)
  }, [])

  const handleValueSelected = useCallback(
    (value: MultiSelectValue) => {
      setInputValue('')
      onValueSelected(value)
    },
    [onValueSelected],
  )

  const debouncedSetOpen = useMemo(() => debounce((value: boolean) => setOpen(value), 100), [])

  return (
    <div key={distinctValue.length} className={cn('flex w-full flex-col justify-center gap-y-0.4', selectClassName, disabled && 'cursor-not-allowed')}>
      {label != null && (
        <CnLabel data-testid="select-label">
          <p className={cn(`!text-xs font-semibold ${labelColor}`, labelClassName)} data-testid="select-label-text">
            {label}
          </p>
        </CnLabel>
      )}
      <CnCommand className={cn('overflow-visible bg-black-0', disabled && 'cursor-not-allowed')} id="command-wrapper">
        <div className="border-input group flex cursor-pointer flex-row items-center rounded-md border text-sm ring-0 hover:border-primary" tabIndex={-1}>
          <CmdkCommand.Input
            disabled={disabled}
            ref={inputRef}
            value={inputValue}
            onValueChange={handleInputChange}
            placeholder={placeholder}
            onFocus={() => {
              setOpen(true)
            }}
            onClick={() => {
              setOpen(true)
            }}
            onBlur={(e) => {
              if (e.relatedTarget === inputRef.current?.parentElement || e.relatedTarget === document.getElementById('command-wrapper')) {
                return
              }
              setOpen(false)
            }}
            className={cn(
              'w-[90%] flex-1 bg-transparent py-2 pl-3 text-sm font-normal outline-none placeholder:text-text-2 placeholder:opacity-40',
              disabled && 'cursor-not-allowed',
            )}
          />
          <Button
            disabled={disabled}
            className={cn('z-10 py-2 pr-3', disabled && 'cursor-not-allowed')}
            onClick={(e) => {
              e.stopPropagation()
              if (!open) {
                inputRef.current?.focus()
              } else {
                inputRef.current?.blur()
              }
            }}
            variant={ButtonVariant.Ghost}
          >
            {!open ? (
              <Icon variant={IconVariant.ExpandMore} className={cn('m-0 text-text-1', disabled && 'cursor-not-allowed')} />
            ) : (
              <Icon variant={IconVariant.ExpandLess} className={cn('m-0 text-text-1', disabled && 'cursor-not-allowed')} />
            )}
          </Button>
        </div>
        <div className="relative mt-2">
          <CnCommandList>
            {open && distinctValue.length > 0 && (
              <div ref={selectListRef} className="absolute top-0 z-[1000] w-full rounded-lg border bg-black-0 text-text-1 shadow-dropdown outline-none animate-in">
                <CnCommandGroup className={cn('overflow-auto', listContainerClassName)}>
                  {distinctValue.map((fancyMultiSelectValue) => (
                    <CnCommandItem
                      key={fancyMultiSelectValue.value}
                      onMouseDown={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                      }}
                      onSelect={() => {
                        handleValueSelected(fancyMultiSelectValue)
                        debouncedSetOpen(false)
                      }}
                      className="cursor-pointer"
                    >
                      <div className="flex h-full w-full items-center justify-between">
                        <span className="my-auto flex items-start whitespace-break-spaces break-all">{fancyMultiSelectValue.label}</span>
                      </div>
                    </CnCommandItem>
                  ))}
                </CnCommandGroup>
              </div>
            )}
          </CnCommandList>
        </div>
      </CnCommand>
    </div>
  )
}

export { MultiSelect, type MultiSelectValue }
