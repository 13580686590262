import { useDispatch, useSelector } from 'react-redux'
import { ComponentType } from '@flyward/platform'
import { updateStepData, setActiveStep, resetWizard } from '../store/wizardSlice'

export const useWizardState = (type: ComponentType) => {
  const dispatch = useDispatch()
  const wizardState = useSelector((state: any) => state.wizard[type])

  const handleStepUpdate = (step: any, data: any) => {
    dispatch(updateStepData({ type, step, data }))
    dispatch(setActiveStep({ type, step: wizardState.activeStep + 1 }))
  }

  const handleStepChange = (step: any) => {
    dispatch(setActiveStep({ type, step }))
  }

  const handleReset = () => {
    dispatch(resetWizard())
  }

  return {
    state: wizardState,
    handleStepUpdate,
    handleStepChange,
    handleReset,
  }
}
