import { type ZitadelTokenResponse } from '../../models'
import { isNil, isEmpty } from 'lodash'

export const LOCALSTORAGE_USERTOKEN_KEY = 'Flyward_UserAccessToken'
export const LOCALSTORAGE_ZITADEL_USERTOKEN_KEY = 'Flyward_ZitadelUserAccessToken'

/**
 * Checks if a string is null, undefined, or empty.
 * @param {unknown} value - The string to check.
 * @returns {boolean} - True if the string is null, undefined, or empty; otherwise, false.
 */
const isNullOrEmpty = (value: unknown) => isNil(value) || isEmpty(value)

const persistZitadelUserAccessToken = (tokenData: ZitadelTokenResponse) => {
  if (isNullOrEmpty(tokenData)) {
    return
  }
  localStorage.setItem(LOCALSTORAGE_ZITADEL_USERTOKEN_KEY, JSON.stringify(tokenData))
}

const getZitadelPersistedUserAccessToken = (): ZitadelTokenResponse | null => {
  const tokenString = localStorage.getItem(LOCALSTORAGE_ZITADEL_USERTOKEN_KEY)
  if (isNullOrEmpty(tokenString)) return null
  try {
    return JSON.parse(tokenString!)
  } catch (error) {
    // TODO: have a redirect here to login or smth
    return null
  }
}

const removePersistedZitadelUserAccessToken = () => {
  localStorage.removeItem(LOCALSTORAGE_ZITADEL_USERTOKEN_KEY)
}

const clearLocalStorage = () => {
  localStorage.clear()
}

const clearAllCookiesAndStorage = () => {
  const domains = ['login.flyward.eu', 'app.flyward.eu', 'test.flyward.eu', 'uat.flyward.eu', 'dev.flyward.eu']

  // Clear cookies
  document.cookie.split(';').forEach((cookie) => {
    const name = cookie.trim().split('=')[0]

    // Remove cookie for the current domain
    document.cookie = `${name}=; path=/; domain=${window.location.hostname}`

    // Attempt to remove cookies for all specified domains
    domains.forEach((domain) => {
      document.cookie = `${name}=; path=/; domain=${domain}`
    })
  })

  // Clear localStorage
  clearLocalStorage()

  // Clear sessionStorage
  sessionStorage.clear()
}

export {
  getZitadelPersistedUserAccessToken,
  persistZitadelUserAccessToken,
  removePersistedZitadelUserAccessToken,
  clearLocalStorage,
  clearAllCookiesAndStorage,
}
