import { useAssetComponentsForms } from '@flyward/assets/context/AssetComponentsFormsContext'
import { AircraftAssignedMaintenanceProgramsSchema, type AircraftAssignedMaintenancePrograms, type AircraftMaintenancePrograms } from '@flyward/assets/models'
import { CnForm, CnFormControl, CnFormField, CnFormItem, CnFormMessage, Combobox } from '@flyward/platform/components'
import { isArrayEmptyOrNull } from '@flyward/platform/helpers/arrayHelpers'
import { formatAxiosErrorMessage } from '@flyward/platform/helpers/ErrorHelpers'
import { showError, showSuccess } from '@flyward/platform/services'
import { zodResolver } from '@hookform/resolvers/zod'
import { isNil } from 'lodash'
import { useEffect, useRef } from 'react'
import { useForm, type SubmitHandler } from 'react-hook-form'

export const EditAircraftMaintenancePrograms = ({
  assetId,
  airframesIds,
  enginesIds,
  apusIds,
  lgIds,
  assignedAirframeProgramId,
  assignedAPUProgramId,
  assignedEngineProgramId,
  assignedLandingGearProgramId,
  availablePrograms,
  changePrograms,
}: AircraftMaintenancePrograms) => {
  const { registerFormRef } = useAssetComponentsForms()
  const submitRef = useRef<HTMLInputElement>(null)
  const resetRef = useRef<HTMLInputElement>(null)

  const assignedPrograms: AircraftAssignedMaintenancePrograms = {
    assignedAirframeProgramId,
    assignedAPUProgramId,
    assignedEngineProgramId,
    assignedLandingGearProgramId,
  }

  const form = useForm<AircraftAssignedMaintenancePrograms>({
    defaultValues: assignedPrograms,
    values: assignedPrograms,
    resolver: zodResolver(
      AircraftAssignedMaintenanceProgramsSchema(!isArrayEmptyOrNull(airframesIds), !isArrayEmptyOrNull(enginesIds), !isArrayEmptyOrNull(lgIds), !isArrayEmptyOrNull(apusIds)),
    ),
    mode: 'all',
    reValidateMode: 'onChange',
  })

  const onUpdateAircraftMaintenancePrograms: SubmitHandler<AircraftAssignedMaintenancePrograms> = async (data) => {
    let hasErrors = false
    if (!isNil(data.assignedAirframeProgramId)) {
      const result = await changePrograms.changeAirframeProgram(data.assignedAirframeProgramId)
      if (!isNil(result)) {
        showError(formatAxiosErrorMessage(result))
        hasErrors = true
      }
    }
    if (!isNil(data.assignedEngineProgramId)) {
      const result = await changePrograms.changeEngineProgram(data.assignedEngineProgramId)
      if (!isNil(result)) {
        showError(formatAxiosErrorMessage(result))
        hasErrors = true
      }
    }
    if (!isNil(data.assignedLandingGearProgramId)) {
      const result = await changePrograms.changeLandingGearProgram(data.assignedLandingGearProgramId)
      if (!isNil(result)) {
        showError(formatAxiosErrorMessage(result))
        hasErrors = true
      }
    }
    if (!isNil(data.assignedAPUProgramId)) {
      const result = await changePrograms.changeAuxiliaryPowerUnitProgram(data.assignedAPUProgramId)
      if (!isNil(result)) {
        showError(formatAxiosErrorMessage(result))
        hasErrors = true
      }
    }

    if (!hasErrors) {
      showSuccess('Aircraft maintenance programmes are updated successfully')
    }
  }

  const {
    handleSubmit: handleAircraftMaintenanceProgramsUpdate,
    formState,
    getValues: getAircraftMaintenanceProgramsFormValues,
    setValue: setAircraftMaintenanceProgramsFormValue,
    control: aircraftMaintenanceProgramsFormControl,
  } = form

  const aircraftMaintenanceProgramsFormValues = getAircraftMaintenanceProgramsFormValues()

  const { isDirty, dirtyFields, isValid } = formState

  useEffect(() => {
    registerFormRef({ id: [assetId, 'allComponentsMaintenancePrograms'], submitRef, resetRef, hasChanges: isDirty, isValid })
  }, [assetId, registerFormRef, dirtyFields, aircraftMaintenanceProgramsFormValues, isDirty, isValid, setAircraftMaintenanceProgramsFormValue])

  return (
    <CnForm {...form}>
      <form onSubmit={handleAircraftMaintenanceProgramsUpdate(onUpdateAircraftMaintenancePrograms)}>
        <div className="flex w-full gap-x-6">
          {!isArrayEmptyOrNull(airframesIds) && (
            <CnFormField
              control={aircraftMaintenanceProgramsFormControl}
              name={`assignedAirframeProgramId`}
              render={({ field }) => (
                <CnFormItem>
                  <CnFormControl>
                    <Combobox
                      items={availablePrograms.availableAirframePrograms}
                      id="airframeProgram"
                      itemLabel="Airframe Programme"
                      className="basis-3/12"
                      label="Assign Airframe Programme"
                      selectedValues={isNil(field.value) ? [] : [field.value?.toString()]}
                      setSelectedValues={(value: string[]) => {
                        field.onChange(value[0])
                      }}
                    />
                  </CnFormControl>
                  <CnFormMessage />
                </CnFormItem>
              )}
            />
          )}

          {!isArrayEmptyOrNull(enginesIds) && (
            <CnFormField
              control={aircraftMaintenanceProgramsFormControl}
              name={`assignedEngineProgramId`}
              render={({ field }) => (
                <CnFormItem>
                  <CnFormControl>
                    <Combobox
                      items={availablePrograms.availableEnginePrograms}
                      id="engineProgram"
                      itemLabel="Engine Programme"
                      className="basis-3/12"
                      label="Assign Engine Programme"
                      selectedValues={isNil(field.value) ? [] : [field.value?.toString()]}
                      setSelectedValues={(value: string[]) => {
                        field.onChange(value[0])
                      }}
                    />
                  </CnFormControl>
                  <CnFormMessage />
                </CnFormItem>
              )}
            />
          )}

          {!isArrayEmptyOrNull(lgIds) && (
            <CnFormField
              control={aircraftMaintenanceProgramsFormControl}
              name={`assignedLandingGearProgramId`}
              render={({ field }) => (
                <CnFormItem>
                  <CnFormControl>
                    <Combobox
                      items={availablePrograms.availableLandingGearPrograms}
                      id="landingGearProgram"
                      itemLabel="Landing Gear Programme"
                      className="basis-3/12"
                      label="Assign Landing Gear Programme"
                      selectedValues={isNil(field.value) ? [] : [field.value?.toString()]}
                      setSelectedValues={(value: string[]) => {
                        field.onChange(value[0])
                      }}
                    />
                  </CnFormControl>
                  <CnFormMessage />
                </CnFormItem>
              )}
            />
          )}

          {!isArrayEmptyOrNull(apusIds) && (
            <CnFormField
              control={aircraftMaintenanceProgramsFormControl}
              name={`assignedAPUProgramId`}
              render={({ field }) => (
                <CnFormItem>
                  <CnFormControl>
                    <Combobox
                      items={availablePrograms.availableAuxiliaryPowerUnitPrograms}
                      id="apuProgram"
                      itemLabel="APU Programme"
                      className="basis-3/12"
                      label="Assign APU Programme"
                      selectedValues={isNil(field.value) ? [] : [field.value?.toString()]}
                      setSelectedValues={(value: string[]) => {
                        field.onChange(value[0])
                      }}
                    />
                  </CnFormControl>
                  <CnFormMessage />
                </CnFormItem>
              )}
            />
          )}

          <input type="submit" className="hidden" ref={submitRef} />
          <input type="reset" className="hidden" ref={resetRef} onClick={() => form.reset()} />
        </div>
      </form>
    </CnForm>
  )
}
