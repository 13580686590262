import { useDispatch, useSelector } from 'react-redux'
import type { TRootState } from '@flyward/platform/store/configureReducers'
import { Box, Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import { Label } from '../common/Label'
import { CreateAssetDto } from '@flyward/assets/models/CreateAssetDto'
import { useCreateAssetMutation } from '@flyward/assets/store/api'
import { AssetType, NumberDisplay, NumberDisplayType } from '@flyward/platform'
import { resetWizard } from '../../store/assetWizardSlice'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '@flyward/main-app/providers/routes'
import { showError, showSuccess } from '@flyward/platform/services/Notifications'
import { formatAxiosErrorMessage } from '@flyward/platform/helpers/ErrorHelpers'
import { isEmpty } from 'lodash'
import { stringToLongDateFormat } from '@flyward/platform/helpers/dateHelpers'

interface ReviewAndPublishProps {
  onNext: () => void
  onBack: () => void
  stepTitle: string
}

export const StandaloneEngineReviewAndPublish: React.FC<ReviewAndPublishProps> = ({ onBack, stepTitle }) => {
  const wizardState = useSelector((state: TRootState) => state.assetWizard.steps[AssetType.StandaloneEngine])
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [createAsset] = useCreateAssetMutation()

  const onPublish = () => {
    const assetToCreate: CreateAssetDto = {
      dateOfLastTechSpec: wizardState.general.dateOfLastTechSpec,
      leaseStartDate: wizardState.general.leaseStartDate,
      leaseEndDate: wizardState.general.leaseEndDate,
      lessee: wizardState.general.lessee,
      lesseeHabitualBase: wizardState.general.lesseeHabitualBase,
      region: wizardState.general.region,
      portfolio: wizardState.general.portfolio,
      averageMonthlyFlightHours: wizardState.general.averageMonthlyFlightHours,
      averageMonthlyFlightCycles: wizardState.general.averageMonthlyFlightCycles,
      sourceUrl: wizardState.general.sourceUrl,
      engines: [
        {
          serialNumber: wizardState.general.sn,
          dom: wizardState.general.dom,
          manufacturer: wizardState.general.manufacturer,
          model: wizardState.general.model,
          totalHoursSinceNew: Number(wizardState.engine.pr.tsn),
          cyclesSinceNew: Number(wizardState.engine.pr.csn),
          totalHoursSinceNewAtLastEvent: wizardState.engine.pr.tsnLastPr,
          cyclesSinceNewAtLastEvent: wizardState.engine.pr.csnLastPr,
          lastEventDate: wizardState.engine.pr.dateLastPr,
          deratePct: wizardState.general.derate,
          hoursSinceEventAtContractDelivery: wizardState.engine.pr.hoursSinceEventAtContractDelivery,
          engineLifeLimitedParts: wizardState.engine.llps.map((llp) => {
            return {
              model: llp.description,
              module: llp.module,
              partNumber: llp.partNumber,
              serialNumber: llp.serialNumber,
              technicalLimitAmount: llp.limit,
              cyclesSinceNew: llp.csn,
              cyclesRemaining: llp.cyclesRemaining,
              cyclesSinceNewAtContractDelivery: llp.csnAtContractDelivery,
            }
          }),
        },
      ],
    }

    createAsset({
      assetType: AssetType.StandaloneEngine,
      createAssetDto: assetToCreate,
    })
      .then((data) => {
        if (data.error) {
          showError(formatAxiosErrorMessage(data.error.message))
        } else {
          showSuccess(<span>The Standalone Engine {wizardState.general.sn} was successfully added.</span>)
          dispatch(resetWizard())
          navigate(ROUTES.FLEET.ROOT)
        }
      })
      .catch((error) => {
        showError(formatAxiosErrorMessage(JSON.stringify(error?.response)))
      })
  }

  const flexBoxStyles = {
    display: 'flex',
    flexDirection: 'row',
    gap: 2,
    flexWrap: 'wrap',
    '& > *': {
      flex: '1 1 calc(33.333% - 16px)', // Takes up 1/3 of the space minus the gap
      minWidth: '200px',
      maxWidth: 'calc(33.333% - 16px)', // Ensures it doesn't grow beyond 1/3
    },
  }

  return (
    <div className="shadow-sm rounded-lg bg-white p-6">
      <Typography variant="h6" sx={{ mb: 3 }}>
        {stepTitle}
      </Typography>

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, mb: 4 }}>
        <div className="mb-2 text-lg font-semibold">1. General</div>
        <Box sx={flexBoxStyles}>
          <Label label="ESN" value={wizardState.general.sn} />
          <Label label="Manufacturer" value={isEmpty(wizardState.general.manufacturer) ? '-' : wizardState.general.manufacturer} />
          <Label label="Model" value={isEmpty(wizardState.general.model) ? '-' : wizardState.general.model} />
          <Label label="DOM" value={stringToLongDateFormat(wizardState.general.dom) ?? '-'} />
          <Label label="Tech Spec Date" value={stringToLongDateFormat(wizardState.general.dateOfLastTechSpec) ?? '-'} />
          <Label label="Derate" value={`${wizardState.general.derate ?? '0'} %`} />
          <Label label="Lease Start Date" value={stringToLongDateFormat(wizardState.general.leaseStartDate) ?? '-'} />
          <Label label="Lease End Date" value={stringToLongDateFormat(wizardState.general.leaseEndDate) ?? '-'} />
          <Label label="Operator" value={isEmpty(wizardState.general.lessee) ? '-' : wizardState.general.lessee} />
          <Label label="Lessee Habitual Base" value={isEmpty(wizardState.general.lesseeHabitualBase) ? '-' : wizardState.general.lesseeHabitualBase} />
          <Label label="Region" value={isEmpty(wizardState.general.region) ? '-' : wizardState.general.region} />
          <Label label="Portfolio" value={isEmpty(wizardState.general.portfolio) ? '-' : wizardState.general.portfolio} />
          <Label label="Average Monthly Flight Hours" value={`${wizardState.general.averageMonthlyFlightHours ?? '0'}`} />
          <Label label="Average Monthly Flight Cycles" value={`${wizardState.general.averageMonthlyFlightCycles ?? '0'}`} />
          <div />
          <div />
          <div />
        </Box>
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, mb: 4 }}>
        <div className="mb-2 text-lg font-semibold">2. Engine</div>

        <Typography variant="subtitle1" sx={{ mb: 1 }}>
          PR Technical
        </Typography>
        <Box sx={flexBoxStyles}>
          <Label label="TSN" value={wizardState.engine.pr.tsn ?? '-'} />
          <Label label="CSN" value={wizardState.engine.pr.csn ?? '-'} />
          <div />
          <Label label="TSN @ Last PR" value={wizardState.engine.pr.tsnLastPr ?? '-'} />
          <Label label="CSN @ Last PR" value={wizardState.engine.pr.csnLastPr ?? '-'} />
          <Label label="Date @ Last PR" value={stringToLongDateFormat(wizardState.engine.pr.dateLastPr) ?? '-'} />
          <Label label="Hours since PR at Contract Delivery" value={wizardState.engine.pr.hoursSinceEventAtContractDelivery ?? '-'} />
        </Box>
        <Typography variant="subtitle1" sx={{ mb: 1, pt: 1 }}>
          LLP Technical
        </Typography>
        {wizardState.engine.llps.length > 0 && (
          <TableContainer component={Paper} sx={{ mb: 4 }}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: 600 }}>Module</TableCell>
                  <TableCell sx={{ fontWeight: 600 }}>Description</TableCell>
                  <TableCell sx={{ fontWeight: 600 }}>Part Number</TableCell>
                  <TableCell sx={{ fontWeight: 600 }}>Serial Number</TableCell>
                  <TableCell sx={{ fontWeight: 600, textAlign: 'right' }}>Limit [FC]</TableCell>
                  <TableCell sx={{ fontWeight: 600, textAlign: 'right' }}>CSN</TableCell>
                  <TableCell sx={{ fontWeight: 600, textAlign: 'right' }}>Cycles Remaining</TableCell>
                  <TableCell sx={{ fontWeight: 600, textAlign: 'right' }}>CSN At Contract Delivery</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {wizardState.engine.llps.map(
                  (
                    {
                      description,
                      module,
                      partNumber,
                      serialNumber,
                      limit,
                      csn,
                      cyclesRemaining,
                      csnAtContractDelivery,
                    }: {
                      description: string
                      module: string | null
                      partNumber: string | null
                      serialNumber: string | null
                      limit: number | null
                      csn: number | null
                      cyclesRemaining: number | null
                      csnAtContractDelivery: number | null
                    },
                    index: number,
                  ) => (
                    <TableRow key={index}>
                      <TableCell>{module ?? '-'}</TableCell>
                      <TableCell>{description}</TableCell>
                      <TableCell>{partNumber ?? '-'}</TableCell>
                      <TableCell>{serialNumber ?? '-'}</TableCell>
                      <TableCell sx={{ textAlign: 'right' }}>
                        <NumberDisplay value={limit} displayType={NumberDisplayType.Integer} />
                      </TableCell>
                      <TableCell sx={{ textAlign: 'right' }}>
                        <NumberDisplay value={csn} displayType={NumberDisplayType.Integer} />
                      </TableCell>
                      <TableCell sx={{ textAlign: 'right' }}>
                        <NumberDisplay value={cyclesRemaining} displayType={NumberDisplayType.Integer} />
                      </TableCell>
                      <TableCell sx={{ textAlign: 'right' }}>
                        <NumberDisplay value={csnAtContractDelivery} displayType={NumberDisplayType.Integer} />
                      </TableCell>
                    </TableRow>
                  ),
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Box>

      <Box sx={{ mt: 4 }}>
        <Button
          variant="contained"
          onClick={() => {
            onPublish()
          }}
          sx={{ mr: 1 }}
        >
          Publish
        </Button>
        <Button variant="text" onClick={onBack}>
          Back
        </Button>
      </Box>
    </div>
  )
}
