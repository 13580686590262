import React, { useEffect } from 'react'
import { AssetType, ErrorBoundary, cn, ComponentContainer, Icon, IconVariant, Size, useNavigationState } from '@flyward/platform'
import { Box, Button, LinearProgress, Step, StepLabel, Stepper, styled, Typography } from '@mui/material'
import { useAssetWizardState } from './hooks/useAssetWizardState'
import { useAssetWizardConfig } from './setup'
import { useSelector } from 'react-redux'
import { isNil } from 'lodash'
import { pageContentStyles } from '@flyward/main-app/layout'
import { ROUTES } from '@flyward/main-app/providers/routes.ts'
import { FWBreadcrumbs } from '@flyward/main-app/layout/MasterLayout/Header/FWBreadcrumbs.tsx'

const VerticalStepper = styled(Stepper)({
  flexDirection: 'column',
  alignItems: 'flex-start',
  '& .MuiStep-root': {
    width: '100%',
  },
  '& .MuiStepLabel-root': {
    padding: '8px 0',
  },
  '& .MuiStepLabel-labelContainer': {
    display: 'flex',
    flexDirection: 'column',
  },
  '& .MuiStepLabel-label': {
    textAlign: 'left',
  },
})

// Add a styled component for the sub-label
const StepSubLabel = styled(Typography)({
  fontSize: '0.75rem',
  color: 'var(--text-3)',
  marginTop: '2px',
})

interface StepProgressBarProps {
  currentStep: number
  totalSteps: number
}
const StepProgressBar: React.FC<StepProgressBarProps> = ({ currentStep, totalSteps }) => {
  const progress = (currentStep / totalSteps) * 100

  return (
    <Box sx={{ width: '100%', mb: 2, display: 'flex', alignItems: 'center', gap: 2 }}>
      <LinearProgress
        variant="determinate"
        value={progress}
        sx={{
          height: 6,
          borderRadius: 3,
          flexGrow: 1,
          backgroundColor: 'var(--primary-light-2)',
          '& .MuiLinearProgress-bar': {
            backgroundColor: 'var(--primary-dark-1)',
          },
        }}
      />
      <Typography variant="body2" color="text.secondary" sx={{ minWidth: 'fit-content' }}>
        {currentStep}/{totalSteps}
      </Typography>
    </Box>
  )
}

export const AssetWizardPage = () => {
  const { toggleVisibility } = useNavigationState()
  const assetType = useSelector((state: any) => state.assetWizard.assetType)
  const isAiPopulated = useSelector((state: any) => state.assetWizard.isAiPopulated)

  const { state, handleStepUpdate, handleAssetTypeChange, handleStepChange } = useAssetWizardState(assetType)
  const config = useAssetWizardConfig(assetType)
  const currentStep = config?.steps?.[state.activeStep] as {
    component: React.ComponentType<any>
    label: string
    key: string
  }

  const handleNext = (data) => {
    handleStepUpdate(currentStep.key, data)
  }

  const handleBack = () => {
    handleStepChange(state.activeStep - 1)
  }

  const StepComponent = currentStep?.component || null

  // TODO @dan pls fix
  // useEffect(() => {
  //   return () => {
  //     dispatch(resetWizard())
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])

  useEffect(() => {
    toggleVisibility()

    return () => {
      toggleVisibility()
    }
  }, [toggleVisibility])

  return (
    <ErrorBoundary>
      <main className={cn('h-full w-full', pageContentStyles)}>
        <div className="flex h-20 w-full items-center px-2 py-9">
          <FWBreadcrumbs breadcrumbs={[{ title: 'Fleet Summary', url: ROUTES.FLEET.ROOT }]} currentTitle={'Asset Creation'} />
        </div>
        <ComponentContainer className="h-full w-full !items-start !justify-start">
          {isNil(assetType) ? (
            <div className="shadow-md w-full rounded-lg bg-white p-6">
              <Typography variant="h6" className="mb-6">
                Select Asset Type
              </Typography>
              <div className="flex gap-8">
                <Button variant="contained" size="large" onClick={() => handleAssetTypeChange(AssetType.Aircraft)}>
                  <Icon size={Size.Large} variant={IconVariant.PlaneZoomOut} />
                  <span className="pl-1">Aircraft</span>
                </Button>
                <Button variant="contained" size="large" onClick={() => handleAssetTypeChange(AssetType.StandaloneEngine)}>
                  <Icon size={Size.Large} variant={IconVariant.Engine} />
                  <span className="pl-1">Standalone Engine</span>
                </Button>
              </div>
            </div>
          ) : (
            <div className="shadow-md w-full rounded-lg bg-white p-6">
              <div className="flex w-full">
                {/* Left side - Vertical Stepper */}
                <div className="w-62 flex-shrink-0 border-r border-gray-300">
                  <div className="shadow-sm h-full rounded-lg bg-white p-4">
                    <Typography variant="subtitle1" fontWeight="bold">
                      {config.stepperTitle}
                    </Typography>
                    <StepProgressBar currentStep={state.activeStep + 1} totalSteps={config.steps.length} />
                    <VerticalStepper activeStep={state.activeStep} orientation="vertical" className="mt-4">
                      {config.steps.map((step, index: number) => {
                        const isClickable = index <= state.activeStep
                        return (
                          <Step
                            key={index}
                            onClick={() => isClickable && handleStepChange(index)}
                            sx={{
                              cursor: isClickable ? 'pointer' : 'default',
                              '&:hover': {
                                backgroundColor: isClickable ? 'rgba(0, 0, 0, 0.04)' : 'transparent',
                              },
                              transition: 'background-color 0.2s',
                            }}
                          >
                            <StepLabel>
                              <div>
                                {step.label}
                                {step.subLabel && <StepSubLabel>{step.subLabel}</StepSubLabel>}
                              </div>
                            </StepLabel>
                          </Step>
                        )
                      })}
                    </VerticalStepper>
                  </div>
                </div>

                {/* Right side - Content */}
                <div className="max-h-[calc(100vh-12rem)] overflow-auto pl-6">
                  <StepComponent onNext={handleNext} onBack={handleBack} stepTitle={currentStep.label} initialData={state[currentStep.key]} isAiPopulated={isAiPopulated} />
                </div>
              </div>
            </div>
          )}
        </ComponentContainer>
      </main>
    </ErrorBoundary>
  )
}
