import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { ComponentType } from '@flyward/platform'

const baseState = {
  activeStep: 0,
  general: {
    name: '',
    manufacturer: '',
    model: '',
    baseYear: undefined,
  },
  basicAssumptions: {
    checkDowntime: 1,
    delayInPayment: undefined,
  },
}

const initialState = {
  [ComponentType.EngineUnit]: {
    ...baseState,
    perfLLP: {
      firstRunLimit: undefined,
      firstRunCost: undefined,
      lastRunLimit: undefined,
      lastRunCost: undefined,
      llpReplacements: [],
    },
    adjustCostGrowth: {
      pr: undefined,
      llp: undefined,
      fhfc: [],
      derate: [],
      envImpact: [],
    },
  },
  [ComponentType.Airframe]: {
    ...baseState,
    general: {
      ...baseState.general,
      aircraft: undefined,
      series: undefined,
    },
    checksEscalationsAssumptions: {
      checks: [],
      escalation: undefined,
      ...baseState.basicAssumptions,
    },
  },
  [ComponentType.LandingGear]: {
    ...baseState,
    checksEscalationsAssumptions: {
      checks: [],
      escalation: undefined,
      ...baseState.basicAssumptions,
    },
  },
  [ComponentType.AuxiliaryPowerUnit]: {
    ...baseState,
    checksEscalationsAssumptions: {
      checks: [],
      escalation: undefined,
      ...baseState.basicAssumptions,
    },
  },
}

type WizardState = typeof initialState

type UpdateStepPayload = {
  type: ComponentType
  step: keyof WizardState[keyof WizardState]
  data: any
}

type SetActiveStepPayload = {
  type: ComponentType
  step: number
}

const wizardSlice = createSlice({
  name: 'wizard',
  initialState,
  reducers: {
    updateStepData: (state, { payload }: PayloadAction<UpdateStepPayload>) => {
      const { type, step, data } = payload
      const target = state[type][step]
      if (!target) return

      state[type][step] = Array.isArray(target) ? [...target, ...data] : { ...target, ...data }
    },
    setActiveStep: (state, { payload }: PayloadAction<SetActiveStepPayload>) => {
      state[payload.type].activeStep = payload.step
    },
    resetWizard: () => initialState,
  },
})

export const { updateStepData, setActiveStep, resetWizard } = wizardSlice.actions
export default wizardSlice.reducer
