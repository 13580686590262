import { getCoreRowModel, useReactTable } from '@tanstack/react-table'
import { useState } from 'react'
import { type Control } from 'react-hook-form'
import { type DraggableLlp, type DraggableLlpStack } from '../../../../../../../models/aircraftComponents'
import { type VerifyLlpResultDto } from '../../../../../../../models/aircraftComponents/aircraftEngine/verify/VerifyLlpResultDto'
import { AssetLlpsHeader } from './AssetLlpsHeader'
import { AssetLlpsRows } from './AssetLlpsRows'
import { editableColumns } from './columns'
import { type RowSizeMap } from '@flyward/platform/components'

interface IAssetAlignmentWizardProps {
  verifyLLPsResult: VerifyLlpResultDto[]
  componentFormValues: DraggableLlpStack
  formControl: Control<DraggableLlpStack, unknown>
  onDeleteExistingLlp: (llpId: string) => void
  onExistingLlpCopyFromKb: (positionalIndex: number, llp: DraggableLlp) => void
  persistAssetLllStackInForm: (llpStack: DraggableLlp[]) => void
  kbTotalCount: number
}

export const AssetLlpsTable = ({
  verifyLLPsResult,
  componentFormValues,
  formControl,
  onDeleteExistingLlp,
  onExistingLlpCopyFromKb,
  persistAssetLllStackInForm,
  kbTotalCount,
}: IAssetAlignmentWizardProps) => {
  const assetLlps: DraggableLlp[] = componentFormValues.llps

  const [columnSizes, setColumnSizes] = useState<RowSizeMap>({
    llp_componentModule: { defaultSize: 120, minSize: 50, maxSize: 300, currentSize: 120 },
    llp_componentModel: { defaultSize: 200, minSize: 80, maxSize: 400, currentSize: 200 },
    llp_partNumber: { defaultSize: 85, minSize: 50, maxSize: 200, currentSize: 85 },
    llp_componentSerialNumber: { defaultSize: 95, minSize: 50, maxSize: 200, currentSize: 95 },
    llp_technicalLimitAmount: { defaultSize: 70, minSize: 50, maxSize: 200, currentSize: 70 },
    'llp_assetComponentUtilizationSnapshot.cyclesSinceNew': { defaultSize: 75, minSize: 50, maxSize: 160, currentSize: 75 },
    'llp_assetComponentUtilizationSnapshot.remainingCycles': { defaultSize: 115, minSize: 50, maxSize: 160, currentSize: 115 },
    llp_cyclesSinceNewAtContractDelivery: { defaultSize: 95, minSize: 50, maxSize: 260, currentSize: 95 },
    alignmentStatus: { defaultSize: 20, minSize: 20, maxSize: 20, currentSize: 20 },
    order: { defaultSize: 20, minSize: 20, maxSize: 20, currentSize: 20 },
    align: { defaultSize: 20, minSize: 20, maxSize: 20, currentSize: 20 },
  })

  const table = useReactTable<DraggableLlp>({
    data: assetLlps,
    columns: editableColumns(columnSizes),
    getCoreRowModel: getCoreRowModel(),
    columnResizeMode: 'onChange',
  })

  return (
    <table className="max-w-fit basis-8/12 border-collapse border border-black-20">
      <AssetLlpsHeader headerGroups={table.getHeaderGroups()} columnSizes={columnSizes} setColumnSizes={setColumnSizes} />
      <AssetLlpsRows
        componentFormValues={componentFormValues}
        verifyLLPsResult={verifyLLPsResult}
        kbTotalCount={kbTotalCount}
        formControl={formControl}
        onDeleteExistingLlp={onDeleteExistingLlp}
        onExistingLlpCopyFromKb={onExistingLlpCopyFromKb}
        persistAssetLllStackInForm={persistAssetLllStackInForm}
        columnSizes={columnSizes}
      />
    </table>
  )
}
