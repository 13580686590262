import { useDispatch, useSelector } from 'react-redux'
import { updateStepData, setActiveStep, resetWizard, setAssetType } from '../store/assetWizardSlice'
import { AssetType } from '@flyward/platform'

export const useAssetWizardState = (type: AssetType) => {
  const dispatch = useDispatch()
  const assetWizard = useSelector((state: any) => ({
    ...state.assetWizard.steps[type],
    activeStep: state.assetWizard.activeStep,
    assetType: state.assetWizard.assetType,
  }))

  const handleStepUpdate = (step: any, data: any, moveNext: boolean = true) => {
    dispatch(updateStepData({ type, step, data }))
    if (moveNext) {
      dispatch(setActiveStep({ step: assetWizard.activeStep + 1 }))
    }
  }

  const handleAssetTypeChange = (type: AssetType) => {
    dispatch(setAssetType(type))
  }

  const handleStepChange = (step: number) => {
    dispatch(setActiveStep({ step }))
  }

  const handleReset = () => {
    dispatch(resetWizard())
  }

  return {
    state: assetWizard,
    handleAssetTypeChange,
    handleStepUpdate,
    handleStepChange,
    handleReset,
  }
}
