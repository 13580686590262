import { createTheme } from '@mui/material/styles'
import type {} from '@mui/x-date-pickers/themeAugmentation'

// Helper function to get computed CSS variable value
const getCssVar = (variable: string): string => {
  // Get the value from the CSS variable
  const value = getComputedStyle(document.documentElement).getPropertyValue(variable).trim()
  return value || '#00838f' // Fallback color if variable is not found - Island Lush (default color)
}

export const theme = createTheme({
  typography: {
    fontFamily: 'Inter, sans-serif',
  },
  palette: {
    mode: 'light',
    primary: {
      main: getCssVar('--primary'),
    },
    secondary: {
      main: getCssVar('--secondary'),
    },
    error: {
      main: getCssVar('--error'),
    },
    text: {
      primary: getCssVar('--text-1'),
      secondary: getCssVar('--text-2'),
    },
  },
  components: {
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
      variants: [
        {
          props: { variant: 'outlined', color: 'primary' },
          style: {
            backgroundColor: 'var(--black-0)',
            '&:hover': {
              backgroundColor: 'var(--primary-light-2)',
            },
          },
        },
      ],
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontFeatureSettings: "'liga' 1, 'calt' 1",
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        InputLabelProps: {
          shrink: true,
        },
        autoComplete: 'off',
      },
    },
    MuiSnackbar: {
      defaultProps: {
        anchorOrigin: { vertical: 'top', horizontal: 'center' },
      },
      styleOverrides: {
        root: {
          border: `1px solid var(--black-40)`,
          borderRadius: '0.25rem',
          backgroundColor: 'var(--text-4)',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          marginTop: '-.6rem',
        },
        shrink: {
          marginTop: '0',
        },
      },
    },
    MuiInputBase: {
      defaultProps: {
        autoComplete: 'off',
      },
      styleOverrides: {
        root: {
          '&.MuiInputBase-root ': {
            height: '2.25rem',
          },
          '&.MuiInputBase-root  input': {
            padding: '0 .5rem',
            backgroundColor: 'var(--black-0)',
            '&::placeholder': {
              fontSize: '0.75rem',
            },
          },
          '&.MuiInputBase-sizeSmall': {
            height: '1.25rem',
            fontSize: '0.8rem',
          },
          '&.MuiInputBase-sizeSmall input': {
            padding: '0 .25rem',
            backgroundColor: 'var(--black-0)',
            '&::placeholder': {
              fontSize: '0.5rem',
            },
          },
        },
      },
    },
    MuiTable: {
      defaultProps: {
        padding: 'none',
      },
    },
    MuiTableCell: {
      defaultProps: {
        padding: 'none',
      },
      styleOverrides: {
        root: {
          '&.MuiTableCell-paddingNone': {
            padding: '4px',
          },
        },
        head: {
          backgroundColor: 'var(--header-table)',
        },
        sizeSmall: {
          padding: '0.5rem 1rem',
        },
      },
    },
    MuiDatePicker: {
      defaultProps: {
        sx: {
          '& .MuiInputBase-root': {
            backgroundColor: 'var(--black-0)',
          },
          '&.MuiCustom-date-picker-small': {
            width: '100%',
            height: '1.25rem',
            padding: '0',
            '& .MuiInputBase-root': {
              fontSize: '0.8rem',
              '& input': {
                padding: '0 2px',
              },
              '& .MuiButtonBase-root': {
                padding: '0',
                height: '1rem',
              },
            },
          },
        },
      },
    },
  },
})
