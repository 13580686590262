import { useGetTenantsQuery } from '@flyward/appIdentity/store/api/tenant/endpoints'
import { useAppDispatch, useAppSelector } from '@flyward/platform/store/configureHooks'
import { flywardApi } from '@flyward/platform/store/slices/api/flywardApi/flywardApiSlice'
import { resetFleetSlice } from '@flyward/platform/store/slices/features'
import { selectSupportFilteredTenantId } from '@flyward/platform/store/slices/features/auth/authSelectors'
import { setSupportFilteredTenantId } from '@flyward/platform/store/slices/features/auth/authSlice'
import { resetFlyForwardSlice } from '@flyward/platform/store/slices/features/flyForward/flyForwardSlice'
import { persistSupportFilteredTenantId } from '@flyward/platform/services/AUTH/supportFilteredTenantIdStore'
import { isNil } from 'lodash'
import { useUserAuthenticated, useZitadelAuthenticated } from '@flyward/appIdentity/context'
import { Role } from '@flyward/platform'

export const useTenantSelector = () => {
  const dispatch = useAppDispatch()
  const { isUserAuthenticated } = useZitadelAuthenticated()
  const { loggedUser } = useUserAuthenticated()

  const userRole = loggedUser?.role

  const { data: tenants } = useGetTenantsQuery(undefined, { skip: isUserAuthenticated !== true && userRole !== Role.Admin })
  const supportFilteredTenantId = useAppSelector(selectSupportFilteredTenantId)

  const tenantsList =
    tenants?.map((tenant) => ({
      label: tenant.name,
      value: tenant.id,
      isDisabled: !tenant.isActive,
    })) ?? []

  const handleTenantChange = (value: string[]) => {
    const tenantId = value[0]
    dispatch(flywardApi.util.invalidateTags(['apiData']))
    dispatch(resetFlyForwardSlice())
    dispatch(resetFleetSlice())
    dispatch(setSupportFilteredTenantId({ tenantId }))
    persistSupportFilteredTenantId(value[0])
  }

  return {
    tenantsList,
    supportFilteredTenantId,
    handleTenantChange,
    hasSelectedTenant: !isNil(supportFilteredTenantId),
  }
}
