export enum ReportDisplayType {
  AssetReport = 1,
  PortfolioReport = 2,
}

export const getReportDisplayTypeName = (reportDisplayType: ReportDisplayType): string => {
  switch (reportDisplayType) {
    case ReportDisplayType.AssetReport:
      return 'Asset Report'
    case ReportDisplayType.PortfolioReport:
      return 'Portfolio Report'
  }
}

export const ReportDisplayTypeOptions = [
  { value: (ReportDisplayType.AssetReport as number).toString(), label: getReportDisplayTypeName(ReportDisplayType.AssetReport) },
  { value: (ReportDisplayType.PortfolioReport as number).toString(), label: getReportDisplayTypeName(ReportDisplayType.PortfolioReport) },
]
