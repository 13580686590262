import { Box, Typography } from '@mui/material'

interface LabelProps {
  label: string
  value: any
}

export const Label = ({ label, value }: LabelProps) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Typography variant="caption" color="text.secondary" className="mb-0">
        {label}
      </Typography>
      <Typography variant="inherit">{value}</Typography>
    </Box>
  )
}
