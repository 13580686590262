import { type IYearMonth, CheckTypeTableDisplay, cn } from '@flyward/platform'
import { useState } from 'react'
import { type IMaintenanceScheduleDisplay } from '../../../../../models'
import { isNil } from 'lodash'
import { Button as MuiButton } from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'

interface IPortfolioChecks {
  eventsGrid: IMaintenanceScheduleDisplay[]
  openEventDetailViewPopover: (yearMonth: IYearMonth) => void
}

export const PortfolioChecks = ({ eventsGrid = [], openEventDetailViewPopover }: IPortfolioChecks) => {
  const eventsGridGroupedByAssetSn = Object.values(
    eventsGrid.reduce((acc: Record<string, { assetSerialNumber: string; events: IMaintenanceScheduleDisplay[] }>, item) => {
      if (isNil(acc[item.assetSerialNumber])) {
        acc[item.assetSerialNumber] = { assetSerialNumber: item.assetSerialNumber, events: [] }
      }
      acc[item.assetSerialNumber].events.push(item)
      return acc
    }, {}),
  )

  const onOpenEventDetailViewPopover = () => {
    openEventDetailViewPopover(eventsGrid[0].yearMonth as IYearMonth)
  }

  const AssetButton = ({ assetSerialNumber, events }: { assetSerialNumber: string; events: IMaintenanceScheduleDisplay[] }) => {
    const [expandEvents, setExpandEvents] = useState(false)

    const toggleExpand = (event: React.MouseEvent) => {
      event.stopPropagation()
      setExpandEvents(!expandEvents)
    }

    return (
      <div className="flex w-full flex-col">
        <div className="flex w-full  justify-between">
          {!expandEvents && <KeyboardArrowDownIcon sx={{ padding: '0.25rem' }} onClick={toggleExpand} />}
          {expandEvents && <KeyboardArrowUpIcon sx={{ padding: '0.25rem' }} onClick={toggleExpand} />}
          <span className="pr-1 text-gray-800">{`${assetSerialNumber}`}</span>
        </div>
        <div
          className={cn('flex w-full flex-col gap-1 p-1', 'transition-all flex-shrink-0 whitespace-nowrap bg-black-0 duration-300 ease-in-out', {
            hidden: !expandEvents,
          })}
        >
          {events.map((event) => (
            <div
              className="inline-flex w-full self-stretch overflow-y-hidden rounded bg-primary-dark-2 px-2 py-1 text-xs font-normal text-white"
              key={event.id}
            >{`${event.componentSerialNumber} (${CheckTypeTableDisplay(event.forecastedCheck.checkType)})`}</div>
          ))}
        </div>
      </div>
    )
  }

  const renderItems = () => {
    const displayedItems = eventsGridGroupedByAssetSn.map((group) => (
      <MuiButton
        key={group.assetSerialNumber}
        variant="outlined"
        color="primary"
        size="small"
        sx={{ textTransform: 'none', width: '100%', padding: 0, marginBottom: '0.25rem' }}
        onClick={onOpenEventDetailViewPopover}
      >
        <AssetButton assetSerialNumber={group.assetSerialNumber} events={group.events} />
      </MuiButton>
    ))

    return <>{displayedItems}</>
  }

  return renderItems()
}
