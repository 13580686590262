import { isNil } from 'lodash'

export enum CheckType {
  Epr = 1,
  EngineLlpReplacement = 2,
  LandingGearOverhaul = 4,
  ApuPr = 5,
  ApuLlpReplacement = 6,

  // number of months
  Airframe2y = 24,
  Airframe3y = 36,
  Airframe4y = 48,
  Airframe5y = 60,
  Airframe6y = 72,
  Airframe7y = 84,
  Airframe8y = 96,
  Airframe9y = 108,
  Airframe10y = 120,
  Airframe12y = 144,
  Airframe14y = 168,
  Airframe15y = 180,
  Airframe16y = 192,
  Airframe18y = 216,
  Airframe20y = 240,
  Airframe21y = 252,
  Airframe24y = 288,
}

export const airframeChecks = [
  CheckType.Airframe2y,
  CheckType.Airframe3y,
  CheckType.Airframe4y,
  CheckType.Airframe5y,
  CheckType.Airframe6y,
  CheckType.Airframe8y,
  CheckType.Airframe9y,
  CheckType.Airframe10y,
  CheckType.Airframe12y,
  CheckType.Airframe14y,
  CheckType.Airframe15y,
  CheckType.Airframe16y,
  CheckType.Airframe18y,
  CheckType.Airframe20y,
  CheckType.Airframe21y,
  CheckType.Airframe24y,
]

export const allNonEngineCheckTypes = [CheckType.LandingGearOverhaul, CheckType.ApuPr, CheckType.ApuLlpReplacement, ...airframeChecks]

export const CheckTypeDisplay = (value: CheckType) => {
  switch (value) {
    case CheckType.Epr:
      return 'PR'
    case CheckType.EngineLlpReplacement:
      return 'LLP'
    case CheckType.LandingGearOverhaul:
      return 'Overhaul'
    case CheckType.ApuPr:
      return 'PR'
    case CheckType.ApuLlpReplacement:
      return 'LLP'
    case CheckType.Airframe2y:
      return '2y'
    case CheckType.Airframe3y:
      return '3y'
    case CheckType.Airframe4y:
      return '4y'
    case CheckType.Airframe5y:
      return '5y'
    case CheckType.Airframe6y:
      return '6y'
    case CheckType.Airframe7y:
      return '7y'
    case CheckType.Airframe8y:
      return '8y'
    case CheckType.Airframe9y:
      return '9y'
    case CheckType.Airframe10y:
      return '10y'
    case CheckType.Airframe12y:
      return '12y'
    case CheckType.Airframe14y:
      return '14y'
    case CheckType.Airframe15y:
      return '15y'
    case CheckType.Airframe16y:
      return '16y'
    case CheckType.Airframe18y:
      return '18y'
    case CheckType.Airframe20y:
      return '20y'
    case CheckType.Airframe21y:
      return '21y'
    case CheckType.Airframe24y:
      return '24y'
  }
}

export const CheckTypeTableDisplay = (value: CheckType) => {
  switch (value) {
    case CheckType.Epr:
      return 'PR'
    case CheckType.EngineLlpReplacement:
      return 'LLP'
    case CheckType.LandingGearOverhaul:
      return 'LG'
    case CheckType.ApuPr:
      return 'APU'
    case CheckType.ApuLlpReplacement:
      return 'LLP'
    case CheckType.Airframe2y:
      return '2y'
    case CheckType.Airframe3y:
      return '3y'
    case CheckType.Airframe4y:
      return '4y'
    case CheckType.Airframe5y:
      return '5y'
    case CheckType.Airframe6y:
      return '6y'
    case CheckType.Airframe7y:
      return '7y'
    case CheckType.Airframe8y:
      return '8y'
    case CheckType.Airframe9y:
      return '9y'
    case CheckType.Airframe10y:
      return '10y'
    case CheckType.Airframe12y:
      return '12y'
    case CheckType.Airframe14y:
      return '14y'
    case CheckType.Airframe15y:
      return '15y'
    case CheckType.Airframe16y:
      return '16y'
    case CheckType.Airframe18y:
      return '18y'
    case CheckType.Airframe20y:
      return '20y'
    case CheckType.Airframe21y:
      return '21y'
    case CheckType.Airframe24y:
      return '24y'
  }
}

export enum ComponentTypesAPIURIs {
  Airframe = 'airframes',
  EngineUnit = 'engine-units',
  AuxiliaryPowerUnit = 'auxiliary-power-units',
  EnginePerformanceRestoration = 'engine-performance-restorations',
  EngineLifeLimitedPart = 'engine-llps',
  LandingGear = 'landing-gears',
  ThrustReversers = 'thrust-reversers',
}

export const getEngineChecks = (): CheckType[] => {
  return [CheckType.Epr, CheckType.EngineLlpReplacement]
}

export const isEngineCheck = (checkType: CheckType): boolean => {
  return getEngineChecks().includes(checkType)
}

export const isAirframeCheck = (checkType: CheckType): boolean => {
  return airframeChecks.includes(checkType)
}

export enum AirframeCheckType {
  Airframe2y = 24,
  Airframe3y = 36,
  Airframe4y = 48,
  Airframe5y = 60,
  Airframe6y = 72,
  Airframe7y = 84,
  Airframe8y = 96,
  Airframe9y = 108,
  Airframe10y = 120,
  Airframe12y = 144,
  Airframe14y = 168,
  Airframe15y = 180,
  Airframe16y = 192,
  Airframe18y = 216,
  Airframe20y = 240,
  Airframe21y = 252,
  Airframe24y = 288,
}

export const AirframeCheckTypeDisplay = (value: AirframeCheckType) => {
  switch (value) {
    case AirframeCheckType.Airframe2y:
      return '2Y'
    case AirframeCheckType.Airframe3y:
      return '3Y'
    case AirframeCheckType.Airframe4y:
      return '4Y'
    case AirframeCheckType.Airframe5y:
      return '5Y'
    case AirframeCheckType.Airframe6y:
      return '6Y'
    case AirframeCheckType.Airframe7y:
      return '7Y'
    case AirframeCheckType.Airframe8y:
      return '8Y'
    case AirframeCheckType.Airframe9y:
      return '9Y'
    case AirframeCheckType.Airframe10y:
      return '10Y'
    case AirframeCheckType.Airframe12y:
      return '12Y'
    case AirframeCheckType.Airframe14y:
      return '14Y'
    case AirframeCheckType.Airframe15y:
      return '15Y'
    case AirframeCheckType.Airframe16y:
      return '16Y'
    case AirframeCheckType.Airframe18y:
      return '18Y'
    case AirframeCheckType.Airframe20y:
      return '20Y'
    case AirframeCheckType.Airframe21y:
      return '21Y'
    case AirframeCheckType.Airframe24y:
      return '24Y'
  }
}

export const AirframeCheckTypeOptions = [
  { value: (AirframeCheckType.Airframe2y as number).toString(), label: AirframeCheckTypeDisplay(AirframeCheckType.Airframe2y) },
  { value: (AirframeCheckType.Airframe3y as number).toString(), label: AirframeCheckTypeDisplay(AirframeCheckType.Airframe3y) },
  { value: (AirframeCheckType.Airframe4y as number).toString(), label: AirframeCheckTypeDisplay(AirframeCheckType.Airframe4y) },
  { value: (AirframeCheckType.Airframe5y as number).toString(), label: AirframeCheckTypeDisplay(AirframeCheckType.Airframe5y) },
  { value: (AirframeCheckType.Airframe6y as number).toString(), label: AirframeCheckTypeDisplay(AirframeCheckType.Airframe6y) },
  { value: (AirframeCheckType.Airframe7y as number).toString(), label: AirframeCheckTypeDisplay(AirframeCheckType.Airframe7y) },
  { value: (AirframeCheckType.Airframe8y as number).toString(), label: AirframeCheckTypeDisplay(AirframeCheckType.Airframe8y) },
  { value: (AirframeCheckType.Airframe9y as number).toString(), label: AirframeCheckTypeDisplay(AirframeCheckType.Airframe9y) },
  { value: (AirframeCheckType.Airframe10y as number).toString(), label: AirframeCheckTypeDisplay(AirframeCheckType.Airframe10y) },
  { value: (AirframeCheckType.Airframe12y as number).toString(), label: AirframeCheckTypeDisplay(AirframeCheckType.Airframe12y) },
  { value: (AirframeCheckType.Airframe14y as number).toString(), label: AirframeCheckTypeDisplay(AirframeCheckType.Airframe14y) },
  { value: (AirframeCheckType.Airframe15y as number).toString(), label: AirframeCheckTypeDisplay(AirframeCheckType.Airframe15y) },
  { value: (AirframeCheckType.Airframe16y as number).toString(), label: AirframeCheckTypeDisplay(AirframeCheckType.Airframe16y) },
  { value: (AirframeCheckType.Airframe18y as number).toString(), label: AirframeCheckTypeDisplay(AirframeCheckType.Airframe18y) },
  { value: (AirframeCheckType.Airframe20y as number).toString(), label: AirframeCheckTypeDisplay(AirframeCheckType.Airframe20y) },
  { value: (AirframeCheckType.Airframe21y as number).toString(), label: AirframeCheckTypeDisplay(AirframeCheckType.Airframe21y) },
  { value: (AirframeCheckType.Airframe24y as number).toString(), label: AirframeCheckTypeDisplay(AirframeCheckType.Airframe24y) },
]

export const sortByCheckTypeAndSerial = (
  first: { checkType: CheckType; masterComponentSerialNumber?: string | null },
  second: { checkType: CheckType; masterComponentSerialNumber?: string | null },
) => {
  // 1. Airframe checks should always be first
  const firstIsAirframe = isAirframeCheck(first.checkType)
  const secondIsAirframe = isAirframeCheck(second.checkType)
  if (firstIsAirframe !== secondIsAirframe) return firstIsAirframe ? -1 : 1

  // 2. Engine checks should come after Airframe
  const firstIsEngine = isEngineCheck(first.checkType)
  const secondIsEngine = isEngineCheck(second.checkType)
  if (firstIsEngine !== secondIsEngine) return firstIsEngine ? -1 : 1

  // 3. Sort by masterComponentSerialNumber (for engines, if present)
  if (!isNil(first.masterComponentSerialNumber) && !isNil(second.masterComponentSerialNumber)) {
    const masterSerialDiff = first.masterComponentSerialNumber.localeCompare(second.masterComponentSerialNumber)
    if (masterSerialDiff !== 0) return masterSerialDiff
  }

  // 4. Sort all other checks (Landing Gear, APU) last by checkType value
  return first.checkType - second.checkType
}
