import { useAssetComponentsForms } from '@flyward/assets/context'
import { StandaloneEngineDtoSchema, type StandAloneEngineDTO } from '../../../models'
import { StandaloneEngineReadonlyDetails, StandaloneEngineEditableDetails } from './TechSpecs/EngineDetailsTabs/EditStandaloneEngine'
import { useEffect, useRef } from 'react'
import { useUpdateStandAloneEngineMutation } from '@flyward/assets/store'
import { zodResolver } from '@hookform/resolvers/zod'
import { type SubmitHandler, useForm } from 'react-hook-form'
import { CnForm } from '@flyward/platform'
import { isNil } from 'lodash'
import { formatAxiosErrorMessage } from '@flyward/platform/helpers/ErrorHelpers'
import { showError, showSuccess } from '@flyward/platform/services'

interface IStandAloneEngineDetailsProps {
  assetId: string
  standAloneEngine: StandAloneEngineDTO
}

export const StandAloneEngineDetails = ({ standAloneEngine, assetId }: IStandAloneEngineDetailsProps): React.ReactElement<IStandAloneEngineDetailsProps> => {
  const { isInEditMode, registerFormRef, setHasErrors } = useAssetComponentsForms()
  const submitRef = useRef<HTMLInputElement>(null)
  const resetRef = useRef<HTMLInputElement>(null)
  const [editStandaloneEngine] = useUpdateStandAloneEngineMutation()

  const form = useForm<StandAloneEngineDTO>({
    defaultValues: standAloneEngine,
    values: standAloneEngine,
    resolver: zodResolver(StandaloneEngineDtoSchema),
    mode: 'all',
    reValidateMode: 'onChange',
  })

  const {
    handleSubmit: handleStandaloneEngineUpdate,
    formState: { dirtyFields, isValid },
    getValues,
    control: standaloneEngineFormControl,
    setValue: setStandaloneEngineFormValue,
  } = form

  const standaloneEngineFormValues = getValues()

  const hasActualChanges =
    Object.keys(dirtyFields).length > 0 &&
    Object.keys(dirtyFields).some((field) => {
      const currentValue = getValues(field as keyof StandAloneEngineDTO)
      const initialValue = standAloneEngine[field as keyof StandAloneEngineDTO]
      return currentValue !== initialValue
    })

  useEffect(() => {
    registerFormRef({
      id: [assetId, 'standAloneEngineDetails'],
      submitRef,
      resetRef,
      hasChanges: hasActualChanges,
      isValid,
    })
  }, [registerFormRef, hasActualChanges, isValid, assetId])

  const onUpdateStandaloneEngine: SubmitHandler<StandAloneEngineDTO> = async (data) => {
    const result = await editStandaloneEngine({ assetId, standaloneEngine: data })
    if (!isNil(result.error)) {
      setHasErrors(true)
      showError(formatAxiosErrorMessage(result.error?.message))
    } else {
      setHasErrors(false)
      showSuccess('Standalone engine updated successfully')
    }
  }

  return (
    <CnForm {...form}>
      <form onSubmit={handleStandaloneEngineUpdate(onUpdateStandaloneEngine)}>
        {isInEditMode ? (
          <StandaloneEngineEditableDetails
            formControl={standaloneEngineFormControl}
            formState={form.formState}
            watchFields={form.watch}
            setFormValue={setStandaloneEngineFormValue}
            getStandaloneEngineFormValues={getValues}
          />
        ) : (
          <StandaloneEngineReadonlyDetails standAloneEngine={standaloneEngineFormValues} />
        )}
        <input type="submit" className="hidden" ref={submitRef} />
        <input type="reset" className="hidden" ref={resetRef} onClick={() => form.reset()} />
      </form>
    </CnForm>
  )
}
