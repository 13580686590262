import { type ReportSearchDto, type ReportSearchInput, ReportsGrid, ReportsSearchFilter } from '@flyward/forecasts'
import { ComponentContainer, type IdWithNameDto, OrderDirection, PageSizes, PaginatedResult } from '@flyward/platform'
import { useDeleteReportMutation, useGetReportsSearchQuery, useGetUsersNamesQuery } from '@flyward/platform/store/slices'
import { isNil } from 'lodash'
import { useState } from 'react'
import { ReportsEmptyState } from '../../../assets'
import { isArrayEmptyOrNull } from '@flyward/platform/helpers/arrayHelpers'
import { Typography } from '@mui/material'

export const ReportsPage = () => {
  const [reportSearchInput, setReportSearchInput] = useState<ReportSearchInput>({
    Name: '',
    StartDate: '',
    EndDate: '',
    UserIds: [],
    OrderDirection: OrderDirection.Desc,
    Page: 0,
    PageSize: PageSizes.Twenty,
  })

  // TODO: refactor this to use onFilterUpdateHandler
  const setCurrentPage = (page: number) => {
    setReportSearchInput((oldReportSearchInput: ReportSearchInput) => ({
      ...oldReportSearchInput,
      Page: page,
    }))
  }

  const onFilterUpdateHandler = (filter: Partial<ReportSearchInput>) => {
    setReportSearchInput((oldReportSearchInput: ReportSearchInput) => ({
      ...oldReportSearchInput,
      ...filter,
    }))
    setCurrentPage(0)
  }

  // TODO: refactor this to use onFilterUpdateHandler
  const setPageSize = (size: PageSizes) => {
    setReportSearchInput((oldReportSearchInput: ReportSearchInput) => ({
      ...oldReportSearchInput,
      PageSize: size,
    }))
  }

  // TODO: refactor this to use onFilterUpdateHandler
  const setOrderDirection = (orderDirection: OrderDirection) => {
    setReportSearchInput((oldReportSearchInput: ReportSearchInput) => ({
      ...oldReportSearchInput,
      OrderDirection: orderDirection,
    }))
  }
  const { data: reports, isLoading: areReportsLoading } = useGetReportsSearchQuery(reportSearchInput)
  const userSearch: IdWithNameDto[] = []
  const { data: users, isSuccess: isUserSuccess } = useGetUsersNamesQuery({ hasReports: true })

  const searchByUsers = () => {
    users?.forEach((user) => {
      userSearch.push(user)
    })
  }

  if (isUserSuccess) {
    searchByUsers()
  }

  const [deleteReport] = useDeleteReportMutation()

  const onDeleteHandler = async (id: string): Promise<string | undefined> => {
    const result = await deleteReport({ reportId: id })
    return result.error?.message
  }

  return (
    <>
      {/* Header */}
      <div className="flex h-20 w-full items-center px-2 py-9">
        <Typography sx={{ fontSize: '1.25rem', fontWeight: 600, color: 'var(--text-1)' }}>Reports</Typography>
      </div>
      {/* Main Pane */}
      <ReportsSearchFilter users={userSearch ?? []} filter={reportSearchInput} onFilterUpdate={onFilterUpdateHandler} />
      <ComponentContainer className="mt-1 flex h-[calc(100vh-13rem)] w-full items-center overflow-hidden px-2 py-1">
        {(!isNil(reports) && !isArrayEmptyOrNull(reports.items)) || areReportsLoading ? (
          <ReportsGrid
            reports={reports ?? new PaginatedResult<ReportSearchDto>([], 0, 0, 0)}
            currentPage={reportSearchInput.Page}
            pageSize={reportSearchInput.PageSize}
            isLoading={areReportsLoading}
            setCurrentPage={setCurrentPage}
            setPageSize={setPageSize}
            orderDirection={reportSearchInput.OrderDirection}
            setOrderDirection={setOrderDirection}
            onDelete={onDeleteHandler}
          />
        ) : (
          <ReportsEmptyState width={911} height={580} />
        )}
      </ComponentContainer>
    </>
  )
}
