import { UserAuthorizationProvider, ZitadelAuthenticatedProvider } from '@flyward/appIdentity/context'
import { type IconVariant } from '@flyward/platform/components'
import { HeaderContentContextProvider, NavigationStateContextProvider } from '@flyward/platform/contexts'
import { MasterLayout } from '../MasterLayout/MasterLayout'
import { ProtectedRouteOutlet } from './ProtectedRouteOutlet'

interface Route {
  path: string
  label: string
  icon: IconVariant
}

interface IProtectedRoutesProp {
  routes: Route[]
}

export const ProtectedRoutes = ({ routes }: IProtectedRoutesProp) => {
  return (
    <ZitadelAuthenticatedProvider>
      <UserAuthorizationProvider>
        <NavigationStateContextProvider>
          <HeaderContentContextProvider>
            <MasterLayout routes={routes}>
              <ProtectedRouteOutlet />
            </MasterLayout>
          </HeaderContentContextProvider>
        </NavigationStateContextProvider>
      </UserAuthorizationProvider>
    </ZitadelAuthenticatedProvider>
  )
}
