import { type EngineProgramDto, EngineProgramDtoSchema } from '@flyward/knowledgeBase'
import { useGetEngineMaintenanceProgramDetailsQuery, useUpdateEngineMaintenanceProgramDetailsMutation } from '@flyward/knowledgeBase/store'
import { ErrorBoundary, CnForm, LoadingFallback, errorMessages, useNavigationState, cn, ComponentType } from '@flyward/platform'
import { zodResolver } from '@hookform/resolvers/zod'
import { isEmpty, isNil } from 'lodash'
import { useEffect, useRef, useState } from 'react'
import { type SubmitHandler, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { EngineMaintenanceProgramPageBody } from './EngineMaintenanceProgramPageBody'
import { MaintenanceProgramPageHeader } from '../common/MaintenanceProgramPageHeader'
import { formatAxiosErrorMessage } from '@flyward/platform/helpers/ErrorHelpers'
import { showError, showSuccess } from '@flyward/platform/services'
import { pageContentStyles } from '@flyward/main-app/layout'

export const EngineMaintenanceProgramPage = () => {
  const { id } = useParams()

  const [isInEditMode, setIsInEditMode] = useState(false)

  const submitRef = useRef<HTMLInputElement>(null)
  const [updateEngine] = useUpdateEngineMaintenanceProgramDetailsMutation()

  const { toggleVisibility } = useNavigationState()

  const { data: engineProgram, isLoading } = useGetEngineMaintenanceProgramDetailsQuery(
    {
      programId: id!,
    },
    {
      skip: isNil(id) || isEmpty(id),
    },
  )

  useEffect(() => {
    toggleVisibility()

    return () => {
      toggleVisibility()
    }
  }, [toggleVisibility])

  const form = useForm<EngineProgramDto>({
    defaultValues: engineProgram,
    values: engineProgram,
    resolver: zodResolver(EngineProgramDtoSchema),
    mode: 'all',
    reValidateMode: 'onChange',
  })

  const {
    handleSubmit: handleEngineUpdate,
    formState,
    getValues: getEngineFormValues,
    setValue: setFormValue,
    control: formControl,
    trigger: triggerValidations,
    reset,
    register: registerFormField,
    unregister: unRegisterFormField,
  } = form

  const engineFormValues: EngineProgramDto = getEngineFormValues()

  const { isDirty, isValid } = formState

  if (isDirty && !isValid) {
    console.log('KB Engine formState.errors', formState.errors)
  }

  const onUpdateEngine: SubmitHandler<EngineProgramDto> = async (data) => {
    const result = await updateEngine({ programId: id!, engine: data })
    if (!isNil(result.error)) {
      reset()

      showError(formatAxiosErrorMessage(result.error?.message, errorMessages.kb.update))
    } else {
      reset()

      showSuccess(<span>Engine updated successfully!</span>)
    }
  }

  useEffect(() => {
    triggerValidations()
  }, [isValid, triggerValidations])

  const submitForm = () => {
    submitRef.current?.click()
  }

  return isLoading ? (
    <LoadingFallback />
  ) : (
    <>
      <MaintenanceProgramPageHeader
        type={ComponentType.EngineUnit}
        isInEditMode={isInEditMode}
        setIsInEditMode={setIsInEditMode}
        isDirty={isDirty}
        isValid={isValid}
        submitForm={submitForm}
        onCancel={reset}
        programTitle={engineProgram?.maintenanceProgramName ?? ''}
      />
      <main className={cn('h-full flex-1', pageContentStyles)}>
        <CnForm {...form}>
          <form onSubmit={handleEngineUpdate(onUpdateEngine)} className="overflow-auto">
            <ErrorBoundary>
              <EngineMaintenanceProgramPageBody
                engineMaintenanceProgram={engineFormValues}
                isInEditMode={isInEditMode}
                formControl={formControl}
                setFormValue={setFormValue}
                registerFormField={registerFormField}
                unRegisterFormField={unRegisterFormField}
                triggerComponentValidation={triggerValidations}
              />
            </ErrorBoundary>
            <input type="submit" className="hidden" ref={submitRef} />
          </form>
        </CnForm>
      </main>
    </>
  )
}
