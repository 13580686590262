import { NumberDisplay, NumberDisplayType, TableRow, TableCell, GUID_EMPTY, UtilizationUnits, CheckType } from '@flyward/platform'
import { MaintenanceProgramDetailsTable } from '../../MaintenanceProgramDetailsTable'
import { type llpCheckDto, type EngineProgramDto } from '../../../../models'
import { cloneDeep } from 'lodash'
import {
  type UseFormSetValue,
  type Control,
  type FieldValues,
  type UseFormRegister,
  type UseFormUnregister,
  type UseFormTrigger,
} from 'react-hook-form'
import { existingLlpTableColumns, newLlpTableColumns, type EngineLlpCheckEditableDisplay } from './columns'

interface IEngineLifeLimitedPartsTableProps<TFormData extends FieldValues> {
  existingLlpChecks: llpCheckDto[] | undefined
  newLlpChecks: llpCheckDto[] | undefined
  removedLlpChecksId: string[] | undefined
  engineProgram: EngineProgramDto | undefined
  formControl: Control<TFormData, unknown>
  setFormValue: UseFormSetValue<EngineProgramDto>
  registerFormField: UseFormRegister<EngineProgramDto>
  unRegisterFormField: UseFormUnregister<EngineProgramDto>
  tableClassName?: string
  triggerComponentValidation: UseFormTrigger<EngineProgramDto>
}

export const EngineLifeLimitedPartsEditableTable = <TFormData extends FieldValues>({
  existingLlpChecks,
  newLlpChecks,
  removedLlpChecksId,
  engineProgram,
  formControl,
  setFormValue,
  registerFormField,
  unRegisterFormField,
  tableClassName = '',
  triggerComponentValidation,
}: IEngineLifeLimitedPartsTableProps<TFormData>): React.ReactElement<IEngineLifeLimitedPartsTableProps<TFormData>> => {
  const existingLlpTableData: EngineLlpCheckEditableDisplay[] | undefined =
    existingLlpChecks !== undefined
      ? cloneDeep(existingLlpChecks).map((t) => ({
          programId: t.id,
          componentModel: t.componentModel,
          componentModule: t.componentModule,
          unit: t?.utilizationUnit,
          limit: t?.matureLimit,
          cost: t?.baseCost,
          costPerCycle: t?.costPerCycle,
        }))
      : undefined

  const newLlpTableData: EngineLlpCheckEditableDisplay[] | undefined =
    newLlpChecks !== undefined
      ? cloneDeep(newLlpChecks).map((t) => ({
          programId: t.id,
          componentModel: t.componentModel,
          componentModule: t.componentModule,
          unit: t?.utilizationUnit,
          limit: t?.matureLimit,
          cost: t?.baseCost,
          costPerCycle: t?.costPerCycle,
        }))
      : undefined

  const onAddRow = () => {
    const newRow: llpCheckDto = {
      id: GUID_EMPTY,
      componentModule: '',
      componentModel: '',
      baseCost: 0,
      costPerCycle: 0,
      matureLimit: 0,
      utilizationUnit: UtilizationUnits.FlightCycles,
      checkType: CheckType.EngineLlpReplacement,
    }
    const updatedData = [...(newLlpChecks ?? []), newRow]
    registerFormField(`llpCheckAddRemoveItems.addedItems.${updatedData.length - 1}`)
    setFormValue(`llpCheckAddRemoveItems.addedItems.${updatedData.length - 1}`, newRow, {
      shouldDirty: true,
    })
    setFormValue('llpCheckAddRemoveItems.addedItems', updatedData, { shouldDirty: true })
  }

  const onExistingRowRemove = (row: EngineLlpCheckEditableDisplay, index: number) => {
    if (existingLlpChecks === undefined) {
      return
    }

    const updatedData = existingLlpChecks?.filter((_data, _index) => _index !== index) ?? []
    unRegisterFormField(`llpChecks.${index}`)
    setFormValue('llpChecks', updatedData, { shouldDirty: true })

    const removedIds = [...(removedLlpChecksId ?? []), row.programId]
    registerFormField(`llpCheckAddRemoveItems.removedItemIds.${updatedData.length - 1}`)
    setFormValue('llpCheckAddRemoveItems.removedItemIds', removedIds, { shouldDirty: true })
  }

  const onNewRowRemove = (_row: EngineLlpCheckEditableDisplay, index: number) => {
    if (newLlpChecks === undefined) {
      return
    }

    const updatedData = newLlpChecks?.filter((_data, _index) => _index !== index) ?? []
    unRegisterFormField(`llpCheckAddRemoveItems.addedItems.${index}`)
    setFormValue('llpCheckAddRemoveItems.addedItems', updatedData, { shouldDirty: true })
    triggerComponentValidation()
  }

  const totalLLPsCost = engineProgram?.totalLLPsCost
  const totalLLPsCostPerCycle = engineProgram?.totalLLPsCostPerCycle

  const summaryRow = (
    <TableRow className="flex font-semibold ">
      <TableCell className="text-1 basis-1/6 border border-black-20 bg-primary-dark-3 p-px">Total</TableCell>
      <TableCell className="basis-3/6 border border-black-20 bg-primary-dark-3 p-px text-text-1"></TableCell>
      <TableCell className="basis-1/6 border border-black-20 bg-primary-dark-3 p-px text-text-1">
        <NumberDisplay displayType={NumberDisplayType.Currency} value={totalLLPsCost} />
      </TableCell>
      <TableCell className="basis-1/6 border border-black-20 bg-primary-dark-3 p-px text-text-1">
        <NumberDisplay displayType={NumberDisplayType.Currency} value={totalLLPsCostPerCycle} />
      </TableCell>
      <div className="h-6 w-5 gap-x-0 p-0"></div>
    </TableRow>
  )

  return (
    <MaintenanceProgramDetailsTable
      existingColumns={existingLlpTableColumns(formControl)}
      existingData={existingLlpTableData}
      newColumns={newLlpTableColumns(formControl)}
      newData={newLlpTableData}
      onAddRow={onAddRow}
      onExistingRowRemove={onExistingRowRemove}
      onNewRowRemove={onNewRowRemove}
      summaryRow={summaryRow}
      tableClassName={tableClassName}
    />
  )
}
