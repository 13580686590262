import { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState, type ReactNode } from 'react'

export interface AssetComponentFormEntry {
  id: [string, string]
  submitRef: React.RefObject<HTMLInputElement>
  resetRef: React.RefObject<HTMLInputElement>
  hasChanges: boolean
  isValid: boolean
}

interface AssetComponentsFormsContextType {
  registerFormRef: (_entry: AssetComponentFormEntry) => void
  getAllFormRefs: () => AssetComponentFormEntry[]
  hasAnyChanges: boolean
  isInEditMode: boolean
  isAllValid: boolean
  setIsInEditMode: (_value: boolean) => void
  hasErrors: boolean
  setHasErrors: (_value: boolean) => void
}

const AssetComponentsFormsContext = createContext<AssetComponentsFormsContextType | undefined>(undefined)

export const useAssetComponentsForms = (): AssetComponentsFormsContextType => {
  const context = useContext(AssetComponentsFormsContext)
  if (context === undefined) {
    throw new Error('useAssetComponentsForms must be used within an AssetComponentsFormsProvider')
  }
  return context
}
interface IAssetComponentsFormsProviderProps {
  children: ReactNode
}

export const AssetComponentsFormsProvider = ({ children }: IAssetComponentsFormsProviderProps) => {
  const formRefs = useRef<AssetComponentFormEntry[]>([])
  const [hasAnyChanges, setHasAnyChanges] = useState(false)
  const [isAllValid, setIsAllValid] = useState(false)
  const [isInEditMode, setIsInEditMode] = useState(false)
  const [hasErrors, setHasErrors] = useState(false)

  useEffect(() => {
    if (hasErrors) {
      setIsInEditMode(true)
    }
  }, [hasErrors])

  const registerFormRef = useCallback((entry: AssetComponentFormEntry) => {
    const existingEntryIndex = formRefs.current.findIndex((item) => item.id[0] === entry.id[0] && item.id[1] === entry.id[1])

    if (existingEntryIndex > -1) {
      formRefs.current[existingEntryIndex] = entry
    } else {
      formRefs.current.push(entry)
    }

    const hasChanges = formRefs.current.some((entry) => entry.hasChanges)
    const isValid = formRefs.current.every((entry) => entry.isValid)

    setHasAnyChanges(hasChanges)
    setIsAllValid(isValid)
  }, [])

  const getAllFormRefs = (): AssetComponentFormEntry[] => {
    return formRefs.current
  }

  const contextValue = useMemo(
    () => ({
      registerFormRef,
      getAllFormRefs,
      isInEditMode,
      setIsInEditMode,
      hasAnyChanges,
      isAllValid,
      hasErrors,
      setHasErrors,
    }),
    [registerFormRef, isInEditMode, hasAnyChanges, isAllValid, hasErrors],
  )

  return <AssetComponentsFormsContext.Provider value={contextValue}>{children}</AssetComponentsFormsContext.Provider>
}
