import { validateNullableNaturalNumber, validateRequiredNaturalNumber } from '@flyward/platform/helpers/inputValidators'
import { AirframeCheckType } from '@flyward/platform/models'
import { zodResolver } from '@hookform/resolvers/zod'
import { Box, Button, TextField, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { ChecksTable } from './ChecksTable'
import { handleNumericInput } from '@flyward/platform/utils'

const escalationsAssumptionsSchema = z.object({
  escalation: z.any().nullable(),
  checkDowntime: validateRequiredNaturalNumber('Check Downtime', 1),
  delayInPayment: validateNullableNaturalNumber,
})

type EscalationsAssumptionsFormData = z.infer<typeof escalationsAssumptionsSchema>

const EmptyRow = {
  id: 0,
  checkType: AirframeCheckType.Airframe3y,
  limit: '',
  cost: '',
} as const

interface TableRowData {
  id: number
  checkType: AirframeCheckType
  limit: string
  cost: string
}

interface ChecksEscalationsAssumptionsProps {
  onNext: (_data: {
    checks: Array<{
      checkType: AirframeCheckType
      limit: number
      cost: number
    }>
    escalation?: number
    checkDowntime?: number
    delayInPayment?: number
  }) => void
  onBack: () => void | undefined
  stepTitle: string
  initialData?: {
    checks: Array<{
      checkType: AirframeCheckType
      limit: number
      cost: number
    }>
    escalation?: number
    checkDowntime?: number
    delayInPayment?: number
  }
}

export const ChecksEscalationsAssumptions = ({ onNext, onBack, stepTitle, initialData }: ChecksEscalationsAssumptionsProps) => {
  const [tableRows, setTableRows] = useState<TableRowData[]>([{ ...EmptyRow, id: 1 }])

  const checkDuplicateCheckTypes = (): boolean => {
    const seenCheckTypes = new Set<number>()
    for (const row of tableRows) {
      if (!row.checkType) continue
      if (seenCheckTypes.has(row.checkType)) return true
      seenCheckTypes.add(row.checkType)
    }
    return false
  }

  const [escalationPercentage, setEscalationPercentage] = useState<string>(initialData?.escalation?.toString() ?? '')
  const {
    formState: { errors, touchedFields, isValid },
    register,
    watch,
  } = useForm<EscalationsAssumptionsFormData>({
    resolver: zodResolver(escalationsAssumptionsSchema),
    mode: 'all',
    defaultValues: {
      escalation: initialData?.escalation,
      checkDowntime: initialData?.checkDowntime,
      delayInPayment: initialData?.delayInPayment,
    },
  })
  const values = watch()

  const isRequiredFieldsFilled = Boolean(values.checkDowntime)

  const isRowComplete = (row: TableRowData) => Boolean(row.checkType && row.limit && row.cost)

  const isRowPartiallyFilled = (row: TableRowData) => {
    const hasAnyValue = Boolean(row.checkType || row.limit || row.cost)
    return hasAnyValue && !isRowComplete(row)
  }

  const hasAtLeastOneLLP = tableRows.some(isRowComplete)
  const hasIncompleteRows = tableRows.some(isRowPartiallyFilled)
  const hasDuplicateCheckTypes = checkDuplicateCheckTypes()

  const isButtonDisabled = !isRequiredFieldsFilled || !isValid || !hasAtLeastOneLLP || hasIncompleteRows || hasDuplicateCheckTypes

  // Populate LLP replacements from initialData
  useEffect(() => {
    if (initialData?.checks?.length) {
      const populatedRows = initialData.checks.map((airframeCheck, index) => ({
        id: index + 1,
        checkType: airframeCheck.checkType,
        limit: airframeCheck.limit.toString(),
        cost: airframeCheck.cost.toString(),
      }))
      setTableRows(populatedRows)
    }
  }, [initialData])

  const handleAddRow = () => {
    setTableRows([...tableRows, { ...EmptyRow, id: tableRows.length + 1 }])
  }

  const handleRowChange = (id: number, field: keyof TableRowData, value: string) => {
    setTableRows(
      tableRows.map((row) => {
        if (row.id === id) {
          return { ...row, [field]: value }
        }
        return row
      }),
    )
  }

  const handleDeleteRow = (id: number) => {
    // Don't allow deleting if there's only one row
    if (tableRows.length === 1) {
      return
    }

    setTableRows(tableRows.filter((row) => row.id !== id))
  }

  const handleContinue = async () => {
    const formattedchecks = tableRows
      .filter((row) => row.checkType && row.limit && row.cost)
      .map((row) => ({
        checkType: row.checkType,
        limit: Number(row.limit),
        cost: Number(row.cost),
      }))

    onNext({
      checks: formattedchecks,
      escalation: Number(escalationPercentage),
      checkDowntime: values.checkDowntime,
      delayInPayment: values.delayInPayment,
    })
  }

  return (
    <div className="shadow-sm rounded-lg bg-white p-6">
      <Typography variant="h6" sx={{ mb: 3 }}>
        {stepTitle}
      </Typography>

      <Box sx={{ mb: 4 }}>
        <ChecksTable rows={tableRows} onRowChange={handleRowChange} onAddRow={handleAddRow} onDeleteRow={handleDeleteRow} />
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 3,
          mb: 4,
        }}
      >
        <TextField
          label="Escalation Percentage"
          value={escalationPercentage}
          onChange={(e) => setEscalationPercentage(handleNumericInput(e.target.value))}
          InputProps={{ startAdornment: '%' }}
          inputProps={{ inputMode: 'decimal' }}
        />

        <TextField
          {...register('checkDowntime')}
          error={touchedFields.checkDowntime && !!errors.checkDowntime}
          label="Check Downtime (Months)"
          helperText={touchedFields.checkDowntime && errors.checkDowntime?.message}
          inputProps={{ inputMode: 'numeric', min: 1 }}
          defaultValue={1}
        />
        <TextField
          {...register('delayInPayment')}
          error={touchedFields.delayInPayment && !!errors.delayInPayment}
          label="Delay in Payment on Claims (Months)"
          helperText={touchedFields.delayInPayment && errors.delayInPayment?.message}
          inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
        />
      </Box>

      <Box>
        <Button variant="contained" onClick={handleContinue} disabled={isButtonDisabled} sx={{ mr: 1 }}>
          Continue
        </Button>
        <Button variant="text" onClick={onBack}>
          Back
        </Button>
      </Box>
    </div>
  )
}
