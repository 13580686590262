import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { AssetType } from '@flyward/platform'

const baseState = {
  general: {
    sn: '',
    manufacturer: undefined,
    model: undefined,
    dom: undefined,
    dateOfLastTechSpec: undefined,
    leaseStartDate: undefined,
    leaseEndDate: undefined,
    lessee: undefined,
    lesseeHabitualBase: undefined,
    region: undefined,
    portfolio: undefined,
    averageMonthlyFlightHours: undefined,
    averageMonthlyFlightCycles: undefined,
    sourceUrl: undefined,
  },
}

const initialState = {
  activeStep: 0,
  isAiPopulated: false,
  assetType: undefined as AssetType | undefined,
  steps: {
    [AssetType.Aircraft]: {
      general: { ...baseState.general, averageMonthlyAPUHours: undefined, registrationNo: undefined },
      airframe: {
        technical: {
          tsn: undefined,
          csn: undefined,
        },
        checks: [],
      },
      engines: {
        items: [],
      },
      landingGear: {
        technical: {
          sn: undefined,
          manufacturer: undefined,
          model: undefined,
          partNumber: undefined,
          dom: undefined,
          tsn: undefined,
          csn: undefined,
          tsnLastPr: undefined,
          csnLastPr: undefined,
          dateLastPr: undefined,
          monthsSinceOverhaulAtContractDelivery: undefined,
        },
      },
      apu: {
        prTechnical: {
          sn: undefined,
          manufacturer: undefined,
          model: undefined,
          dom: undefined,
          tsn: undefined,
          csn: undefined,
          tsnLastPr: undefined,
          csnLastPr: undefined,
          dateLastPr: undefined,
          apuHoursSinceEventAtContractDelivery: undefined,
        },
      },
    },
    [AssetType.StandaloneEngine]: {
      general: { ...baseState.general, derate: undefined },
      engine: {
        pr: {
          tsn: undefined,
          csn: undefined,
          tsnLastPr: undefined,
          csnLastPr: undefined,
          dateLastPr: undefined,
          hoursSinceEventAtContractDelivery: undefined,
        },
        llps: [],
      },
    },
  },
}

type AssetWizardState = typeof initialState

type UpdateStepPayload = {
  type: AssetType
  step: keyof AssetWizardState['steps'][keyof AssetWizardState['steps']]
  data: any
}

type SetActiveStepPayload = {
  step: number
}

const assetWizardSlice = createSlice({
  name: 'assetWizard',
  initialState,
  reducers: {
    updateStepData: (state, { payload }: PayloadAction<UpdateStepPayload>) => {
      const { type, step, data } = payload
      const target: any = state.steps[type][step]
      if (!target) return

      state.steps[type][step] = Array.isArray(target) && Array.isArray(data) ? [...target, ...data] : { ...target, ...data }
    },
    setActiveStep: (state, { payload }: PayloadAction<SetActiveStepPayload>) => {
      state.activeStep = payload.step
    },
    setAssetType: (state, { payload }: PayloadAction<AssetType>) => {
      state.assetType = payload
    },
    initializeWizardState: (state, { payload }: PayloadAction<{ type: AssetType; data: any }>) => {
      const { type, data } = payload
      state.activeStep = 0
      state.isAiPopulated = true
      state.assetType = type
      state.steps[type] = data
    },
    resetWizard: () => initialState,
  },
})

export const { updateStepData, setActiveStep, setAssetType, initializeWizardState, resetWizard } = assetWizardSlice.actions
export default assetWizardSlice.reducer
