/* eslint-disable max-len */
import {
  type ComponentMonthlyStatistics,
  type IYearMonth,
  cn,
  FullScreenPopover,
  ReportDisplayType,
  sortByCheckTypeAndSerial,
} from '@flyward/platform'
import { type ComponentTotalStatisticsAtEvent } from '@flyward/platform/models/entities/ComponentTotalStatisticsAtEvent'
import { cloneDeep } from 'lodash'
import { type ReactElement, useState } from 'react'
import { type IMaintenanceScheduleDisplay } from '../../../../models'
import { EventsGrid } from '../monthView/EventsGrid'
import { IndividualAssetChecks } from './CalendarItem/IndividualAssetChecks'
import { PortfolioChecks } from './CalendarItem/PortfolioChecks'
import { shortMonths } from '@flyward/platform/helpers/dateHelpers'

interface IEventViewPopoverProps {
  reportDisplayType: ReportDisplayType
  uniqueEvents: IMaintenanceScheduleDisplay[]
  allEvents: IMaintenanceScheduleDisplay[]
  componentsMonthlyStatistics: ComponentMonthlyStatistics[]
  componentTotalStatisticsAtEvent: ComponentTotalStatisticsAtEvent[]
}

const getEventsGrid = (months: string[], events: IMaintenanceScheduleDisplay[], year: number, month: string) => {
  return events?.filter((el) => {
    const { year: eventYear, month: eventMonth } = el.yearMonth as IYearMonth
    const monthIndex = months.findIndex((m) => m === month) + 1
    return eventYear === year && eventMonth === monthIndex
  })
}

export const EventViewCalendar = ({
  reportDisplayType,
  allEvents,
  uniqueEvents,
  componentsMonthlyStatistics,
  componentTotalStatisticsAtEvent,
}: Readonly<IEventViewPopoverProps>) => {
  const sortedUniqueEvents = cloneDeep(uniqueEvents).sort((a, b) => {
    const yearDiff = (a.yearMonth as IYearMonth).year - (b.yearMonth as IYearMonth).year
    if (yearDiff !== 0) {
      return yearDiff
    } else {
      const monthsDiff = (a.yearMonth as IYearMonth).month - (b.yearMonth as IYearMonth).month
      if (monthsDiff !== 0) {
        return monthsDiff
      } else {
        const assetSerialDif = a.assetSerialNumber.localeCompare(b.assetSerialNumber)
        if (assetSerialDif !== 0) return assetSerialDif

        return sortByCheckTypeAndSerial(
          { checkType: a.forecastedCheck.checkType, masterComponentSerialNumber: a.forecastedCheck.masterComponentSerialNumber },
          { checkType: b.forecastedCheck.checkType, masterComponentSerialNumber: b.forecastedCheck.masterComponentSerialNumber },
        )
      }
    }
  })
  const sortedAllEvents = cloneDeep(allEvents).sort((a, b) => {
    const yearDiff = (a.yearMonth as IYearMonth).year - (b.yearMonth as IYearMonth).year
    if (yearDiff !== 0) {
      return yearDiff
    } else {
      const monthsDiff = (a.yearMonth as IYearMonth).month - (b.yearMonth as IYearMonth).month
      if (monthsDiff !== 0) {
        return monthsDiff
      } else {
        const assetSerialDif = a.assetSerialNumber.localeCompare(b.assetSerialNumber)
        if (assetSerialDif !== 0) return assetSerialDif

        return sortByCheckTypeAndSerial(
          { checkType: a.forecastedCheck.checkType, masterComponentSerialNumber: a.forecastedCheck.masterComponentSerialNumber },
          { checkType: b.forecastedCheck.checkType, masterComponentSerialNumber: b.forecastedCheck.masterComponentSerialNumber },
        )
      }
    }
  })

  const startYear: number = (sortedUniqueEvents[0]?.yearMonth as IYearMonth)?.year
  const endYear: number = (sortedUniqueEvents[sortedUniqueEvents.length - 1]?.yearMonth as IYearMonth)?.year
  const years: number[] = []
  const [isEventViewPopoverOpen, setIsEventViewPopoverOpen] = useState<boolean>(false)
  const [eventYearMonth, setEventYearMonth] = useState<IYearMonth>()
  const [selectedEventsGrid, setSelectedEventsGrid] = useState<IMaintenanceScheduleDisplay[]>([])

  const openEventDetailViewPopover = (yearMonth: IYearMonth) => {
    setIsEventViewPopoverOpen(true)
    setEventYearMonth(yearMonth)
    setSelectedEventsGrid(getEventsGrid(shortMonths, sortedAllEvents, yearMonth.year, shortMonths[yearMonth.month - 1]))
  }

  const closePopover = () => {
    setIsEventViewPopoverOpen(false)
  }

  const getBorderRadiusForBoxes = (quarterNumber: number) => {
    if (quarterNumber !== 4) {
      return 'border-r'
    } else {
      return ''
    }
  }

  const getBorderEntireBox = (quarterNumber: number) => {
    if (quarterNumber === 1) {
      return 'rounded-tl-lg'
    } else if (quarterNumber === 4) {
      return 'rounded-tr-lg'
    } else {
      return ''
    }
  }

  for (let index = startYear; index <= endYear; index++) {
    years.push(index)
  }

  const getEventInYearMonth = (year: number, month: string): ReactElement => {
    const events = getEventsGrid(shortMonths, sortedUniqueEvents, year, month)
    if (events.length === 0)
      return (
        <div className="h-full w-full p-2">
          <div className="text-left text-xs font-normal text-zinc-700">No Event</div>
        </div>
      )

    if (reportDisplayType === ReportDisplayType.AssetReport) {
      return <IndividualAssetChecks eventsGrid={events} openEventDetailViewPopover={openEventDetailViewPopover} />
    } else {
      return <PortfolioChecks eventsGrid={events} openEventDetailViewPopover={openEventDetailViewPopover} />
    }
  }

  const popoverTitle =
    reportDisplayType === ReportDisplayType.AssetReport ? `Asset ${uniqueEvents[0].assetSerialNumber} Events Details` : 'Portfolio Events Details'

  return (
    <>
      <div className="mt-6 h-[calc(100vh-8rem)] w-full overflow-y-scroll">
        <div className="inline-flex h-16 w-full items-start justify-between border-b border-cyan-700 bg-white py-4 pl-2 text-xl font-semibold text-zinc-950">
          From {startYear} - To {endYear}
        </div>
        {years.map((year) => (
          <div key={year}>
            <div className="flex h-auto min-h-38 w-full bg-white">
              {[1, 2, 3, 4].map((quarterNumber) => (
                <div className={cn('h-auto w-1/4 border-stone-300 bg-white', getBorderRadiusForBoxes(quarterNumber))} key={quarterNumber}>
                  <div
                    className={cn(
                      'mb-4 inline-flex h-14 w-full items-center justify-center gap-4 bg-header-table p-4 text-center text-xl font-semibold text-zinc-950',
                      getBorderEntireBox(quarterNumber),
                    )}
                  >
                    Q{quarterNumber}
                  </div>
                  <div className="inline-flex h-auto min-h-20 w-full items-start justify-start">
                    {[1, 2, 3].map((monthsElement) => (
                      <div key={monthsElement} className="inline-flex h-auto w-1/3 flex-col items-start justify-start gap-2 px-2 pb-2">
                        <div className="inline-flex h-5 w-full items-center justify-start gap-1">
                          <div className="text-base font-bold leading-tight text-gray-800">
                            {shortMonths[3 * (quarterNumber - 1) + (monthsElement - 1)]}
                          </div>
                          <div className="text-base font-normal leading-tight text-gray-800">{year}</div>
                        </div>
                        <div className="h-px w-full bg-neutral-200"></div>
                        <div className="h-auto w-full">{getEventInYearMonth(year, shortMonths[3 * (quarterNumber - 1) + (monthsElement - 1)])}</div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
      <FullScreenPopover
        content={
          <EventsGrid
            reportDisplayType={reportDisplayType}
            componentsMonthlyStatistics={componentsMonthlyStatistics}
            eventsGrid={selectedEventsGrid}
            yearMonth={eventYearMonth}
            componentTotalStatisticsAtEvent={componentTotalStatisticsAtEvent}
          />
        }
        isOpen={isEventViewPopoverOpen}
        onClose={closePopover}
        popoverTitle={popoverTitle}
      />
    </>
  )
}
