import React from 'react'
import { cn } from '../../utils'

export const SimpleCell = ({
  children,
  className,
  onClick = () => {},
}: {
  children: string | number | undefined | React.ReactNode
  className?: string
  onClick?: () => void
}) => (
  <p className={cn('p-2 text-xs text-text-2', className)} onClick={onClick}>
    {children}
  </p>
)
