import { useSortable } from '@dnd-kit/sortable'
import {
  Button,
  ButtonVariant,
  cn,
  EditableDateCellWithLabelWithLabel,
  EditableInputCellWithLabelWithLabel,
  IconVariant,
  InputType,
  SimpleCell,
  Size,
  Tooltip,
  type RowSizeMap,
} from '@flyward/platform/components'
import {
  AlignmentStatus,
  AlignmentStatusDisplay,
  type CheckType,
  CheckTypeDisplay,
  MaintenanceReserveCollectionTypeDisplay,
  UtilizationUnitsDisplay,
} from '@flyward/platform/models'
import { type UseFormSetValue, type Control, type Path } from 'react-hook-form'
import { type DraggableAirframeCheck, type DraggableAirframeChecksStack } from '../../../../../../../models'

interface IAssetAirframeCheckRowProps {
  airframeCheckStatus: AlignmentStatus
  formAirframeCheck: DraggableAirframeCheck
  positionalIndex: number
  kbTotalCount: number
  formControl: Control<DraggableAirframeChecksStack, unknown>
  setComponentFormValues: UseFormSetValue<DraggableAirframeChecksStack>
  onDeleteExistingAirframeCheck: (AirframeCheckId: string) => void
  onExistingAirframeCheckCopyFromKb: (positionalIndex: number, AirframeCheck: DraggableAirframeCheck) => void
  pathPrefix: string
  columnSizes: RowSizeMap
  draggedIndex?: number | null
  overIndex?: number | null
}

const AssetAirframeCheckRow = ({
  formAirframeCheck,
  airframeCheckStatus,
  positionalIndex,
  kbTotalCount,
  formControl,
  setComponentFormValues,
  onDeleteExistingAirframeCheck,
  onExistingAirframeCheckCopyFromKb,
  pathPrefix,
  columnSizes,
  draggedIndex = null,
  overIndex = null,
}: IAssetAirframeCheckRowProps) => {
  const isEmptyAirframeCheck = airframeCheckStatus === AlignmentStatus.MissingInAsset

  const isInDeletePosition = positionalIndex >= kbTotalCount

  const canDeleteAirframeCheck = ![AlignmentStatus.MissingInAsset].includes(airframeCheckStatus) && isInDeletePosition

  const canAlignAirframeCheck =
    !canDeleteAirframeCheck &&
    [AlignmentStatus.MissingInKb, AlignmentStatus.ToAlignManually, AlignmentStatus.Success, AlignmentStatus.SuggestedAlignment].includes(
      airframeCheckStatus,
    )

  const suggestAlignment =
    airframeCheckStatus === AlignmentStatus.MissingInKb ||
    airframeCheckStatus === AlignmentStatus.ToAlignManually ||
    airframeCheckStatus === AlignmentStatus.SuggestedAlignment

  const isSwappedTo = positionalIndex === overIndex
  const isSwappedFrom = positionalIndex === draggedIndex

  const { attributes, listeners, setNodeRef, setActivatorNodeRef } = useSortable({ id: positionalIndex })

  const isNewAirframeCheckEditable = false

  const cellOpacity = isSwappedTo && 'opacity-25 !bg-blue-500'

  return (
    <tr
      ref={setNodeRef}
      className={cn(
        'box-border  border bg-header-table',
        isSwappedFrom && ' box-border border border-gray-500 opacity-50',
        isSwappedTo && 'box-border border border-blue-500 ',
      )}
      {...attributes}
    >
      <td
        className={cn(cellOpacity, ' h-6  truncate border border-black-20 bg-header-table')}
        style={{
          width: `${columnSizes.airframeCheck_checkType.currentSize}px`,
          maxWidth: `${columnSizes.airframeCheck_checkType.currentSize}px`,
        }}
      >
        <div className="  text-xs">
          {!isEmptyAirframeCheck || isNewAirframeCheckEditable ? (
            <SimpleCell className="truncate p-0 text-center text-xs text-text-1">
              {CheckTypeDisplay(formAirframeCheck.airframeCheck.checkType as unknown as CheckType) ?? '-'}
            </SimpleCell>
          ) : (
            <></>
          )}
        </div>
      </td>
      <td
        className={cn(cellOpacity, ' h-6  truncate border border-black-20 bg-header-table')}
        style={{
          width: `${columnSizes.airframeCheck_dateAtLastQualifyingEvent.currentSize}px`,
          maxWidth: `${columnSizes.airframeCheck_dateAtLastQualifyingEvent.currentSize}px`,
        }}
      >
        <div className="  text-xs">
          {!isEmptyAirframeCheck || isNewAirframeCheckEditable ? (
            <EditableDateCellWithLabelWithLabel
              label={''}
              formControl={formControl}
              fieldName={`${pathPrefix}.dateAtLastQualifyingEvent` as Path<DraggableAirframeChecksStack>}
              setFormValue={setComponentFormValues}
            />
          ) : (
            <></>
          )}
        </div>
      </td>
      <td
        className={cn(cellOpacity, ' h-6  truncate border border-black-20 bg-header-table')}
        style={{
          width: `${columnSizes.airframeCheck_mrRateAmount.currentSize}px`,
          maxWidth: `${columnSizes.airframeCheck_mrRateAmount.currentSize}px`,
        }}
      >
        <div className="  text-xs">
          {!isEmptyAirframeCheck || isNewAirframeCheckEditable ? (
            <EditableInputCellWithLabelWithLabel
              inputType={InputType.Currency}
              showErrorAsTooltip
              inputClassName="p-0 py-0 pl-0 pr-0"
              formControl={formControl}
              fieldName={`${pathPrefix}.mrRateAmount` as Path<DraggableAirframeChecksStack>}
            />
          ) : (
            <></>
          )}
        </div>
      </td>
      <td
        className={cn(cellOpacity, ' h-6  truncate border border-black-20 bg-header-table')}
        style={{
          width: `${columnSizes.airframeCheck_mrRateType.currentSize}px`,
          maxWidth: `${columnSizes.airframeCheck_mrRateType.currentSize}px`,
        }}
      >
        <div className="  text-xs">
          {!isEmptyAirframeCheck || isNewAirframeCheckEditable ? (
            <SimpleCell className="truncate p-0 text-center text-xs text-text-1">
              {UtilizationUnitsDisplay(formAirframeCheck.airframeCheck.mrRateType) ?? '-'}
            </SimpleCell>
          ) : (
            <></>
          )}
        </div>
      </td>
      <td
        className={cn(cellOpacity, ' h-6  truncate border border-black-20 bg-header-table')}
        style={{
          width: `${columnSizes.airframeCheck_mrRateYear.currentSize}px`,
          maxWidth: `${columnSizes.airframeCheck_mrRateYear.currentSize}px`,
        }}
      >
        <div className="   text-xs">
          {!isEmptyAirframeCheck || isNewAirframeCheckEditable ? (
            <EditableInputCellWithLabelWithLabel
              inputType={InputType.Year}
              showErrorAsTooltip
              inputClassName="p-0 py-0 pl-0 pr-0"
              formControl={formControl}
              fieldName={`${pathPrefix}.mrRateYear` as Path<DraggableAirframeChecksStack>}
            />
          ) : (
            <></>
          )}
        </div>
      </td>
      <td
        className={cn(cellOpacity, ' h-6  truncate border border-black-20 bg-header-table')}
        style={{
          width: `${columnSizes.airframeCheck_mrCollectionType.currentSize}px`,
          maxWidth: `${columnSizes.airframeCheck_mrCollectionType.currentSize}px`,
        }}
      >
        <div className="  text-xs">
          {!isEmptyAirframeCheck || isNewAirframeCheckEditable ? (
            <SimpleCell className="truncate p-0 text-center text-xs text-text-1">
              {MaintenanceReserveCollectionTypeDisplay(formAirframeCheck.airframeCheck.mrCollectionType) ?? '-'}
            </SimpleCell>
          ) : (
            <></>
          )}
        </div>
      </td>
      <td
        className={cn(cellOpacity, ' h-6  truncate border border-black-20 bg-header-table')}
        style={{
          width: `${columnSizes.airframeCheck_mrCurrentFund.currentSize}px`,
          maxWidth: `${columnSizes.airframeCheck_mrCurrentFund.currentSize}px`,
        }}
      >
        <div className="  text-xs">
          {!isEmptyAirframeCheck || isNewAirframeCheckEditable ? (
            <EditableInputCellWithLabelWithLabel
              showErrorAsTooltip
              inputClassName="p-0 py-0 pl-0 pr-0"
              inputType={InputType.IntegerCurrency}
              formControl={formControl}
              fieldName={`${pathPrefix}.mrCurrentFund` as Path<DraggableAirframeChecksStack>}
            />
          ) : (
            <></>
          )}
        </div>
      </td>

      <td
        className={cn(cellOpacity, ' h-6  truncate border border-black-20 bg-header-table')}
        style={{
          width: `${columnSizes.airframeCheck_monthsSinceEventAtContractDelivery.currentSize}px`,
          maxWidth: `${columnSizes.airframeCheck_monthsSinceEventAtContractDelivery.currentSize}px`,
        }}
      >
        <div className="  text-xs">
          {!isEmptyAirframeCheck || isNewAirframeCheckEditable ? (
            <EditableInputCellWithLabelWithLabel
              showErrorAsTooltip
              inputClassName="p-0 py-0 pl-0 pr-0"
              inputType={InputType.NaturalNumber}
              formControl={formControl}
              fieldName={`${pathPrefix}.monthsSinceEventAtContractDelivery` as Path<DraggableAirframeChecksStack>}
            />
          ) : (
            <></>
          )}
        </div>
      </td>
      <td
        className={cn('box-border  h-6 truncate border border-black-20 bg-header-table')}
        style={{
          width: `${columnSizes.alignmentStatus.currentSize}px`,
          maxWidth: `${columnSizes.alignmentStatus.currentSize}px`,
          minWidth: `${columnSizes.order.currentSize}px`,
        }}
      >
        <div className="flex items-center justify-center">
          <Tooltip
            tooltipContent={AlignmentStatusDisplay(airframeCheckStatus)}
            content={
              <span className="m-auto text-xs">
                {airframeCheckStatus === AlignmentStatus.Success && '✅'}
                {airframeCheckStatus === AlignmentStatus.MissingInKb && '🔵'}
                {airframeCheckStatus === AlignmentStatus.ToAlignManually && '🔵'}
                {airframeCheckStatus === AlignmentStatus.SuggestedAlignment && '🔵'}
                {airframeCheckStatus === AlignmentStatus.MissingInAsset && ''}
              </span>
            }
          />
        </div>
      </td>
      <td
        className={cn(cellOpacity, 'h-6 truncate border border-black-20 bg-header-table')}
        style={{
          width: `${columnSizes.order.currentSize}px`,
          maxWidth: `${columnSizes.order.currentSize}px`,
          minWidth: `${columnSizes.order.currentSize}px`,
        }}
      >
        <div ref={setActivatorNodeRef} {...listeners} className=" flex items-center justify-center text-xs">
          <Button
            className="m-auto h-6 cursor-grab p-0 text-xs"
            leftIcon={IconVariant.Apps}
            variant={ButtonVariant.Ghost}
            size={Size.Small}
            onClick={() => {}}
          />
        </div>
      </td>
      <td
        className={cn(cellOpacity, ' h-6 truncate border border-black-20 bg-black-0')}
        style={{
          width: `${columnSizes.align.currentSize}px`,
          maxWidth: `${columnSizes.align.currentSize}px`,
          minWidth: `${columnSizes.order.currentSize}px`,
        }}
      >
        <div className=" text-xs">
          {canAlignAirframeCheck && (
            <Tooltip
              tooltipContent="Align with KB"
              content={
                <Button
                  className={cn('m-auto mx-auto h-6 p-0 text-xs', suggestAlignment && 'text-text-red-500')}
                  leftIcon={IconVariant.KeyboardArrowLeft}
                  variant={ButtonVariant.Ghost}
                  size={Size.Small}
                  onClick={() => {
                    if (!isEmptyAirframeCheck) {
                      onExistingAirframeCheckCopyFromKb(positionalIndex, formAirframeCheck)
                    }
                  }}
                />
              }
            />
          )}

          {canDeleteAirframeCheck && (
            <Button
              className="m-auto mx-auto h-6 p-0 text-xs"
              leftIcon={IconVariant.Delete}
              variant={ButtonVariant.Ghost}
              size={Size.Small}
              onClick={() => {
                onDeleteExistingAirframeCheck(formAirframeCheck?.airframeCheck?.checkId ?? '')
              }}
            />
          )}
        </div>
      </td>
    </tr>
  )
}

export { AssetAirframeCheckRow }
