import React, { useEffect } from 'react'
import { IconButton, Link, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'

interface TableRowData {
  id: number
  deratePct: string
  limitAdjustmentPct: string
}

interface DerateRatioProps {
  rows: TableRowData[]
  onRowChange: (id: number, field: keyof TableRowData, value: string) => void
  onAddRow: () => void
  onDeleteRow: (id: number) => void
  onValidationChange: (isValid: boolean) => void
}

const duplicateStyle = {
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'red',
      borderWidth: '1px',
    },
    '&:hover fieldset': {
      borderColor: 'red',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'red',
    },
  },
}

const normalizeNumber = (value: string): string => {
  if (!value.trim()) return ''
  const num = parseFloat(value)
  return isNaN(num) ? '' : num.toString()
}

const TableHeader: React.FC = () => (
  <TableHead>
    <TableRow sx={{ '& th': { borderBottom: '1px solid rgba(0, 0, 0, 0.12)', backgroundColor: 'transparent' } }}>
      <TableCell>Derate Impact</TableCell>
      <TableCell>EPR Limit Adjustment</TableCell>
      <TableCell width={50} />
    </TableRow>
  </TableHead>
)

interface RowInputProps {
  value: string
  onChange: (value: string) => void
  hasError: boolean
  isDuplicate: boolean
}

const RowInput = ({ value, onChange, hasError, isDuplicate }: RowInputProps) => (
  <TextField
    fullWidth
    value={value}
    onChange={(e) => onChange(e.target.value)}
    error={hasError}
    sx={isDuplicate ? duplicateStyle : {}}
    InputProps={{
      endAdornment: '%',
    }}
    inputProps={{
      inputMode: 'decimal',
      pattern: '-?[0-9]*[.]?[0-9]*',
    }}
  />
)

const DeleteButton: React.FC<{ onClick: () => void }> = ({ onClick }) => (
  <IconButton size="small" onClick={onClick} sx={{ color: 'rgba(0, 0, 0, 0.54)' }}>
    <DeleteIcon fontSize="small" />
  </IconButton>
)

const AddRowLink: React.FC<{ onClick: () => void }> = ({ onClick }) => (
  <Link
    component="button"
    variant="body2"
    onClick={onClick}
    sx={{
      mt: 2,
      textDecoration: 'none',
      '&:hover': { textDecoration: 'none' },
    }}
  >
    + Add another row
  </Link>
)

export const DerateRatio: React.FC<DerateRatioProps> = ({ rows, onRowChange, onAddRow, onDeleteRow, onValidationChange }) => {
  const validateRow = (row: TableRowData): boolean => {
    const hasDeratePct = Boolean(row.deratePct.trim())
    const hasLimitAdjustment = Boolean(row.limitAdjustmentPct.trim())
    return (hasDeratePct && hasLimitAdjustment) || (!hasDeratePct && !hasLimitAdjustment)
  }

  const checkDuplicateRatio = (currentId: number, deratePct: string): boolean => {
    if (!deratePct.trim()) return false
    const normalizedCurrentRatio = normalizeNumber(deratePct)
    return rows.some((row) => row.id !== currentId && normalizeNumber(row.deratePct) === normalizedCurrentRatio)
  }

  const hasValidationError = (row: TableRowData): boolean => {
    const hasDeratePct = Boolean(row.deratePct.trim())
    const hasLimitAdjustment = Boolean(row.limitAdjustmentPct.trim())
    return (hasLimitAdjustment && !hasDeratePct) || (hasDeratePct && !hasLimitAdjustment)
  }

  const handleInputChange = (id: number, field: keyof TableRowData, value: string) => {
    // force only numeric values inside this input
    if (!/^-?\d*\.?\d*$/.test(value)) return
    onRowChange(id, field, value)
  }

  useEffect(() => {
    const isValid = rows.every((row) => validateRow(row) && !checkDuplicateRatio(row.id, row.deratePct))
    onValidationChange(isValid)
  }, [rows, onValidationChange])

  return (
    <>
      <Typography variant="subtitle1">Derate Ratio</Typography>
      <Table size="small">
        <TableHeader />
        <TableBody>
          {rows.map((row) => {
            const isDuplicate = checkDuplicateRatio(row.id, row.deratePct)
            const hasError = hasValidationError(row)

            return (
              <TableRow key={row.id} sx={{ '& td': { border: 'none' } }}>
                <TableCell>
                  <RowInput
                    value={row.deratePct}
                    onChange={(value) => handleInputChange(row.id, 'deratePct', value)}
                    hasError={hasError}
                    isDuplicate={isDuplicate}
                  />
                </TableCell>
                <TableCell>
                  <RowInput
                    value={row.limitAdjustmentPct}
                    onChange={(value) => handleInputChange(row.id, 'limitAdjustmentPct', value)}
                    hasError={hasError}
                    isDuplicate={isDuplicate}
                  />
                </TableCell>
                <TableCell>{rows.length > 1 && <DeleteButton onClick={() => onDeleteRow(row.id)} />}</TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
      <AddRowLink onClick={onAddRow} />
    </>
  )
}
