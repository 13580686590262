// Platform imports
import { AssetType, getAssetTypeDisplayName } from '@flyward/platform'

// Standalone Engine imports
import { StandaloneEngineGeneral } from './WizardScreens/standaloneEngine/General'
import { StandaloneEngineReviewAndPublish } from './WizardScreens/standaloneEngine/Review&Publish'
import { EngineComponent } from './WizardScreens/standaloneEngine/Engine'
import { AircraftGeneral } from './WizardScreens/aircraft/General'
import { APUComponent } from './WizardScreens/aircraft/APU'
import { AircraftReviewAndPublish } from './WizardScreens/aircraft/Review&Publish'
import { LgComponent } from './WizardScreens/aircraft/LG'
import { EnginesComponent } from './WizardScreens/aircraft/Engines'
import { AirframeComponent } from './WizardScreens/aircraft/Airframe'

const BASE_WIZARD_CONFIG = {
  [AssetType.Aircraft]: {
    stepperTitle: `Setting up a new ${getAssetTypeDisplayName(AssetType.Aircraft)}`,
    steps: [
      { label: 'General', subLabel: null, component: AircraftGeneral, key: 'general' },
      { label: 'Airframe', subLabel: null, component: AirframeComponent, key: 'airframe' },
      { label: 'Engine(s)', subLabel: 'Optional', component: EnginesComponent, key: 'engines' },
      { label: 'Landing Gear', subLabel: 'Optional', component: LgComponent, key: 'landingGear' },
      { label: 'APU', subLabel: 'Optional', component: APUComponent, key: 'apu' },
      { label: 'Review & Publish', subLabel: null, component: AircraftReviewAndPublish, key: 'reviewPublish' },
    ],
  },
  [AssetType.StandaloneEngine]: {
    stepperTitle: `Setting up a new ${getAssetTypeDisplayName(AssetType.StandaloneEngine)}`,
    steps: [
      { label: 'General', subLabel: null, component: StandaloneEngineGeneral, key: 'general' },
      { label: 'Engine', subLabel: null, component: EngineComponent, key: 'engine' },
      { label: 'Review & Publish', subLabel: null, component: StandaloneEngineReviewAndPublish, key: 'reviewPublish' },
    ],
  },
} as const

export const useAssetWizardConfig = (type: keyof typeof BASE_WIZARD_CONFIG) => BASE_WIZARD_CONFIG[type]
