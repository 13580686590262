import { type ApuDto } from '@flyward/assets/models'
import { EditableDateCellWithLabelWithLabel, EditableInputCellWithLabelWithLabel, InputType } from '@flyward/platform'
import { type UseFormSetValue, type Control } from 'react-hook-form'

export interface IAPUDetailsEditable {
  setFormValue: UseFormSetValue<ApuDto>
  formControl: Control<ApuDto, unknown>
}

export const APUDetailsEditable = ({ setFormValue, formControl }: IAPUDetailsEditable) => {
  return (
    <div className="flex gap-x-6">
      <EditableInputCellWithLabelWithLabel
        inputType={InputType.Text}
        className="mr-6 basis-2/6"
        label="Manufacturer"
        formControl={formControl}
        fieldName={`manufacturingDetail.manufacturer`}
      />
      <EditableInputCellWithLabelWithLabel
        inputType={InputType.Text}
        className="basis-1/6"
        label="Model"
        formControl={formControl}
        fieldName={`componentModel`}
      />
      <EditableInputCellWithLabelWithLabel
        inputType={InputType.Text}
        className="basis-1/6"
        label="S/N"
        formControl={formControl}
        fieldName={`componentSerialNumber`}
      />
      <EditableDateCellWithLabelWithLabel
        className="basis-1/6"
        label={`DOM`}
        formControl={formControl}
        fieldName={`manufacturingDetail.dom`}
        allowClear={true}
        setFormValue={setFormValue}
      />
      <div className="basis-1/6" />
    </div>
  )
}
