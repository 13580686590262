import { Box, Button, TextField, Typography } from '@mui/material'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { NumericFormat } from 'react-number-format'
import { validateNullableDate } from '@flyward/platform/helpers/inputValidators'
import { isEmpty, isNil } from 'lodash'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { dateToIsoString } from '@flyward/platform/helpers/dateHelpers'
import { useEffect } from 'react'

const MIN_VALUE = 0
const MAX_VALUE = 1000000000
const MAX_LENGTH = 200
const MAX_LENGTH_MESSAGE = 'Maximum 200 characters allowed'

const technicalSchema = z.object({
  sn: z.string().min(1, 'Serial Number is required').max(MAX_LENGTH, MAX_LENGTH_MESSAGE),
  manufacturer: z.string().optional().nullable(),
  model: z.string().optional().nullable(),
  dom: validateNullableDate,
  tsn: z.number().optional().nullable(),
  csn: z.number().optional().nullable(),
  tsnLastPr: z.number().optional().nullable(),
  csnLastPr: z.number().optional().nullable(),
  dateLastPr: validateNullableDate,
  apuHoursSinceEventAtContractDelivery: z.number().optional().nullable(),
})

const componentSchema = z.object({
  prTechnical: technicalSchema,
})

export type ComponentFormData = z.infer<typeof componentSchema>

interface Props {
  onNext: (_data: ComponentFormData) => void
  onBack?: () => void
  stepTitle: string
  initialData?: ComponentFormData
  isAiPopulated?: boolean
}

const APUComponent = ({ onNext, onBack, stepTitle, initialData, isAiPopulated }: Props) => {
  const {
    formState: { errors, touchedFields },
    watch,
    trigger,
    setValue,
    register,
  } = useForm<ComponentFormData>({
    resolver: zodResolver(componentSchema),
    mode: 'all',
    defaultValues: {
      prTechnical: {
        sn: initialData?.prTechnical?.sn ?? '',
        manufacturer: initialData?.prTechnical?.manufacturer ?? '',
        model: initialData?.prTechnical?.model ?? '',
        dom: initialData?.prTechnical?.dom ?? undefined,
        tsn: initialData?.prTechnical?.tsn,
        csn: initialData?.prTechnical?.csn,
        tsnLastPr: initialData?.prTechnical?.tsnLastPr ?? null,
        csnLastPr: initialData?.prTechnical?.csnLastPr ?? null,
        dateLastPr: initialData?.prTechnical?.dateLastPr ?? null,
        apuHoursSinceEventAtContractDelivery: initialData?.prTechnical?.apuHoursSinceEventAtContractDelivery ?? null,
      },
    },
  })

  useEffect(() => {
    if (isAiPopulated) {
      trigger()
    }
  }, [isAiPopulated])

  const values = watch()

  const hasAnyVales = Object.values(values.prTechnical).some((value) => value !== undefined && value !== null && value !== '')

  const handleContinue = async () => {
    const isValidForm = await trigger()
    if (!hasAnyVales || isValidForm) {
      onNext({
        prTechnical: {
          sn: values?.prTechnical?.sn,
          manufacturer: values?.prTechnical?.manufacturer,
          model: values?.prTechnical?.model,
          dom: values?.prTechnical?.dom,
          tsn: values?.prTechnical?.tsn,
          csn: values?.prTechnical?.csn,
          tsnLastPr: values?.prTechnical?.tsnLastPr,
          csnLastPr: values?.prTechnical?.csnLastPr,
          dateLastPr: values?.prTechnical?.dateLastPr,
          apuHoursSinceEventAtContractDelivery: values?.prTechnical?.apuHoursSinceEventAtContractDelivery,
        },
      })
    }
  }

  const hasValidationErrors = Object.keys(errors).length > 0
  const hasMissingRequiredFields = !values.prTechnical.sn
  const isButtonDisabled = hasAnyVales && (hasValidationErrors || hasMissingRequiredFields)

  const getTouchedErrorMessage = (fieldName) => (!touchedFields?.prTechnical?.[fieldName] ? '' : errors.prTechnical?.[fieldName]?.message ?? '')

  const getRequiredErrorMessage = (value, fieldName) => (!hasAnyVales || value ? '' : `${fieldName} is required`)

  return (
    <div className="shadow-sm rounded-lg bg-white p-6" style={{ minWidth: '32rem' }}>
      <Typography variant="h6" sx={{ mb: 3 }}>
        {stepTitle}
      </Typography>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 3,
          mb: 4,
        }}
      >
        <Typography variant="subtitle1" sx={{ mb: 1 }}>
          Technical
        </Typography>

        <Box sx={{ display: 'flex', gap: 2 }}>
          <TextField
            {...register('prTechnical.sn')}
            error={(touchedFields?.prTechnical?.sn && !!errors?.prTechnical?.sn) ?? values?.prTechnical?.sn?.length > MAX_LENGTH}
            label="Serial Number *"
            helperText={getTouchedErrorMessage('sn') || getRequiredErrorMessage(values.prTechnical.sn, 'Serial Number')}
            sx={{ flex: 1 }}
          />

          <DatePicker
            label="DOM"
            value={!isNil(values?.prTechnical.dom) && !isEmpty(values?.prTechnical.dom) ? new Date(values?.prTechnical.dom) : undefined}
            onChange={(newValue) => {
              const isoFormat = isNil(newValue) ? undefined : dateToIsoString(newValue)
              setValue('prTechnical.dom', isoFormat)
              trigger('prTechnical.dom')
            }}
            slotProps={{
              textField: {
                helperText: errors?.prTechnical?.dom?.message?.toString() ?? '',
                error: !!errors?.prTechnical?.dom?.message,
                onBlur: () => {
                  trigger('prTechnical.dom')
                },
              },
              field: {
                clearable: true,
                onClear: () => {
                  setValue('prTechnical.dom', undefined)
                  trigger('prTechnical.dom')
                },
              },
            }}
            sx={{ flex: 1 }}
          />

          <Box sx={{ flex: 1 }} />
        </Box>

        <Box sx={{ display: 'flex', gap: 2 }}>
          <TextField
            {...register('prTechnical.manufacturer')}
            error={(touchedFields?.prTechnical?.manufacturer && !!errors?.prTechnical?.manufacturer) ?? values?.prTechnical?.manufacturer?.length > MAX_LENGTH}
            label="Manufacturer"
            helperText={getTouchedErrorMessage('manufacturer')}
            sx={{ flex: 1 }}
          />

          <TextField
            {...register('prTechnical.model')}
            error={(touchedFields?.prTechnical?.model && !!errors?.prTechnical?.model) ?? values?.prTechnical?.model?.length > MAX_LENGTH}
            label="Model"
            helperText={getTouchedErrorMessage('model')}
            sx={{ flex: 1 }}
          />

          <Box sx={{ flex: 1 }} />
        </Box>

        <Box sx={{ display: 'flex', gap: 2 }}>
          <NumericFormat
            customInput={TextField}
            label="TSN"
            value={values.prTechnical.tsn ?? ''}
            error={touchedFields?.prTechnical?.tsn && !!errors?.prTechnical?.tsn}
            helperText={getTouchedErrorMessage('tsn')}
            thousandSeparator
            decimalScale={0}
            allowNegative={false}
            isAllowed={({ floatValue }) => floatValue === undefined || (floatValue >= MIN_VALUE && floatValue <= MAX_VALUE)}
            onValueChange={({ floatValue }) => {
              setValue('prTechnical.tsn', floatValue!, {
                shouldValidate: true,
                shouldTouch: true,
              })
            }}
            onBlur={() => {
              if (!touchedFields?.prTechnical?.tsn) {
                setValue('prTechnical.tsn', values.prTechnical.tsn, {
                  shouldTouch: true,
                  shouldValidate: true,
                })
              }
            }}
            sx={{ flex: 1 }}
          />

          <NumericFormat
            customInput={TextField}
            label="CSN"
            value={values.prTechnical.csn ?? ''}
            error={touchedFields?.prTechnical?.csn && !!errors?.prTechnical?.csn}
            helperText={getTouchedErrorMessage('csn')}
            thousandSeparator
            decimalScale={0}
            allowNegative={false}
            isAllowed={({ floatValue }) => floatValue === undefined || (floatValue >= MIN_VALUE && floatValue <= MAX_VALUE)}
            onValueChange={({ floatValue }) => {
              setValue('prTechnical.csn', floatValue!, {
                shouldValidate: true,
                shouldTouch: true,
              })
            }}
            onBlur={() => {
              if (!touchedFields?.prTechnical?.csn) {
                setValue('prTechnical.csn', values.prTechnical.csn, {
                  shouldTouch: true,
                  shouldValidate: true,
                })
              }
            }}
            sx={{ flex: 1 }}
          />

          <Box sx={{ flex: 1 }} />
        </Box>

        <Box sx={{ display: 'flex', gap: 2 }}>
          <NumericFormat
            customInput={TextField}
            label="TSN @ Last PR"
            value={values.prTechnical.tsnLastPr ?? ''}
            error={touchedFields?.prTechnical?.tsnLastPr && !!errors?.prTechnical?.tsnLastPr}
            helperText={getTouchedErrorMessage('prTechnical.tsnLastPr')}
            thousandSeparator
            decimalScale={0}
            allowNegative={false}
            isAllowed={({ floatValue }) => floatValue === undefined || (floatValue >= MIN_VALUE && floatValue <= MAX_VALUE)}
            onValueChange={({ floatValue }) => {
              setValue('prTechnical.tsnLastPr', floatValue!, {
                shouldValidate: true,
                shouldTouch: true,
              })
            }}
            onBlur={() => {
              if (!touchedFields?.prTechnical?.tsnLastPr) {
                setValue('prTechnical.tsnLastPr', values.prTechnical.tsnLastPr, {
                  shouldTouch: true,
                  shouldValidate: true,
                })
              }
            }}
            sx={{ flex: 1 }}
          />

          <NumericFormat
            customInput={TextField}
            label="CSN @ Last PR"
            value={values.prTechnical.csnLastPr ?? ''}
            error={touchedFields?.prTechnical?.csnLastPr && !!errors?.prTechnical?.csnLastPr}
            helperText={getTouchedErrorMessage('prTechnical.csnLastPr')}
            thousandSeparator
            decimalScale={0}
            allowNegative={false}
            isAllowed={({ floatValue }) => floatValue === undefined || (floatValue >= MIN_VALUE && floatValue <= MAX_VALUE)}
            onValueChange={({ floatValue }) => {
              setValue('prTechnical.csnLastPr', floatValue!, {
                shouldValidate: true,
                shouldTouch: true,
              })
            }}
            onBlur={() => {
              if (!touchedFields?.prTechnical?.csnLastPr) {
                setValue('prTechnical.csnLastPr', values.prTechnical.csnLastPr, {
                  shouldTouch: true,
                  shouldValidate: true,
                })
              }
            }}
            sx={{ flex: 1 }}
          />

          <DatePicker
            label="Date @ Last PR"
            value={!isNil(values.prTechnical.dateLastPr) && !isEmpty(values.prTechnical.dateLastPr) ? new Date(values.prTechnical.dateLastPr) : undefined}
            onChange={(newValue) => {
              const isoFormat = isNil(newValue) ? undefined : dateToIsoString(newValue)
              setValue('prTechnical.dateLastPr', isoFormat)
              trigger('prTechnical.dateLastPr')
            }}
            slotProps={{
              field: {
                clearable: true,
                onClear: () => {
                  setValue('prTechnical.dateLastPr', undefined)
                  trigger('prTechnical.dateLastPr')
                },
              },
            }}
            sx={{ flex: 1 }}
          />
        </Box>

        <Box sx={{ display: 'flex', gap: 2 }}>
          <NumericFormat
            customInput={TextField}
            label="APU Hours @ Contract Delivery"
            value={values.prTechnical.apuHoursSinceEventAtContractDelivery ?? ''}
            error={touchedFields?.prTechnical?.apuHoursSinceEventAtContractDelivery && !!errors?.prTechnical?.apuHoursSinceEventAtContractDelivery}
            helperText={getTouchedErrorMessage('prTechnical.apuHoursSinceEventAtContractDelivery')}
            thousandSeparator
            decimalScale={0}
            allowNegative={false}
            isAllowed={({ floatValue }) => floatValue === undefined || (floatValue >= MIN_VALUE && floatValue <= MAX_VALUE)}
            onValueChange={({ floatValue }) => {
              setValue('prTechnical.apuHoursSinceEventAtContractDelivery', floatValue!, {
                shouldValidate: true,
                shouldTouch: true,
              })
            }}
            onBlur={() => {
              if (!touchedFields?.prTechnical?.apuHoursSinceEventAtContractDelivery) {
                setValue('prTechnical.apuHoursSinceEventAtContractDelivery', values.prTechnical.apuHoursSinceEventAtContractDelivery, {
                  shouldTouch: true,
                  shouldValidate: true,
                })
              }
            }}
            sx={{ flex: 1 }}
          />
          <Box sx={{ flex: 1 }} />
          <Box sx={{ flex: 1 }} />
        </Box>
      </Box>

      <Box>
        <Button variant="contained" onClick={handleContinue} disabled={isButtonDisabled}>
          Continue
        </Button>
        <Button variant="text" onClick={onBack}>
          Back
        </Button>
      </Box>
    </div>
  )
}

export { APUComponent }
