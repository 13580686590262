/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { AuthorizedElement } from '@flyward/appIdentity'
import { AssetFilterDialog, AssetsDataGrid, AssetTypeFilter, type FleetSearchInput, type IFilterSelection } from '@flyward/assets'
import { useDeleteAssetMutation, useGetAllAssetsBySearchQuery, useImportAiAssetFromFileMutation } from '@flyward/assets/store'
import { FleetEmptyState } from '@flyward/main-app/assets'
import { AssetType, ErrorBoundary, ComponentContainer, errorMessages, IconVariant, Size, type PageSizes, FullPageProgressBar } from '@flyward/platform'
import { Button, ButtonVariant } from '@flyward/platform/components/Button/Button'
import { setFleetPageNumber, setFleetPageSize, setFleetSearchInput, setFleetSearchTerm } from '@flyward/platform/store'
import { useAppDispatch, useAppSelector } from '@flyward/platform/store/configureHooks'
import { selectFleetSearchInput, selectSelectedAssetIds } from '@flyward/platform/store/slices/features/fleet/fleetSelectors'
import { isNil } from 'lodash'
import { useCallback, useState } from 'react'
import FleetPageSearchTerm from './FleetPageSearchTerm'
import { AddAssetModal } from './AddAssetModal'
import { ROUTES } from '@flyward/main-app/providers/routes'
import { showError, showSuccess } from '@flyward/platform/services'
import { formatAxiosErrorMessage } from '@flyward/platform/helpers/ErrorHelpers'
import { Typography, Button as MuiButton } from '@mui/material'
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff'
import queryString from 'query-string'
import { useNavigate } from 'react-router-dom'
import { initializeWizardState, resetWizard } from './Wizard/store/assetWizardSlice'
import { mapDraftAssetToAircraftState, mapDraftAssetToEngineState } from '@flyward/main-app/pages/Fleet/Wizard/utils/draftAssetMappers.ts'
import { DOCUMENT_PROCESSING_STEPS } from '@flyward/main-app/pages/Fleet/Wizard/utils/aiImportProgressSteps.ts'

const FlyForwardButton = () => {
  const selectedAssetIds = useAppSelector(selectSelectedAssetIds)
  const navigate = useNavigate()

  return (
    <MuiButton
      variant="contained"
      color="primary"
      startIcon={<FlightTakeoffIcon />}
      onClick={() => {
        navigate(`${ROUTES.FLY_FORWARD}/?${queryString.stringify({ assetIds: selectedAssetIds })}`)
      }}
    >
      Fly Forward
    </MuiButton>
  )
}

export const FleetPage = () => {
  const fleetSearchInput = useAppSelector(selectFleetSearchInput)
  const dispatch = useAppDispatch()
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false)
  const [isFileProcessing, setIsFileProcessing] = useState(false)
  const [importAiAssetFromFile] = useImportAiAssetFromFileMutation()
  const navigate = useNavigate()

  const { data: allAssets } = useGetAllAssetsBySearchQuery(fleetSearchInput)

  const applyFilterCallback = (filterData: IFilterSelection) => {
    dispatch(
      setFleetSearchInput({
        newFleetSearchInput: {
          ...fleetSearchInput,
          filterData,
        },
      }),
    )
  }

  const setCurrentPage = (page: number) => {
    dispatch(
      setFleetPageNumber({
        page,
      }),
    )
  }

  const setPageSize = (size: PageSizes) => {
    dispatch(
      setFleetPageSize({
        pageSize: size,
      }),
    )
  }

  const onFilterUpdateHandler = (filter: Partial<FleetSearchInput>) => {
    dispatch(
      setFleetSearchInput({
        newFleetSearchInput: {
          ...fleetSearchInput,
          ...filter,
        },
      }),
    )

    setCurrentPage(0)
  }

  const [deleteAsset] = useDeleteAssetMutation()

  const onDelete = async (assetId: string): Promise<string | undefined> => {
    const result = await deleteAsset({ assetId })
    return result.error?.message
  }

  const handleOpenUploadModal = () => {
    dispatch(resetWizard()) // todo @dan remove this line, after resetWizard() it's fixed in AssetWizardPage.tsx
    setIsUploadModalOpen(true)
  }

  const handleUpload = useCallback(
    async (file?: File) => {
      setIsFileProcessing(true)
      setIsUploadModalOpen(false)

      const { data: draftAsset, error: importError } = await importAiAssetFromFile({ file: file! })

      if (isNil(importError)) {
        const assetType = draftAsset.assetType
        const mappedState = assetType === AssetType.StandaloneEngine ? mapDraftAssetToEngineState(draftAsset) : mapDraftAssetToAircraftState(draftAsset)

        dispatch(
          initializeWizardState({
            type: assetType,
            data: mappedState,
          }),
        )
        navigate(ROUTES.FLEET.WIZARD)
        showSuccess(
          <span>
            AI agent has completed its review of the technical specification!
            <br />
            Please verify that the extracted details match your expectations.
          </span>,
          { autoHideDuration: 6000 },
        )
      } else {
        showError(formatAxiosErrorMessage(importError?.message, errorMessages.assets.importAi))
      }
      setIsFileProcessing(false)
    },
    [dispatch, navigate, importAiAssetFromFile],
  )

  //cancel doesn't work properly, button is commented in FullPageProgressBar
  const handleCancelProcessing = () => {
    setIsFileProcessing(false)
    showSuccess('Document processing cancelled')
  }

  return (
    <>
      {/* Header */}
      <div className="flex h-20 w-full items-center justify-between px-2">
        <Typography sx={{ fontSize: '1.25rem', fontWeight: 600, color: 'var(--text-1)' }}>Fleet Summary</Typography>
        <div className="flex items-center gap-4">
          <FlyForwardButton />
        </div>
      </div>
      {/* Main Pane */}
      <ErrorBoundary>
        <div className="flex flex-grow flex-col gap-y-2">
          <ComponentContainer className="flex-grow justify-start gap-0">
            <div className="flex w-full items-center justify-between bg-header-table p-6">
              <div className="flex gap-x-6">
                <FleetPageSearchTerm
                  initialSearchTerm={fleetSearchInput.searchTerm}
                  updateSearchTerm={(searchTerm: string) => {
                    dispatch(
                      setFleetSearchTerm({
                        searchTerm,
                      }),
                    )
                  }}
                  clearSearchTerm={() => {
                    dispatch(
                      setFleetSearchTerm({
                        searchTerm: '',
                      }),
                    )
                  }}
                />
                <AssetTypeFilter
                  assetType={fleetSearchInput.assetType}
                  setAssetType={(assetTypeChosen) => {
                    onFilterUpdateHandler({ ...fleetSearchInput, assetType: assetTypeChosen })
                  }}
                  id={'filter-asset'}
                />
                <AssetFilterDialog applyFilter={applyFilterCallback} onFilterUpdate={onFilterUpdateHandler} />
              </div>
              <AuthorizedElement>
                <div data-permission-element-id="asset-import" className="flex">
                  <Button variant={ButtonVariant.Secondary} size={Size.Small} label="New Aircraft/Engine" onClick={handleOpenUploadModal} leftIcon={IconVariant.Add} />
                </div>
              </AuthorizedElement>
            </div>
            {isNil(allAssets) ? (
              <FleetEmptyState width={998} height={580} />
            ) : (
              <AssetsDataGrid
                key={fleetSearchInput.assetType}
                fleet={allAssets}
                setCurrentPage={setCurrentPage}
                setPageSize={setPageSize}
                currentPage={fleetSearchInput.Page}
                pageSize={fleetSearchInput.PageSize}
                onDelete={onDelete}
              />
            )}
          </ComponentContainer>

          <AddAssetModal
            isOpen={isUploadModalOpen}
            setIsOpen={setIsUploadModalOpen}
            title={`Create Asset`}
            onConfirm={handleUpload}
            acceptedFileTypes=".xlsx,.xls,.pdf,.jpeg,.jpg,.png,.bmp,.tiff,.heif,.pptx,.html"
            confirmButtonLabel="Upload"
            isLoading={isFileProcessing}
            wizardUri={ROUTES.FLEET.WIZARD}
          />
        </div>
      </ErrorBoundary>
      <FullPageProgressBar
        isOpen={isFileProcessing}
        onComplete={() => setIsFileProcessing(false)}
        onCancel={handleCancelProcessing}
        title="AI Agent Document Analysis"
        subtitle="Our AI agent is carefully analyzing your technical specification. This may take a minute."
        steps={DOCUMENT_PROCESSING_STEPS}
      />
    </>
  )
}
