import React, { useState } from 'react'
import { Box, Button, TextField, Typography } from '@mui/material'
import { FHFCRatio } from './FHFCRatio'
import { DerateRatio } from './DerateRatio'
import { EnvironmentImpact } from './EnvironmentImpact'
import { EnvironmentalImpactType } from '@flyward/platform/models/enums'
import { handleNumericInput } from '@flyward/platform/utils'

interface TableRowData {
  id: number
  ratio: string
  limitAdjustmentPct: string
}

interface DerateRowData {
  id: number
  deratePct: string
  limitAdjustmentPct: string
}

interface EnvironmentImpactRowData {
  id: number
  impactType: EnvironmentalImpactType
  limitAdjustmentPct: string
}

interface AdjustmentsAndCostGrowthProps {
  onNext: (_data: {
    pr?: number
    llp?: number
    fhfc?: Array<{ ratio: number; limit: number }>
    derate?: Array<{ impact: number; limit: number }>
    envImpact?: Array<{ environment: EnvironmentalImpactType; limit: number }>
  }) => void
  onBack: () => void
  stepTitle: string
  initialData?: {
    pr?: number
    llp?: number
    fhfc?: Array<{ ratio: number; limit: number }>
    derate?: Array<{ impact: number; limit: number }>
    envImpact?: Array<{ environment: EnvironmentalImpactType; limit: number }>
  }
}

const getInitialFHFCRows = (initialData?: AdjustmentsAndCostGrowthProps['initialData']): TableRowData[] => {
  return initialData?.fhfc?.length
    ? initialData.fhfc.map((item, index) => ({
        id: index + 1,
        ratio: item.ratio.toString(),
        limitAdjustmentPct: item.limit.toString(),
      }))
    : [{ id: 1, ratio: '', limitAdjustmentPct: '' }]
}

const getInitialDerateRows = (initialData?: AdjustmentsAndCostGrowthProps['initialData']): DerateRowData[] => {
  return initialData?.derate?.length
    ? initialData.derate.map((item, index) => ({
        id: index + 1,
        deratePct: item.impact.toString(),
        limitAdjustmentPct: item.limit.toString(),
      }))
    : [{ id: 1, deratePct: '', limitAdjustmentPct: '' }]
}

const getInitialEnvironmentRows = (initialData?: AdjustmentsAndCostGrowthProps['initialData']): EnvironmentImpactRowData[] => {
  return (
    initialData?.envImpact?.map((item, index) => ({
      id: index + 1,
      impactType: item.environment,
      limitAdjustmentPct: item.limit.toString(),
    })) ?? []
  )
}

// Custom hooks for managing rows
const useRowState = <T extends { id: number }>(initialRows: T[]) => {
  const [rows, setRows] = useState<T[]>(initialRows)

  const handleRowChange = (id: number, field: keyof T, value: any) => {
    setRows((prevRows) => prevRows.map((row) => (row.id === id ? { ...row, [field]: value } : row)))
  }

  const handleAddRow = (newRow: T) => {
    const newId = Math.max(...rows.map((row) => row.id), 0) + 1
    setRows((prevRows) => [...prevRows, { ...newRow, id: newId }])
  }

  const handleDeleteRow = (id: number) => {
    setRows((prevRows) => prevRows.filter((row) => row.id !== id))
  }

  return { rows, handleRowChange, handleAddRow, handleDeleteRow }
}

export const AdjustmentsAndCostGrowth = ({ onNext, onBack, stepTitle, initialData }: AdjustmentsAndCostGrowthProps) => {
  const [prPercentage, setPrPercentage] = useState<string>(initialData?.pr?.toString() ?? '')
  const [llpPercentage, setLlpPercentage] = useState<string>(initialData?.llp?.toString() ?? '')
  const [isFHFCValid, setIsFHFCValid] = useState(true)
  const [isDerateValid, setIsDerateValid] = useState(true)
  const [isEnvironmentValid, setIsEnvironmentValid] = useState(true)

  const {
    rows: fhfcRows,
    handleRowChange: handleFhfcRowChange,
    handleAddRow: handleAddFhfcRow,
    handleDeleteRow: handleDeleteFhfcRow,
  } = useRowState<TableRowData>(getInitialFHFCRows(initialData))

  const {
    rows: derateRows,
    handleRowChange: handleDerateRowChange,
    handleAddRow: handleAddDerateRow,
    handleDeleteRow: handleDeleteDerateRow,
  } = useRowState<DerateRowData>(getInitialDerateRows(initialData))

  const {
    rows: environmentRows,
    handleRowChange: handleEnvironmentRowChange,
    handleAddRow: handleAddEnvironmentRow,
    handleDeleteRow: handleDeleteEnvironmentRow,
  } = useRowState<EnvironmentImpactRowData>(getInitialEnvironmentRows(initialData))

  const handleContinue = () => {
    const formattedData = {
      pr: prPercentage ? Number(prPercentage) : undefined,
      llp: llpPercentage ? Number(llpPercentage) : undefined,
      fhfc: fhfcRows
        .filter((row) => row.ratio && row.limitAdjustmentPct)
        .map((row) => ({
          ratio: Number(row.ratio),
          limit: Number(row.limitAdjustmentPct),
        })),
      derate: derateRows
        .filter((row) => row.deratePct && row.limitAdjustmentPct)
        .map((row) => ({
          impact: Number(row.deratePct),
          limit: Number(row.limitAdjustmentPct),
        })),
      envImpact: environmentRows
        .filter((row) => row.limitAdjustmentPct)
        .map((row) => ({
          environment: row.impactType,
          limit: Number(row.limitAdjustmentPct),
        })),
    }

    onNext(formattedData)
  }

  const isButtonDisabled = !isFHFCValid || !isDerateValid || !isEnvironmentValid

  return (
    <div className="shadow-sm rounded-lg bg-white p-6">
      <Typography variant="h6" sx={{ mb: 3 }}>
        {stepTitle}
      </Typography>

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, mb: 4 }}>
        <Typography variant="subtitle1">Escalation</Typography>
        <TextField
          label="PR Percentage"
          value={prPercentage}
          onChange={(e) => setPrPercentage(handleNumericInput(e.target.value))}
          InputProps={{ startAdornment: '%' }}
          inputProps={{ inputMode: 'decimal' }}
        />

        <TextField
          label="LLP Percentage"
          value={llpPercentage}
          onChange={(e) => setLlpPercentage(handleNumericInput(e.target.value))}
          InputProps={{ startAdornment: '%' }}
          inputProps={{ inputMode: 'decimal' }}
        />

        <Box sx={{ mt: 2 }}>
          <FHFCRatio
            rows={fhfcRows}
            onRowChange={handleFhfcRowChange}
            onAddRow={() => handleAddFhfcRow({ id: 0, ratio: '', limitAdjustmentPct: '' })}
            onDeleteRow={handleDeleteFhfcRow}
            onValidationChange={setIsFHFCValid}
          />
        </Box>

        <Box>
          <DerateRatio
            rows={derateRows}
            onRowChange={handleDerateRowChange}
            onAddRow={() => handleAddDerateRow({ id: 0, deratePct: '', limitAdjustmentPct: '' })}
            onDeleteRow={handleDeleteDerateRow}
            onValidationChange={setIsDerateValid}
          />
        </Box>

        <Box sx={{ mt: 2 }}>
          <EnvironmentImpact
            rows={environmentRows}
            onRowChange={handleEnvironmentRowChange}
            onAddRow={() => handleAddEnvironmentRow({ id: 0, impactType: EnvironmentalImpactType.Benign, limitAdjustmentPct: '' })}
            onDeleteRow={handleDeleteEnvironmentRow}
            onValidationChange={setIsEnvironmentValid}
          />
        </Box>
      </Box>

      <Box>
        <Button variant="contained" onClick={handleContinue} sx={{ mr: 1 }} disabled={isButtonDisabled}>
          Continue
        </Button>
        <Button variant="text" onClick={onBack}>
          Back
        </Button>
      </Box>
    </div>
  )
}
