import { isNil } from 'lodash'

export enum UtilizationUnits {
  Months = 1,
  FlightHours = 2,
  FlightCycles = 3,
  AuxiliaryPowerUnitHours = 4,
}

const unitLabels: Record<UtilizationUnits, { longLabel: string; shortLabel: string }> = {
  [UtilizationUnits.AuxiliaryPowerUnitHours]: { longLabel: 'APU Hours', shortLabel: 'APU H' },
  [UtilizationUnits.FlightCycles]: { longLabel: 'Flight Cycles', shortLabel: 'FC' },
  [UtilizationUnits.FlightHours]: { longLabel: 'Flight Hours', shortLabel: 'FH' },
  [UtilizationUnits.Months]: { longLabel: 'Months', shortLabel: 'Months' },
}

export const UtilizationUnitsDisplay = (value: UtilizationUnits | undefined | null) => (!isNil(value) ? unitLabels[value].longLabel ?? '-' : '-')

export const UtilizationUnitsDisplayShort = (value: UtilizationUnits | undefined | null) =>
  !isNil(value) ? unitLabels[value].shortLabel ?? '-' : '-'

export const UtilizationUnitsOptions = Object.keys(unitLabels).map((key) => ({
  value: key,
  label: unitLabels[Number(key) as UtilizationUnits].longLabel,
}))
