import { useExportReportQuery } from '@flyward/forecasts/store'
import { isEmpty, isNil } from 'lodash'
import { useEffect, useState } from 'react'
import { IconVariant } from '@flyward/platform/components'
import { useSpinnerState } from '@flyward/platform/contexts'
import { showError, showSuccess } from '@flyward/platform/services'
import { errorMessages } from '@flyward/platform'
import { cn } from '@flyward/platform'
import { ExcelExtract } from '@flyward/platform/components/Icon/svg'
import { Button } from '@mui/material'

interface IReportExcelExtractProps {
  reportId: string
  reportName: string
  className?: string
  disabled?: boolean
}

export const ReportExcelExtract = ({ reportId, reportName, className, disabled = false }: IReportExcelExtractProps) => {
  const [buttonPressed, setButtonPressed] = useState(false)
  const { showSpinner, hideSpinner } = useSpinnerState()

  const { data: file, error } = useExportReportQuery({ reportId }, { skip: !buttonPressed })

  useEffect(() => {
    if (!isNil(file)) {
      if (file.data instanceof Blob) {
        const url = window.URL.createObjectURL(file.data)
        const link = document.createElement('a')
        link.href = url
        link.download = `${!isEmpty(reportName) ? reportName : 'Portfolio Report'}.xlsx`
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        window.URL.revokeObjectURL(url)
      } else {
        throw new Error('MediaSource handling is not supported in this method.')
      }

      showSuccess(<span>Portfolio Excel file downloaded</span>)
      setButtonPressed(false)
      hideSpinner()
    }
    if (!isNil(error)) {
      showError(errorMessages.reports.generateExcelError)
      setButtonPressed(false)
      hideSpinner()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file, error])

  const handleDownload = () => {
    setButtonPressed(true)
    showSpinner()
  }
  return (
    <Button
      key={`portfolio-excel-extract-${reportId}`}
      variant="outlined"
      startIcon={
        <div className="flex h-5 w-5 items-center justify-center">
          <ExcelExtract variant={IconVariant.ExcelExtract} className="h-full w-full" />
        </div>
      }
      className={cn('mr-1 h-9 px-3', className)}
      onClick={handleDownload}
      disabled={disabled || buttonPressed}
      sx={{
        '&.MuiButton-outlined': {
          borderColor: 'rgba(0, 0, 0, 0.23)',
          '&:hover': {
            borderColor: 'primary.main',
          },
        },
        '&.Mui-disabled': {
          backgroundColor: 'var(--black-5)',
          borderColor: 'var(--black-20)',
          '& .MuiSvgIcon-root, & svg': {
            color: 'var(--black-40)',
            opacity: 0.7,
          },
        },
        '& .MuiButton-startIcon': {
          marginRight: 0,
          marginLeft: 0,
        },
      }}
    />
  )
}
