import { EventSchedule, formatAssetSchedule, ResponsiveForecastingSummaryChart, Statistics } from '@flyward/forecasts/components'
import { type EventsScheduleTableInput } from '@flyward/forecasts/components/schedule/ScheduleTable/models'
import { type ReportItem, type IMaintenanceScheduleDisplay } from '@flyward/forecasts/models'
import { ErrorBoundary, CollapsibleContainer, ComponentContainer, LoadingFallback } from '@flyward/platform'
import { isArrayEmptyOrNull } from '@flyward/platform/helpers/arrayHelpers'
import { ReportDisplayType, YearMonth, type ComponentMonthlyStatistics } from '@flyward/platform/models'
import { type ComponentTotalStatisticsAtEvent } from '@flyward/platform/models/entities/ComponentTotalStatisticsAtEvent'
import { useGetFullReportDetailsQuery, useGetReportStatisticsQuery } from '@flyward/platform/store/slices'
import { cloneDeep, isNil } from 'lodash'

interface IPortfolioReportBodyProps {
  reportId: string
}

export const PortfolioReportBody = ({ reportId }: IPortfolioReportBodyProps) => {
  const { data: fullReport, isLoading: isLoadingFullReport } = useGetFullReportDetailsQuery({ reportId }, { skip: isNil(reportId) })
  const { data: reportStatistics } = useGetReportStatisticsQuery({ id: reportId }, { skip: isNil(reportId) })

  let uniqueEvents: IMaintenanceScheduleDisplay[] = []
  let allEvents: IMaintenanceScheduleDisplay[] = []
  const componentsMonthlyStatistics: ComponentMonthlyStatistics[] = []
  let componentTotalStatisticsAtEvent: ComponentTotalStatisticsAtEvent[] = []

  const reportItemsSchedules: EventsScheduleTableInput[] = []

  const sortedReportItems =
    !isLoadingFullReport && !isArrayEmptyOrNull(fullReport?.reportItems) && (fullReport?.reportItems?.length ?? 0) > 0
      ? cloneDeep(fullReport?.reportItems)?.sort((a, b) => {
          return a.assetSerialNumber.localeCompare(b.assetSerialNumber)
        })
      : []

  sortedReportItems?.forEach((reportAsset: ReportItem, index) => {
    const uniqueSchedules: IMaintenanceScheduleDisplay[] = formatAssetSchedule(
      reportAsset.outputSchedules,
      reportAsset.inputFlyForwardParams.endDate,
      reportAsset.assetDetailsSnapshot.serialNumber,
      reportAsset.id,
    )

    const allSchedules: IMaintenanceScheduleDisplay[] = formatAssetSchedule(
      reportAsset.outputSchedules,
      reportAsset.inputFlyForwardParams.endDate,
      reportAsset.assetDetailsSnapshot.serialNumber,
      reportAsset.id,
      true,
    )

    uniqueEvents = uniqueEvents.concat(uniqueSchedules)
    allEvents = allEvents.concat(allSchedules)

    reportItemsSchedules.push({
      flyForwardParameters: reportAsset.inputFlyForwardParams,
      reportItemId: reportAsset.id ?? '',
      assetIdWithIndex: {
        id: reportAsset.assetId,
        index,
      },
      schedules: uniqueSchedules,
    })

    reportAsset?.assetComponentsMonthlyStatistics?.groupedComponentStatistics.forEach((component) => {
      if (component.subComponents !== undefined && component.subComponents.length > 0) {
        componentsMonthlyStatistics.push(...component.subComponents)
      } else {
        componentsMonthlyStatistics.push(component)
      }
    })

    if (!isNil(reportAsset?.assetComponentsMonthlyStatistics?.componentTotalStatisticsAtEvent)) {
      componentTotalStatisticsAtEvent = componentTotalStatisticsAtEvent.concat(reportAsset.assetComponentsMonthlyStatistics.componentTotalStatisticsAtEvent)
    }
  })

  if (isLoadingFullReport) {
    return (
      <span className="min-h-screen">
        <LoadingFallback />
      </span>
    )
  }

  const isoStartDate = YearMonth.fromYearMonthOrIYearMonth(reportStatistics![0].yearMonth).toISOString()
  const isoEndDate = YearMonth.fromYearMonthOrIYearMonth(reportStatistics![reportStatistics!.length - 1].yearMonth).toISOString()

  return (
    <>
      {!isNil(reportStatistics) && reportStatistics.length > 0 && (
        <ComponentContainer className="p-6">
          <CollapsibleContainer title="Chart" containerClassName="w-full gap-y-0">
            <div className="h-96 w-full">
              <ErrorBoundary>
                <ResponsiveForecastingSummaryChart assetMonthlyStatistics={reportStatistics} />
              </ErrorBoundary>
            </div>
          </CollapsibleContainer>
        </ComponentContainer>
      )}
      {reportItemsSchedules.length > 0 && (
        <EventSchedule
          reportDisplayType={ReportDisplayType.PortfolioReport}
          reportItemsSchedules={reportItemsSchedules}
          uniqueSchedules={uniqueEvents}
          allSchedules={allEvents}
          componentsMonthlyStatistics={componentsMonthlyStatistics}
          componentTotalStatisticsAtEvent={componentTotalStatisticsAtEvent}
          isEditable={false}
        />
      )}
      {!isArrayEmptyOrNull(reportStatistics) && !isArrayEmptyOrNull(componentsMonthlyStatistics) && (
        <Statistics
          reportDisplayType={ReportDisplayType.PortfolioReport}
          allEvents={allEvents}
          assetMonthlyStatisticsSummary={reportStatistics ?? []}
          allReportComponentsMonthlyStatistics={{
            assetMonthlyStatistics: reportStatistics ?? [],
            groupedComponentStatistics: componentsMonthlyStatistics,
            componentTotalStatisticsAtEvent,
          }}
          key={'portfolioReport'}
          isoStartDate={isoStartDate}
          isoEndDate={isoEndDate}
        />
      )}
    </>
  )
}
