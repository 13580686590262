import { ReportItemStatus } from '@flyward/forecasts/models/enums'
import { cn, Icon, IconVariant, Size, Tooltip } from '@flyward/platform'
import { AssetSelectionStatusIcon } from './AssetSelectionStatusIcon'

interface IAssetSelectionBadgeProps {
  assetId: string
  onItemClicked: (assetId: string) => void
  onClearIconClicked: () => void
  text: string
  isSelected?: boolean
  status: ReportItemStatus
  isDisabled?: boolean
}

const AssetSelectionBadge = ({
  assetId,
  onItemClicked,
  onClearIconClicked,
  text,
  status,
  isSelected = false,
  isDisabled = false,
}: IAssetSelectionBadgeProps) => {
  return (
    <li
      className={cn(
        'group flex cursor-pointer flex-row items-center gap-0.5 rounded-lg py-2.5 pl-1 pr-2 hover:bg-[#C9DEE0]',
        isSelected ? 'bg-primary' : 'bg-transparent',
        status === ReportItemStatus.Processing && 'bg-primary-light-2',
        isDisabled && 'cursor-not-allowed bg-[#C9DEE0]',
      )}
      data-testid="asset-selection-badge-container"
      onClick={() => {
        if (status !== ReportItemStatus.Processing) {
          !isDisabled && onItemClicked(assetId)
        }
      }}
    >
      <span
        className={cn(
          'flex w-20 truncate text-xs font-semibold',
          isSelected ? 'text-black-0' : 'text-primary',
          status === ReportItemStatus.Processing && 'text-black-0',
          isDisabled && 'italic text-primary',
        )}
        data-testid="asset-selection-badge-text"
      >
        <AssetSelectionStatusIcon status={status} />
        <span className="mx-auto">
          <Tooltip content={text} tooltipContent={<span className="text-primary">{text}</span>} />
        </span>
      </span>
      {status !== ReportItemStatus.Processing && status !== ReportItemStatus.QueuedForProcessing && (
        <div
          className={'pointer-events-auto z-50 h-full'}
          onClick={(e) => {
            !isDisabled && onClearIconClicked()
            e.stopPropagation()
          }}
        >
          <Icon
            className={cn('h-4 w-4 text-transparent', !isDisabled && 'group-hover:text-primary')}
            size={Size.Medium}
            variant={IconVariant.Clear}
          />
        </div>
      )}
    </li>
  )
}

export { AssetSelectionBadge }
