import { useState, useEffect, useCallback } from 'react'
import { IconButton, Link, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import { NumericFormat } from './NumericFormat'
import { LLPTableRowData } from './types'
import { isEmpty } from 'lodash'
import { cn } from '@flyward/platform/components'

interface LLPTableProps {
  rows: LLPTableRowData[]
  onRowChange: (_id: number, _field: keyof LLPTableRowData, _value: string) => void
  onAddRow: () => void
  onDeleteRow: (_id: number) => void
  onValidationChange?: (_isValid: boolean) => void
  isAiPopulated?: boolean
}

const TABLE_COLUMN_SIZES = {
  sm: 'w-24', // 8rem - for smaller numeric columns
  md: 'w-40', // 10rem - for part/serial numbers
  full: 'flex-1', // for flexible columns
} as const

export const LLPTable = ({ rows, onRowChange, onAddRow, onDeleteRow, onValidationChange = () => {}, isAiPopulated }: LLPTableProps) => {
  const [touchedFields, setTouchedFields] = useState<Record<number, Record<string, boolean>>>({})

  const handleBlur = (rowId: number, field: string) => {
    setTouchedFields((prev) => ({
      ...prev,
      [rowId]: {
        ...(prev[rowId] || {}),
        [field]: true,
      },
    }))
  }

  const checkDuplicateDescription = useCallback(
    (currentId: number, description: string): boolean => {
      if (!description.trim()) return false
      const normalizedDescription = description.trim().toLowerCase()
      return rows.some((row) => row.id !== currentId && row.description?.trim()?.toLowerCase() === normalizedDescription)
    },
    [rows],
  )

  useEffect(() => {
    const hasEmptyDescriptions = rows.some(
      (row) =>
        isEmpty(row.description) &&
        (!isEmpty(row.module) ||
          !isEmpty(row.partNumber) ||
          !isEmpty(row.serialNumber) ||
          !isEmpty(row.limit) ||
          !isEmpty(row.csn) ||
          !isEmpty(row.cyclesRemaining) ||
          !isEmpty(row.csnAtContractDelivery)),
    )
    const hasDuplicates = rows.some((row) => row.description?.trim() && checkDuplicateDescription(row.id, row.description))
    onValidationChange(!hasDuplicates && !hasEmptyDescriptions)
  }, [rows, onValidationChange, checkDuplicateDescription])

  const hasError = (rowId: number, field: string, value: string) => {
    const isTouched = touchedFields[rowId]?.[field]
    const isRequired = field === 'description'

    if (!isTouched && !isAiPopulated) return false

    if (field === 'description') {
      return !value?.trim() || checkDuplicateDescription(rowId, value)
    }

    return isRequired && !value.trim()
  }

  const descriptionHasErrors = rows.some((row) => hasError(row.id, 'description', row.description))

  return (
    <>
      <Table size={'small'} sx={{ width: '100%' }}>
        <TableHead>
          <TableRow sx={{ '& th': { borderBottom: '1px solid rgba(0, 0, 0, 0.12)', backgroundColor: 'transparent' } }}>
            <TableCell sx={{ whiteSpace: 'nowrap', display: 'none' }}>#</TableCell>
            <TableCell className={TABLE_COLUMN_SIZES.full} sx={{ whiteSpace: 'nowrap', alignContent: 'end' }}>
              <span className="flex pl-1">Module</span>
            </TableCell>
            <TableCell className={TABLE_COLUMN_SIZES.full} sx={{ whiteSpace: 'nowrap', alignContent: 'end' }}>
              <span className={cn('flex pl-1', { 'text-red-500': descriptionHasErrors })}>Description *</span>
            </TableCell>
            <TableCell className={TABLE_COLUMN_SIZES.md} sx={{ whiteSpace: 'nowrap', alignContent: 'end' }}>
              <span className="flex pl-1">Part Number</span>
            </TableCell>
            <TableCell className={TABLE_COLUMN_SIZES.md} sx={{ whiteSpace: 'nowrap', alignContent: 'end' }}>
              <span className="flex pl-1">Serial Number</span>
            </TableCell>
            <TableCell className={TABLE_COLUMN_SIZES.sm} sx={{ whiteSpace: 'nowrap', alignContent: 'end' }}>
              <span className="flex pl-1">Limit [FC]</span>
            </TableCell>
            <TableCell className={TABLE_COLUMN_SIZES.sm} sx={{ whiteSpace: 'nowrap', alignContent: 'end' }}>
              <span className="flex pl-1">CSN</span>
            </TableCell>
            <TableCell className={TABLE_COLUMN_SIZES.sm} sx={{ whiteSpace: 'nowrap', alignContent: 'end' }}>
              <span className="flex pl-1">
                Cycles <br /> Remaining
              </span>
            </TableCell>
            <TableCell className={TABLE_COLUMN_SIZES.sm} sx={{ whiteSpace: 'nowrap', alignContent: 'end' }}>
              <span className="flex pl-1">
                CSN At <br /> Contract <br /> Delivery
              </span>
            </TableCell>
            <TableCell width={50} />
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.id} sx={{ '& td': { border: 'none' } }}>
              <TableCell sx={{ display: 'none' }}>{row.id}</TableCell>
              <TableCell className={TABLE_COLUMN_SIZES.full}>
                <TextField fullWidth value={row.module} onChange={(e) => onRowChange(row.id, 'module', e.target.value)} />
              </TableCell>
              <TableCell className={TABLE_COLUMN_SIZES.full}>
                <TextField
                  fullWidth
                  value={row.description}
                  onChange={(e) => onRowChange(row.id, 'description', e.target.value)}
                  onBlur={() => handleBlur(row.id, 'description')}
                  error={!!hasError(row.id, 'description', row.description)}
                />
              </TableCell>
              <TableCell className={TABLE_COLUMN_SIZES.md}>
                <TextField
                  fullWidth
                  value={row.partNumber}
                  onChange={(e) => onRowChange(row.id, 'partNumber', e.target.value)}
                  onBlur={() => handleBlur(row.id, 'partNumber')}
                  error={!!hasError(row.id, 'partNumber', row.partNumber)}
                />
              </TableCell>
              <TableCell className={TABLE_COLUMN_SIZES.md}>
                <TextField
                  fullWidth
                  value={row.serialNumber}
                  onChange={(e) => onRowChange(row.id, 'serialNumber', e.target.value)}
                  onBlur={() => handleBlur(row.id, 'serialNumber')}
                  error={!!hasError(row.id, 'serialNumber', row.serialNumber)}
                />
              </TableCell>
              <TableCell className={TABLE_COLUMN_SIZES.sm}>
                <TextField
                  fullWidth
                  value={row.limit}
                  onBlur={() => handleBlur(row.id, 'limit')}
                  error={!!hasError(row.id, 'limit', row.limit?.toString())}
                  InputProps={{
                    inputComponent: NumericFormat,
                  }}
                  onChange={(e) => onRowChange(row.id, 'limit', e.target.value)}
                />
              </TableCell>
              <TableCell className={TABLE_COLUMN_SIZES.sm}>
                <TextField
                  fullWidth
                  value={row.csn}
                  onBlur={() => handleBlur(row.id, 'csn')}
                  error={!!hasError(row.id, 'csn', row.csn?.toString())}
                  InputProps={{
                    inputComponent: NumericFormat,
                  }}
                  onChange={(e) => onRowChange(row.id, 'csn', e.target.value)}
                />
              </TableCell>
              <TableCell className={TABLE_COLUMN_SIZES.sm}>
                <TextField
                  fullWidth
                  value={row.cyclesRemaining}
                  onBlur={() => handleBlur(row.id, 'cyclesRemaining')}
                  error={!!hasError(row.id, 'cyclesRemaining', row.cyclesRemaining?.toString())}
                  InputProps={{
                    inputComponent: NumericFormat,
                  }}
                  onChange={(e) => onRowChange(row.id, 'cyclesRemaining', e.target.value)}
                />
              </TableCell>
              <TableCell className={TABLE_COLUMN_SIZES.sm}>
                <TextField
                  fullWidth
                  value={row.csnAtContractDelivery}
                  onBlur={() => handleBlur(row.id, 'csnAtContractDelivery')}
                  error={!!hasError(row.id, 'csnAtContractDelivery', row.csnAtContractDelivery?.toString())}
                  InputProps={{
                    inputComponent: NumericFormat,
                  }}
                  onChange={(e) => onRowChange(row.id, 'csnAtContractDelivery', e.target.value)}
                />
              </TableCell>
              <TableCell width={50}>
                {rows.length > 1 && (
                  <IconButton size="small" onClick={() => onDeleteRow(row.id)} sx={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Link
        component="button"
        variant="body2"
        onClick={onAddRow}
        sx={{
          mt: 2,
          textDecoration: 'none',
          '&:hover': {
            textDecoration: 'none',
          },
        }}
      >
        + Add another row
      </Link>
    </>
  )
}
