import { Button, ButtonVariant, IconVariant, Size } from '@flyward/platform/components'
import { type ReportDisplayType, type IEventViewDetailDto } from '@flyward/platform/models'
import EventRow from './EventRow'
import { type ComponentTotalStatisticsAtEvent } from '@flyward/platform/models/entities/ComponentTotalStatisticsAtEvent'
import { isArrayEmptyOrNull } from '@flyward/platform/helpers/arrayHelpers'
import { useState } from 'react'
import { MasterComponentEventsTotal } from './MasterComponentEventsTotal'

export interface MasterComponentWithEvents {
  masterComponentId: string
  eventsOnComponent: IEventViewDetailDto[]
}

interface IMasterComponentEventsRowsProps {
  reportDisplayType: ReportDisplayType
  componentEvents: MasterComponentWithEvents
  componentTotalStatisticsAtEvent: ComponentTotalStatisticsAtEvent[]
  tdStyle: string
  borderAndTextStyle: string
}

const MasterComponentEventsRows = ({ reportDisplayType, componentEvents, componentTotalStatisticsAtEvent, tdStyle, borderAndTextStyle }: IMasterComponentEventsRowsProps) => {
  const [showSiblingComponents, setShowSiblingComponents] = useState(false)

  const hasEnyEvents = componentEvents.eventsOnComponent.some((componentEvent) => componentEvent.isEvent === true)

  if (!hasEnyEvents) {
    return null
  }

  const componentsAffectedByEvent = componentEvents.eventsOnComponent.filter((componentEvent) => componentEvent.isEvent === true)
  const siblingComponents = componentEvents.eventsOnComponent.filter((componentEvent) => componentEvent.isEvent !== true)

  return (
    <>
      {componentsAffectedByEvent.map((componentEvent) => (
        <EventRow
          // eslint-disable-next-line max-len
          key={`${componentEvent.masterComponentId}_${componentEvent.componentId}_${componentEvent.eventType}_${componentEvent.eventId}_${componentEvent.reason}`}
          reportDisplayType={reportDisplayType}
          componentEvent={componentEvent}
          tdStyle={tdStyle}
          borderAndTextStyle={borderAndTextStyle}
        />
      ))}
      {!isArrayEmptyOrNull(siblingComponents) && (
        <Button
          className="absolute left-0 -ml-4 -mt-5"
          leftIcon={showSiblingComponents ? IconVariant.RemoveCircle : IconVariant.AddCircle}
          variant={ButtonVariant.Ghost}
          size={Size.Small}
          onClick={() => {
            setShowSiblingComponents((prev) => !prev)
          }}
        />
      )}
      {showSiblingComponents &&
        siblingComponents.map((componentEvent) => (
          <EventRow
            // eslint-disable-next-line max-len
            key={`${componentEvent.masterComponentId}_${componentEvent.componentId}_${componentEvent.eventType}_${componentEvent.eventId}_${componentEvent.reason}`}
            reportDisplayType={reportDisplayType}
            componentEvent={componentEvent}
            tdStyle={tdStyle}
            borderAndTextStyle={borderAndTextStyle}
            isSiblingComponent
          />
        ))}
      <MasterComponentEventsTotal
        reportDisplayType={reportDisplayType}
        componentEvents={componentEvents}
        componentTotalStatisticsAtEvent={componentTotalStatisticsAtEvent}
        tdStyle={tdStyle}
        borderAndTextStyle={borderAndTextStyle}
      />
    </>
  )
}

export { MasterComponentEventsRows }
