import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import { cn } from '../utils'

export interface SelectOption {
  label: string
  value: string
  isDisabled?: boolean
}

interface StyledSelectProps {
  options: SelectOption[]
  value: string
  onChange: (_value: string) => void
  label: string
  id: string
  className?: string
  disabled?: boolean
}

export const MUISelect = ({ options, value, onChange, label, id, className, disabled }: StyledSelectProps) => {
  const handleChange = (event: SelectChangeEvent<string>) => {
    onChange(event.target.value)
  }

  return (
    <FormControl
      className={cn(
        'w-52',
        '[&_.MuiOutlinedInput-root]:bg-black-0',
        '[&_.MuiOutlinedInput-root]:text-text-1',
        '[&_.MuiOutlinedInput-notchedOutline]:border-black-20',
        '[&_.MuiOutlinedInput-root:hover_.MuiOutlinedInput-notchedOutline]:border-primary',
        '[&_.MuiOutlinedInput-root.Mui-focused_.MuiOutlinedInput-notchedOutline]:border-primary',
        '[&_.MuiInputLabel-root]:text-text-1',
        '[&_.MuiInputLabel-root.Mui-focused]:text-primary',
        '[&_.MuiSelect-icon]:text-text-1',
        className,
      )}
      disabled={disabled}
    >
      <InputLabel id={`${id}-label`}>{label}</InputLabel>
      <Select
        labelId={`${id}-label`}
        id={id}
        value={value}
        label={label}
        onChange={handleChange}
        MenuProps={{
          PaperProps: {
            className: cn(
              'bg-black-0',
              '[&_.MuiMenuItem-root]:text-text-1',
              '[&_.MuiMenuItem-root:hover]:bg-primary-light-2',
              '[&_.MuiMenuItem-root.Mui-selected]:bg-primary',
              '[&_.MuiMenuItem-root.Mui-selected]:text-black-0',
              '[&_.MuiMenuItem-root.Mui-selected:hover]:bg-primary-light-1',
              '[&_.MuiMenuItem-root.Mui-disabled]:text-black-40',
            ),
            style: {
              maxHeight: '24rem',
              overflowY: 'auto',
            },
          },
        }}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value} disabled={option.isDisabled}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
