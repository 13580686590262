import {
  AsyncErrorBoundary,
  ComponentContainer,
  EditableDateCellWithLabelWithLabel,
  EditableInputCellWithLabelWithLabel,
  EditableSelectCellWithLabel,
  EnvironmentalImpactTypeOptions,
  InputType,
  TextCellWithLabel,
  getAssetAge,
} from '@flyward/platform'
import { type Control, type UseFormGetValues, type UseFormSetValue } from 'react-hook-form'
import { type AircraftDetailsDto } from '../../../../models/DTOs/aircraftDetailsDto'

interface IAircraftEditableDetailsProps {
  aircraft: AircraftDetailsDto
  formControl: Control<AircraftDetailsDto, unknown>
  setFormValue: UseFormSetValue<AircraftDetailsDto>
  getAircraftFormValues: UseFormGetValues<AircraftDetailsDto>
}

export const AircraftEditableDetails = ({ aircraft, formControl, setFormValue, getAircraftFormValues }: IAircraftEditableDetailsProps) => {
  const updateFhFcRatio = () => {
    const averageMonthlyFlightHours = getAircraftFormValues().averageMonthlyFlightHours
    const averageMonthlyFlightCycles = getAircraftFormValues().averageMonthlyFlightCycles

    const newFhFCRation = Number((averageMonthlyFlightHours / averageMonthlyFlightCycles).toFixed(2))
    setFormValue('averageFhFcRatio', newFhFCRation, { shouldDirty: true, shouldTouch: true })
  }

  return (
    <ComponentContainer className="p-6">
      <AsyncErrorBoundary>
        <div className="flex w-full flex-col">
          <p className="mb-4 w-full border-b border-primary-light-2 pb-2 text-base font-semibold text-text-1">Aircraft</p>
          <div className="flex flex-col gap-x-6 gap-y-2">
            <div className="flex gap-x-6">
              <EditableInputCellWithLabelWithLabel className="basis-1/6" label="Manufacturer" formControl={formControl} fieldName={`manufacturer`} />
              <EditableInputCellWithLabelWithLabel className="basis-1/6" label="Aircraft Type" formControl={formControl} fieldName={`aircraftType`} />
              <EditableInputCellWithLabelWithLabel className="basis-1/6" label="MSN" formControl={formControl} fieldName={`serialNumber`} />
              <EditableInputCellWithLabelWithLabel
                className="basis-1/6"
                label="Registration"
                formControl={formControl}
                fieldName={`registrationNo`}
              />
              <div className="mr-6 basis-2/6"></div>
            </div>
            <div className="flex gap-x-6">
              <EditableInputCellWithLabelWithLabel className="basis-1/6" label="Engine Model" formControl={formControl} fieldName={`engineModel`} />
              <EditableInputCellWithLabelWithLabel
                inputType={InputType.NaturalNumber}
                className="basis-1/6"
                label="Engine Thrust rating"
                formControl={formControl}
                fieldName={`engineThrustRating`}
              />
              <EditableDateCellWithLabelWithLabel
                className="basis-1/6"
                label="DOM"
                formControl={formControl}
                fieldName={`dom`}
                allowClear={false}
                setFormValue={setFormValue}
              />
              <TextCellWithLabel className="basis-1/6" label="Aircraft Age" info={getAssetAge(aircraft.dom)} />
              <EditableDateCellWithLabelWithLabel
                className="basis-1/6"
                label="Lease Start Date"
                formControl={formControl}
                fieldName={`leaseStartDate`}
                setFormValue={setFormValue}
              />
              <EditableDateCellWithLabelWithLabel
                className="basis-1/6"
                label="Lease End Date"
                formControl={formControl}
                fieldName={`leaseEndDate`}
                setFormValue={setFormValue}
              />
            </div>
            <div className="flex gap-x-6">
              <EditableDateCellWithLabelWithLabel
                className="basis-1/6"
                label="Tech Spec. Date"
                formControl={formControl}
                fieldName={`dateOfLastTechSpec`}
                allowClear={false}
                setFormValue={setFormValue}
              />
              <EditableInputCellWithLabelWithLabel className="basis-1/6" label="Operator/Lessee" formControl={formControl} fieldName={`lessee`} />
              <EditableInputCellWithLabelWithLabel
                className="basis-1/6"
                label="Lessee Habitual Base"
                formControl={formControl}
                fieldName={`lesseeHabitualBase`}
              />
              <EditableSelectCellWithLabel
                className="basis-1/6"
                label="Operating Environment"
                options={EnvironmentalImpactTypeOptions}
                formControl={formControl}
                fieldName={`operatingEnvironment`}
              />
              <EditableInputCellWithLabelWithLabel className="basis-1/6" label="Region" formControl={formControl} fieldName={`region`} />
              <EditableInputCellWithLabelWithLabel className="basis-1/6" label="Portfolio" formControl={formControl} fieldName={`portfolio`} />
            </div>
            <div className="flex gap-x-6">
              <EditableInputCellWithLabelWithLabel
                inputType={InputType.NaturalNumber}
                className="basis-1/6"
                label="Average Monthly Flight Hours"
                formControl={formControl}
                fieldName={`averageMonthlyFlightHours`}
                afterChange={(_newValue) => {
                  updateFhFcRatio()
                }}
              />

              <EditableInputCellWithLabelWithLabel
                inputType={InputType.NaturalNumber}
                className="basis-1/6"
                label="Average Monthly Flight Cycles"
                formControl={formControl}
                fieldName={`averageMonthlyFlightCycles`}
                afterChange={(_newValue) => {
                  updateFhFcRatio()
                }}
              />

              <EditableInputCellWithLabelWithLabel
                isReadOnly={true}
                inputType={InputType.Decimal}
                className="basis-1/6"
                label="Average FH/FC Ratio"
                formControl={formControl}
                fieldName={`averageFhFcRatio`}
              />

              <EditableInputCellWithLabelWithLabel
                inputType={InputType.NaturalNumber}
                className="basis-1/6"
                label="Average Monthly APU Hours"
                formControl={formControl}
                fieldName={`averageMonthlyApuHours`}
              />
              <div className="mr-6 basis-2/6"></div>
            </div>
          </div>
        </div>
      </AsyncErrorBoundary>
    </ComponentContainer>
  )
}
