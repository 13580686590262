import { ActionDialog, Button as CnButton, ButtonVariant, IconVariant, type IYearMonth } from '@flyward/platform/components'
import { YearMonth } from '@flyward/platform/models'
import { Button as MuiButton } from '@mui/material'
import { Edit } from '@mui/icons-material'
import { type Row } from '@tanstack/react-table'
import { useState } from 'react'
import { type IMaintenanceScheduleDisplay } from '../../../../../models/entities'
import { type EventsScheduleTableInput } from '../../models'

const EditEventWizard = (reportItemsSchedules: EventsScheduleTableInput[], isEditable: boolean, row: Row<IMaintenanceScheduleDisplay>) => {
  const reportItemSchedule = reportItemsSchedules.find((r) => r.reportItemId === row.original.reportItemId)!

  const startDate = reportItemSchedule.flyForwardParameters.commonAssetDetails?.assetDetailsSnapshot.dateOfLastTechSpec
  const endDate = reportItemSchedule.flyForwardParameters.endDate

  const assetType = reportItemSchedule.flyForwardParameters.assetType
  const reportItemId = reportItemSchedule.reportItemId
  const index = reportItemSchedule.assetIdWithIndex.index

  const startYearMonth = YearMonth.fromISOString(startDate!)
  const endYearMonth = YearMonth.fromISOString(endDate)

  const editedMasterComponentId = row.original.forecastedCheck.masterComponentId ?? row.original.forecastedCheck.componentId
  const currentCheckType = row.original.forecastedCheck.checkType
  const originalYearMonth = row.original.yearMonth as IYearMonth

  const [isEditClicked, setIsEditClicked] = useState<boolean>(false)
  const [userConfirmedRisk, setUserConfirmedRisk] = useState<boolean>(false)

  return (
    <>
      <>
        {isEditable && (
          <MuiButton
            onClick={() => {
              setIsEditClicked(true)
            }}
          >
            <Edit />
          </MuiButton>
        )}
      </>

      {!userConfirmedRisk && (
        <ActionDialog
          isValid={true}
          confirmBtnLabel="Continue"
          key={`confirm-risk-${editedMasterComponentId}-${currentCheckType}-${originalYearMonth.year}-${originalYearMonth.month}`}
          onConfirm={async () => {
            setUserConfirmedRisk(true)
          }}
          isOpen={isEditClicked}
          setIsOpen={setIsEditClicked}
          onCancel={() => {
            setUserConfirmedRisk(false)
          }}
          dialogContent={
            <p className="font-semibold">
              If you make changes, they will affect the entire process.
              <br />
              Are you sure you want to continue?
            </p>
          }
        />
      )}

      {userConfirmedRisk && <></>}
    </>
  )
}

export { EditEventWizard }
