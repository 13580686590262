import { useEffect, useState } from 'react'
import { Box, Button, TextField, Typography } from '@mui/material'
import { Label } from '../common/Label'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { LLPTable } from './LLPTable'
import { NumericFormat } from 'react-number-format'

const MIN_VALUE = 1
const MAX_VALUE = 1000000000

const performanceSchema = z.object({
  firstRunLimit: z
    .number({
      required_error: 'First Run Limit is required',
    })
    .min(MIN_VALUE, 'First Run Limit must be at least 1')
    .max(MAX_VALUE, 'First Run Limit cannot exceed 1,000,000,000'),
  firstRunCost: z
    .number({
      required_error: 'First Run Cost is required',
    })
    .min(MIN_VALUE, 'Mature Cost must be at least 1')
    .max(MAX_VALUE, 'Mature Cost cannot exceed 1,000,000,000'),
  lastRunLimit: z
    .number({
      required_error: 'Mature Limit is required',
    })
    .min(MIN_VALUE, 'Mature Limit must be at least 1')
    .max(MAX_VALUE, 'Mature Limit cannot exceed 1,000,000,000'),
  lastRunCost: z.number().min(MIN_VALUE).max(MAX_VALUE).optional().nullable(),
})

type PerformanceFormData = z.infer<typeof performanceSchema>

const EmptyRow = {
  id: 0,
  description: '',
  module: '',
  limit: '',
  clp: '',
} as const

interface TableRowData {
  id: number
  description: string
  module: string
  limit: string
  clp: string
}

interface PerformanceAndLLPProps {
  onNext: (data: {
    firstRunLimit: number
    firstRunCost: number
    lastRunLimit: number // renamed from matureLimit to match state
    lastRunCost: number | null // renamed from cost to match state
    llpReplacements: Array<{
      description: string
      module?: string
      limit: number
      clp: number
    }>
  }) => void
  onBack: () => void | undefined
  stepTitle: string
  initialData?: {
    firstRunLimit?: number
    firstRunCost?: number
    lastRunLimit?: number
    lastRunCost?: number | null
    llpReplacements: Array<{
      description: string
      module?: string
      limit: number
      clp: number
    }>
  }
}

const PerformanceAndLLP = ({ onNext, onBack, stepTitle, initialData }: PerformanceAndLLPProps) => {
  const [tableRows, setTableRows] = useState<TableRowData[]>([{ ...EmptyRow, id: 1 }])
  const [isLLPTableValid, setIsLLPTableValid] = useState(true)

  const {
    formState: { errors, touchedFields, isValid },
    setValue,
    trigger,
    watch,
  } = useForm<PerformanceFormData>({
    resolver: zodResolver(performanceSchema),
    mode: 'all',
    defaultValues: {
      firstRunLimit: initialData?.firstRunLimit,
      firstRunCost: initialData?.firstRunCost,
      lastRunLimit: initialData?.lastRunLimit,
      lastRunCost: initialData?.lastRunCost ?? null,
    },
  })
  const values = watch()

  const isRequiredFieldsFilled = Boolean(values.firstRunLimit && values.firstRunCost && values.lastRunLimit)

  const isRowComplete = (row: TableRowData) => Boolean(row.description && row.limit && row.clp)

  const isRowPartiallyFilled = (row: TableRowData) => {
    const hasAnyValue = Boolean(row.description || row.limit || row.clp)
    return hasAnyValue && !isRowComplete(row)
  }

  const hasAtLeastOneLLP = tableRows.some(isRowComplete)
  const hasIncompleteRows = tableRows.some(isRowPartiallyFilled)

  const isButtonDisabled = !isRequiredFieldsFilled || !isValid || !hasAtLeastOneLLP || hasIncompleteRows || !isLLPTableValid

  // Populate LLP replacements from initialData
  useEffect(() => {
    if (initialData?.llpReplacements.length) {
      const populatedRows = initialData.llpReplacements.map((replacement, index) => ({
        id: index + 1,
        description: replacement.description,
        module: replacement.module ?? '',
        limit: replacement.limit.toString(),
        clp: replacement.clp.toString(),
      }))
      setTableRows(populatedRows)
    }
  }, [initialData])

  const handleAddRow = () => {
    setTableRows([...tableRows, { ...EmptyRow, id: tableRows.length + 1 }])
  }

  const handleRowChange = (id: number, field: keyof TableRowData, value: string) => {
    setTableRows(
      tableRows.map((row) => {
        if (row.id === id) {
          return { ...row, [field]: value }
        }
        return row
      }),
    )
  }

  const handleDeleteRow = (id: number) => {
    // Don't allow deleting if there's only one row
    if (tableRows.length === 1) {
      return
    }

    setTableRows(tableRows.filter((row) => row.id !== id))
  }

  const handleContinue = async () => {
    const isValidForm = await trigger()
    if (isValidForm) {
      const formattedLLPReplacements = tableRows
        .filter((row) => row.description && row.limit && row.clp)
        .map((row) => ({
          description: row.description,
          module: row.module || undefined,
          limit: Number(row.limit),
          clp: Number(row.clp),
        }))

      onNext({
        firstRunLimit: values.firstRunLimit,
        firstRunCost: values.firstRunCost,
        lastRunLimit: values.lastRunLimit,
        lastRunCost: values.lastRunCost ?? null,
        llpReplacements: formattedLLPReplacements,
      })
    }
  }

  const getErrorMessage = (fieldName: keyof PerformanceFormData) => {
    if (!touchedFields[fieldName]) return ''
    return errors[fieldName]?.message ?? ''
  }

  return (
    <div className="shadow-sm rounded-lg bg-white p-6">
      <Typography variant="h6" sx={{ mb: 3 }}>
        {stepTitle}
      </Typography>

      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(2, 1fr)',
          gap: 2,
          mb: 4,
        }}
        className="w-150"
      >
        <Box sx={{ gridColumn: '1 / 3', display: 'flex', flexDirection: 'column', gap: 1 }}>
          <Label label="Event Type" value="PR" />
          <Label label="Unit" value="Flight Hours" />
        </Box>
        <Box sx={{ gridColumn: '2 / 3' }}></Box>

        <NumericFormat
          customInput={TextField}
          label="First Run Limit *"
          value={values.firstRunLimit ?? ''}
          error={touchedFields.firstRunLimit && (!values.firstRunLimit || !!errors.firstRunLimit)}
          helperText={getErrorMessage('firstRunLimit')}
          thousandSeparator
          decimalScale={0}
          allowNegative={false}
          isAllowed={({ floatValue }) => floatValue === undefined || (floatValue >= MIN_VALUE && floatValue <= MAX_VALUE)}
          onValueChange={({ floatValue }) => {
            setValue('firstRunLimit', floatValue!, {
              shouldValidate: true,
              shouldTouch: true,
            })
          }}
          onBlur={() => {
            if (!touchedFields.firstRunLimit) {
              setValue('firstRunLimit', values.firstRunLimit, {
                shouldTouch: true,
                shouldValidate: true,
              })
            }
          }}
        />

        <NumericFormat
          customInput={TextField}
          label="First Run Cost *"
          value={values.firstRunCost ?? ''}
          error={touchedFields.firstRunCost && (!values.firstRunCost || !!errors.firstRunCost)}
          helperText={getErrorMessage('firstRunCost')}
          thousandSeparator
          prefix="$"
          decimalScale={2}
          allowNegative={false}
          isAllowed={({ floatValue }) => floatValue === undefined || (floatValue >= MIN_VALUE && floatValue <= MAX_VALUE)}
          onValueChange={({ floatValue }) => {
            setValue('firstRunCost', floatValue!, {
              shouldValidate: true,
              shouldTouch: true,
            })
          }}
          onBlur={() => {
            if (!touchedFields.firstRunCost) {
              setValue('firstRunCost', values.firstRunCost, {
                shouldTouch: true,
                shouldValidate: true,
              })
            }
          }}
        />

        <NumericFormat
          customInput={TextField}
          label="Mature Limit *"
          value={values.lastRunLimit ?? ''}
          error={touchedFields.lastRunLimit && (!values.lastRunLimit || !!errors.lastRunLimit)}
          helperText={getErrorMessage('lastRunLimit')}
          thousandSeparator
          decimalScale={0}
          allowNegative={false}
          isAllowed={({ floatValue }) => floatValue === undefined || (floatValue >= MIN_VALUE && floatValue <= MAX_VALUE)}
          onValueChange={({ floatValue }) => {
            setValue('lastRunLimit', floatValue!, {
              shouldValidate: true,
              shouldTouch: true,
            })
          }}
          onBlur={() => {
            if (!touchedFields.lastRunLimit) {
              setValue('lastRunLimit', values.lastRunLimit, {
                shouldTouch: true,
                shouldValidate: true,
              })
            }
          }}
        />

        <NumericFormat
          customInput={TextField}
          label="Mature Cost"
          error={touchedFields.lastRunCost && !!errors.lastRunCost}
          value={values.lastRunCost ?? ''}
          helperText={getErrorMessage('lastRunCost')}
          thousandSeparator
          prefix="$"
          decimalScale={2}
          allowNegative={false}
          isAllowed={({ floatValue }) => floatValue === undefined || (floatValue >= MIN_VALUE && floatValue <= MAX_VALUE)}
          onValueChange={({ floatValue }) => {
            setValue('lastRunCost', floatValue ?? null, {
              shouldValidate: true,
              shouldTouch: true,
            })
          }}
          onBlur={() => {
            if (!touchedFields.lastRunCost) {
              setValue('lastRunCost', values.lastRunCost ?? null, {
                shouldTouch: true,
                shouldValidate: true,
              })
            }
          }}
        />
      </Box>

      <Box sx={{ mb: 4 }}>
        <Label label="Event Type" value="LLP Replacement" />
        <LLPTable rows={tableRows} onRowChange={handleRowChange} onAddRow={handleAddRow} onDeleteRow={handleDeleteRow} onValidationChange={setIsLLPTableValid} />
      </Box>

      <Box>
        <Button variant="contained" onClick={handleContinue} disabled={isButtonDisabled} sx={{ mr: 1 }}>
          Continue
        </Button>
        <Button variant="text" onClick={onBack}>
          Back
        </Button>
      </Box>
    </div>
  )
}

export { PerformanceAndLLP }
