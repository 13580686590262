import { type CreateAirframeProgramDto } from '@flyward/knowledgeBase/models/DTOs/Create/Airframe'
import { useCreateAirframeMaintenanceProgramMutation } from '@flyward/knowledgeBase/store/api/airframeMaintenancePrograms'
import { ROUTES } from '@flyward/main-app/providers/routes'
import type { AirframeCheckType } from '@flyward/platform'
import { ComponentType } from '@flyward/platform/models/enums/ComponentType'
import { AirframeCheckTypeDisplay, NumberDisplay, NumberDisplayType } from '@flyward/platform'
import { formatAxiosErrorMessage } from '@flyward/platform/helpers/ErrorHelpers'
import { showError, showSuccess } from '@flyward/platform/services'
import type { TRootState } from '@flyward/platform/store/configureReducers'
import { Box, Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import { isNil } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { resetWizard } from '../../store/wizardSlice'
import { Label } from '../common/Label'

interface ReviewAndPublishProps {
  onNext: () => void
  onBack: () => void
  stepTitle: string
}

export const ReviewAndPublish: React.FC<ReviewAndPublishProps> = ({ onBack, stepTitle }) => {
  const wizardState = useSelector((state: TRootState) => state.wizard[ComponentType.Airframe])
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [createAirframeMaintenanceProgram] = useCreateAirframeMaintenanceProgramMutation()

  const onPublish = () => {
    const programToCreate: CreateAirframeProgramDto = {
      programName: wizardState.general.name,
      manufacturer: wizardState.general.manufacturer,
      componentModel: wizardState.general.model,
      aircraftSeries: wizardState.general.series,
      aircraftType: wizardState.general.aircraft,
      baseYear: wizardState.general.baseYear,
      basicAssumptions: {
        downtimeMonths: wizardState.checksEscalationsAssumptions.checkDowntime,
        monthsDelayInPaymentOnClaims:
          isNil(wizardState.checksEscalationsAssumptions.delayInPayment) || wizardState.checksEscalationsAssumptions.delayInPayment === ''
            ? 0
            : wizardState.checksEscalationsAssumptions.delayInPayment,
      },
      checks: wizardState.checksEscalationsAssumptions.checks.map((check: { checkType: AirframeCheckType; limit: number; cost: number }) => ({
        checkType: Number(check.checkType),
        limit: check.limit,
        cost: check.cost,
      })),
      escalationPct: wizardState.checksEscalationsAssumptions.escalation,
    }

    createAirframeMaintenanceProgram({
      program: programToCreate,
    })
      .then(() => {
        dispatch(resetWizard())
        navigate(ROUTES.MAINTENANCE_PROGRAMS.AMP)
        showSuccess(<span>The maintenance programme was successfully added.</span>)
      })
      .catch((error) => {
        showError(formatAxiosErrorMessage(JSON.stringify(error?.response)))
      })
  }

  const flexBoxStyles = {
    display: 'flex',
    flexDirection: 'row',
    gap: 2,
    flexWrap: 'wrap',
    '& > *': {
      flex: '1 1 calc(25% - 16px)',
      minWidth: '200px',
    },
  }

  return (
    <div className="shadow-sm rounded-lg bg-white p-6">
      <Typography variant="h6" sx={{ mb: 3 }}>
        {stepTitle}
      </Typography>

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, mb: 4 }}>
        <div className="mb-2 text-lg font-semibold">1. General</div>
        <Box sx={flexBoxStyles}>
          <Label label="Programme Name" value={wizardState.general.name} />
          <Label label="Manufacturer" value={wizardState.general.manufacturer} />
          <Label label="Model" value={wizardState.general.model} />
          <Label label="Aircraft" value={wizardState.general.aircraft} />
          <Label label="Series" value={wizardState.general.series || '-'} />
          <Label label="Base Year" value={wizardState.general.baseYear} />
          <span></span>
          <span></span>
        </Box>
      </Box>

      {wizardState.checksEscalationsAssumptions.checks.length > 0 && (
        <>
          <div className="mb-2 mt-4 text-lg font-semibold">2. Checks - Escalations and Basic Assumptions</div>
          <Typography variant="subtitle2" sx={{ mb: 1 }}>
            Checks
          </Typography>
          <TableContainer component={Paper} sx={{ mb: 4 }}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: 600 }}>Event Type</TableCell>
                  <TableCell sx={{ fontWeight: 600 }}>Unit</TableCell>
                  <TableCell sx={{ fontWeight: 600, textAlign: 'right' }}>Limit</TableCell>
                  <TableCell sx={{ fontWeight: 600, textAlign: 'right' }}>Cost</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {wizardState.checksEscalationsAssumptions.checks.map(
                  (
                    {
                      checkType,
                      limit,
                      cost,
                    }: {
                      checkType: AirframeCheckType
                      limit: number
                      cost: number
                    },
                    index: number,
                  ) => (
                    <TableRow key={index}>
                      <TableCell>{AirframeCheckTypeDisplay(Number(checkType))}</TableCell>
                      <TableCell>{'Months'}</TableCell>
                      <TableCell sx={{ textAlign: 'right' }}>
                        <NumberDisplay value={limit} displayType={NumberDisplayType.Integer} />
                      </TableCell>
                      <TableCell sx={{ textAlign: 'right' }}>
                        <NumberDisplay value={cost} displayType={NumberDisplayType.CurrencyRounded} />
                      </TableCell>
                    </TableRow>
                  ),
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}

      {/* Adjustments & Cost Growth Section */}
      <Typography variant="subtitle2" sx={{ mb: 1 }}>
        Escalation
      </Typography>
      <Box sx={flexBoxStyles} className="mb-4">
        <Label label="Percentage" value={`${wizardState.checksEscalationsAssumptions.escalation ?? 0}%`} />
      </Box>

      {/* Basic Assumptions Section */}
      <Typography variant="subtitle2" sx={{ mb: 1 }}>
        Basic Assumptions
      </Typography>
      <Box sx={flexBoxStyles}>
        <Label label="Check Downtime (months)" value={wizardState.checksEscalationsAssumptions.checkDowntime} />
        <Label label="Delay in Payment (months)" value={wizardState.checksEscalationsAssumptions.delayInPayment || 0} />
        <span></span>
        <span></span>
      </Box>

      <Box sx={{ mt: 4 }}>
        <Button
          variant="contained"
          onClick={() => {
            onPublish()
          }}
          sx={{ mr: 1 }}
        >
          Publish
        </Button>
        <Button variant="text" onClick={onBack}>
          Back
        </Button>
      </Box>
    </div>
  )
}
