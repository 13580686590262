import { type IYearMonth } from '@flyward/platform'
import { type IMaintenanceScheduleDisplay, type MaintenanceSchedule } from '../../../models/entities/MaintenanceSchedule'
import { addMonths } from 'date-fns'

export const formatAssetSchedule = (
  scheduleData: MaintenanceSchedule[] | undefined,
  endDate: string | undefined,
  assetSerialNumber: string | undefined,
  reportItemId: string | undefined,
  useAllEvents: boolean | undefined = false,
): IMaintenanceScheduleDisplay[] => {
  if (scheduleData === undefined) {
    return []
  }
  try {
    const maintenanceScheduleDisplayData = scheduleData
      .map((schedule) => {
        const events = useAllEvents ? schedule.events : schedule.distinctEvents
        const eventsUntilLeaseEndDate = events.filter((event) => {
          return (
            new Date((event.yearMonth as IYearMonth).year, (event.yearMonth as IYearMonth).month, 1).getTime() <=
            addMonths(new Date(endDate ?? ''), 1).getTime()
          )
        })
        const maintenanceScheduleDisplays: IMaintenanceScheduleDisplay[] = eventsUntilLeaseEndDate.map((event) => {
          return {
            ...event,
            assetId: schedule.assetId,
            componentId: schedule.parentComponentId,
            componentSerialNumber: schedule.parentComponentSerialNumber,
            assetSerialNumber: assetSerialNumber ?? '',
            reportItemId: reportItemId ?? '',
          }
        })
        return maintenanceScheduleDisplays
      })
      .flat()
    return maintenanceScheduleDisplayData
  } catch (error) {
    return []
  }
}
