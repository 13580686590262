import {
  type CheckType,
  CheckTypeDisplay,
  NumberDisplay,
  NumberDisplayType,
  type TableColumn,
  type UtilizationUnits,
  UtilizationUnitsDisplay,
} from '@flyward/platform'
import { MaintenanceProgramDetailsTable } from '../MaintenanceProgramDetailsTable'

export interface ChecksDisplay {
  checkId: string
  checkType: CheckType
  utilizationUnit: UtilizationUnits
  utilization: number
  baseCost: number
}

interface IChecksReadOnlyTableProps {
  checks: ChecksDisplay[] | undefined
  tableClassName?: string
}

const tableColumns: Array<TableColumn<ChecksDisplay>> = [
  {
    accessor: 'checkType',
    isIdField: true,
    title: 'Event Type',
    valueGetter: (value) => {
      return CheckTypeDisplay(value as CheckType)
    },
    cellClassName: 'basis-6/24',
    headerClassName: 'basis-6/24',
  },
  {
    accessor: 'utilizationUnit',
    title: 'Unit',
    valueGetter: (value) => {
      return UtilizationUnitsDisplay(value as UtilizationUnits)
    },
    cellClassName: 'basis-6/24',
    headerClassName: 'basis-6/24',
  },
  {
    accessor: 'utilization',
    title: 'Limit',
    valueGetter: (value) => <NumberDisplay value={value} />,
    cellClassName: 'basis-6/24',
    headerClassName: 'basis-6/24',
  },
  {
    accessor: 'baseCost',
    title: 'Cost',
    valueGetter: (value) => <NumberDisplay displayType={NumberDisplayType.Currency} value={value} />,
    cellClassName: 'basis-6/24',
    headerClassName: 'basis-6/24',
  },
]

export const ChecksReadOnlyTable = ({ checks, tableClassName = '' }: IChecksReadOnlyTableProps): React.ReactElement<IChecksReadOnlyTableProps> => {
  return <MaintenanceProgramDetailsTable existingColumns={tableColumns} existingData={checks} tableClassName={tableClassName} />
}
