import { type IndexedAssetWithReportItem } from '@flyward/forecasts/models'
import { cn } from '@flyward/platform'

interface IReportItemAssets {
  reportItemAssetBase: IndexedAssetWithReportItem[]
  onAssetSelect: (reportItemId: string, index: number) => void
  selectedAsset: string | null
  isDisabled?: boolean
}

export const ReportItemAssets = ({ reportItemAssetBase: assetsData, onAssetSelect, selectedAsset, isDisabled = false }: IReportItemAssets) => {
  const assetText = (reportAsset: IndexedAssetWithReportItem) => {
    if (reportAsset.index > 0) {
      return `${reportAsset.assetSerialNumber}(${reportAsset.index})`
    }

    return `${reportAsset.assetSerialNumber}`
  }

  return (
    <div className="flex w-full flex-row flex-wrap items-center rounded-lg bg-black-0">
      {assetsData.map((asset) => {
        const isSelected = selectedAsset === asset.reportItemId

        return (
          <div
            key={asset.reportItemId}
            className={cn(
              'group m-1 flex cursor-pointer flex-row items-center gap-0.5 rounded-lg px-4 py-2.5 hover:bg-[#C9DEE0]',
              isSelected ? 'bg-primary' : 'bg-transparent',
              isDisabled && 'cursor-not-allowed bg-[#C9DEE0]',
            )}
            data-testid="asset-selection-badge-container"
            onClick={() => {
              !isDisabled && onAssetSelect(asset.reportItemId, asset.index)
            }}
            role="listitem"
          >
            <span
              className={cn('w-20 truncate text-xs font-semibold', isSelected ? 'text-black-0' : 'text-primary', isDisabled && 'italic text-primary')}
              data-testid="asset-selection-badge-text"
            >
              {assetText(asset)}
            </span>
          </div>
        )
      })}
    </div>
  )
}
