import { closestCenter, DndContext, DragOverlay, KeyboardSensor, PointerSensor, useSensor, useSensors, type DragEndEvent, type DragOverEvent } from '@dnd-kit/core'
import { arraySwap, SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { type AirframeCheckType, AlignmentStatus, type RowSizeMap } from '@flyward/platform'
import { isNil } from 'lodash'
import { useState } from 'react'
import { type UseFormSetValue, type Control } from 'react-hook-form'
import { type DraggableAirframeCheck, type DraggableAirframeChecksStack } from '../../../../../../../models'
import { type VerifyAirframeCheckResultDto } from '../../../../../../../models/aircraftComponents/airframe/verify/VerifyAirframeCheckResultDto'
import { AssetAirframeCheckRow } from './AsseAirframeCheckRow'

interface IAssetAirframeChecksRowsProps {
  verifyAirframeChecksResult: VerifyAirframeCheckResultDto[]
  componentFormValues: DraggableAirframeChecksStack
  setComponentFormValues: UseFormSetValue<DraggableAirframeChecksStack>
  formControl: Control<DraggableAirframeChecksStack, unknown>
  onDeleteExistingAirframeCheck: (_AirframeCheckId: string) => void
  onExistingAirframeCheckCopyFromKb: (_positionalIndex: number, AirframeCheck: DraggableAirframeCheck) => void
  persistAssetLllStackInForm: (_itemAirframeCheckStack: DraggableAirframeCheck[]) => void
  kbTotalCount: number
  columnSizes: RowSizeMap
}

const AssetAirframeChecksRows = ({
  verifyAirframeChecksResult,
  componentFormValues,
  setComponentFormValues,
  formControl,
  onDeleteExistingAirframeCheck,
  onExistingAirframeCheckCopyFromKb,
  persistAssetLllStackInForm,
  kbTotalCount,
  columnSizes,
}: IAssetAirframeChecksRowsProps) => {
  const assetAirframeChecks: DraggableAirframeCheck[] = componentFormValues.airframeChecks
  const [draggedIndex, setDraggedIndex] = useState<number | null>(null)
  const [overIndex, setOverIndex] = useState<number | null>(null)

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  )

  const handleDragCancel = () => {
    setDraggedIndex(null)
    setOverIndex(null)
  }

  const handleDragStart = (event: DragEndEvent) => {
    setDraggedIndex(Number(event.active.id))
  }

  const handleDragOver = (event: DragOverEvent) => {
    const overId = event.over?.id
    if (!isNil(overId)) {
      setOverIndex(Number(overId))
    }
  }

  const updateAirframeChecksStatus = ({
    reorderedBackingFields,
    fromIndex,
    toIndex,
  }: {
    reorderedBackingFields: DraggableAirframeCheck[]
    fromIndex: number
    toIndex: number
  }): void => {
    const successStatuses = [AlignmentStatus.Success, AlignmentStatus.SuggestedAlignment]

    const fromAirframeCheck = assetAirframeChecks[fromIndex]
    const toAirframeCheck = assetAirframeChecks[toIndex]
    const verifyFromAirframeCheck = verifyAirframeChecksResult[fromIndex]
    const verifyToAirframeCheck = verifyAirframeChecksResult[toIndex]

    const fromStatus = fromAirframeCheck.alignmentStatus
    const toStatus = toAirframeCheck.alignmentStatus

    const fromAirframeCheckMatchesKb =
      !isNil(verifyToAirframeCheck?.kbCheck?.checkType) &&
      fromAirframeCheck?.airframeCheck?.checkType === (verifyToAirframeCheck?.kbCheck?.checkType as unknown as AirframeCheckType)
    const toAirframeCheckMatchesKb =
      !isNil(verifyFromAirframeCheck?.kbCheck?.checkType) &&
      toAirframeCheck?.airframeCheck?.checkType === (verifyFromAirframeCheck?.kbCheck?.checkType as unknown as AirframeCheckType)

    /**
     * IF AirframeCheck is placed in the correct position(matches KB AirframeCheck description) -> set it to success
     * ELSE
     *    IF AirframeCheck already placed correctly or SuggestedAlignment, when moved to another position -> set it to ToAlignManually
     *    ELSE -> leave the status as it is because the entire AirframeCheck row is being swapped and statuses will be switched anyways by arraySwap()
     */

    if (fromAirframeCheckMatchesKb) {
      reorderedBackingFields[fromIndex].alignmentStatus = AlignmentStatus.Success
    } else {
      reorderedBackingFields[fromIndex].alignmentStatus = successStatuses.includes(fromStatus) ? AlignmentStatus.ToAlignManually : fromStatus
    }

    if (toAirframeCheckMatchesKb) {
      reorderedBackingFields[toIndex].alignmentStatus = AlignmentStatus.Success
    } else {
      reorderedBackingFields[toIndex].alignmentStatus = successStatuses.includes(toStatus) ? AlignmentStatus.ToAlignManually : toStatus
    }
  }

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event

    if (active.id !== over?.id) {
      const fromIndex = Number(active.id)
      const toIndex = Number(over?.id)

      let reorderedAssetAirframeChecks = assetAirframeChecks

      updateAirframeChecksStatus({ reorderedBackingFields: reorderedAssetAirframeChecks, fromIndex, toIndex })

      // swap statuses by default
      reorderedAssetAirframeChecks = arraySwap(assetAirframeChecks, fromIndex, toIndex)

      persistAssetLllStackInForm(reorderedAssetAirframeChecks)
    }
    setDraggedIndex(null)
    setOverIndex(null)
  }

  const DraggedElement = ({ draggedIndex }: { draggedIndex: number }) => {
    const formAirframeCheck = assetAirframeChecks[Number(draggedIndex)]
    const AirframeCheckStatus = formAirframeCheck.alignmentStatus
    const pathPrefix = `airframeChecks.${draggedIndex}.airframeCheck`

    return (
      <div className="-mt-1 opacity-100">
        <AssetAirframeCheckRow
          kbTotalCount={kbTotalCount}
          positionalIndex={Number(draggedIndex)}
          formAirframeCheck={assetAirframeChecks[Number(draggedIndex)]}
          airframeCheckStatus={AirframeCheckStatus}
          formControl={formControl}
          setComponentFormValues={setComponentFormValues}
          onDeleteExistingAirframeCheck={onDeleteExistingAirframeCheck}
          onExistingAirframeCheckCopyFromKb={onExistingAirframeCheckCopyFromKb}
          pathPrefix={pathPrefix}
          columnSizes={columnSizes}
        />
      </div>
    )
  }

  return (
    <tbody>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        onDragCancel={handleDragCancel}
        onDragOver={handleDragOver}
      >
        <SortableContext items={assetAirframeChecks.map((_item, index) => index)} strategy={verticalListSortingStrategy}>
          {assetAirframeChecks.map((formAirframeCheck, positionalIndex) => {
            const AirframeCheckStatus = formAirframeCheck.alignmentStatus

            const pathPrefix = `airframeChecks.${positionalIndex}.airframeCheck`

            return (
              <AssetAirframeCheckRow
                key={pathPrefix}
                kbTotalCount={kbTotalCount}
                positionalIndex={positionalIndex}
                formAirframeCheck={formAirframeCheck}
                setComponentFormValues={setComponentFormValues}
                airframeCheckStatus={AirframeCheckStatus}
                formControl={formControl}
                onDeleteExistingAirframeCheck={onDeleteExistingAirframeCheck}
                onExistingAirframeCheckCopyFromKb={onExistingAirframeCheckCopyFromKb}
                pathPrefix={pathPrefix}
                draggedIndex={draggedIndex}
                overIndex={overIndex}
                columnSizes={columnSizes}
              />
            )
          })}
        </SortableContext>
        <DragOverlay>{draggedIndex !== null && <DraggedElement draggedIndex={draggedIndex} />}</DragOverlay>
      </DndContext>
    </tbody>
  )
}

export { AssetAirframeChecksRows }
