import { type RowSizeMap, SimpleHeader } from '@flyward/platform/components'
import { type ColumnDef } from '@tanstack/react-table'

import { type DraggableLlp } from '../../../../../../../models/aircraftComponents'

const editableColumns = (columnSizes: RowSizeMap): Array<ColumnDef<DraggableLlp>> => [
  {
    accessorKey: 'llp.componentModule',
    header: () => {
      return (
        <div
          className="flex items-center justify-center"
          style={{
            width: columnSizes.llp_componentModule?.currentSize ?? 0,
            maxWidth: columnSizes.llp_componentModule?.currentSize ?? 0,
          }}
        >
          <SimpleHeader title="Module" className="text-semibold m-0 w-full truncate p-1 text-center text-xs text-black-0" />
        </div>
      )
    },
    size: columnSizes.llp_componentModule?.defaultSize ?? 0,
    minSize: columnSizes.llp_componentModule?.minSize ?? 0,
    maxSize: columnSizes.llp_componentModule?.maxSize ?? 0,
  },
  {
    accessorKey: 'llp.componentModel',
    header: () => {
      return (
        <div className="flex items-center justify-center" style={{ width: columnSizes.llp_componentModel?.currentSize ?? 0 }}>
          <SimpleHeader title="Description" className="text-semibold m-0 w-full truncate p-1 text-center text-xs text-black-0" />
        </div>
      )
    },
    size: columnSizes.llp_componentModel?.defaultSize ?? 0,
    minSize: columnSizes.llp_componentModel?.minSize ?? 0,
    maxSize: columnSizes.llp_componentModel?.maxSize ?? 0,
  },
  {
    accessorKey: 'llp.partNumber',
    header: () => {
      return (
        <div className="flex items-center justify-center" style={{ width: columnSizes.llp_partNumber?.currentSize ?? 0 }}>
          <SimpleHeader title="Part Number" className="text-semibold m-0 w-full truncate p-1 text-center text-xs text-black-0" />
        </div>
      )
    },
    size: columnSizes.llp_partNumber?.defaultSize ?? 0,
    minSize: columnSizes.llp_partNumber?.minSize ?? 0,
    maxSize: columnSizes.llp_partNumber?.maxSize ?? 0,
  },
  {
    accessorKey: 'llp.componentSerialNumber',
    header: () => {
      return (
        <div className="flex items-center justify-center" style={{ width: columnSizes.llp_componentSerialNumber?.currentSize ?? 0 }}>
          <SimpleHeader title="Serial Number" className="text-semibold m-0 w-full truncate p-1 text-center text-xs text-black-0" />
        </div>
      )
    },
    size: columnSizes.llp_componentSerialNumber?.defaultSize ?? 0,
    minSize: columnSizes.llp_componentSerialNumber?.minSize ?? 0,
    maxSize: columnSizes.llp_componentSerialNumber?.maxSize ?? 0,
  },
  {
    accessorKey: 'llp.technicalLimitAmount',
    header: () => {
      return (
        <div className="flex items-center justify-center" style={{ width: columnSizes.llp_technicalLimitAmount?.currentSize ?? 0 }}>
          <SimpleHeader title="Limit" className="text-semibold m-0 w-full truncate p-1 text-center text-xs text-black-0" />
        </div>
      )
    },
    size: columnSizes.llp_technicalLimitAmount?.defaultSize ?? 0,
    minSize: columnSizes.llp_technicalLimitAmount?.minSize ?? 0,
    maxSize: columnSizes.llp_technicalLimitAmount?.maxSize ?? 0,
  },
  {
    accessorKey: 'llp.assetComponentUtilizationSnapshot.cyclesSinceNew',
    header: () => {
      return (
        <div className="flex items-center justify-center" style={{ width: columnSizes['llp_assetComponentUtilizationSnapshot.cyclesSinceNew']?.currentSize ?? 0 }}>
          <SimpleHeader title="CSN" className="text-semibold m-0 w-full truncate p-1 text-center text-xs text-black-0" />
        </div>
      )
    },
    size: columnSizes['llp_assetComponentUtilizationSnapshot.cyclesSinceNew']?.defaultSize ?? 0,
    minSize: columnSizes['llp_assetComponentUtilizationSnapshot.cyclesSinceNew']?.minSize ?? 0,
    maxSize: columnSizes['llp_assetComponentUtilizationSnapshot.cyclesSinceNew']?.maxSize ?? 0,
  },
  {
    accessorKey: 'llp.assetComponentUtilizationSnapshot.remainingCycles',
    header: () => {
      return (
        <div className="flex items-center justify-center" style={{ width: columnSizes['llp_assetComponentUtilizationSnapshot.remainingCycles']?.currentSize ?? 0 }}>
          <SimpleHeader title="Cycles Remaining" className="text-semibold m-0 w-full truncate p-1 text-center text-xs text-black-0" />
        </div>
      )
    },
    size: columnSizes['llp_assetComponentUtilizationSnapshot.remainingCycles']?.defaultSize ?? 0,
    minSize: columnSizes['llp_assetComponentUtilizationSnapshot.remainingCycles']?.minSize ?? 0,
    maxSize: columnSizes['llp_assetComponentUtilizationSnapshot.remainingCycles']?.maxSize ?? 0,
  },
  {
    accessorKey: 'llp.cyclesSinceNewAtContractDelivery',
    header: () => {
      return (
        <div className="flex items-center justify-center" style={{ width: columnSizes.llp_cyclesSinceNewAtContractDelivery?.currentSize ?? 0 }}>
          <SimpleHeader title="CSN At Contract Delivery" className="text-semibold m-0 w-full truncate p-1 text-center text-xs text-black-0" />
        </div>
      )
    },
    size: columnSizes.llp_cyclesSinceNewAtContractDelivery?.defaultSize ?? 0,
    minSize: columnSizes.llp_cyclesSinceNewAtContractDelivery?.minSize ?? 0,
    maxSize: columnSizes.llp_cyclesSinceNewAtContractDelivery?.maxSize ?? 0,
  },
  {
    accessorKey: 'alignmentStatus',
    header: () => <div className="flex items-center justify-center" style={{ width: columnSizes.alignmentStatus?.currentSize ?? 0 }} />,
    size: columnSizes.alignmentStatus?.defaultSize ?? 0,
    minSize: columnSizes.alignmentStatus?.minSize ?? 0,
    maxSize: columnSizes.alignmentStatus?.maxSize ?? 0,
    enableResizing: false,
  },
  {
    accessorKey: 'order',
    header: () => <div className="flex items-center justify-center" style={{ width: columnSizes.order?.currentSize ?? 0 }} />,
    size: columnSizes.order?.defaultSize ?? 0,
    minSize: columnSizes.order?.minSize ?? 0,
    maxSize: columnSizes.order?.maxSize ?? 0,
    enableResizing: false,
  },
  {
    accessorKey: 'align',
    header: () => <div className="flex items-center justify-center" style={{ width: columnSizes.align?.currentSize ?? 0 }} />,
    size: columnSizes.align?.defaultSize ?? 0,
    minSize: columnSizes.align?.minSize ?? 0,
    maxSize: columnSizes.align?.maxSize ?? 0,
    enableResizing: false,
  },
]
export { editableColumns }
