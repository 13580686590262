import { Box, Button, TextField, Typography } from '@mui/material'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { NumericFormat } from 'react-number-format'
import { validateNullableDate, validateNullableNaturalNumber, validateRequiredDate, validateRequiredNaturalNumber } from '@flyward/platform/helpers/inputValidators'
import { isEmpty, isNil } from 'lodash'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { dateToIsoString } from '@flyward/platform/helpers/dateHelpers'
import { AirframeCheckType, CheckType } from '@flyward/platform'
import { ChecksTable } from './ChecksTable'
import { useEffect, useState } from 'react'

const MIN_VALUE = 0
const MAX_VALUE = 1000000000
const MAX_LENGTH = 200
const MAX_LENGTH_MESSAGE = 'Maximum 200 characters allowed'

const technicalSchema = z.object({
  tsn: z.number().optional().nullable(),
  csn: z.number().optional().nullable(),
})

const checksSchema = z.object({
  checkType: validateNullableNaturalNumber,
  dateAtLastCheck: validateNullableDate,
  monthsSinceEventAtContractDelivery: z.number().optional().nullable(),
})

const componentSchema = z.object({
  technical: technicalSchema,
  checks: z.array(checksSchema),
})

export type ComponentFormData = z.infer<typeof componentSchema>

const EmptyRow = {
  id: 0,
  checkType: undefined,
  dateAtLastCheck: undefined,
  monthsSinceEventAtContractDelivery: undefined,
} as const

export interface TableRowData {
  id: number
  checkType?: AirframeCheckType
  dateAtLastCheck: string
  monthsSinceEventAtContractDelivery: number
}

interface Props {
  onNext: (_data: ComponentFormData) => void
  onBack?: () => void
  stepTitle: string
  initialData?: ComponentFormData
  isAiPopulated?: boolean
}

const AirframeComponent = ({ onNext, onBack, stepTitle, initialData, isAiPopulated }: Props) => {
  const [tableRows, setTableRows] = useState<TableRowData[]>([EmptyRow])

  const checkDuplicateCheckTypes = (): boolean => {
    const seenCheckTypes = new Set<number>()
    for (const row of tableRows) {
      if (!row.checkType) continue
      if (seenCheckTypes.has(row.checkType)) return true
      seenCheckTypes.add(row.checkType)
    }
    return false
  }

  const checkEmptyChecks = (): boolean => {
    return tableRows.some((row) => isNil(row.checkType) && (!isEmpty(row.dateAtLastCheck) || row.monthsSinceEventAtContractDelivery !== undefined))
  }

  const {
    formState: { errors, touchedFields },
    watch,
    trigger,
    setValue,
    register,
  } = useForm<ComponentFormData>({
    resolver: zodResolver(componentSchema),
    mode: 'all',
    defaultValues: {
      technical: {
        tsn: initialData?.technical?.tsn,
        csn: initialData?.technical?.csn,
      },
      checks: initialData?.checks,
    },
  })

  useEffect(() => {
    if (isAiPopulated) {
      trigger()
    }
  }, [isAiPopulated])

  const values = watch()

  // Populate LLP replacements from initialData
  useEffect(() => {
    if (initialData?.checks?.length) {
      const populatedRows = initialData.checks.map((airframeCheck, index) => ({
        id: index + 1,
        checkType: airframeCheck.checkType,
        dateAtLastCheck: airframeCheck.dateAtLastCheck,
        monthsSinceEventAtContractDelivery: airframeCheck.monthsSinceEventAtContractDelivery,
      }))
      setTableRows(populatedRows)
    }
  }, [initialData])

  const handleAddRow = () => {
    setTableRows([...tableRows, { ...EmptyRow, id: tableRows.length + 1 }])
  }

  const handleRowChange = (id: number, field: keyof TableRowData, value: string) => {
    setTableRows(
      tableRows.map((row) => {
        if (row.id === id) {
          return { ...row, [field]: value }
        }
        return row
      }),
    )
  }

  const handleDeleteRow = (id: number) => {
    setTableRows(tableRows.filter((row) => row.id !== id))
  }

  const handleContinue = async () => {
    const isValidForm = await trigger()
    if (isValidForm) {
      onNext({
        technical: {
          tsn: values?.technical?.tsn,
          csn: values?.technical?.csn,
        },
        checks: tableRows.filter((row) => row.checkType),
      })
    }
  }

  const hasValidationErrors = Object.keys(errors).length > 0
  const hasDuplicateCheckTypes = checkDuplicateCheckTypes()
  const hasEmptyChecks = checkEmptyChecks()
  const isButtonDisabled = hasEmptyChecks || hasDuplicateCheckTypes || hasValidationErrors

  const getTouchedErrorMessage = (fieldName) => (!touchedFields?.technical?.[fieldName] ? '' : errors.technical?.[fieldName]?.message ?? '')

  return (
    <div className="shadow-sm rounded-lg bg-white p-6" style={{ minWidth: '32rem' }}>
      <Typography variant="h6" sx={{ mb: 3 }}>
        {stepTitle}
      </Typography>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 3,
          mb: 4,
        }}
      >
        <Typography variant="subtitle1" sx={{ mb: 1 }}>
          Technical
        </Typography>

        <Box sx={{ display: 'flex', gap: 2 }}>
          <NumericFormat
            customInput={TextField}
            label="TSN"
            value={values.technical.tsn ?? ''}
            error={touchedFields?.technical?.tsn && !!errors?.technical?.tsn}
            helperText={getTouchedErrorMessage('tsn')}
            thousandSeparator
            decimalScale={0}
            allowNegative={false}
            isAllowed={({ floatValue }) => floatValue === undefined || (floatValue >= MIN_VALUE && floatValue <= MAX_VALUE)}
            onValueChange={({ floatValue }) => {
              setValue('technical.tsn', floatValue!, {
                shouldValidate: true,
                shouldTouch: true,
              })
            }}
            onBlur={() => {
              if (!touchedFields?.technical?.tsn) {
                setValue('technical.tsn', values.technical.tsn, {
                  shouldTouch: true,
                  shouldValidate: true,
                })
              }
            }}
            sx={{ flex: 1 }}
          />

          <NumericFormat
            customInput={TextField}
            label="CSN"
            value={values.technical.csn ?? ''}
            error={touchedFields?.technical?.csn && !!errors?.technical?.csn}
            helperText={getTouchedErrorMessage('csn')}
            thousandSeparator
            decimalScale={0}
            allowNegative={false}
            isAllowed={({ floatValue }) => floatValue === undefined || (floatValue >= MIN_VALUE && floatValue <= MAX_VALUE)}
            onValueChange={({ floatValue }) => {
              setValue('technical.csn', floatValue!, {
                shouldValidate: true,
                shouldTouch: true,
              })
            }}
            onBlur={() => {
              if (!touchedFields?.technical?.csn) {
                setValue('technical.csn', values.technical.csn, {
                  shouldTouch: true,
                  shouldValidate: true,
                })
              }
            }}
            sx={{ flex: 1 }}
          />

          <Box sx={{ flex: 1 }} />
        </Box>
      </Box>

      <Typography variant="subtitle1" sx={{ mb: 1 }}>
        Check history
      </Typography>

      <Box sx={{ mb: 4, width: '100%', minWidth: '48rem' }}>
        <ChecksTable rows={tableRows} onRowChange={handleRowChange} onAddRow={handleAddRow} onDeleteRow={handleDeleteRow} />
      </Box>

      <Box>
        <Button variant="contained" onClick={handleContinue} disabled={isButtonDisabled}>
          Continue
        </Button>
        <Button variant="text" onClick={onBack}>
          Back
        </Button>
      </Box>
    </div>
  )
}

export { AirframeComponent }
