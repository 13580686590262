import React, { type InputHTMLAttributes } from 'react'
import { Checkbox } from '@mui/material'

interface IndeterminateCheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  indeterminate?: boolean
}

export const IntermediateCheckbox: React.FC<IndeterminateCheckboxProps> = ({ indeterminate, checked, onChange, disabled }) => {
  return (
    <Checkbox
      indeterminate={Boolean(!checked && indeterminate)}
      checked={checked}
      onChange={onChange}
      disabled={disabled}
      sx={{
        color: 'var(--black-20)',
        '&.Mui-checked': {
          color: 'var(--primary)',
        },
        '&.MuiCheckbox-indeterminate': {
          color: 'var(--primary)',
        },
      }}
    />
  )
}
