import { Button, ButtonVariant, CheckTypeTableDisplay, type IYearMonth } from '@flyward/platform'
import { useState } from 'react'
import { type IMaintenanceScheduleDisplay } from '../../../../../models'

interface IIndividualAssetChecks {
  eventsGrid: IMaintenanceScheduleDisplay[]
  openEventDetailViewPopover: (yearMonth: IYearMonth) => void
}

export const IndividualAssetChecks = ({ eventsGrid = [], openEventDetailViewPopover }: IIndividualAssetChecks) => {
  const [showAll, setShowAll] = useState(false)

  const onOpenEventDetailViewPopover = () => {
    openEventDetailViewPopover(eventsGrid[0].yearMonth as IYearMonth)
  }

  const onMoreButtonClick = () => {
    setShowAll(true)
  }

  const onCloseButtonClick = () => {
    setShowAll(false)
  }

  const getButtonLabel = (event: IMaintenanceScheduleDisplay) => {
    return `${event.componentSerialNumber} (${CheckTypeTableDisplay(event.forecastedCheck.checkType)})`
  }

  const renderItems = () => {
    const displayedTwoItems = eventsGrid
      .slice(0, 2)
      .map((event) => (
        <Button variant={ButtonVariant.EventView} key={event.id} label={getButtonLabel(event)} onClick={onOpenEventDetailViewPopover} />
      ))
    const displayedItems = eventsGrid.map((event) => (
      <Button variant={ButtonVariant.EventView} key={event.id} label={getButtonLabel(event)} onClick={onOpenEventDetailViewPopover} />
    ))

    if (eventsGrid.length <= 2) {
      return <>{displayedItems}</>
    }

    if (showAll) {
      return (
        <>
          {displayedItems}
          <Button
            onClick={onCloseButtonClick}
            variant={ButtonVariant.Ghost}
            className="inline-flex items-center justify-center text-center text-xs !text-primary-dark-2"
            label="Close"
          />
        </>
      )
    }

    return (
      <>
        {displayedTwoItems}
        <Button
          onClick={onMoreButtonClick}
          variant={ButtonVariant.Ghost}
          className="inline-flex items-center justify-center text-center text-xs !text-primary-dark-2"
          label="More"
        />
      </>
    )
  }

  return renderItems()
}
