import { type ZitadelTokenResponse } from '@flyward/platform/models'
import { getPersistedSupportFilteredTenantId } from '@flyward/platform/services/AUTH/supportFilteredTenantIdStore'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

export interface TZitadelUser {
  token: ZitadelTokenResponse
}

interface AuthState {
  zitadelUser: TZitadelUser | null
  supportFilteredTenantId: string | null
}

// Initial State (Pure)
const initialState: AuthState = {
  zitadelUser: null,
  supportFilteredTenantId: getPersistedSupportFilteredTenantId(), // Consider moving this to the store initialization for full purity
}

const authSlice = createSlice({
  name: 'auth',
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  initialState,
  reducers: {
    clearZitadelUserInformation: (state) => {
      state.zitadelUser = null
      state.supportFilteredTenantId = null
    },

    setZitadelUserInformation: (state, { payload: { user } }: PayloadAction<{ user: TZitadelUser }>) => {
      state.zitadelUser = user
    },

    setSupportFilteredTenantId: (state, { payload: { tenantId } }: PayloadAction<{ tenantId: string }>) => {
      state.supportFilteredTenantId = tenantId
    },
  },
})

export const { clearZitadelUserInformation, setZitadelUserInformation, setSupportFilteredTenantId } = authSlice.actions
export const { reducer: authReducer } = authSlice
