import { type EngineLifeLimitedPartDto, type AircraftEngineDto } from '@flyward/assets/models'
import { type LlpLessorContributionDto } from '@flyward/assets/models/aircraftComponents/aircraftEngine/LlpLessorContributionDto'
import { NumberDisplay, NumberDisplayType, SimpleHeader } from '@flyward/platform'
import { GenericTable } from '@flyward/platform/components/Table/GenericTable'
import { type ColumnDef, getCoreRowModel, useReactTable } from '@tanstack/react-table'
import { isNil } from 'lodash'

interface LLPsLessorContributionsReadonlyTabProps {
  component: AircraftEngineDto
  engineLifeLimitedParts: EngineLifeLimitedPartDto[]
}

const generateGridColumns = (engineLifeLimitedParts: EngineLifeLimitedPartDto[]): Array<ColumnDef<LlpLessorContributionDto>> => {
  const contractualReserveColumns: Array<ColumnDef<LlpLessorContributionDto>> = [
    {
      id: 'module',
      accessorKey: 'module',
      header: () => <SimpleHeader title="Module" className="text-semibold m-0 w-full p-1 text-center text-xs text-black-0" />,
      cell: ({ row }) => (
        <div className="truncate text-center text-xs">
          {engineLifeLimitedParts.find((llp) => llp.componentId === row.original.llpId)?.componentModule}
        </div>
      ),
      meta: {
        className: 'basis-2/10',
      },
    },
    {
      id: 'componentModel',
      accessorKey: 'componentModel',
      header: () => <SimpleHeader title="Description" className="text-semibold m-0 w-full p-1 text-center text-xs text-black-0" />,
      cell: ({ row }) => (
        <div className="truncate text-center text-xs">
          {engineLifeLimitedParts.find((llp) => llp.componentId === row.original.llpId)?.componentModel}
        </div>
      ),
      meta: {
        className: 'basis-2/10',
      },
    },
    {
      id: 'partNumber',
      accessorKey: 'partNumber',
      header: () => <SimpleHeader title="Part Number" className="text-semibold m-0 w-full p-1 text-center text-xs text-black-0" />,
      cell: ({ row }) => (
        <div className="truncate text-center text-xs">{engineLifeLimitedParts.find((llp) => llp.componentId === row.original.llpId)?.partNumber}</div>
      ),
      meta: {
        className: 'basis-1/10',
      },
    },
    {
      id: 'componentSerialNumber',
      accessorKey: 'componentSerialNumber',
      header: () => <SimpleHeader title="Serial Number" className="text-semibold m-0 w-full p-1 text-center text-xs text-black-0" />,
      cell: ({ row }) => (
        <div className="text-center text-xs">
          {engineLifeLimitedParts.find((llp) => llp.componentId === row.original.llpId)?.componentSerialNumber}
        </div>
      ),
      meta: {
        className: 'basis-2/10',
      },
    },
    {
      id: 'isActive',
      accessorKey: 'isActive',
      header: () => <SimpleHeader title="Applicable" className="text-semibold m-0 w-full p-1 text-center text-xs text-black-0" />,
      cell: ({ row }) => <div className="text-center text-xs">{row.original?.lessorContribution?.isActive === true ? 'Yes' : 'No'}</div>,
      meta: {
        className: 'basis-1/10',
      },
    },
    {
      id: 'isUnlimited',
      accessorKey: 'isUnlimited',
      header: () => <SimpleHeader title="Unlimited" className="text-semibold m-0 w-full p-1 text-center text-xs text-black-0" />,
      cell: ({ row }) => {
        if (row.original?.lessorContribution?.isActive === true) {
          return <div className="text-center text-xs">{row.original?.lessorContribution?.isUnlimited ? 'Yes' : 'No'}</div>
        } else {
          return <div className="h-5 bg-black-20 text-center"></div>
        }
      },
      meta: {
        className: 'basis-1/10',
      },
    },
    {
      id: 'amount',
      accessorKey: 'amount',
      header: () => <SimpleHeader title="Total Amount" className="text-semibold m-0 w-full p-1 text-center text-xs text-black-0" />,
      cell: ({ row }) => {
        if (row.original?.lessorContribution?.isActive === true && !row.original?.lessorContribution?.isUnlimited) {
          return (
            <div className="text-center text-xs">
              <NumberDisplay displayType={NumberDisplayType.Currency} value={row.original.lessorContribution?.amount ?? 0} />
            </div>
          )
        } else {
          return <div className="flex h-5 w-32 min-w-32 bg-black-20"></div>
        }
      },
      meta: {
        className: 'basis-1/10',
      },
    },
  ]
  return contractualReserveColumns
}

export const LLPsLessorContributionsReadonlyTab = ({ component, engineLifeLimitedParts }: LLPsLessorContributionsReadonlyTabProps) => {
  const lessorContributions = !isNil(component.lessorContributions?.llpLessorContributions)
    ? component.lessorContributions.llpLessorContributions
    : new Array<LlpLessorContributionDto>()

  const contractualReserveColumns = generateGridColumns(engineLifeLimitedParts)

  const contractualReserveTable = useReactTable<LlpLessorContributionDto>({
    data: lessorContributions,
    columns: contractualReserveColumns,
    getRowCanExpand: () => true,
    getCoreRowModel: getCoreRowModel(),
    enableMultiRowSelection: false,
  })

  return (
    <div className="w-250">
      <GenericTable tableInstance={contractualReserveTable} />
    </div>
  )
}
