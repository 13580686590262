import { ThemeProvider } from '@mui/material'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { enGB } from 'date-fns/locale'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider/LocalizationProvider'
import { AppProvider as MUIAppProvider } from '@toolpad/core/AppProvider'
import { NotificationsProvider as MUINotificationsProvider } from '@toolpad/core/useNotifications'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { IntlProvider } from 'react-intl'
import { BrowserRouter } from 'react-router-dom'
import { App } from './main-app/App'
import { reportHandler, reportWebVitals } from './main-app/services'
import './main.css'
import { ErrorBoundary } from './platform'
import { theme } from './theme'

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
      <MUIAppProvider theme={theme}>
        <ThemeProvider theme={theme}>
          <MUINotificationsProvider>
            {/* ErrorBoundary is ta the root of the app
        so that the user never gets to see an empty white page
        in case an error occurs */}
            <ErrorBoundary>
              <IntlProvider locale="en-GB">
                <BrowserRouter>
                  <App />
                </BrowserRouter>
              </IntlProvider>
            </ErrorBoundary>
          </MUINotificationsProvider>
        </ThemeProvider>
      </MUIAppProvider>
    </LocalizationProvider>
  </React.StrictMode>,
)

void reportWebVitals(reportHandler)
