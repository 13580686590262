import { isEmpty, isNil } from 'lodash'

export const validateNaturalNumber = (
  inputValue: string | null | undefined,
  allowOnlyPositive: boolean = false,
): {
  isValid: boolean
  value: string
} => {
  if (isEmpty(inputValue) || isNil(inputValue)) {
    return { isValid: true, value: '' } // If input is null or undefined, return true
  }

  const numericPattern = /^\d*$/
  const isValidAlphanumeric = numericPattern.test(inputValue)
  const numberValue = Number(inputValue)

  let isValid = isValidAlphanumeric || inputValue === ''

  if (allowOnlyPositive) {
    isValid = isValid && numberValue > 0
  }

  return {
    isValid,
    value: isValid ? numberValue.toString() : '',
  }
}

export const validateEmail = (
  inputValue: string,
): {
  isValid: boolean
  value: string
} => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  return {
    isValid: emailRegex.test(inputValue),
    value: inputValue,
  }
}

export const validateSafeText = (
  inputValue: string,
): {
  isValid: boolean
  value: string
} => {
  const safeTextPattern = /^[.,a-zA-Z0-9_()-]+(?: [.,a-zA-Z0-9_()-]+)*$/
  return {
    isValid: safeTextPattern.test(inputValue),
    value: inputValue,
  }
}

export const validateNumberFormat = (
  inputValue: string,
  allowNegative = false,
): {
  isValid: boolean
  value: string
} => {
  const numericPattern = allowNegative ? /^-?\d*\.?\d*$/ : /^\d*\.?\d*$/
  return {
    isValid: numericPattern.test(inputValue),
    value: numericPattern.test(inputValue) ? inputValue : '',
  }
}
