export enum EnvironmentalImpactType {
  Benign = 1,
  Moderate = 2,
  Harsh = 3,
  VeryHarsh = 4,
  Severe = 5,
}

export const EnvironmentalImpactTypeMap = new Map<EnvironmentalImpactType, string>([
  [EnvironmentalImpactType.Benign, 'Benign'],
  [EnvironmentalImpactType.Moderate, 'Moderate'],
  [EnvironmentalImpactType.Harsh, 'Harsh'],
  [EnvironmentalImpactType.VeryHarsh, 'Very Harsh'],
  [EnvironmentalImpactType.Severe, 'Severe'],
])

export const EnvironmentalImpactTypeDisplay = (value: EnvironmentalImpactType): string => {
  return EnvironmentalImpactTypeMap.get(value) ?? 'Unknown'
}

export const EnvironmentalImpactTypeOptions = Array.from(EnvironmentalImpactTypeMap.entries()).map(([value, label]) => ({
  value: value.toString(),
  label,
}))
