import { AssetInformation } from '@flyward/assets'
import {
  EventSchedule,
  ResponsiveForecastingSummaryChart,
  Statistics,
  formatAssetSchedule,
  type IMaintenanceScheduleDisplay,
} from '@flyward/forecasts'
import { useGetReportItemQuery } from '@flyward/forecasts/store/api/reportItems'
import {
  CollapsibleContainer,
  ComponentContainer,
  LoadingFallback,
  ReportDisplayType,
  errorMessages,
  type ComponentMonthlyStatistics,
  type IdWithIndex,
} from '@flyward/platform'
import { showError } from '@flyward/platform/services'
import { isNil } from 'lodash'
import { useCallback, useEffect, useMemo } from 'react'
import { ReportFlyForwardParameters } from '../ReportFlyForwardParameters'

interface IIndividualReportItemBodyProps {
  reportAsset: IdWithIndex
}

export const IndividualReportItemBody = ({ reportAsset }: IIndividualReportItemBodyProps) => {
  const reportItemId = reportAsset.id

  const onGetReportItemError = useCallback(() => {
    showError(errorMessages.reports.loadingError)
  }, [])

  const {
    data: selectedReportItem,
    isError: isReportItemError,
    isFetching: isFetchingReportItem,
  } = useGetReportItemQuery(
    { reportItemId },
    {
      skip: reportAsset.id === undefined,
    },
  )

  useEffect(() => {
    if (isReportItemError) {
      onGetReportItemError()
    }
  }, [isReportItemError, onGetReportItemError])

  const uniqueSchedules: IMaintenanceScheduleDisplay[] = useMemo(
    () =>
      formatAssetSchedule(
        selectedReportItem?.outputSchedules,
        selectedReportItem?.inputFlyForwardParams.endDate,
        selectedReportItem?.assetDetailsSnapshot.serialNumber,
        selectedReportItem?.reportId,
      ),
    [selectedReportItem],
  )

  const allSchedules: IMaintenanceScheduleDisplay[] = useMemo(
    () =>
      formatAssetSchedule(
        selectedReportItem?.outputSchedules,
        selectedReportItem?.inputFlyForwardParams.endDate,
        selectedReportItem?.assetDetailsSnapshot.serialNumber,
        selectedReportItem?.reportId,
        true,
      ),
    [selectedReportItem],
  )

  const componentsMonthlyStatistics: ComponentMonthlyStatistics[] = []
  selectedReportItem?.assetComponentsMonthlyStatistics.groupedComponentStatistics.forEach((component) => {
    if (component.subComponents !== undefined && component.subComponents.length > 0) {
      componentsMonthlyStatistics.push(...component.subComponents)
    } else {
      componentsMonthlyStatistics.push(component)
    }
  })

  if (isFetchingReportItem) {
    return (
      <span className="min-h-screen">
        <LoadingFallback />
      </span>
    )
  }

  return (
    <>
      <ComponentContainer className="p-6">
        <div className="m-0 flex w-full flex-col gap-y-4 p-0">
          {!isNil(selectedReportItem?.assetDetailsSnapshot) && selectedReportItem !== undefined ? (
            <AssetInformation asset={selectedReportItem.assetDetailsSnapshot} showSerialNumber={true} />
          ) : null}
        </div>
      </ComponentContainer>
      <ComponentContainer className="p-6">
        <CollapsibleContainer title="Fly Forward Parameters" containerClassName="w-full gap-y-4">
          {!isNil(selectedReportItem) && <ReportFlyForwardParameters parameters={selectedReportItem?.inputFlyForwardParams} />}
        </CollapsibleContainer>
      </ComponentContainer>
      <ComponentContainer className="p-6">
        <CollapsibleContainer title="Chart" containerClassName="w-full gap-y-0">
          <div className="h-96 w-full">
            {!isNil(selectedReportItem?.assetDetailsSnapshot) && selectedReportItem !== undefined ? (
              <ResponsiveForecastingSummaryChart
                assetMonthlyStatistics={selectedReportItem.assetComponentsMonthlyStatistics.assetMonthlyStatistics}
              />
            ) : null}
          </div>
        </CollapsibleContainer>
      </ComponentContainer>
      {selectedReportItem !== undefined && (
        <EventSchedule
          reportDisplayType={ReportDisplayType.AssetReport}
          reportItemsSchedules={[
            {
              flyForwardParameters: selectedReportItem.inputFlyForwardParams,
              reportItemId: selectedReportItem.reportId,
              assetIdWithIndex: {
                id: selectedReportItem.assetDetailsSnapshot.assetId,
                index: reportAsset.index,
              },
              schedules: uniqueSchedules,
            },
          ]}
          uniqueSchedules={uniqueSchedules}
          allSchedules={allSchedules}
          componentsMonthlyStatistics={componentsMonthlyStatistics}
          componentTotalStatisticsAtEvent={selectedReportItem.assetComponentsMonthlyStatistics.componentTotalStatisticsAtEvent}
          isEditable={false}
        />
      )}
      {selectedReportItem !== undefined && (
        <Statistics
          reportDisplayType={ReportDisplayType.AssetReport}
          allEvents={uniqueSchedules}
          assetMonthlyStatisticsSummary={selectedReportItem.assetComponentsMonthlyStatistics.assetMonthlyStatistics}
          allReportComponentsMonthlyStatistics={selectedReportItem.assetComponentsMonthlyStatistics}
          key={selectedReportItem.assetId}
          assetType={selectedReportItem.assetDetailsSnapshot.assetType}
          isoStartDate={selectedReportItem.assetDetailsSnapshot.dateOfLastTechSpec ?? ''}
          isoEndDate={selectedReportItem.inputFlyForwardParams.endDate}
        />
      )}
    </>
  )
}
