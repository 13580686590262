import { APIRoutes } from '@flyward/platform/constants'
import { flywardApi } from '@flyward/platform/store/slices/api/flywardApi/flywardApiSlice'
import {
  type IGetEngineMaintenanceProgramDetailsQueryRequest,
  type IGetEngineMaintenanceProgramDetailsQueryResponse,
  type IGetAllEngineMaintenanceProgramsQueryResponse,
  type IImportEngineKnowledgeBase,
  type IPutEngineMaintenanceProgramDetailsMutationRequest,
  type IPostEngineMaintenanceProgramMutationRequest,
} from './types'

const engineMaintenanceProgramsApi = flywardApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllEngineMaintenancePrograms: builder.query<IGetAllEngineMaintenanceProgramsQueryResponse, boolean>({
      query: (includeInactive) => ({
        url: APIRoutes.KnowledgeBaseModule.MaintenanceProgramsController.EnginesPrograms.GetAllEnginePrograms(),
        method: 'GET',
        params: { includeInactive },
      }),
      providesTags: ['apiData', 'engineMaintenancePrograms'],
    }),
    getEngineMaintenanceProgramDetails: builder.query<
      IGetEngineMaintenanceProgramDetailsQueryResponse,
      IGetEngineMaintenanceProgramDetailsQueryRequest
    >({
      query: ({ programId }) => ({
        url: APIRoutes.KnowledgeBaseModule.MaintenanceProgramsController.EnginesPrograms.GetSingleEngineProgram(programId),
        method: 'GET',
      }),
      providesTags: (_, __, request) => ['apiData', { type: 'engineMaintenancePrograms', id: request.programId }],
    }),
    importEngineKnowledgeBase: builder.mutation<void, IImportEngineKnowledgeBase>({
      query: ({ componentType, file }) => {
        const formData = new FormData()
        formData.append('inputFile', file)
        return {
          url: `${APIRoutes.KnowledgeBaseModule.MaintenancePlansController.ImportMaintenancePrograms()}?type=${componentType}`,
          method: 'POST',
          data: formData,
          headers: {
            'Content-Type': ' multipart/form-data',
          },
        }
      },
      invalidatesTags: [{ type: 'engineMaintenancePrograms' }, { type: 'assetVerification' }],
    }),
    createEngineMaintenanceProgram: builder.mutation<string, IPostEngineMaintenanceProgramMutationRequest>({
      query: ({ program }) => ({
        url: APIRoutes.KnowledgeBaseModule.MaintenanceProgramsController.EnginesPrograms.CreateEngineProgram(),
        method: 'POST',
        data: program,
      }),
      invalidatesTags: (_, __, _request) => [{ type: 'engineMaintenancePrograms' }],
    }),
    updateEngineMaintenanceProgramDetails: builder.mutation<string, IPutEngineMaintenanceProgramDetailsMutationRequest>({
      query: ({ programId, engine }) => ({
        url: APIRoutes.KnowledgeBaseModule.MaintenanceProgramsController.EnginesPrograms.UpdateEngineProgram(programId),
        method: 'PUT',
        data: engine,
      }),
      invalidatesTags: (_, __, _request) => [{ type: 'engineMaintenancePrograms' }],
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetAllEngineMaintenanceProgramsQuery,
  useGetEngineMaintenanceProgramDetailsQuery,
  useImportEngineKnowledgeBaseMutation,
  useCreateEngineMaintenanceProgramMutation,
  useUpdateEngineMaintenanceProgramDetailsMutation,
  util: engineMaintenanceProgramsApiSliceUtil,
} = engineMaintenanceProgramsApi

type Endpoints = typeof engineMaintenanceProgramsApi.endpoints
type InitiateActions = {
  [K in keyof Endpoints]: ReturnType<ReturnType<Endpoints[K]['initiate']>>
}
export type TEngineMaintenanceProgramsApiActions = InitiateActions[keyof InitiateActions]
