import { configureStore, createListenerMiddleware } from '@reduxjs/toolkit'
import { persistStore } from 'redux-persist'
import { thunk } from 'redux-thunk'
import { rootReducer } from './configureReducers'
import { flywardApi } from './slices'

const signalRListenerMiddleware = createListenerMiddleware()

export function setupStore(preloadedState?: Partial<ReturnType<typeof rootReducer>>) {
  const store = configureStore({
    reducer: rootReducer,
    preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({ serializableCheck: false })
        .concat(signalRListenerMiddleware.middleware)
        .concat(flywardApi.middleware)
        .concat(thunk),
  })
  const persistor = persistStore(store)

  return { store, persistor }
}

export { signalRListenerMiddleware }
export const { store, persistor } = setupStore({})
