import { type RowSizeMap } from '@flyward/platform/components'
import { type DraggableLlp } from '../../../../../../../models/aircraftComponents/aircraftEngine'
import { flexRender, type HeaderGroup } from '@tanstack/react-table'
import { isNil } from 'lodash'
import { useEffect } from 'react'

interface IAssetLlpsHeaderProps {
  headerGroups: Array<HeaderGroup<DraggableLlp>>
  columnSizes: RowSizeMap
  setColumnSizes: (_columnSizes: RowSizeMap) => void
}

const AssetLlpsHeader = ({ headerGroups, columnSizes, setColumnSizes }: IAssetLlpsHeaderProps) => {
  useEffect(() => {
    const updatedSizes = { ...columnSizes }
    let hasChanges = false

    headerGroups.forEach((headerGroup) => {
      headerGroup.headers.forEach((header) => {
        const accessorKey = header.column.id
        const currentSize = header.getSize()

        if (!isNil(updatedSizes[accessorKey]) && updatedSizes[accessorKey].currentSize !== currentSize) {
          updatedSizes[accessorKey] = {
            ...updatedSizes[accessorKey],
            currentSize,
          }
          hasChanges = true
        }
      })
    })

    if (hasChanges) {
      setColumnSizes(updatedSizes)
    }
  }, [headerGroups, columnSizes, setColumnSizes])

  return (
    <thead>
      {headerGroups.map((headerGroup) => (
        <tr key={headerGroup.id}>
          {headerGroup.headers.map((header) => {
            const accessorKey = header.column.id

            return (
              <th
                key={header.id}
                colSpan={header.colSpan}
                style={{
                  width: `${columnSizes[accessorKey]?.currentSize}px`,
                  maxWidth: `${columnSizes[accessorKey]?.currentSize}px`,
                }}
                className="relative truncate border border-black-20 bg-primary-dark-1"
              >
                {header.isPlaceholder ? null : <div>{flexRender(header.column.columnDef.header, header.getContext())}</div>}

                {/* Resize Handle */}
                {header.column.getCanResize() && (
                  <div
                    onMouseDown={header.getResizeHandler()}
                    onTouchStart={header.getResizeHandler()}
                    className={`absolute right-0 top-0 h-full w-1 cursor-col-resize ${header.column.getIsResizing() ? '!bg-blue-300' : ''}`}
                  />
                )}
              </th>
            )
          })}
        </tr>
      ))}
    </thead>
  )
}

export { AssetLlpsHeader }
