import { z } from 'zod'
import { validateNullableNaturalNumber, validateRequiredNaturalNumber } from '@flyward/platform/helpers/inputValidators.ts'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { Box, Button, TextField, Typography } from '@mui/material'
import { ChecksTable } from './ChecksTable.tsx'
import { TableRowData } from './types.ts'
import { handleNumericInput } from '@flyward/platform/utils'

const escalationsAssumptionsSchema = z.object({
  escalation: z.any().nullable(),
  checkDowntime: validateRequiredNaturalNumber('Check Downtime', 1),
  delayInPayment: validateNullableNaturalNumber,
})

type EscalationsAssumptionsFormData = z.infer<typeof escalationsAssumptionsSchema>

const EmptyRow = {
  id: 0,
  checkType: 'PR' as const,
  limit: '',
  cost: '',
} as const

interface ChecksEscalationsAssumptionsProps {
  onNext: (_data: {
    checks: Array<{
      limit: number
      cost: number
    }>
    escalation?: number
    checkDowntime?: number
    delayInPayment?: number
  }) => void
  onBack: () => void | undefined
  stepTitle: string
  initialData?: {
    checks: Array<{
      limit: number
      cost: number
    }>
    escalation?: number
    checkDowntime?: number
    delayInPayment?: number
  }
}

export const ChecksEscalationsAssumptions = ({ onNext, onBack, stepTitle, initialData }: ChecksEscalationsAssumptionsProps) => {
  const [tableRows, setTableRows] = useState<TableRowData[]>([{ ...EmptyRow, id: 1 }])
  const [escalationPercentage, setEscalationPercentage] = useState<string>(initialData?.escalation?.toString() ?? '')

  const {
    formState: { errors, touchedFields, isValid },
    register,
    watch,
  } = useForm<EscalationsAssumptionsFormData>({
    resolver: zodResolver(escalationsAssumptionsSchema),
    mode: 'all',
    defaultValues: {
      escalation: initialData?.escalation,
      checkDowntime: initialData?.checkDowntime,
      delayInPayment: initialData?.delayInPayment,
    },
  })
  const values = watch()

  const isRequiredFieldsFilled = Boolean(values.checkDowntime)

  const isRowComplete = (row: TableRowData) => Boolean(row.limit && row.cost)

  const isRowPartiallyFilled = (row: TableRowData) => {
    const hasAnyValue = Boolean(row.limit || row.cost)
    return hasAnyValue && !isRowComplete(row)
  }

  const hasAtLeastOneLLP = tableRows.some(isRowComplete)
  const hasIncompleteRows = tableRows.some(isRowPartiallyFilled)

  const checkDuplicateLimits = (): boolean => {
    const seenLimits = new Set<string>()
    for (const row of tableRows) {
      if (!row.limit.trim()) continue
      const normalizedLimit = parseFloat(row.limit).toString()
      if (seenLimits.has(normalizedLimit)) return true
      seenLimits.add(normalizedLimit)
    }
    return false
  }

  const isButtonDisabled =
    !isRequiredFieldsFilled || !isValid || !hasAtLeastOneLLP || hasIncompleteRows || tableRows.some((row) => !row.limit && !row.cost) || checkDuplicateLimits()

  useEffect(() => {
    if (initialData?.checks?.length) {
      const populatedRows = initialData.checks.map((check, index) => ({
        id: index + 1,
        checkType: 'PR' as const,
        limit: check.limit.toString(),
        cost: check.cost.toString(),
      }))
      setTableRows(populatedRows)
    }
  }, [initialData])

  const handleContinue = () => {
    const formattedchecks = tableRows
      .filter((row) => row.limit && row.cost)
      .map((row) => ({
        limit: Number(row.limit),
        cost: Number(row.cost),
      }))

    onNext({
      checks: formattedchecks,
      escalation: Number(escalationPercentage),
      checkDowntime: values.checkDowntime,
      delayInPayment: values.delayInPayment,
    })
  }

  const handleRowChange = (id: number, field: keyof TableRowData, value: string) => {
    setTableRows(
      tableRows.map((row) => {
        if (row.id === id) {
          return { ...row, [field]: value }
        }
        return row
      }),
    )
  }

  const handleAddRow = () => {
    setTableRows([...tableRows, { ...EmptyRow, id: tableRows.length + 1 }])
  }

  const handleDeleteRow = (id: number) => {
    // Don't allow deleting if there's only one row
    if (tableRows.length === 1) {
      return
    }

    setTableRows(tableRows.filter((row) => row.id !== id))
  }

  return (
    <div className="shadow-sm rounded-lg bg-white p-6">
      <Typography variant="h6" sx={{ mb: 3 }}>
        {stepTitle}
      </Typography>

      <Box sx={{ mb: 4 }}>
        <ChecksTable rows={tableRows} onRowChange={handleRowChange} onAddRow={handleAddRow} onDeleteRow={handleDeleteRow} />
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 3,
          mb: 4,
        }}
      >
        <TextField
          label="Escalation Percentage"
          value={escalationPercentage}
          onChange={(e) => setEscalationPercentage(handleNumericInput(e.target.value))}
          InputProps={{
            startAdornment: '%',
          }}
          inputProps={{
            inputMode: 'decimal',
          }}
        />

        <TextField
          {...register('checkDowntime')}
          error={touchedFields.checkDowntime && !!errors.checkDowntime}
          label="Check Downtime (Months)"
          helperText={touchedFields.checkDowntime && errors.checkDowntime?.message}
          inputProps={{ inputMode: 'numeric', min: 1 }}
          defaultValue={1}
        />
        <TextField
          {...register('delayInPayment')}
          error={touchedFields.delayInPayment && !!errors.delayInPayment}
          label="Delay in Payment on Claims (Months)"
          helperText={touchedFields.delayInPayment && errors.delayInPayment?.message}
          inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
        />
      </Box>

      <Box>
        <Button variant="contained" onClick={handleContinue} disabled={isButtonDisabled} sx={{ mr: 1 }}>
          Continue
        </Button>
        <Button variant="text" onClick={onBack}>
          Back
        </Button>
      </Box>
    </div>
  )
}
