import { format, isValid, parse } from 'date-fns'
import { isEmpty } from 'lodash'

const longDateFormat = 'dd-MMM-yyyy'
const isoDateFormat = 'yyyy-MM-dd'
const shortDateFormat = 'MMM-yyyy'

const isFutureMonth = (date: string) => {
  const today = format(new Date(), isoDateFormat)
  if (today.substring(0, 4) > date.substring(0, 4)) {
    return false
  }
  if (today.substring(0, 4) < date.substring(0, 4)) {
    return true
  }
  if (today.substring(5, 7) >= date.substring(5, 7)) {
    return false
  }

  return true
}

const isWithinFiftyYears = (date: string) => {
  const parsedDate = parse(date, isoDateFormat, new Date())
  const currentDate = new Date()
  const fiftyYearsFromNow = new Date()
  fiftyYearsFromNow.setFullYear(currentDate.getFullYear() + 50)
  return parsedDate <= fiftyYearsFromNow
}

const fromIsoToFormatDate = (date: string) => {
  const formattedDate = format(date, longDateFormat)
  return formattedDate
}

/**
 * Converts an ISO date to a short format date (MMM-yyyy)
 * @param date format yyyy-MM-dd
 * @returns
 */
const fromIsoToShortFormatDate = (date: string) => {
  const isoDate = parse(date, isoDateFormat, new Date())
  const isValidDate = isValid(isoDate)
  if (!isValidDate) {
    return ''
  }
  const formattedDate = format(isoDate, shortDateFormat)
  return formattedDate
}

const dateToIsoString = (date: Date): string => {
  return format(date, isoDateFormat)
}

const stringToLongDateFormat = (date: string): string | undefined => (isEmpty(date) ? undefined : format(new Date(date), longDateFormat))

const shortMonths = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

export { fromIsoToFormatDate, fromIsoToShortFormatDate, isoDateFormat, dateToIsoString, isFutureMonth, isWithinFiftyYears, longDateFormat, shortMonths, stringToLongDateFormat }
