import { type AircraftEngineDto, EngineMaintenanceProgram } from '@flyward/assets'
import { formatAxiosErrorMessage } from '@flyward/platform/helpers/ErrorHelpers'
import { showError, showSuccess } from '@flyward/platform/services'
import { useGetAllEngineMaintenanceProgramsQuery, useUpdateEnginesMaintenanceProgramMutation } from '@flyward/platform/store'
import { isNil } from 'lodash'

interface IStandaloneEngineMaintenanceProgramSelectionProps {
  assetId: string
  engines: AircraftEngineDto[] | undefined
}

export const StandaloneEngineMaintenanceProgramSelection = ({ assetId, engines }: IStandaloneEngineMaintenanceProgramSelectionProps) => {
  const [updateEngineMaintenanceProgram] = useUpdateEnginesMaintenanceProgramMutation()

  const engineAssignedKBProgramId = engines?.[0]?.assignedKBProgramId ?? ''

  const { data: availableEnginePrograms } = useGetAllEngineMaintenanceProgramsQuery(false)

  const changeEngineProgram = async (engineProgramId: string) => {
    const result = await updateEngineMaintenanceProgram({ assetId, kbProgramId: engineProgramId, engines })
    if (!isNil(result.error)) {
      showError(formatAxiosErrorMessage(result.error?.message))
    } else {
      showSuccess(<span>Standalone engine program updated successfully</span>)
    }
  }

  return (
    <EngineMaintenanceProgram
      assetId={assetId}
      availablePrograms={{
        availableEnginePrograms:
          availableEnginePrograms?.map((program) => {
            return {
              value: program.id,
              label: program.maintenanceProgramName,
            }
          }) ?? [],
      }}
      changePrograms={{
        changeEngineProgram,
      }}
      assignedEngineProgramId={engineAssignedKBProgramId}
    />
  )
}
