import { type IMaintenanceScheduleDisplay } from '../../models'
import {
  Button,
  ButtonVariant,
  CollapsibleContainer,
  ComponentContainer,
  AsyncErrorBoundary,
  FullScreenPopover,
  IconVariant,
  Size,
  type ReportDisplayType,
  type MonthlyStatistics,
  type AssetComponentsMonthlyStatistics,
  type AssetType,
} from '@flyward/platform'
import { useState } from 'react'
import { StatisticsTableContainer } from './StatisticsTableContainer'

interface IStatisticsProps {
  reportDisplayType: ReportDisplayType
  allEvents: IMaintenanceScheduleDisplay[]
  assetMonthlyStatisticsSummary: MonthlyStatistics[]
  allReportComponentsMonthlyStatistics: AssetComponentsMonthlyStatistics
  key: string
  assetType?: AssetType
  isoStartDate: string
  isoEndDate: string
}

const Statistics = ({
  reportDisplayType,
  allEvents,
  assetMonthlyStatisticsSummary,
  allReportComponentsMonthlyStatistics,
  key,
  assetType,
  isoStartDate,
  isoEndDate,
}: IStatisticsProps) => {
  const [isStatisticsTableInFullPage, setIsStatisticsTableInFullPage] = useState<boolean>(false)

  return (
    <>
      <ComponentContainer className="p-6">
        <CollapsibleContainer
          title="Table and month/event map"
          containerClassName="w-full"
          labelClassName="font-semibold text-text-2"
          button={
            <Button
              variant={ButtonVariant.Ghost}
              leftIcon={IconVariant.FullScreen}
              className="w-14"
              size={Size.Large}
              onClick={() => {
                setIsStatisticsTableInFullPage(true)
              }}
            />
          }
        >
          <AsyncErrorBoundary>
            <StatisticsTableContainer
              reportDisplayType={reportDisplayType}
              allEvents={allEvents}
              key={key}
              assetType={assetType}
              assetMonthlyStatisticsSummary={assetMonthlyStatisticsSummary}
              allReportComponentsMonthlyStatistics={allReportComponentsMonthlyStatistics}
              startDate={isoStartDate}
              endDate={isoEndDate}
            />
          </AsyncErrorBoundary>
        </CollapsibleContainer>
      </ComponentContainer>
      <FullScreenPopover
        content={
          <StatisticsTableContainer
            reportDisplayType={reportDisplayType}
            allEvents={allEvents}
            key={key}
            assetType={assetType}
            assetMonthlyStatisticsSummary={assetMonthlyStatisticsSummary}
            allReportComponentsMonthlyStatistics={allReportComponentsMonthlyStatistics}
            startDate={isoStartDate}
            endDate={isoEndDate}
            isFullPage
          />
        }
        isOpen={isStatisticsTableInFullPage}
        onClose={() => {
          setIsStatisticsTableInFullPage(false)
        }}
        popoverTitle="Table and month/event map"
      />
    </>
  )
}

export { Statistics }
