import React, { useEffect } from 'react'
import { Box, Button, TextField, Typography } from '@mui/material'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { validateNullableDate, validateRequiredDate } from '@flyward/platform/helpers/inputValidators'
import { dateToIsoString } from '@flyward/platform/helpers/dateHelpers'
import { isNil, isEmpty } from 'lodash'

import { DatePicker as MUIDatePicker } from '@mui/x-date-pickers/DatePicker'
import { NumericFormat } from 'react-number-format'

const MIN_VALUE = 0
const MAX_VALUE_PERCENTAGE = 99
const MAX_VALUE_HOURS = 1000
const MAX_VALUE_CYCLES = 3000

const MAX_LENGTH = 200
const MAX_LENGTH_MESSAGE = 'Maximum 200 characters allowed'

const generalSchema = z.object({
  sn: z.string().min(1, 'MSN is required').max(MAX_LENGTH, MAX_LENGTH_MESSAGE),
  manufacturer: z.string().optional().nullable(),
  model: z.string().optional().nullable(),
  dom: validateRequiredDate('DOM'),
  dateOfLastTechSpec: validateRequiredDate('Tech Spec Date'),
  leaseStartDate: validateNullableDate,
  leaseEndDate: validateNullableDate,
  lessee: z.string().optional().nullable(),
  lesseeHabitualBase: z.string().optional().nullable(),
  portfolio: z.string().optional().nullable(),
  region: z.string().optional().nullable(),
  averageMonthlyFlightHours: z.number().optional().nullable(),
  averageMonthlyFlightCycles: z.number().optional().nullable(),
  averageMonthlyAPUHours: z.number().optional().nullable(),
  registrationNo: z.string().optional().nullable(),
})

export type GeneralFormData = z.infer<typeof generalSchema>

interface GeneralProps {
  onNext: (_data: GeneralFormData) => void
  onBack?: () => void
  stepTitle: string
  initialData?: GeneralFormData
  isAiPopulated?: boolean
}

const AircraftGeneral: React.FC<GeneralProps> = ({ onNext, stepTitle, initialData, isAiPopulated }) => {
  const {
    register,
    formState: { errors, touchedFields },
    watch,
    trigger,
    handleSubmit,
    setValue,
  } = useForm<GeneralFormData>({
    resolver: zodResolver(generalSchema),
    mode: 'all',
    defaultValues: {
      sn: initialData?.sn ?? '',
      manufacturer: initialData?.manufacturer,
      model: initialData?.model,
      dom: initialData?.dom ?? undefined,
      dateOfLastTechSpec: initialData?.dateOfLastTechSpec ?? undefined,
      leaseStartDate: initialData?.leaseStartDate,
      leaseEndDate: initialData?.leaseEndDate,
      lessee: initialData?.lessee,
      lesseeHabitualBase: initialData?.lesseeHabitualBase,
      portfolio: initialData?.portfolio,
      region: initialData?.region,
      averageMonthlyFlightHours: initialData?.averageMonthlyFlightHours,
      averageMonthlyFlightCycles: initialData?.averageMonthlyFlightCycles,
      averageMonthlyAPUHours: initialData?.averageMonthlyAPUHours,
      registrationNo: initialData?.registrationNo,
    },
  })

  useEffect(() => {
    if (isAiPopulated) {
      trigger()
    }
  }, [isAiPopulated])

  const snValue = watch('sn') || ''
  const manufacturerValue = watch('manufacturer') || ''
  const modelValue = watch('model') || ''
  const domValue = watch('dom') || ''
  const dateOfLastTechSpecValue = watch('dateOfLastTechSpec') || ''
  const leaseStartDateValue = watch('leaseStartDate') || ''
  const leaseEndDateValue = watch('leaseEndDate') || ''
  const lesseeValue = watch('lessee') || ''
  const lesseeHabitualBaseValue = watch('lesseeHabitualBase') || ''
  const portfolioValue = watch('portfolio') || ''
  const regionValue = watch('region') || ''
  const averageMonthlyFlightHoursValue = watch('averageMonthlyFlightHours') || 0
  const averageMonthlyFlightCyclesValue = watch('averageMonthlyFlightCycles') || 0
  const averageMonthlyAPUHoursValue = watch('averageMonthlyAPUHours') || 0
  const registrationNoValue = watch('registrationNo') || ''

  const getHelperText = (value: string, touched: boolean, error?: string) => {
    if (value.length > MAX_LENGTH) return MAX_LENGTH_MESSAGE
    if (touched && error && error !== MAX_LENGTH_MESSAGE) return error
    return ''
  }

  const handleContinue = async () => {
    const isValidForm = await trigger()
    if (isValidForm) {
      handleSubmit(onNext)()
    }
  }

  const isFormEmpty = !snValue || !dateOfLastTechSpecValue
  const hasLengthErrors = [snValue, manufacturerValue, registrationNoValue, modelValue].some((value) => value.length > MAX_LENGTH)
  const hasValidationErrors = Object.keys(errors).length > 0
  const isButtonDisabled = isFormEmpty || hasLengthErrors || hasValidationErrors

  return (
    <div className="shadow-sm rounded-lg bg-white p-6" style={{ minWidth: '32rem' }}>
      <Typography variant="h6" sx={{ mb: 3 }}>
        {stepTitle}
      </Typography>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 3,
          mb: 4,
        }}
      >
        <Box sx={{ display: 'flex', gap: 2 }}>
          <TextField
            {...register('sn')}
            error={(touchedFields?.sn && !!errors.sn) ?? snValue.length > MAX_LENGTH}
            label="MSN *"
            helperText={getHelperText(snValue, !!touchedFields?.sn, errors.sn?.message)}
            sx={{ flex: 1 }}
          />

          <MUIDatePicker
            label="DOM *"
            value={!isNil(domValue) && !isEmpty(domValue) ? new Date(domValue) : undefined}
            onChange={(newValue) => {
              const isoFormat = isNil(newValue) ? undefined : dateToIsoString(newValue)
              setValue('dom', isoFormat)
              trigger('dom')
            }}
            slotProps={{
              textField: {
                helperText: errors?.dom?.message,
                error: !!errors?.dom?.message,
                onBlur: () => {
                  trigger('dom')
                },
              },
              field: {
                clearable: true,
                onClear: () => {
                  setValue('dom', undefined)
                  trigger('dom')
                },
              },
            }}
            sx={{ flex: 1 }}
          />

          <MUIDatePicker
            label="Tech Spec Date *"
            value={!isNil(dateOfLastTechSpecValue) && !isEmpty(dateOfLastTechSpecValue) ? new Date(dateOfLastTechSpecValue) : undefined}
            onChange={(newValue) => {
              const isoFormat = isNil(newValue) ? undefined : dateToIsoString(newValue)
              setValue('dateOfLastTechSpec', isoFormat)
              trigger('dateOfLastTechSpec')
            }}
            slotProps={{
              textField: {
                helperText: errors?.dateOfLastTechSpec?.message,
                error: !!errors?.dateOfLastTechSpec?.message,
                onBlur: () => {
                  trigger('dateOfLastTechSpec')
                },
              },
              field: {
                clearable: true,
                onClear: () => {
                  setValue('dateOfLastTechSpec', undefined)
                  trigger('dateOfLastTechSpec')
                },
              },
            }}
            sx={{ flex: 1 }}
          />
        </Box>
        <Box sx={{ display: 'flex', gap: 2 }}>
          <TextField
            {...register('registrationNo')}
            error={(touchedFields?.registrationNo && !!errors.registrationNo) ?? registrationNoValue.length > MAX_LENGTH}
            label="Registration"
            helperText={getHelperText(registrationNoValue, !!touchedFields?.registrationNo, errors.registrationNo?.message)}
            sx={{ flex: 1 }}
          />

          <TextField
            {...register('manufacturer')}
            error={(touchedFields?.manufacturer && !!errors.manufacturer) ?? manufacturerValue.length > MAX_LENGTH}
            label="Manufacturer"
            helperText={getHelperText(manufacturerValue, !!touchedFields?.manufacturer, errors.manufacturer?.message)}
            sx={{ flex: 1 }}
          />
          <TextField
            {...register('model')}
            error={(touchedFields?.model && !!errors.model) ?? modelValue.length > MAX_LENGTH}
            label="Aircraft Type"
            helperText={getHelperText(modelValue, !!touchedFields?.model, errors.model?.message)}
            sx={{ flex: 1 }}
          />
        </Box>

        <Box sx={{ display: 'flex', gap: 2 }}>
          <MUIDatePicker
            label="Lease Start Date"
            value={!isNil(leaseStartDateValue) && !isEmpty(leaseStartDateValue) ? new Date(leaseStartDateValue) : undefined}
            onChange={(newValue) => {
              const isoFormat = isNil(newValue) ? undefined : dateToIsoString(newValue)
              setValue('leaseStartDate', isoFormat)
              trigger('leaseStartDate')
            }}
            slotProps={{
              field: {
                clearable: true,
                onClear: () => {
                  setValue('leaseStartDate', undefined)
                  trigger('leaseStartDate')
                },
              },
            }}
            sx={{ flex: 1 }}
          />

          <MUIDatePicker
            label="Lease End Date"
            value={!isNil(leaseEndDateValue) && !isEmpty(leaseEndDateValue) ? new Date(leaseEndDateValue) : undefined}
            onChange={(newValue) => {
              const isoFormat = isNil(newValue) ? undefined : dateToIsoString(newValue)
              setValue('leaseEndDate', isoFormat)
              trigger('leaseEndDate')
            }}
            slotProps={{
              field: {
                clearable: true,
                onClear: () => {
                  setValue('leaseEndDate', undefined)
                  trigger('leaseEndDate')
                },
              },
            }}
            sx={{ flex: 1 }}
          />

          <TextField
            {...register('lessee')}
            error={(touchedFields?.lessee && !!errors.lessee) ?? lesseeValue.length > MAX_LENGTH}
            label="Operator"
            helperText={getHelperText(lesseeValue, !!touchedFields?.lessee, errors.lessee?.message)}
            sx={{ flex: 1 }}
          />
        </Box>

        <Box sx={{ display: 'flex', gap: 2 }}>
          <TextField
            {...register('lesseeHabitualBase')}
            error={(touchedFields?.lesseeHabitualBase && !!errors.lesseeHabitualBase) ?? lesseeHabitualBaseValue.length > MAX_LENGTH}
            label="Lessee Habitual Base"
            helperText={getHelperText(lesseeHabitualBaseValue, !!touchedFields?.lesseeHabitualBase, errors.lesseeHabitualBase?.message)}
            sx={{ flex: 1 }}
          />

          <TextField
            {...register('region')}
            error={(touchedFields?.region && !!errors.region) ?? regionValue.length > MAX_LENGTH}
            label="Region"
            helperText={getHelperText(regionValue, !!touchedFields?.region, errors.region?.message)}
            sx={{ flex: 1 }}
          />

          <TextField
            {...register('portfolio')}
            error={(touchedFields?.portfolio && !!errors.portfolio) ?? portfolioValue.length > MAX_LENGTH}
            label="Portfolio"
            helperText={getHelperText(portfolioValue, !!touchedFields?.portfolio, errors.portfolio?.message)}
            sx={{ flex: 1 }}
          />
        </Box>

        <Box sx={{ display: 'flex', gap: 2 }}>
          <NumericFormat
            customInput={TextField}
            label="Average Monthly Flight Hours"
            value={averageMonthlyFlightHoursValue}
            error={touchedFields?.averageMonthlyFlightHours && !!errors?.averageMonthlyFlightHours}
            helperText={getHelperText(modelValue, !!touchedFields?.averageMonthlyFlightHours, errors.averageMonthlyFlightHours?.message)}
            thousandSeparator
            decimalScale={0}
            allowNegative={false}
            isAllowed={({ floatValue }) => floatValue === undefined || (floatValue >= MIN_VALUE && floatValue <= MAX_VALUE_HOURS)}
            onValueChange={({ floatValue }) => {
              setValue('averageMonthlyFlightHours', floatValue!, {
                shouldValidate: true,
                shouldTouch: true,
              })
            }}
            onBlur={() => {
              if (!touchedFields?.averageMonthlyFlightHours) {
                setValue('averageMonthlyFlightHours', averageMonthlyFlightHoursValue, {
                  shouldTouch: true,
                  shouldValidate: true,
                })
              }
            }}
            sx={{ flex: 1 }}
          />

          <NumericFormat
            customInput={TextField}
            label="Average Monthly Flight Cycles"
            value={averageMonthlyFlightCyclesValue}
            error={touchedFields?.averageMonthlyFlightCycles && !!errors?.averageMonthlyFlightCycles}
            helperText={getHelperText(modelValue, !!touchedFields?.averageMonthlyFlightCycles, errors.averageMonthlyFlightCycles?.message)}
            thousandSeparator
            decimalScale={0}
            allowNegative={false}
            isAllowed={({ floatValue }) => floatValue === undefined || (floatValue >= MIN_VALUE && floatValue <= MAX_VALUE_HOURS)}
            onValueChange={({ floatValue }) => {
              setValue('averageMonthlyFlightCycles', floatValue!, {
                shouldValidate: true,
                shouldTouch: true,
              })
            }}
            onBlur={() => {
              if (!touchedFields?.averageMonthlyFlightCycles) {
                setValue('averageMonthlyFlightCycles', averageMonthlyFlightCyclesValue, {
                  shouldTouch: true,
                  shouldValidate: true,
                })
              }
            }}
            sx={{ flex: 1 }}
          />

          <NumericFormat
            customInput={TextField}
            label="Average Monthly APU Hours"
            value={averageMonthlyAPUHoursValue}
            error={touchedFields?.averageMonthlyAPUHours && !!errors?.averageMonthlyAPUHours}
            helperText={getHelperText(modelValue, !!touchedFields?.averageMonthlyAPUHours, errors.averageMonthlyAPUHours?.message)}
            thousandSeparator
            decimalScale={0}
            allowNegative={false}
            isAllowed={({ floatValue }) => floatValue === undefined || (floatValue >= MIN_VALUE && floatValue <= MAX_VALUE_CYCLES)}
            onValueChange={({ floatValue }) => {
              setValue('averageMonthlyAPUHours', floatValue!, {
                shouldValidate: true,
                shouldTouch: true,
              })
            }}
            onBlur={() => {
              if (!touchedFields?.averageMonthlyAPUHours) {
                setValue('averageMonthlyAPUHours', averageMonthlyAPUHoursValue, {
                  shouldTouch: true,
                  shouldValidate: true,
                })
              }
            }}
            sx={{ flex: 1 }}
          />
        </Box>
      </Box>

      <Box>
        <Button variant="contained" onClick={handleContinue} disabled={isButtonDisabled}>
          Continue
        </Button>
      </Box>
    </div>
  )
}

export { AircraftGeneral }
