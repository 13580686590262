import { ROUTES } from '@flyward/main-app/providers/routes'

export enum ComponentType {
  // ComponentMainUnit level
  Airframe = 1,
  EngineUnit = 2,
  AuxiliaryPowerUnit = 3,
  LandingGear = 4,
  ThrustReversers = 5,

  // ComponentSubUnit level
  EngineCore = 101,

  // ComponentModule level
  // nothing here yet

  // Subcomponent level
  EngineLlp = 301,
}

export const COMPONENT_TYPES = {
  [ComponentType.Airframe]: {
    id: 'AIRFRAME',
    displayName: 'Airframe',
    wizardUri: ROUTES.MAINTENANCE_PROGRAMS.WIZARD.AIRFRAME,
  },
  [ComponentType.EngineUnit]: {
    id: 'ENGINE_UNIT',
    displayName: 'Engine Unit',
    wizardUri: ROUTES.MAINTENANCE_PROGRAMS.WIZARD.ENGINE,
  },
  [ComponentType.AuxiliaryPowerUnit]: {
    id: 'APU',
    displayName: 'APU',
    wizardUri: ROUTES.MAINTENANCE_PROGRAMS.WIZARD.AUXILIARY_POWER_UNIT,
  },
  [ComponentType.LandingGear]: {
    id: 'LANDING_GEAR',
    displayName: 'Landing Gear',
    wizardUri: ROUTES.MAINTENANCE_PROGRAMS.WIZARD.LANDING_GEAR,
  },
  [ComponentType.EngineCore]: {
    id: 'ENGINE_CORE',
    displayName: 'Engine Core',
    wizardUri: null,
  },
  [ComponentType.EngineLlp]: {
    id: 'ENGINE_LLP',
    displayName: 'Engine LLP',
    wizardUri: null,
  },
  [ComponentType.ThrustReversers]: {
    id: 'THRUST_REVERSERS',
    displayName: 'Thrust Reversers',
    wizardUri: null,
  },
} as const

export const getComponentTypeDisplay = (type: ComponentType): string => {
  return COMPONENT_TYPES[type]?.displayName ?? ''
}
