import React from 'react'
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { LLPTableRowData } from './types'

interface LLPTableReadOnlyProps {
  rows: LLPTableRowData[]
}

export const LLPTableReadOnly = ({ rows }: LLPTableReadOnlyProps) => {
  return (
    <TableContainer component={Paper}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Module</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>Part Number</TableCell>
            <TableCell>Serial Number</TableCell>
            <TableCell>Limit</TableCell>
            <TableCell>CSN</TableCell>
            <TableCell>Cycles Remaining</TableCell>
            <TableCell>CSN @ Contract Delivery</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <TableRow key={index}>
              <TableCell>{row.module || '-'}</TableCell>
              <TableCell>{row.description || '-'}</TableCell>
              <TableCell>{row.partNumber || '-'}</TableCell>
              <TableCell>{row.serialNumber || '-'}</TableCell>
              <TableCell>{row.limit || '-'}</TableCell>
              <TableCell>{row.csn || '-'}</TableCell>
              <TableCell>{row.cyclesRemaining || '-'}</TableCell>
              <TableCell>{row.csnAtContractDelivery || '-'}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
