import { useChangeStateMutation, useGetUsersQuery, useResendEmailMutation } from '@flyward/appIdentity/store'
import { type GetAllUsersDto, type ISelectOption, RoleDisplay, UserState, UserStateDisplay, errorMessages } from '@flyward/platform'
import { isNil } from 'lodash'
import { useCallback, useState } from 'react'
import { AddEditUser } from './AddEditUser'
import { useUserAuthenticated } from '@flyward/appIdentity'
import { fromIsoToFormatDate } from '@flyward/platform/helpers/dateHelpers'
import { showError, showSuccess } from '@flyward/platform/services'
import { Typography } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Select, MenuItem, IconButton, Tooltip } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import EmailIcon from '@mui/icons-material/Email'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import { Dialog as MuiDialog, DialogContent, DialogActions, Button as MuiButton } from '@mui/material'

enum Action {
  None,
  ResendInvitation,
  ChangeStatus,
}

type SortConfig = {
  key: keyof GetAllUsersDto | null
  direction: 'asc' | 'desc'
}

const tableStyles = {
  headerCell: {
    height: '3rem',
    fontWeight: 600,
    fontSize: '0.9rem',
    borderBottom: '1px solid var(--black-20)',
    padding: '0 16px',
  },
}

export const UserManagement = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [isEditMode, setIsEditMode] = useState<boolean>(false)
  const [selectedUser, setSelectedUser] = useState<GetAllUsersDto | null>(null)
  const [confirmQuestion, setConfirmQuestion] = useState<string>('')
  const [userId, setUserId] = useState<string>('')
  const [option, setOption] = useState<Action>(Action.None)
  const { data: users } = useGetUsersQuery()
  const [changeState] = useChangeStateMutation()
  const [resendInvitation] = useResendEmailMutation()
  const { loggedUser } = useUserAuthenticated()
  const userStates: ISelectOption[] = [
    { label: UserStateDisplay(UserState.Active), value: UserState.Active.toString() },
    { label: UserStateDisplay(UserState.Inactive), value: UserState.Inactive.toString() },
  ]
  const [sortConfig, setSortConfig] = useState<SortConfig>({ key: null, direction: 'asc' })
  const [dialogOpen, setDialogOpen] = useState(false)

  const handleCloseDialog = () => {
    setDialogOpen(false)
  }

  const handleOpenDialog = (action: Action, userId: string, confirmMessage: string) => {
    setOption(action)
    setUserId(userId)
    setConfirmQuestion(confirmMessage)
    setDialogOpen(true)
  }

  const handleSave = useCallback(async () => {
    if (option === Action.ResendInvitation) {
      const { error: invitationError } = await resendInvitation(userId)
      if (isNil(invitationError)) {
        showSuccess('Invitation resent successfully!')
      } else {
        showError(errorMessages.userManagement.sendVerificationEmailError)
      }
    } else if (option === Action.ChangeStatus) {
      const { error: invitationError } = await changeState(userId)
      if (isNil(invitationError)) {
        showSuccess('Status changed successfully!')
      } else {
        showError(errorMessages.userManagement.changeStateError)
      }
    }
  }, [changeState, option, resendInvitation, userId])

  const handleSort = (key: keyof GetAllUsersDto) => {
    setSortConfig((currentSort) => ({
      key,
      direction: currentSort.key === key && currentSort.direction === 'asc' ? 'desc' : 'asc',
    }))
  }

  const sortedUsers = users?.slice().sort((a, b) => {
    if (!sortConfig.key) return 0

    const aValue = a[sortConfig.key]
    const bValue = b[sortConfig.key]

    if (aValue === null || bValue === null) return 0

    if (typeof aValue === 'string' && typeof bValue === 'string') {
      return sortConfig.direction === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue)
    }

    return sortConfig.direction === 'asc' ? (aValue < bValue ? -1 : 1) : bValue < aValue ? -1 : 1
  })

  const SortIcon = ({ columnKey }: { columnKey: keyof GetAllUsersDto }) => {
    if (sortConfig.key !== columnKey) return null
    return sortConfig.direction === 'asc' ? <ArrowUpwardIcon fontSize="small" /> : <ArrowDownwardIcon fontSize="small" />
  }

  const headerCellStyle = (key: keyof GetAllUsersDto) => ({
    ...tableStyles.headerCell,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'var(--black-5)',
    },
  })

  return (
    <>
      {/* Header */}
      <div className="flex h-20 w-full items-center justify-between px-2">
        <Typography sx={{ fontSize: '1.25rem', fontWeight: 600, color: 'var(--text-1)' }}>User Management</Typography>
        <div className="flex items-center gap-4">
          <MuiButton
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => {
              setSelectedUser(null)
              setIsEditMode(false)
              setIsOpen(true)
            }}
            sx={{
              backgroundColor: 'var(--primary)',
              '&:hover': {
                backgroundColor: 'var(--primary-light-1)',
              },
              textTransform: 'none',
            }}
          >
            Add user
          </MuiButton>
        </div>
      </div>
      <TableContainer component={Paper}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell sx={headerCellStyle('firstName')} onClick={() => handleSort('firstName')}>
                First Name {sortConfig.key === 'firstName' && <SortIcon columnKey="firstName" />}
              </TableCell>
              <TableCell sx={headerCellStyle('lastName')} onClick={() => handleSort('lastName')}>
                Last Name {sortConfig.key === 'lastName' && <SortIcon columnKey="lastName" />}
              </TableCell>
              <TableCell sx={headerCellStyle('email')} onClick={() => handleSort('email')}>
                Email {sortConfig.key === 'email' && <SortIcon columnKey="email" />}
              </TableCell>
              <TableCell sx={headerCellStyle('role')} onClick={() => handleSort('role')}>
                Role {sortConfig.key === 'role' && <SortIcon columnKey="role" />}
              </TableCell>
              <TableCell sx={headerCellStyle('state')} onClick={() => handleSort('state')}>
                Status {sortConfig.key === 'state' && <SortIcon columnKey="state" />}
              </TableCell>
              <TableCell sx={headerCellStyle('createdAt')} onClick={() => handleSort('createdAt')}>
                Profile Added {sortConfig.key === 'createdAt' && <SortIcon columnKey="createdAt" />}
              </TableCell>
              <TableCell sx={tableStyles.headerCell} align="right">
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedUsers?.map((user) => (
              <TableRow
                key={user.userId}
                sx={{
                  '&:nth-of-type(odd)': {
                    backgroundColor: 'var(--black-2)',
                  },
                  '&:nth-of-type(even)': {
                    backgroundColor: 'var(--black-0)',
                  },
                }}
              >
                <TableCell>{user.firstName}</TableCell>
                <TableCell>{user.lastName}</TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>{RoleDisplay(user.role)}</TableCell>
                <TableCell>
                  <div className="flex items-center gap-2">
                    <FiberManualRecordIcon
                      sx={{
                        fontSize: 12,
                        color: user.state === UserState.Active ? 'success.main' : 'text.disabled',
                      }}
                    />
                    {user.state !== UserState.PendingInvitation && !isNil(loggedUser) && loggedUser.id !== user.userId ? (
                      <Select
                        value={user.state.toString()}
                        onChange={() => {
                          handleOpenDialog(Action.ChangeStatus, user.userId, 'Are you sure you want to change the status?')
                        }}
                        variant="standard"
                        size="small"
                        sx={{
                          height: '2rem',
                          minWidth: '7rem',
                          fontSize: '0.875rem',
                          '&:before': {
                            borderBottom: 'none',
                          },
                          '&:after': {
                            borderBottom: 'none',
                          },
                          '& .MuiSelect-select': {
                            fontSize: '0.875rem',
                            padding: '2px 8px',
                          },
                          '& .MuiMenuItem-root': {
                            fontSize: '0.875rem',
                          },
                        }}
                      >
                        {userStates.map((state) => (
                          <MenuItem key={state.value} value={state.value}>
                            {state.label}
                          </MenuItem>
                        ))}
                      </Select>
                    ) : (
                      <span className={user.state === UserState.PendingInvitation || (user.state === UserState.Active && loggedUser?.id === user.userId) ? 'pl-2' : ''}>
                        {UserStateDisplay(user.state)}
                      </span>
                    )}
                  </div>
                </TableCell>
                <TableCell>{fromIsoToFormatDate(user.createdAt.toString())}</TableCell>
                <TableCell align="right">
                  <div className="flex justify-end gap-2">
                    {!isNil(loggedUser) && loggedUser?.id !== user.userId && (
                      <Tooltip title="Edit user details" placement="top" arrow>
                        <IconButton
                          size="small"
                          onClick={() => {
                            setSelectedUser(user)
                            setIsOpen(true)
                            setIsEditMode(true)
                          }}
                          sx={{ color: 'var(--primary)' }}
                        >
                          <EditIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    )}
                    {!user.isEmailVerified && (
                      <Tooltip title="Resend verification email" placement="top" arrow>
                        <IconButton
                          size="small"
                          onClick={() => {
                            handleOpenDialog(Action.ResendInvitation, user.userId, 'Are you sure you want to resend the invitation?')
                          }}
                          sx={{ color: 'var(--primary)' }}
                        >
                          <EmailIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    )}
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <AddEditUser isEditMode={isEditMode} isOpen={isOpen} setIsOpen={setIsOpen} user={selectedUser} />
      <MuiDialog open={dialogOpen} onClose={handleCloseDialog} maxWidth="xs" fullWidth>
        <DialogContent>{confirmQuestion}</DialogContent>
        <DialogActions>
          <MuiButton onClick={handleCloseDialog} variant="outlined">
            Cancel
          </MuiButton>
          <MuiButton
            onClick={() => {
              handleSave()
              handleCloseDialog()
            }}
            variant="contained"
            sx={{
              backgroundColor: 'var(--primary)',
              '&:hover': {
                backgroundColor: 'var(--primary-light-1)',
              },
            }}
          >
            Confirm
          </MuiButton>
        </DialogActions>
      </MuiDialog>
    </>
  )
}
