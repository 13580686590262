import { type AircraftEngineDto, AircraftMaintenanceProgram, type AirframeDto, type ApuDto, type LandingGearDto } from '@flyward/assets'
import {
  useGetAllAirframeMaintenanceProgramsQuery,
  useGetAllAuxiliaryPowerUnitMaintenanceProgramsQuery,
  useGetAllEngineMaintenanceProgramsQuery,
  useGetAllLandingGearMaintenanceProgramsQuery,
  useUpdateAirframeMaintenanceProgramMutation,
  useUpdateAuxiliaryPowerUnitsMaintenanceProgramMutation,
  useUpdateEnginesMaintenanceProgramMutation,
  useUpdateLandingGearsMaintenanceProgramMutation,
} from '@flyward/platform/store'

interface IAircraftMaintenanceProgramsSectionProps {
  assetId: string
  airframesIds: string[]
  enginesIds: string[]
  apusIds: string[]
  lgIds: string[]
  airframeKBProgramId: string
  engineKBProgramId: string
  auxiliaryPowerUnitKBProgramId: string
  landingGearKBProgramId: string
  airframes: AirframeDto[] | undefined
  engines: AircraftEngineDto[] | undefined
  auxiliaryPowerUnits: ApuDto[] | undefined
  landingGears: LandingGearDto[] | undefined
}

export const AircraftMaintenanceProgramsSection = ({
  assetId,
  airframesIds,
  enginesIds,
  apusIds,
  lgIds,
  airframeKBProgramId,
  engineKBProgramId,
  auxiliaryPowerUnitKBProgramId,
  landingGearKBProgramId,
  airframes,
  engines,
  auxiliaryPowerUnits,
  landingGears,
}: IAircraftMaintenanceProgramsSectionProps) => {
  const [updateAirframeMaintenanceProgram] = useUpdateAirframeMaintenanceProgramMutation()
  const [updateEngineMaintenanceProgram] = useUpdateEnginesMaintenanceProgramMutation()
  const [updateAuxiliaryPowerUnitMaintenanceProgram] = useUpdateAuxiliaryPowerUnitsMaintenanceProgramMutation()
  const [updateLandingGearMaintenanceProgram] = useUpdateLandingGearsMaintenanceProgramMutation()

  const { data: availableEnginePrograms } = useGetAllEngineMaintenanceProgramsQuery(false)
  const { data: availableAirframePrograms } = useGetAllAirframeMaintenanceProgramsQuery(false)
  const { data: availableLandingGearPrograms } = useGetAllLandingGearMaintenanceProgramsQuery(false)
  const { data: availableAuxiliaryPowerUnitPrograms } = useGetAllAuxiliaryPowerUnitMaintenanceProgramsQuery(false)

  const changeAirframeProgram = async (airframeProgramId: string) => {
    const result = await updateAirframeMaintenanceProgram({ assetId, kbProgramId: airframeProgramId, airframes })
    return result.error?.message
  }

  const changeEngineProgram = async (engineProgramId: string) => {
    const result = await updateEngineMaintenanceProgram({ assetId, kbProgramId: engineProgramId, engines })
    return result.error?.message
  }

  const changeAuxiliaryPowerUnitProgram = async (auxiliaryPowerUnitProgramId: string) => {
    const result = await updateAuxiliaryPowerUnitMaintenanceProgram({ assetId, kbProgramId: auxiliaryPowerUnitProgramId, auxiliaryPowerUnits })
    return result.error?.message
  }

  const changeLandingGearProgram = async (landingGearProgramId: string) => {
    const result = await updateLandingGearMaintenanceProgram({ assetId, kbProgramId: landingGearProgramId, landingGears })
    return result.error?.message
  }

  return (
    <AircraftMaintenanceProgram
      assetId={assetId}
      airframesIds={airframesIds}
      enginesIds={enginesIds}
      apusIds={apusIds}
      lgIds={lgIds}
      availablePrograms={{
        availableAirframePrograms:
          availableAirframePrograms?.map((program) => {
            return {
              value: program.id,
              label: program.maintenanceProgramName,
            }
          }) ?? [],
        availableEnginePrograms:
          availableEnginePrograms?.map((program) => {
            return {
              value: program.id,
              label: program.maintenanceProgramName,
            }
          }) ?? [],
        availableLandingGearPrograms:
          availableLandingGearPrograms?.map((program) => {
            return {
              value: program.id,
              label: program.maintenanceProgramName,
            }
          }) ?? [],
        availableAuxiliaryPowerUnitPrograms:
          availableAuxiliaryPowerUnitPrograms?.map((program) => {
            return {
              value: program.id,
              label: program.maintenanceProgramName,
            }
          }) ?? [],
      }}
      changePrograms={{
        changeAirframeProgram,
        changeEngineProgram,
        changeAuxiliaryPowerUnitProgram,
        changeLandingGearProgram,
      }}
      assignedAirframeProgramId={airframeKBProgramId}
      assignedEngineProgramId={engineKBProgramId}
      assignedAPUProgramId={auxiliaryPowerUnitKBProgramId}
      assignedLandingGearProgramId={landingGearKBProgramId}
    />
  )
}
