import { AuthorizedElement, ElementForSupportUser, useUserAuthenticated, useZitadelAuthenticated } from '@flyward/appIdentity'
import { IconVariant, useNavigationState, cn, Button, ButtonVariant, Logo, LogoVariant } from '@flyward/platform'
import { isNil } from 'lodash'
import { useLocation, useNavigate, Link } from 'react-router-dom'
import { ROUTES } from '../../../providers/routes'
import { TenantSelector } from './components/TenantSelector'

interface INavMenuProps {
  routes: Array<{ path: string; label: string; icon: IconVariant }>
}

export const NavMenu = ({ routes }: INavMenuProps): React.ReactElement<INavMenuProps> => {
  const { isCollapsed, isNavigationHidden, isHidden, toggleExpansion } = useNavigationState()
  const location = useLocation()
  const { loggedUser, isUserLogged } = useUserAuthenticated()
  const navigate = useNavigate()
  const authContext = useZitadelAuthenticated()

  return (
    <div
      className={cn(
        'transition-all z-50 flex flex-shrink-0 whitespace-nowrap bg-black-0 duration-300 ease-in-out',
        'shadow-[0_8px_16px_0_rgba(0,0,0,0.12)]',
        '[clip-path:inset(0px_-30px_0px_0px)]',
        { hidden: isNavigationHidden || isHidden },
        isCollapsed ? 'w-20' : 'w-62',
      )}
    >
      <div className="flex h-full w-full flex-col">
        <div className={cn('transition-all z-50 flex flex-shrink-0 items-center gap-x-1 bg-black-0 py-6 duration-300 ease-in-out', isCollapsed ? 'pl-7' : 'pl-2')}>
          <Link className="transition-all flex w-full items-center duration-300 ease-in-out" to={`${ROUTES.FLEET.ROOT}`}>
            <div className="transition-all duration-300 ease-in-out">
              <Logo isCollapsed={isCollapsed} variant={LogoVariant.black} height={'18'} width={isCollapsed ? '18' : '126'} />
            </div>
          </Link>
        </div>

        <div className={cn('flex w-full flex-grow flex-col justify-between pb-6 pl-1 pt-2', isCollapsed && 'pl-5')}>
          <ul className="m-0 flex flex-col gap-y-4">
            {routes.map((route) => (
              <li key={route.path} className="mx-0 w-full p-0">
                <Button
                  variant={ButtonVariant.Navigation}
                  leftIcon={route.icon}
                  label={isCollapsed ? '' : route.label}
                  isPressed={location.pathname.includes(route.path)}
                  className="transition-opacity w-full duration-300 ease-in-out"
                  onClick={() => {
                    navigate(route.path)
                  }}
                />
              </li>
            ))}
          </ul>
          <div className="mt-auto h-auto w-full">
            <AuthorizedElement>
              <div data-permission-element-id="user-management-btn">
                <ElementForSupportUser>
                  <TenantSelector isCollapsed={isCollapsed} />
                </ElementForSupportUser>
                <Button
                  variant={ButtonVariant.Account}
                  leftIcon={IconVariant.UserManagement}
                  label={isCollapsed ? '' : 'User Management'}
                  className="transition-opacity w-full duration-300 ease-in-out"
                  onClick={() => {
                    navigate(ROUTES.USER_MANAGEMENT)
                  }}
                />
              </div>
            </AuthorizedElement>
            <Button
              variant={ButtonVariant.Account}
              leftIcon={IconVariant.AccountCircle}
              label={isCollapsed ? '' : (isUserLogged ?? false) && !isNil(loggedUser) ? loggedUser.fullName : ''}
              className="transition-opacity w-full duration-300 ease-in-out"
              onClick={() => {}}
            />
            {(isUserLogged ?? false) && (
              <Button
                variant={ButtonVariant.Account}
                leftIcon={IconVariant.LogOut}
                label={isCollapsed ? '' : 'Log out'}
                className="transition-opacity w-full duration-300 ease-in-out"
                onClick={() => {
                  authContext.logout()
                }}
              />
            )}
          </div>
        </div>
      </div>
      <div className="flex w-6.5 items-center">
        <Button
          data-testid="nav-menu-toggle"
          variant={ButtonVariant.Collapsible}
          leftIcon={isCollapsed ? IconVariant.KeyboardArrowRight : IconVariant.KeyboardArrowLeft}
          onClick={toggleExpansion}
          className={cn('transition-all z-50 duration-300 ease-in-out', isCollapsed ? 'ml-2.5' : 'ml-0')}
        />
      </div>
    </div>
  )
}
