import { type DerateRatio } from '@flyward/knowledgeBase/models'
import { type TableColumn, EditableInputCellWithLabelWithLabel, InputType } from '@flyward/platform'
import { type FieldValues, type Control, type Path } from 'react-hook-form'

export const existingTableColumns = <TFormData extends FieldValues>(formControl: Control<TFormData, unknown>): Array<TableColumn<DerateRatio>> => [
  {
    accessor: 'id',
    title: '',
    isIdField: true,
    isHidden: true,
  },
  {
    accessor: 'deratePct',
    title: 'Derate impact',
    customCell: ({ rowIndex: index }) => {
      const fieldName = `derateRatios.${index}.deratePct` as Path<TFormData>
      return (
        <div className="w-full min-w-full text-xs">
          <EditableInputCellWithLabelWithLabel inputType={InputType.Percentage} formControl={formControl} fieldName={fieldName} />
        </div>
      )
    },
    cellClassName: 'basis-3/6',
    headerClassName: 'basis-3/6',
  },
  {
    accessor: 'derateAdjustmentPct',
    title: 'PR Limit Adjustment',
    customCell: ({ rowIndex: index }) => {
      const fieldName = `derateRatios.${index}.derateAdjustmentPct` as Path<TFormData>
      return (
        <div className="w-full min-w-full text-xs">
          <EditableInputCellWithLabelWithLabel inputType={InputType.Percentage} formControl={formControl} fieldName={fieldName} />
        </div>
      )
    },
    cellClassName: 'basis-3/6',
    headerClassName: 'basis-3/6',
  },
]

export const newTableColumns = <TFormData extends FieldValues>(formControl: Control<TFormData, unknown>): Array<TableColumn<DerateRatio>> => [
  {
    accessor: 'id',
    title: '',
    isIdField: true,
    isHidden: true,
  },
  {
    accessor: 'deratePct',
    title: 'Derate impact',
    customCell: ({ rowIndex: index }) => {
      const fieldName = `derateRatioAddRemoveItems.addedItems.${index}.deratePct` as Path<TFormData>
      return (
        <div className="w-full min-w-full text-xs">
          <EditableInputCellWithLabelWithLabel inputType={InputType.Percentage} formControl={formControl} fieldName={fieldName} />
        </div>
      )
    },
    cellClassName: 'basis-3/6',
    headerClassName: 'basis-3/6',
  },
  {
    accessor: 'derateAdjustmentPct',
    title: 'PR Limit Adjustment',
    customCell: ({ rowIndex: index }) => {
      const fieldName = `derateRatioAddRemoveItems.addedItems.${index}.derateAdjustmentPct` as Path<TFormData>
      return (
        <div className="w-full min-w-full text-xs">
          <EditableInputCellWithLabelWithLabel inputType={InputType.Percentage} formControl={formControl} fieldName={fieldName} />
        </div>
      )
    },
    cellClassName: 'basis-3/6',
    headerClassName: 'basis-3/6',
  },
]
