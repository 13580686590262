import { useState } from 'react'
import { Tooltip as CnTooltip, ToolTipArrow, TooltipContent, TooltipProvider, TooltipTrigger } from '../_shadcn/tooltip'
import { cn } from '../_shadcn'

interface ITooltipLongTextProps {
  content: React.ReactNode
  tooltipContent: React.ReactNode
  className?: string
}

/**
 * @deprecated
 */
export const Tooltip = ({ content, tooltipContent, className }: ITooltipLongTextProps) => {
  const [open, setOpen] = useState(false)
  return (
    <TooltipProvider>
      <CnTooltip open={open}>
        <TooltipTrigger asChild>
          <span
            onMouseEnter={() => {
              setOpen(true)
            }}
            onMouseLeave={() => {
              setOpen(false)
            }}
            onTouchStart={() => {
              setOpen(!open)
            }}
          >
            {content}
          </span>
        </TooltipTrigger>
        <TooltipContent data-testid="tooltip-content" className={cn('z-100 pointer-events-none absolute bottom-0 w-max bg-black-0 opacity-100', className)}>
          {tooltipContent}
          <ToolTipArrow />
        </TooltipContent>
      </CnTooltip>
    </TooltipProvider>
  )
}
