import { APIRoutes } from '@flyward/platform/constants'
import { flywardApi } from '@flyward/platform/store/slices/api/flywardApi/flywardApiSlice'
import {
  type IGetAllAirframeMaintenanceProgramsQueryResponse,
  type IGetAirframeMaintenanceProgramDetailsQueryRequest,
  type IGetAirframeMaintenanceProgramDetailsQueryResponse,
  type IImportAirframeKnowledgeBase,
  type IPutAirframeMaintenanceProgramDetailsMutationRequest,
  type IPostAirframeMaintenanceProgramMutationRequest,
} from './types'

const airframeMaintenanceProgramsApi = flywardApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllAirframeMaintenancePrograms: builder.query<IGetAllAirframeMaintenanceProgramsQueryResponse, boolean>({
      query: (includeInactive) => ({
        url: APIRoutes.KnowledgeBaseModule.MaintenanceProgramsController.AirframesPrograms.GetAllAirframePrograms(),
        method: 'GET',
        params: { includeInactive },
      }),
      providesTags: ['apiData', 'airframeMaintenancePrograms'],
    }),
    getAirframeMaintenanceProgramDetails: builder.query<
      IGetAirframeMaintenanceProgramDetailsQueryResponse,
      IGetAirframeMaintenanceProgramDetailsQueryRequest
    >({
      query: ({ programId }) => ({
        url: APIRoutes.KnowledgeBaseModule.MaintenanceProgramsController.AirframesPrograms.GetSingleAirframeProgram(programId),
        method: 'GET',
      }),
      providesTags: (_, __, request) => ['apiData', { type: 'airframeMaintenancePrograms', id: request.programId }],
    }),
    importAirframeKnowledgeBase: builder.mutation<void, IImportAirframeKnowledgeBase>({
      query: ({ componentType, file }) => {
        const formData = new FormData()
        formData.append('inputFile', file)
        return {
          url: `${APIRoutes.KnowledgeBaseModule.MaintenancePlansController.ImportMaintenancePrograms()}?type=${componentType}`,
          method: 'POST',
          data: formData,
          headers: {
            'Content-Type': ' multipart/form-data',
          },
        }
      },
      invalidatesTags: [{ type: 'airframeMaintenancePrograms' }],
    }),
    createAirframeMaintenanceProgram: builder.mutation<string, IPostAirframeMaintenanceProgramMutationRequest>({
      query: ({ program }) => ({
        url: APIRoutes.KnowledgeBaseModule.MaintenanceProgramsController.AirframesPrograms.CreateAirframeProgram(),
        method: 'POST',
        data: program,
      }),
      invalidatesTags: (_, __, _request) => [{ type: 'airframeMaintenancePrograms' }, { type: 'assetVerification' }],
    }),
    updateAirframeMaintenanceProgramDetails: builder.mutation<string, IPutAirframeMaintenanceProgramDetailsMutationRequest>({
      query: ({ programId, airframe }) => ({
        url: APIRoutes.KnowledgeBaseModule.MaintenanceProgramsController.AirframesPrograms.UpdateAirframeProgram(programId),
        method: 'PUT',
        data: airframe,
      }),
      invalidatesTags: (_, __, _request) => [{ type: 'airframeMaintenancePrograms' }, { type: 'assetVerification' }],
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetAllAirframeMaintenanceProgramsQuery,
  useGetAirframeMaintenanceProgramDetailsQuery,
  useImportAirframeKnowledgeBaseMutation,
  useCreateAirframeMaintenanceProgramMutation,
  useUpdateAirframeMaintenanceProgramDetailsMutation,
  util: airframeMaintenanceProgramsApiSliceUtil,
} = airframeMaintenanceProgramsApi

type Endpoints = typeof airframeMaintenanceProgramsApi.endpoints
type InitiateActions = {
  [K in keyof Endpoints]: ReturnType<ReturnType<Endpoints[K]['initiate']>>
}
export type TAirframeMaintenanceProgramsApiActions = InitiateActions[keyof InitiateActions]
