import React, { useEffect } from 'react'
import { cn } from '@flyward/platform'
import { pageContentStyles } from '@flyward/main-app/layout'
import { ComponentContainer, useNavigationState } from '@flyward/platform'
import { Box, LinearProgress, Step, StepLabel, Stepper, styled, Typography } from '@mui/material'
import { useWizardState } from './hooks/useWizardState'
import { useWizardConfig } from './setup'
import { resetWizard } from './store/wizardSlice'
import { useDispatch } from 'react-redux'
import { FWBreadcrumbs } from '@flyward/main-app/layout/MasterLayout/Header/FWBreadcrumbs.tsx'

const VerticalStepper = styled(Stepper)({
  flexDirection: 'column',
  alignItems: 'flex-start',
  '& .MuiStep-root': {
    width: '100%',
  },
  '& .MuiStepLabel-root': {
    padding: '8px 0',
  },
  '& .MuiStepLabel-labelContainer': {
    display: 'flex',
    flexDirection: 'column',
  },
  '& .MuiStepLabel-label': {
    textAlign: 'left',
  },
})

// Add a styled component for the sub-label
const StepSubLabel = styled(Typography)({
  fontSize: '0.75rem',
  color: 'var(--text-3)',
  marginTop: '2px',
})

interface StepProgressBarProps {
  currentStep: number
  totalSteps: number
}
const StepProgressBar: React.FC<StepProgressBarProps> = ({ currentStep, totalSteps }) => {
  const progress = (currentStep / totalSteps) * 100

  return (
    <Box sx={{ width: '100%', mb: 2, display: 'flex', alignItems: 'center', gap: 2 }}>
      <LinearProgress
        variant="determinate"
        value={progress}
        sx={{
          height: 6,
          borderRadius: 3,
          flexGrow: 1,
          backgroundColor: 'var(--primary-light-2)',
          '& .MuiLinearProgress-bar': {
            backgroundColor: 'var(--primary-dark-1)',
          },
        }}
      />
      <Typography variant="body2" color="text.secondary" sx={{ minWidth: 'fit-content' }}>
        {currentStep}/{totalSteps}
      </Typography>
    </Box>
  )
}

export const WizardPage = ({ type }) => {
  const { toggleVisibility } = useNavigationState()
  const config = useWizardConfig(type)
  const { state, handleStepUpdate, handleStepChange } = useWizardState(type)
  const dispatch = useDispatch()

  useEffect(() => {
    toggleVisibility()
    return () => {
      toggleVisibility()
      dispatch(resetWizard())
    }
  }, [toggleVisibility, config.wizardTitle, dispatch])

  const currentStep = config.steps[state.activeStep] as {
    component: React.ComponentType<any>
    label: string
    key: string
  }

  const handleNext = (data) => {
    handleStepUpdate(currentStep.key, data)
  }

  const handleBack = () => {
    handleStepChange(state.activeStep - 1)
  }

  const StepComponent = currentStep.component
  return (
    <main className={cn('h-full w-full', pageContentStyles)}>
      <div className="flex h-20 w-full items-center px-2 py-9">
        <FWBreadcrumbs breadcrumbs={config.breadcrumbs} currentTitle={config.wizardTitle} />
      </div>
      <ComponentContainer className="h-full w-full !items-start !justify-start">
        <div className="shadow-md w-full rounded-lg bg-white p-6">
          <div className="flex w-full">
            {/* Left side - Vertical Stepper */}
            <div className="w-80 flex-shrink-0 border-r border-gray-300">
              <div className="shadow-sm h-full rounded-lg bg-white p-4">
                <Typography variant="subtitle1" fontWeight="bold">
                  {config.stepperTitle}
                </Typography>
                <StepProgressBar currentStep={state.activeStep + 1} totalSteps={config.steps.length} />
                <VerticalStepper activeStep={state.activeStep} orientation="vertical" className="mt-4">
                  {config.steps.map((step, index: number) => {
                    const isClickable = index <= state.activeStep
                    return (
                      <Step
                        key={index}
                        onClick={() => isClickable && handleStepChange(index)}
                        sx={{
                          cursor: isClickable ? 'pointer' : 'default',
                          '&:hover': {
                            backgroundColor: isClickable ? 'rgba(0, 0, 0, 0.04)' : 'transparent',
                          },
                          transition: 'background-color 0.2s',
                        }}
                      >
                        <StepLabel>
                          <div>
                            {step.label}
                            {step.subLabel && <StepSubLabel>{step.subLabel}</StepSubLabel>}
                          </div>
                        </StepLabel>
                      </Step>
                    )
                  })}
                </VerticalStepper>
              </div>
            </div>

            {/* Right side - Content */}
            <div className="pl-6">
              <StepComponent onNext={handleNext} onBack={handleBack} stepTitle={currentStep.label} initialData={state[currentStep.key]} />
            </div>
          </div>
        </div>
      </ComponentContainer>
    </main>
  )
}
