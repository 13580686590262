import { NumberDisplay, NumberDisplayType, type TableColumn } from '@flyward/platform'
import { type DerateRatio } from '../../../../models'
import { MaintenanceProgramDetailsTable } from '../../MaintenanceProgramDetailsTable'

interface IDerateRatiosReadOnlyTableProps {
  derateRatios: DerateRatio[] | undefined
  tableClassName?: string
}
const tableColumns: Array<TableColumn<DerateRatio>> = [
  {
    accessor: 'id',
    title: '',
    isIdField: true,
    isHidden: true,
  },
  {
    accessor: 'deratePct',
    title: 'Derate impact',
    valueGetter: (value) => <NumberDisplay value={value} displayType={NumberDisplayType.Percentage} />,
    cellClassName: 'basis-3/6',
    headerClassName: 'basis-3/6',
  },
  {
    accessor: 'derateAdjustmentPct',
    title: 'PR Limit Adjustment',
    valueGetter: (value) => <NumberDisplay value={value} displayType={NumberDisplayType.Percentage} />,
    cellClassName: 'basis-3/6',
    headerClassName: 'basis-3/6',
  },
]
export const DerateRatiosReadOnlyTable = ({ derateRatios, tableClassName }: IDerateRatiosReadOnlyTableProps): React.ReactElement<IDerateRatiosReadOnlyTableProps> => {
  return <MaintenanceProgramDetailsTable existingColumns={tableColumns} existingData={derateRatios} tableClassName={tableClassName} />
}
