import React, { useState } from 'react'
import { Box, Tab, Tabs, Typography } from '@mui/material'
import { isEmpty } from 'lodash'
import { EngineFormData, EnginesFormData } from './Engines'
import { LLPTableReadOnly } from '../common/LLPTableReadOnly'
import { Label } from '../common/Label'
import { stringToLongDateFormat } from '@flyward/platform/helpers/dateHelpers'

interface EnginesReadOnlyProps {
  engines: EnginesFormData
}

const flexBoxStyles = {
  display: 'flex',
  flexDirection: 'row',
  gap: 2,
  flexWrap: 'wrap',
  '& > *': {
    flex: '1 1 calc(33.333% - 16px)', // Takes up 1/3 of the space minus the gap
    minWidth: '200px',
    maxWidth: 'calc(33.333% - 16px)', // Ensures it doesn't grow beyond 1/3
  },
}

const EngineReadOnlyComponent = ({ engineData }: { engineData: EngineFormData }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, width: '100%' }}>
      <Typography variant="subtitle1">General</Typography>

      <Box sx={flexBoxStyles}>
        <Label label="ESN" value={engineData?.general?.sn ?? '-'} />
        <Label label="DOM" value={stringToLongDateFormat(engineData?.general?.dom) ?? '-'} />
        <div />
        <Label label="Manufacturer" value={isEmpty(engineData?.general?.manufacturer) ? '-' : engineData?.general?.manufacturer} />
        <Label label="Model" value={isEmpty(engineData?.general?.model) ? '-' : engineData?.general?.model} />
        <div />
        <Label label="Derate" value={`${engineData?.general?.derate ?? '0'} %`} />
      </Box>

      <Typography variant="subtitle1">PR Technical</Typography>
      <Box sx={flexBoxStyles}>
        <Label label="TSN" value={engineData.pr.tsn ?? '-'} />
        <Label label="CSN" value={engineData.pr.csn ?? '-'} />
        <div />
        <Label label="TSN @ Last PR" value={engineData.pr.tsnLastPr ?? '-'} />
        <Label label="CSN @ Last PR" value={engineData.pr.csnLastPr ?? '-'} />
        <Label label="Date @ Last PR" value={stringToLongDateFormat(engineData.pr.dateLastPr) ?? '-'} />
        <Label label="Hours since PR at contract delivery" value={engineData.pr.hoursSinceEventAtContractDelivery ?? '-'} />
      </Box>

      <Typography variant="subtitle1">LLP Technical</Typography>
      {engineData.llps && engineData.llps.length > 0 ? (
        <LLPTableReadOnly rows={engineData.llps} />
      ) : (
        <Typography variant="body2" color="text.secondary">
          No LLP data available
        </Typography>
      )}
    </Box>
  )
}

export const EnginesReadOnly = ({ engines }: EnginesReadOnlyProps) => {
  const [selectedTab, setSelectedTab] = useState(0)

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue)
  }

  if (!engines.items || engines.items.length === 0) {
    return (
      <div className="shadow-sm rounded-lg bg-white p-6">
        <Typography variant="body1">No engines available</Typography>
      </div>
    )
  }

  return (
    <div className="shadow-sm rounded-lg bg-white p-6">
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
          <Tabs value={selectedTab} onChange={handleTabChange} variant="scrollable" scrollButtons="auto">
            {engines.items.map((_, index) => (
              <Tab className="normal-case" key={index} label={`Engine ${index + 1}`} />
            ))}
          </Tabs>
        </Box>

        <Box sx={{ mb: 4 }}>
          <EngineReadOnlyComponent engineData={engines.items[selectedTab]} />
        </Box>
      </Box>
    </div>
  )
}
