import { forwardRef } from 'react'
import { NumericFormat as ReactNumericFormat, type NumberFormatValues } from 'react-number-format'
import { type InputBaseComponentProps } from '@mui/material'

interface CustomProps {
  onChange: (_event: { target: { value: string } }) => void
  thousandSeparator?: boolean
  allowNegative?: boolean
  prefix?: string
  defaultValue?: string
  decimalScale?: number
}

export const NumericFormat = forwardRef<HTMLInputElement, CustomProps & InputBaseComponentProps>(function NumericFormat(props, ref) {
  const { onChange, thousandSeparator = true, allowNegative = false, prefix = '', defaultValue = '', decimalScale, ...other } = props

  return (
    <ReactNumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values: NumberFormatValues) => {
        onChange({
          target: {
            value: values.value,
          },
        })
      }}
      thousandSeparator={thousandSeparator}
      allowNegative={allowNegative}
      prefix={prefix}
      defaultValue={defaultValue}
      decimalScale={decimalScale}
    />
  )
})

NumericFormat.displayName = 'NumericFormat'
