/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { useAssetComponentsForms } from '@flyward/assets/context/AssetComponentsFormsContext'
import { AircraftDetailsDtoSchema, type AircraftDetailsDto } from '../../../../models/DTOs/aircraftDetailsDto'
import { AircraftReadonlyDetails } from './AircraftReadonlyDetails'
import { AircraftEditableDetails } from './AircraftEditableDetails'
import { CnForm } from '@flyward/platform/components'
import { useUpdateAircraftDetailsMutation } from '@flyward/assets/store'
import { useEffect, useRef } from 'react'
import { type SubmitHandler, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { isNil } from 'lodash'
import { formatAxiosErrorMessage } from '@flyward/platform/helpers/ErrorHelpers'
import { showError, showSuccess } from '@flyward/platform/services'

interface IAircraftDetailsProps {
  assetId: string
  aircraft: AircraftDetailsDto
}

export const AircraftDetails = ({ assetId, aircraft }: IAircraftDetailsProps) => {
  const { isInEditMode, registerFormRef, setHasErrors } = useAssetComponentsForms()
  const submitRef = useRef<HTMLInputElement>(null)
  const resetRef = useRef<HTMLInputElement>(null)

  const [updateAircraft] = useUpdateAircraftDetailsMutation()

  const form = useForm<AircraftDetailsDto>({
    defaultValues: aircraft,
    values: aircraft,
    resolver: zodResolver(AircraftDetailsDtoSchema),
    mode: 'all',
    reValidateMode: 'onChange',
  })

  const {
    handleSubmit: handleAircraftUpdate,
    formState: { dirtyFields, isValid },
    getValues,
    control: aircraftFormControl,
    setValue: setAircraftFormValue,
  } = form

  const aircraftFormValues = getValues()

  const hasActualChanges =
    Object.keys(dirtyFields).length > 0 &&
    Object.keys(dirtyFields).some((field) => {
      const currentValue = getValues(field as keyof AircraftDetailsDto)
      const initialValue = aircraft[field as keyof AircraftDetailsDto]
      return currentValue !== initialValue
    })

  useEffect(() => {
    registerFormRef({
      id: [assetId, 'aircraftDetails'],
      submitRef,
      resetRef,
      hasChanges: hasActualChanges,
      isValid,
    })
  }, [registerFormRef, hasActualChanges, isValid, assetId])

  const onUpdateAircraft: SubmitHandler<AircraftDetailsDto> = async (data) => {
    const result = await updateAircraft({ assetId, aircraft: data })
    if (!isNil(result.error)) {
      setHasErrors(true)
      showError(formatAxiosErrorMessage(result.error?.message))
    } else {
      setHasErrors(false)
      showSuccess('Aircraft updated successfully')
    }
  }

  return (
    <CnForm {...form}>
      <form onSubmit={handleAircraftUpdate(onUpdateAircraft)}>
        {isInEditMode ? (
          <AircraftEditableDetails aircraft={aircraftFormValues} formControl={aircraftFormControl} setFormValue={setAircraftFormValue} getAircraftFormValues={getValues} />
        ) : (
          <AircraftReadonlyDetails aircraft={aircraft} />
        )}
        <input type="submit" className="hidden" ref={submitRef} />
        <input type="reset" className="hidden" ref={resetRef} onClick={() => form.reset()} />
      </form>
    </CnForm>
  )
}
