import { AuthorizedElement } from '@flyward/appIdentity'
import { ComponentType } from '@flyward/platform'
import { FWBreadcrumbs } from '@flyward/main-app/layout/MasterLayout/Header/FWBreadcrumbs.tsx'
import { BREADCRUMBS } from '../breadcrumbs'
import { Button as MuiButton } from '@mui/material'
import { Edit as EditIcon, Save as SaveIcon } from '@mui/icons-material'

const componentTypeToPath: Record<ComponentType, string> = {
  [ComponentType.EngineUnit]: 'engine',
  [ComponentType.Airframe]: 'airframe',
  [ComponentType.AuxiliaryPowerUnit]: 'auxiliary-power-unit',
  [ComponentType.LandingGear]: 'landing-gear',
  [ComponentType.EngineCore]: 'engine-core',
  [ComponentType.EngineLlp]: 'engine-llp',
  [ComponentType.ThrustReversers]: 'thrust-reversers',
}

interface IMaintenanceProgramPageHeaderProps {
  type: ComponentType
  isInEditMode: boolean
  setIsInEditMode: (_isInEditMode: boolean) => void
  isDirty: boolean
  isValid: boolean
  submitForm: () => void
  onCancel: () => void
  programTitle: string
}

export const MaintenanceProgramPageHeader = ({ type, isInEditMode, setIsInEditMode, isDirty, isValid, submitForm, onCancel, programTitle }: IMaintenanceProgramPageHeaderProps) => {
  const breadcrumbs = BREADCRUMBS[type]
  const componentPath = componentTypeToPath[type]

  return (
    <div className="flex h-20 w-full items-center justify-between px-6">
      <FWBreadcrumbs breadcrumbs={breadcrumbs} currentTitle={programTitle} />
      <AuthorizedElement>
        <div data-permission-element-id="edit-maintenance-program" className="flex items-center gap-4">
          <MuiButton
            variant={isInEditMode ? 'outlined' : 'contained'}
            color="primary"
            startIcon={isInEditMode ? null : <EditIcon />}
            data-testid={`edit-kb-${componentPath}-maintenance-program`}
            onClick={() => {
              if (isInEditMode) {
                onCancel()
              }
              setIsInEditMode(!isInEditMode)
            }}
          >
            {isInEditMode ? 'Cancel' : 'Edit'}
          </MuiButton>
          <MuiButton
            variant="contained"
            color="primary"
            startIcon={<SaveIcon />}
            disabled={!isValid || !isInEditMode || !isDirty}
            data-testid={`update-kb-${componentPath}-maintenance-program`}
            onClick={() => {
              setIsInEditMode(false)
              submitForm()
            }}
          >
            Save
          </MuiButton>
        </div>
      </AuthorizedElement>
    </div>
  )
}
