import { MaintenanceProgramDetailsArea } from '../MaintenanceProgramDetailsArea'
import { LandingGearChecksReadOnlyTable } from './LandingGearChecksTable'
import { InflationReadOnly } from '../InflationReadOnly'
import { BasicAssumptionsReadOnly } from '../BasicAssumptionsReadOnly'
import { ErrorBoundary, TextCellWithLabel } from '@flyward/platform'
import { type LandingGearProgramDto } from '@flyward/knowledgeBase/models'
import { isArray, isNil } from 'lodash'

interface ILandingGearMaintenanceProgramReadOnlyProps {
  landingGearMaintenanceProgram: LandingGearProgramDto | undefined
}

export const LandingGearMaintenanceProgramReadOnly = ({ landingGearMaintenanceProgram }: ILandingGearMaintenanceProgramReadOnlyProps) => {
  return (
    <ErrorBoundary>
      <MaintenanceProgramDetailsArea title="Landing Gear OEM">
        <div className="flex gap-6">
          <TextCellWithLabel className="basis-1/6" label="Programme Name" info={landingGearMaintenanceProgram?.maintenanceProgramName} />
        </div>
        <div className="flex gap-6">
          <TextCellWithLabel className="basis-1/6" info={landingGearMaintenanceProgram?.manufacturer} label="Manufacturer" />
          <TextCellWithLabel
            className="basis-1/6"
            label="Landing Gear Model"
            info={
              !isNil(landingGearMaintenanceProgram?.compatibleComponentModels) && isArray(landingGearMaintenanceProgram?.compatibleComponentModels)
                ? landingGearMaintenanceProgram?.compatibleComponentModels?.join(', ')
                : landingGearMaintenanceProgram?.compatibleComponentModels
            }
          />
          <TextCellWithLabel className="basis-1/6" label="Base year" info={landingGearMaintenanceProgram?.kbReferenceYear?.toString()} />
        </div>
        <LandingGearChecksReadOnlyTable checks={!isNil(landingGearMaintenanceProgram?.check) ? [landingGearMaintenanceProgram!.check] : []} />
      </MaintenanceProgramDetailsArea>
      <MaintenanceProgramDetailsArea title="Escalation">
        <InflationReadOnly inflation={landingGearMaintenanceProgram?.inflation} />
      </MaintenanceProgramDetailsArea>
      <MaintenanceProgramDetailsArea title="Basic Assumptions">
        <BasicAssumptionsReadOnly basicAssumption={landingGearMaintenanceProgram?.basicAssumptions} />
      </MaintenanceProgramDetailsArea>
    </ErrorBoundary>
  )
}
