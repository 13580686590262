import React from 'react'
import { Box, Button, TextField, Typography } from '@mui/material'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { handleNumericInput } from '@flyward/platform'

const MAX_LENGTH = 200
const MAX_LENGTH_MESSAGE = 'Maximum 200 characters allowed'
const CURRENT_YEAR = new Date().getFullYear()
const MIN_YEAR = 1960
const MAX_YEAR = CURRENT_YEAR + 10

const generalSchema = z.object({
  name: z.string().min(1, 'Programme Name is required').max(MAX_LENGTH, MAX_LENGTH_MESSAGE),
  manufacturer: z.string().min(1, 'Manufacturer is required').max(MAX_LENGTH, MAX_LENGTH_MESSAGE),
  model: z.string().min(1, 'Model is required').max(MAX_LENGTH, MAX_LENGTH_MESSAGE),
  baseYear: z
    .number({
      required_error: 'Base Year is required',
      invalid_type_error: 'Base Year is required',
    })
    .min(MIN_YEAR, `Base Year must be ${MIN_YEAR} or later`)
    .max(MAX_YEAR, `Base Year cannot be later than ${MAX_YEAR}`),
})

export type GeneralFormData = z.infer<typeof generalSchema>

interface GeneralProps {
  onNext: (_data: GeneralFormData) => void
  onBack?: () => void
  stepTitle: string
  initialData?: GeneralFormData
}

const General: React.FC<GeneralProps> = ({ onNext, stepTitle, initialData }) => {
  const {
    register,
    formState: { errors, touchedFields },
    watch,
    trigger,
    handleSubmit,
    setValue,
  } = useForm<GeneralFormData>({
    resolver: zodResolver(generalSchema),
    mode: 'all',
    defaultValues: {
      name: initialData?.name ?? '',
      manufacturer: initialData?.manufacturer ?? '',
      model: initialData?.model ?? '',
      baseYear: initialData?.baseYear ?? undefined,
    },
  })

  const nameValue = watch('name') || ''
  const manufacturerValue = watch('manufacturer') || ''
  const modelValue = watch('model') || ''
  const baseYearValue = watch('baseYear') || ''

  const getHelperText = (value: string, touched: boolean, error?: string) => {
    if (value.length > MAX_LENGTH) return MAX_LENGTH_MESSAGE
    if (touched && error && error !== MAX_LENGTH_MESSAGE) return error
    return ''
  }

  const handleContinue = async () => {
    const isValidForm = await trigger()
    if (isValidForm) {
      handleSubmit(onNext)()
    }
  }

  const isFormEmpty = !nameValue || !manufacturerValue || !modelValue || !baseYearValue
  const hasLengthErrors = [nameValue, manufacturerValue, modelValue].some((value) => value.length > MAX_LENGTH)
  const hasValidationErrors = Object.keys(errors).length > 0
  const isButtonDisabled = isFormEmpty || hasLengthErrors || hasValidationErrors

  return (
    <div className="shadow-sm rounded-lg bg-white p-6" style={{ minWidth: '32rem' }}>
      <Typography variant="h6" sx={{ mb: 3 }}>
        {stepTitle}
      </Typography>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 3,
          mb: 4,
        }}
      >
        <TextField
          {...register('name')}
          error={(touchedFields.name && !!errors.name) ?? nameValue.length > MAX_LENGTH}
          label="Programme Name *"
          helperText={getHelperText(nameValue, !!touchedFields.name, errors.name?.message)}
        />
        <TextField
          {...register('manufacturer')}
          error={(touchedFields.manufacturer && !!errors.manufacturer) ?? manufacturerValue.length > MAX_LENGTH}
          label="Manufacturer *"
          helperText={getHelperText(manufacturerValue, !!touchedFields.manufacturer, errors.manufacturer?.message)}
        />
        <TextField
          {...register('model')}
          error={(touchedFields.model && !!errors.model) ?? modelValue.length > MAX_LENGTH}
          label="Model *"
          helperText={getHelperText(modelValue, !!touchedFields.model, errors.model?.message)}
        />
        <TextField
          {...register('baseYear')}
          error={!!errors.baseYear}
          label="Base Year *"
          helperText={errors.baseYear?.message}
          onChange={(e) => {
            const value = handleNumericInput(e.target.value).slice(0, 4)
            setValue('baseYear', value ? Number(value) : null)
            trigger('baseYear')
          }}
          onBlur={() => trigger('baseYear')}
          value={baseYearValue}
        />
      </Box>

      <Box>
        <Button variant="contained" onClick={handleContinue} disabled={isButtonDisabled}>
          Continue
        </Button>
      </Box>
    </div>
  )
}

export { General }
