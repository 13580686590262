import { cn, NumberDisplay, NumberDisplayType } from '@flyward/platform/components'
import { ReportDisplayType, type IEventViewDetailDto } from '@flyward/platform/models'
import { type ComponentTotalStatisticsAtEvent } from '@flyward/platform/models/entities/ComponentTotalStatisticsAtEvent'

export interface MasterComponentWithEvents {
  masterComponentId: string
  eventsOnComponent: IEventViewDetailDto[]
}

interface IMasterComponentEventsTotalProps {
  reportDisplayType: ReportDisplayType
  componentEvents: MasterComponentWithEvents
  componentTotalStatisticsAtEvent: ComponentTotalStatisticsAtEvent[]
  tdStyle: string
  borderAndTextStyle: string
}

const MasterComponentEventsTotal = ({
  reportDisplayType,
  componentEvents,
  componentTotalStatisticsAtEvent,
  tdStyle,
  borderAndTextStyle,
}: IMasterComponentEventsTotalProps) => {
  const getComponentTotalStatisticsAtEvent = (masterComponentId: string) => {
    const index = componentTotalStatisticsAtEvent.findIndex((c) => c.masterComponentId === masterComponentId)
    if (index === -1) {
      return componentTotalStatisticsAtEvent[0]
    }
    return componentTotalStatisticsAtEvent[index]
  }

  return (
    <tr key={`footer`} className={cn('border-grey-100 mb-3 flex w-full border-t bg-row-even')}>
      <td className={cn('basis-2/36', tdStyle, borderAndTextStyle, reportDisplayType === ReportDisplayType.AssetReport && 'hidden')}></td>
      <td className={cn('basis-2/36', tdStyle, borderAndTextStyle)}></td>
      <td
        className={cn(
          'overflow-hidden text-ellipsis whitespace-nowrap text-nowrap',
          tdStyle,
          borderAndTextStyle,
          reportDisplayType === ReportDisplayType.AssetReport ? 'basis-5/36' : 'basis-4/36',
        )}
      ></td>
      <td
        className={cn(
          'text-end font-semibold',
          tdStyle,
          borderAndTextStyle,
          reportDisplayType === ReportDisplayType.AssetReport ? 'basis-4/36' : 'basis-3/36',
        )}
      ></td>
      <td className={cn('basis-2/36 text-end font-semibold', tdStyle, borderAndTextStyle)}></td>
      <td className={cn('basis-2/36 text-end font-semibold', tdStyle, borderAndTextStyle)}></td>
      <td className={cn('basis-2/36 text-end font-semibold', tdStyle, borderAndTextStyle)}></td>
      <td className={cn('basis-2/36 text-end font-semibold', tdStyle, borderAndTextStyle)}></td>
      <td className={cn('basis-2/36 text-end font-semibold', tdStyle, borderAndTextStyle)}></td>
      <td className={cn('basis-2/36 text-end font-semibold', tdStyle, borderAndTextStyle)}></td>
      <td className={cn('basis-3/36 text-end font-semibold', tdStyle, borderAndTextStyle)}>
        <NumberDisplay
          displayType={NumberDisplayType.CurrencyRounded}
          value={getComponentTotalStatisticsAtEvent(componentEvents.masterComponentId).totalMrBalance}
        />
      </td>
      <td className={cn('basis-3/36 text-end font-semibold', tdStyle, borderAndTextStyle)}>
        <NumberDisplay
          displayType={NumberDisplayType.CurrencyRounded}
          value={getComponentTotalStatisticsAtEvent(componentEvents.masterComponentId).totalEventCost}
        />
      </td>
      <td className={cn('basis-3/36 text-end font-semibold', tdStyle, borderAndTextStyle)}>
        <NumberDisplay
          displayType={NumberDisplayType.CurrencyRounded}
          value={getComponentTotalStatisticsAtEvent(componentEvents.masterComponentId).totalCashFlowOut.value}
        />
      </td>
      <td className={cn('basis-3/36 text-end font-semibold', tdStyle, borderAndTextStyle)}>
        <NumberDisplay
          className={cn(
            getComponentTotalStatisticsAtEvent(componentEvents.masterComponentId).totalLessorContribution?.value !== 0 &&
              `text-highlight-lessor-contribution`,
          )}
          displayType={NumberDisplayType.CurrencyRounded}
          value={getComponentTotalStatisticsAtEvent(componentEvents.masterComponentId).totalLessorContribution?.value}
        />
      </td>
      <td className={cn('basis-3/36 text-end font-semibold', tdStyle, borderAndTextStyle)}>
        <NumberDisplay
          displayType={NumberDisplayType.CurrencyRounded}
          value={getComponentTotalStatisticsAtEvent(componentEvents.masterComponentId).totalEventShortfall}
        />
      </td>
    </tr>
  )
}

export { MasterComponentEventsTotal }
