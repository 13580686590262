import { getAssetImportTypeDisplayName, getAssetTypeDisplayName, type AssetImportType, type AssetType } from '../models'

export const errorMessages = {
  default: (
    <span>
      We encountered an issue processing your request.
      <br />
      Please email us at&nbsp;
      <strong>
        <a href="mailto:support@flyward.eu">support@flyward.eu</a>
      </strong>
      &nbsp;and we’ll help you immediately!
    </span>
  ),
  kb: {
    import: (
      <span>
        There was an issue processing your upload.
        <br />
        Please email us at&nbsp;
        <strong>
          <a href="mailto:support@flyward.eu">support@flyward.eu</a>
        </strong>
        &nbsp;and we’ll assist you right away with your maintenance plan!
      </span>
    ),
    update: (
      <span>
        There was an issue updating you maintenance program.
        <br />
        Please email us at&nbsp;
        <strong>
          <a href="mailto:support@flyward.eu">support@flyward.eu</a>
        </strong>
        &nbsp;and we’ll assist you!
      </span>
    ),
  },
  assets: {
    import: (assetType: AssetType, assetImportType: AssetImportType) => (
      <span>
        There was an issue processing your&nbsp;
        <strong>
          {getAssetTypeDisplayName(assetType)} {getAssetImportTypeDisplayName(assetImportType)}
        </strong>
        &nbsp; upload.
        <br />
        Please email us at&nbsp;
        <strong>
          <a href="mailto:support@flyward.eu">support@flyward.eu</a>
        </strong>
        , and we’ll assist you right away with your aircraft or standalone engine record.
      </span>
    ),
    importAi: (
      <span>
        There was an issue processing the document. The AI agent could not read the technical specification.
        <br />
        Please ensure you have uploaded the correct document. If the issue persists, contact&nbsp;
        <strong>
          <a href="mailto:support@flyward.eu">support@flyward.eu</a>
        </strong>
      </span>
    ),
  },
  forecasting: {
    genericError: (
      <span>
        The Fly Forward process cannot be executed due to an incorrect configuration in the selected maintenance plan or inaccurate asset data.
        <br />
        For assistance, please contact&nbsp;
        <strong>
          <a href="mailto:support@flyward.eu">support@flyward.eu</a>
        </strong>
      </span>
    ),
    manualEventError: (
      <span>
        Failed to update event manually.
        <br />
        For assistance, please contact&nbsp;
        <strong>
          <a href="mailto:support@flyward.eu">support@flyward.eu</a>
        </strong>
      </span>
    ),
    reportSaveError: (
      <span>
        Cannot save the Fly Forward report.
        <br />
        For assistance, please contact&nbsp;
        <strong>
          <a href="mailto:support@flyward.eu">support@flyward.eu</a>
        </strong>
      </span>
    ),
    assetProcessingError: (assetId: string) => (
      <span>
        Cannot process asset {`${assetId}`} due to an incorrect configuration in the selected maintenance plan or inaccurate asset data.
        <br />
        For assistance, please contact&nbsp;
        <strong>
          <a href="mailto:support@flyward.eu">support@flyward.eu</a>
        </strong>
      </span>
    ),
  },
  reports: {
    generateExcelError: (
      <span>
        There was an issue generating the report.
        <br />
        For assistance, please contact&nbsp;
        <strong>
          <a href="mailto:support@flyward.eu">support@flyward.eu</a>
        </strong>
      </span>
    ),
    loadingError: (
      <span>
        There was an issue loading the report.
        <br />
        For assistance, please contact&nbsp;
        <strong>
          <a href="mailto:support@flyward.eu">support@flyward.eu</a>
        </strong>
      </span>
    ),
  },
  userManagement: {
    saveError: (
      <span>
        Something went wrong while trying to save the user!
        <br />
        For assistance, please contact&nbsp;
        <strong>
          <a href="mailto:support@flyward.eu">support@flyward.eu</a>
        </strong>
      </span>
    ),
    sendVerificationEmailError: (
      <span>
        Something went wrong while trying to sent the verification email!
        <br />
        For assistance, please contact&nbsp;
        <strong>
          <a href="mailto:support@flyward.eu">support@flyward.eu</a>
        </strong>
      </span>
    ),
    changeStateError: (
      <span>
        Something went wrong while trying to change the status!
        <br />
        For assistance, please contact&nbsp;
        <strong>
          <a href="mailto:support@flyward.eu">support@flyward.eu</a>
        </strong>
      </span>
    ),
  },
}
