import { AddRemoveItemsSchema, type AddRemoveItems } from '@flyward/platform'
import { validateNullableDecimalNumber, validateNullableNaturalNumber } from '@flyward/platform/helpers/inputValidators'
import { z } from 'zod'
import { ComponentSchema, type ComponentDto } from '../../ComponentDto'
import { EngineLessorContributionsDtoSchema, type EngineLessorContributionsDto } from './EngineLessorContributionsDto'
import { EngineLifeLimitedPartDtoSchema, type EngineLifeLimitedPartDto } from './EngineLifeLimitedPartDto'
import { EngineUnitContractDtoSchema, type EngineUnitContractDto } from './EngineUnitContractDto'
import { ManufacturingDetailsNullDomSchema } from '../../ManufacturingDetailsDto'

/**
 * Cloned from API: Assets.Core.Domain.DTOs.Components.Engine.AircraftEngineDto
 */
export interface AircraftEngineDto extends ComponentDto {
  engineThrustRating?: number
  deratePct?: number
  engineLifeLimitedParts: EngineLifeLimitedPartDto[]
  addRemoveLlps?: AddRemoveItems<EngineLifeLimitedPartDto>
  engineContract: EngineUnitContractDto
  lessorContributions?: EngineLessorContributionsDto
}

export const AircraftEngineDtoSchema = ComponentSchema.omit({ manufacturingDetail: true }).extend({
  manufacturingDetail: ManufacturingDetailsNullDomSchema.optional().nullable(),
  engineThrustRating: validateNullableNaturalNumber,
  deratePct: validateNullableDecimalNumber,
  engineLifeLimitedParts: z.array(EngineLifeLimitedPartDtoSchema).nullable(),
  addRemoveLlps: AddRemoveItemsSchema(EngineLifeLimitedPartDtoSchema).nullable(),
  engineContract: EngineUnitContractDtoSchema,
  lessorContributions: EngineLessorContributionsDtoSchema,
})
