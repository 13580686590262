import { useGetAllAssetsBySearchQuery } from '@flyward/assets/store'
import { clearAllReportAssets, initFilteredAssetsData } from '@flyward/platform/store'
import { useAppDispatch, useAppSelector } from '@flyward/platform/store/configureHooks'
import { isNil } from 'lodash'
import { useEffect } from 'react'
import { FlyForwardPageHeader } from './FlyForwardPageHeader/FlyForwardPageHeader'
import { FlyForwardPageBody } from './FlyForwardPageBody/FlyForwardPageBody'
import { selectForecastingAssetsSearchInput } from '@flyward/platform/store/slices/features/flyForward/flyForwardSelectors'

const FlyForwardPage = () => {
  const dispatch = useAppDispatch()
  const forecastingAssetsSearchInput = useAppSelector(selectForecastingAssetsSearchInput)

  const { data: filteredAssetsData, isLoading } = useGetAllAssetsBySearchQuery(forecastingAssetsSearchInput, {})

  useEffect(() => {
    if (filteredAssetsData) {
      dispatch(initFilteredAssetsData({ newAssets: filteredAssetsData }))
    }
  }, [dispatch, filteredAssetsData])

  useEffect(() => {
    return () => {
      dispatch(clearAllReportAssets())
    }
  }, [dispatch])

  if (isLoading || isNil(filteredAssetsData) || filteredAssetsData.items.length === 0) {
    return <div data-testid="fly-forward-loading"></div>
  }

  return (
    <>
      <div className="flex h-20 w-full items-center justify-between">
        <FlyForwardPageHeader />
      </div>
      <div className="flex flex-grow flex-col gap-y-2 overflow-y-auto">
        <FlyForwardPageBody />
      </div>
    </>
  )
}

export { FlyForwardPage }
