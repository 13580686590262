import { Box, Button, TextField, Typography } from '@mui/material'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { NumericFormat } from 'react-number-format'
import { validateNullableDate, validateRequiredDate } from '@flyward/platform/helpers/inputValidators'
import { isEmpty, isNil } from 'lodash'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { dateToIsoString } from '@flyward/platform/helpers/dateHelpers'
import { useEffect } from 'react'

const MIN_VALUE = 0
const MAX_VALUE = 1000000000
const MAX_LENGTH = 200
const MAX_LENGTH_MESSAGE = 'Maximum 200 characters allowed'

const technicalSchema = z.object({
  sn: z.string().min(1, 'Serial Number  is required').max(MAX_LENGTH, MAX_LENGTH_MESSAGE),
  manufacturer: z.string().optional().nullable(),
  model: z.string().optional().nullable(),
  partNumber: z.string().optional().nullable(),
  dom: validateRequiredDate('DOM'),
  tsn: z.number().optional().nullable(),
  csn: z.number().optional().nullable(),
  tsnLastPr: z.number().optional().nullable(),
  csnLastPr: z.number().optional().nullable(),
  dateLastPr: validateNullableDate,
  monthsSinceOverhaulAtContractDelivery: z.number().optional().nullable(),
})

const componentSchema = z.object({
  technical: technicalSchema,
})

export type ComponentFormData = z.infer<typeof componentSchema>

interface Props {
  onNext: (_data: ComponentFormData) => void
  onBack?: () => void
  stepTitle: string
  initialData?: ComponentFormData
  isAiPopulated?: boolean
}

const LgComponent = ({ onNext, onBack, stepTitle, initialData, isAiPopulated }: Props) => {
  const {
    formState: { errors, touchedFields },
    watch,
    trigger,
    setValue,
    register,
  } = useForm<ComponentFormData>({
    resolver: zodResolver(componentSchema),
    mode: 'all',
    defaultValues: {
      technical: {
        sn: initialData?.technical?.sn ?? '',
        manufacturer: initialData?.technical?.manufacturer ?? '',
        model: initialData?.technical?.model ?? '',
        partNumber: initialData?.technical?.partNumber ?? '',
        dom: initialData?.technical?.dom ?? undefined,
        tsn: initialData?.technical?.tsn,
        csn: initialData?.technical?.csn,
        tsnLastPr: initialData?.technical?.tsnLastPr ?? null,
        csnLastPr: initialData?.technical?.csnLastPr ?? null,
        dateLastPr: initialData?.technical?.dateLastPr ?? null,
        monthsSinceOverhaulAtContractDelivery: initialData?.technical?.monthsSinceOverhaulAtContractDelivery ?? null,
      },
    },
  })

  useEffect(() => {
    if (isAiPopulated) {
      trigger()
    }
  }, [isAiPopulated])

  const values = watch()

  const hasAnyVales = Object.values(values.technical).some((value) => value !== undefined && value !== null && value !== '')

  const handleContinue = async () => {
    const isValidForm = await trigger()
    if (!hasAnyVales || isValidForm) {
      onNext({
        technical: {
          sn: values?.technical?.sn,
          manufacturer: values?.technical?.manufacturer,
          model: values?.technical?.model,
          partNumber: values?.technical?.partNumber,
          dom: values?.technical?.dom,
          tsn: values?.technical?.tsn,
          csn: values?.technical?.csn,
          tsnLastPr: values?.technical?.tsnLastPr,
          csnLastPr: values?.technical?.csnLastPr,
          dateLastPr: values?.technical?.dateLastPr,
          monthsSinceOverhaulAtContractDelivery: values?.technical?.monthsSinceOverhaulAtContractDelivery,
        },
      })
    }
  }

  const hasValidationErrors = Object.keys(errors).length > 0
  const hasMissingRequiredFields = !values.technical.sn || !values.technical.dom
  const isButtonDisabled = hasAnyVales && (hasValidationErrors || hasMissingRequiredFields)

  const getTouchedErrorMessage = (fieldName) => (!touchedFields?.technical?.[fieldName] ? '' : errors.technical?.[fieldName]?.message ?? '')

  const getRequiredErrorMessage = (value, fieldName) => (!hasAnyVales || value ? '' : `${fieldName} is required`)

  return (
    <div className="shadow-sm rounded-lg bg-white p-6" style={{ minWidth: '32rem' }}>
      <Typography variant="h6" sx={{ mb: 3 }}>
        {stepTitle}
      </Typography>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 3,
          mb: 4,
        }}
      >
        <Typography variant="subtitle1" sx={{ mb: 1 }}>
          Technical
        </Typography>

        <Box sx={{ display: 'flex', gap: 2 }}>
          <TextField
            {...register('technical.sn')}
            error={(touchedFields?.technical?.sn && !!errors?.technical?.sn) ?? values?.technical?.sn?.length > MAX_LENGTH}
            label="Serial Number *"
            helperText={getTouchedErrorMessage('sn') || getRequiredErrorMessage(values.technical.sn, 'Serial Number')}
            sx={{ flex: 1 }}
          />

          <DatePicker
            label="DOM *"
            value={!isNil(values?.technical.dom) && !isEmpty(values?.technical.dom) ? new Date(values?.technical.dom) : undefined}
            onChange={(newValue) => {
              const isoFormat = isNil(newValue) ? undefined : dateToIsoString(newValue)
              setValue('technical.dom', isoFormat)
              trigger('technical.dom')
            }}
            slotProps={{
              textField: {
                helperText: errors?.technical?.dom?.message?.toString() || getRequiredErrorMessage(values.technical.dom, 'DOM'),
                error: !!errors?.technical?.dom?.message,
                onBlur: () => {
                  trigger('technical.dom')
                },
              },
              field: {
                clearable: true,
                onClear: () => {
                  setValue('technical.dom', undefined)
                  trigger('technical.dom')
                },
              },
            }}
            sx={{ flex: 1 }}
          />
          <Box sx={{ flex: 1 }} />
        </Box>

        <Box sx={{ display: 'flex', gap: 2 }}>
          <TextField
            {...register('technical.manufacturer')}
            error={(touchedFields?.technical?.manufacturer && !!errors?.technical?.manufacturer) ?? values?.technical?.manufacturer?.length > MAX_LENGTH}
            label="Manufacturer"
            helperText={getTouchedErrorMessage('manufacturer')}
            sx={{ flex: 1 }}
          />

          <TextField
            {...register('technical.model')}
            error={(touchedFields?.technical?.model && !!errors?.technical?.model) ?? values?.technical?.model?.length > MAX_LENGTH}
            label="Model"
            helperText={getTouchedErrorMessage('model')}
            sx={{ flex: 1 }}
          />
          <TextField
            {...register('technical.partNumber')}
            error={(touchedFields?.technical?.partNumber && !!errors?.technical?.partNumber) ?? values?.technical?.partNumber?.length > MAX_LENGTH}
            label="Part Number"
            helperText={getTouchedErrorMessage('partNumber')}
            sx={{ flex: 1 }}
          />
        </Box>

        <Box sx={{ display: 'flex', gap: 2 }}>
          <NumericFormat
            customInput={TextField}
            label="TSN"
            value={values.technical.tsn ?? ''}
            error={touchedFields?.technical?.tsn && !!errors?.technical?.tsn}
            helperText={getTouchedErrorMessage('tsn')}
            thousandSeparator
            decimalScale={0}
            allowNegative={false}
            isAllowed={({ floatValue }) => floatValue === undefined || (floatValue >= MIN_VALUE && floatValue <= MAX_VALUE)}
            onValueChange={(values) => {
              const { floatValue } = values
              setValue('technical.tsn', floatValue!, {
                shouldValidate: true,
                shouldTouch: true,
              })
            }}
            onBlur={() => {
              if (!touchedFields?.technical?.tsn) {
                setValue('technical.tsn', values.technical.tsn, {
                  shouldTouch: true,
                  shouldValidate: true,
                })
              }
            }}
            sx={{ flex: 1 }}
          />

          <NumericFormat
            customInput={TextField}
            label="CSN"
            value={values.technical.csn ?? ''}
            error={touchedFields?.technical?.csn && !!errors?.technical?.csn}
            helperText={getTouchedErrorMessage('csn')}
            thousandSeparator
            decimalScale={0}
            allowNegative={false}
            isAllowed={({ floatValue }) => floatValue === undefined || (floatValue >= MIN_VALUE && floatValue <= MAX_VALUE)}
            onValueChange={(values) => {
              const { floatValue } = values
              setValue('technical.csn', floatValue!, {
                shouldValidate: true,
                shouldTouch: true,
              })
            }}
            onBlur={() => {
              if (!touchedFields?.technical?.csn) {
                setValue('technical.csn', values.technical.csn, {
                  shouldTouch: true,
                  shouldValidate: true,
                })
              }
            }}
            sx={{ flex: 1 }}
          />
          <Box sx={{ flex: 1 }} />
        </Box>

        <Box sx={{ display: 'flex', gap: 2 }}>
          <NumericFormat
            customInput={TextField}
            label="TSN @ Last Overhaul"
            value={values.technical.tsnLastPr ?? ''}
            error={touchedFields?.technical?.tsnLastPr && !!errors?.technical?.tsnLastPr}
            helperText={getTouchedErrorMessage('technical.tsnLastPr')}
            thousandSeparator
            decimalScale={0}
            allowNegative={false}
            isAllowed={({ floatValue }) => floatValue === undefined || (floatValue >= MIN_VALUE && floatValue <= MAX_VALUE)}
            onValueChange={(values) => {
              const { floatValue } = values
              setValue('technical.tsnLastPr', floatValue!, {
                shouldValidate: true,
                shouldTouch: true,
              })
            }}
            onBlur={() => {
              if (!touchedFields?.technical?.tsnLastPr) {
                setValue('technical.tsnLastPr', values.technical.tsnLastPr, {
                  shouldTouch: true,
                  shouldValidate: true,
                })
              }
            }}
            sx={{ flex: 1 }}
          />

          <NumericFormat
            customInput={TextField}
            label="CSN @ Last Overhaul"
            value={values.technical.csnLastPr ?? ''}
            error={touchedFields?.technical?.csnLastPr && !!errors?.technical?.csnLastPr}
            helperText={getTouchedErrorMessage('technical.csnLastPr')}
            thousandSeparator
            decimalScale={0}
            allowNegative={false}
            isAllowed={({ floatValue }) => floatValue === undefined || (floatValue >= MIN_VALUE && floatValue <= MAX_VALUE)}
            onValueChange={(values) => {
              const { floatValue } = values
              setValue('technical.csnLastPr', floatValue!, {
                shouldValidate: true,
                shouldTouch: true,
              })
            }}
            onBlur={() => {
              if (!touchedFields?.technical?.csnLastPr) {
                setValue('technical.csnLastPr', values.technical.csnLastPr, {
                  shouldTouch: true,
                  shouldValidate: true,
                })
              }
            }}
            sx={{ flex: 1 }}
          />

          <DatePicker
            label="Date @ Last Overhaul"
            value={!isNil(values.technical.dateLastPr) && !isEmpty(values.technical.dateLastPr) ? new Date(values.technical.dateLastPr) : undefined}
            onChange={(newValue) => {
              const isoFormat = isNil(newValue) ? undefined : dateToIsoString(newValue)
              setValue('technical.dateLastPr', isoFormat)
              trigger('technical.dateLastPr')
            }}
            slotProps={{
              field: {
                clearable: true,
                onClear: () => {
                  setValue('technical.dateLastPr', undefined)
                  trigger('technical.dateLastPr')
                },
              },
            }}
            sx={{ flex: 1 }}
          />
        </Box>

        <Box sx={{ display: 'flex', gap: 2 }}>
          <NumericFormat
            customInput={TextField}
            label="Months since Overhaul @ Contract Delivery"
            value={values.technical.monthsSinceOverhaulAtContractDelivery ?? ''}
            error={touchedFields?.technical?.monthsSinceOverhaulAtContractDelivery && !!errors?.technical?.monthsSinceOverhaulAtContractDelivery}
            helperText={getTouchedErrorMessage('technical.monthsSinceOverhaulAtContractDelivery')}
            thousandSeparator
            decimalScale={0}
            allowNegative={false}
            isAllowed={({ floatValue }) => floatValue === undefined || (floatValue >= MIN_VALUE && floatValue <= MAX_VALUE)}
            onValueChange={(values) => {
              const { floatValue } = values
              setValue('technical.monthsSinceOverhaulAtContractDelivery', floatValue!, {
                shouldValidate: true,
                shouldTouch: true,
              })
            }}
            onBlur={() => {
              if (!touchedFields?.technical?.monthsSinceOverhaulAtContractDelivery) {
                setValue('technical.monthsSinceOverhaulAtContractDelivery', values.technical.monthsSinceOverhaulAtContractDelivery, {
                  shouldTouch: true,
                  shouldValidate: true,
                })
              }
            }}
            sx={{ flex: 1 }}
          />
          <Box sx={{ flex: 1 }} />
          <Box sx={{ flex: 1 }} />
        </Box>
      </Box>

      <Box>
        <Button variant="contained" onClick={handleContinue} disabled={isButtonDisabled}>
          Continue
        </Button>
        <Button variant="text" onClick={onBack}>
          Back
        </Button>
      </Box>
    </div>
  )
}

export { LgComponent }
