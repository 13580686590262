import { AssetType, IconVariant, Icon, TextCellWithLabel, Size } from '@flyward/platform'
import { Label } from '@radix-ui/react-label'
import { Box } from '@mui/material'

interface IAssetSelectionCell {
  serialNumber: string
  assetType: AssetType
  isExpanded: boolean
}

export const AssetIdentificationCell = ({ serialNumber, assetType, isExpanded }: IAssetSelectionCell) => {
  return (
    <Label htmlFor={serialNumber} className="ml-2 flex min-w-42 max-w-42 cursor-pointer items-center gap-x-4">
      <Box className={`flex h-8 items-center ${isExpanded ? 'text-text-4' : 'text-primary'}`}>
        <Icon variant={assetType === AssetType.Aircraft ? IconVariant.PlaneZoomOut : IconVariant.Engine} size={Size.Large} />
      </Box>

      <TextCellWithLabel
        className="truncate"
        label={assetType === AssetType.Aircraft ? 'MSN' : 'ESN'}
        info={serialNumber}
        labelClassName={` ${isExpanded && 'text-black-20'}`}
        infoClassName={`truncate ${isExpanded && 'text-text-4'}`}
      />
    </Label>
  )
}
