//
enum MainRoutes {
  fleet = '/fleet',
  flyForward = '/flyforward',
  reports = '/reports',
  maintenancePrograms = '/maintenance-programs',
  userManagement = '/user-management',
}

enum SubRoutes {
  aircrafts = '/aircrafts',
  engines = '/engines',
  emp = '/engine-maintenance-program',
  amp = '/airframe-maintenance-program',
  apump = '/auxiliary-power-unit-maintenance-program',
  lgmp = '/landing-gears-maintenance-program',
  engineWizard = '/engine-wizard',
  airframeWizard = '/airframe-wizard',
  landingGearWizard = '/landing-gear-wizard',
  auxiliaryPowerUnitWizard = '/auxiliary-power-unit-wizard',
  assetWizard = '/asset-wizard',
}

// Main ROUTES object
export const ROUTES = {
  ROOT: '/',
  AUTH: {
    DONE: '/auth-done',
    FAILED: '/auth-failed',
    UNAUTHORIZED: '/unauthorized',
  },
  FLEET: {
    ROOT: MainRoutes.fleet,
    AIRCRAFTS: `${MainRoutes.fleet}${SubRoutes.aircrafts}`,
    ENGINES: `${MainRoutes.fleet}${SubRoutes.engines}`,
    WIZARD: `${MainRoutes.fleet}${SubRoutes.assetWizard}`,
  },
  FLY_FORWARD: MainRoutes.flyForward,
  REPORTS: MainRoutes.reports,
  MAINTENANCE_PROGRAMS: {
    ROOT: MainRoutes.maintenancePrograms,
    EMP: `${MainRoutes.maintenancePrograms}${SubRoutes.emp}`,
    AMP: `${MainRoutes.maintenancePrograms}${SubRoutes.amp}`,
    APUMP: `${MainRoutes.maintenancePrograms}${SubRoutes.apump}`,
    LGMP: `${MainRoutes.maintenancePrograms}${SubRoutes.lgmp}`,
    WIZARD: {
      ENGINE: `${MainRoutes.maintenancePrograms}${SubRoutes.engineWizard}`,
      AIRFRAME: `${MainRoutes.maintenancePrograms}${SubRoutes.airframeWizard}`,
      LANDING_GEAR: `${MainRoutes.maintenancePrograms}${SubRoutes.landingGearWizard}`,
      AUXILIARY_POWER_UNIT: `${MainRoutes.maintenancePrograms}${SubRoutes.auxiliaryPowerUnitWizard}`,
    },
  },
  USER_MANAGEMENT: `${MainRoutes.userManagement}`,
}
