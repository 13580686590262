import { validateNullableDate, validateRequiredString } from '@flyward/platform/helpers/inputValidators'
import { zodResolver } from '@hookform/resolvers/zod'
import { AddOutlined, HighlightOffRounded } from '@mui/icons-material'
import { Box, Button, Tab, Tabs, Tooltip, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Path, useForm } from 'react-hook-form'
import { z } from 'zod'
import { LLPTableRowData } from '../common/types'
import { EngineComponent } from './Engine'
import { cn } from '@flyward/platform/components'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { isNil } from 'lodash'

const MIN_VALUE = 0
const MAX_VALUE = 1000000000

const MAX_LENGTH = 200
const MAX_LENGTH_MESSAGE = 'Maximum 200 characters allowed'

const generalSchema = z.object({
  sn: validateRequiredString('ESN')
    .transform((value) => value.toString())
    .pipe(z.string().max(MAX_LENGTH, MAX_LENGTH_MESSAGE)),
  manufacturer: z.string().optional().nullable(),
  model: z.string().optional().nullable(),
  dom: validateNullableDate,
  derate: z.number().optional().nullable(),
})

const prSchema = z.object({
  tsn: z.number().optional().nullable(),
  csn: z.number().optional().nullable(),
  tsnLastPr: z.number().optional().nullable(),
  csnLastPr: z.number().optional().nullable(),
  dateLastPr: validateNullableDate,
  hoursSinceEventAtContractDelivery: z.number().optional().nullable(),
})

const engineSchema = z.object({
  general: generalSchema,
  pr: prSchema,
  llps: z.array(z.any()).nullable().optional(),
  isLLPTableValid: z.boolean().optional().nullable(),
})

const enginesSchema = z.object({
  items: z.array(engineSchema),
})

export type EngineFormData = z.infer<typeof engineSchema>
export type EnginesFormData = z.infer<typeof enginesSchema>

interface EnginesProps {
  onNext: (_data: EnginesFormData) => void
  onBack?: () => void
  stepTitle: string
  initialData?: EnginesFormData
  isAiPopulated?: boolean
}

const EmptyLlpRow: LLPTableRowData = {
  id: 0,
  description: '',
} as const

const DraftEngine: EngineFormData = {
  general: {
    sn: null,
    manufacturer: null,
    model: null,
    dom: null,
  },
  pr: {
    tsn: null,
    csn: null,
    tsnLastPr: null,
    csnLastPr: null,
    dateLastPr: null,
    hoursSinceEventAtContractDelivery: null,
  },
  llps: [EmptyLlpRow],
  isLLPTableValid: true,
} as const

export const EnginesComponent = ({ onNext, onBack, stepTitle, initialData, isAiPopulated }: EnginesProps) => {
  const [selectedTab, setSelectedTab] = useState(0)

  const {
    register,
    formState: { errors, touchedFields },
    watch,
    trigger,
    setValue,
  } = useForm<EnginesFormData>({
    resolver: zodResolver(enginesSchema),
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {
      items: initialData?.items || [],
    },
  })

  const values = watch()

  const handleContinue = async () => {
    const isValidForm = await trigger()
    if (isValidForm) {
      const formattedEngines = values.items?.map((engine) => {
        return {
          general: {
            sn: engine.general.sn,
            manufacturer: engine.general.manufacturer,
            model: engine.general.model,
            dom: engine.general.dom,
            derate: engine.general.derate,
          },
          pr: {
            tsn: engine.pr.tsn,
            csn: engine.pr.csn,
            tsnLastPr: engine.pr.tsnLastPr,
            csnLastPr: engine.pr.csnLastPr,
            dateLastPr: engine.pr.dateLastPr,
            hoursSinceEventAtContractDelivery: engine.pr.hoursSinceEventAtContractDelivery,
          },
          llps: engine.llps
            ?.filter((row) => row.description)
            ?.map((row: LLPTableRowData) => ({
              id: row.id,
              module: row.module,
              description: row.description,
              partNumber: row.partNumber,
              serialNumber: row.serialNumber,
              limit: Number(row.limit),
              csn: Number(row.csn),
              cyclesRemaining: Number(row.cyclesRemaining),
              csnAtContractDelivery: Number(row.csnAtContractDelivery),
            })),
          isLLPTableValid: engine.isLLPTableValid ?? true,
        }
      })

      onNext({ items: formattedEngines })
    }
  }

  const addEngine = () => {
    const newEngine = { ...DraftEngine }
    setValue('items', [...values.items, newEngine])
    setSelectedTab(values.items.length)

    // revalidate
    trigger()
  }

  const removeEngine = (index: number) => {
    if (values.items.length > 0) {
      const newValues = [...values.items]
      newValues.splice(index, 1)
      setValue('items', newValues)

      // revalidate
      trigger()

      if (selectedTab >= index) {
        setSelectedTab(Math.max(0, selectedTab - 1))
      }
    }
  }

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue)
  }

  const areRequiredFieldsMissing = values.items.some((engine) => isNil(engine.general.sn))
  const hasValidationErrors = Object.keys(errors).length > 0
  const hasLLpErrors = values.items.some((engine) => engine.isLLPTableValid === false)
  const isButtonDisabled = hasValidationErrors || hasLLpErrors || areRequiredFieldsMissing

  useEffect(() => {
    trigger()
  }, [])

  useEffect(() => {
    if (isAiPopulated) {
      trigger()
    }
  }, [isAiPopulated])

  useEffect(() => {
    if (areRequiredFieldsMissing) {
      trigger()
    }
  }, [areRequiredFieldsMissing])

  return (
    <div className="shadow-sm rounded-lg bg-white p-6">
      <Typography variant="h6" sx={{ mb: 3 }}>
        {stepTitle}
      </Typography>

      <Box sx={{ mb: 2, borderBottom: 1, borderColor: 'divider', display: 'flex' }}>
        <Box>
          <Tabs value={selectedTab} onChange={handleTabChange} variant="scrollable" scrollButtons="auto">
            {values.items.map((_, index) => {
              const hasPRErrors = Object.keys(errors?.items?.[index] ?? {}).length > 0
              const hasLLPTableErrors = values.items[index].isLLPTableValid === false
              const tabHasErrors = hasPRErrors || hasLLPTableErrors

              const tabErrorMessage = `${hasPRErrors ? 'PR ' : ''}  ${hasPRErrors && hasLLPTableErrors ? 'and ' : ''} ${hasLLPTableErrors ? 'LLs' : ''}`

              return (
                <Tab
                  sx={{ borderBottom: 0, paddingBottom: 0 }}
                  key={index}
                  label={
                    <div className="flex items-center gap-0">
                      <div className={cn('flex')}>
                        {tabHasErrors && (
                          <Tooltip title={`Errors found in this engine ${tabErrorMessage}`} placement="top">
                            <ErrorOutlineIcon
                              sx={{
                                mr: 1,
                                color: '#ef4444', // text-red-500
                              }}
                              fontSize="small"
                            />
                          </Tooltip>
                        )}
                        <span className={cn('normal-case', tabHasErrors && `text-red-500!`)}>{`Engine ${index + 1}`}</span>
                      </div>
                      <HighlightOffRounded
                        fontSize="small"
                        color="primary"
                        sx={{ ml: 1, ':hover': { color: 'red' } }}
                        onClick={(e) => {
                          e.stopPropagation()
                          removeEngine(index)
                        }}
                      />
                    </div>
                  }
                />
              )
            })}
          </Tabs>
        </Box>

        <Box sx={{ pt: 1.5, ml: 0.5, cursor: 'pointer', ':hover': { color: 'primary.main' } }}>
          <Button
            variant="outlined"
            disabled={values.items.length >= 8}
            onClick={(e) => {
              e.stopPropagation()
              addEngine()
            }}
          >
            <AddOutlined />
            Add an Engine
          </Button>
        </Box>
      </Box>
      <Box sx={{ mb: 4 }}>
        {values.items.length === 0 ? (
          <></>
        ) : (
          values.items.map((engineData, index) => (
            <div key={index} style={{ display: selectedTab === index ? 'block' : 'none' }}>
              <EngineComponent
                engineIndex={index}
                engineData={engineData}
                register={register}
                setValue={(field, value) => {
                  setValue(`items.${index}.${field}` as Path<EnginesFormData>, value, { shouldDirty: true })
                  // revalidate all
                  trigger()
                }}
                touchedFields={touchedFields?.items?.[index]}
                errors={errors?.items?.[index]}
                isAiPopulated={isAiPopulated}
                areRequiredFieldsMissing={areRequiredFieldsMissing}
              />
            </div>
          ))
        )}
      </Box>

      <Box>
        <Button variant="contained" onClick={handleContinue} disabled={isButtonDisabled} sx={{ mr: 1 }}>
          Continue
        </Button>
        <Button variant="text" onClick={onBack}>
          Back
        </Button>
      </Box>
    </div>
  )
}
