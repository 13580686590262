import {
  type TableColumn,
  EditableInputCellWithLabelWithLabel,
  UtilizationUnitsDisplay,
  type UtilizationUnits,
  InputType,
  NumberDisplayType,
  NumberDisplay,
} from '@flyward/platform'
import { type Control, type FieldValues, type Path } from 'react-hook-form'

export interface EngineLlpCheckEditableDisplay {
  programId: string
  componentModel?: string
  componentModule?: string
  unit?: UtilizationUnits
  limit?: number
  cost?: number
  costPerCycle?: number
}

export const existingLlpTableColumns = <TFormData extends FieldValues>(
  formControl: Control<TFormData, unknown>,
): Array<TableColumn<EngineLlpCheckEditableDisplay>> => [
  {
    accessor: 'programId',
    title: '',
    isIdField: true,
    isHidden: true,
  },
  {
    accessor: 'componentModel',
    title: 'Description',
    customCell: ({ rowIndex: index }) => {
      const filedName = `llpChecks.${index}.componentModel` as Path<TFormData>
      return (
        <div className="w-full min-w-full text-xs">
          <EditableInputCellWithLabelWithLabel formControl={formControl} fieldName={filedName} />
        </div>
      )
    },
    cellClassName: 'basis-1/6',
    headerClassName: 'basis-1/6',
  },
  {
    accessor: 'componentModule',
    title: 'Module',
    customCell: ({ rowIndex: index }) => {
      const filedName = `llpChecks.${index}.componentModule` as Path<TFormData>
      return (
        <div className="w-full min-w-full text-xs">
          <EditableInputCellWithLabelWithLabel formControl={formControl} fieldName={filedName} />
        </div>
      )
    },
    cellClassName: 'basis-1/6',
    headerClassName: 'basis-1/6',
  },
  {
    accessor: 'unit',
    title: 'Unit',
    valueGetter: (value) => UtilizationUnitsDisplay(value as UtilizationUnits),
    cellClassName: 'basis-1/6',
    headerClassName: 'basis-1/6',
  },
  {
    accessor: 'limit',
    title: 'Limit',
    customCell: ({ rowIndex: index }) => {
      const filedName = `llpChecks.${index}.matureLimit` as Path<TFormData>
      return (
        <div className="w-full min-w-full text-xs">
          <EditableInputCellWithLabelWithLabel inputType={InputType.NaturalNumber} formControl={formControl} fieldName={filedName} />
        </div>
      )
    },
    cellClassName: 'basis-1/6',
    headerClassName: 'basis-1/6',
  },
  {
    accessor: 'cost',
    title: 'CLP',
    customCell: ({ rowIndex: index }) => {
      const filedName = `llpChecks.${index}.baseCost` as Path<TFormData>
      return (
        <div className="w-full min-w-full text-xs">
          <EditableInputCellWithLabelWithLabel inputType={InputType.Currency} formControl={formControl} fieldName={filedName} />
        </div>
      )
    },
    cellClassName: 'basis-1/6',
    headerClassName: 'basis-1/6',
  },
  {
    accessor: 'costPerCycle',
    title: 'Cost/Cycle',
    valueGetter: (value) => <NumberDisplay displayType={NumberDisplayType.Currency} currencyDecimals={2} value={value} />,
    cellClassName: 'basis-1/6',
    headerClassName: 'basis-1/6',
  },
]

export const newLlpTableColumns = <TFormData extends FieldValues>(
  formControl: Control<TFormData, unknown>,
): Array<TableColumn<EngineLlpCheckEditableDisplay>> => [
  {
    accessor: 'programId',
    title: '',
    isIdField: true,
    isHidden: true,
  },
  {
    accessor: 'componentModel',
    title: 'Description',
    customCell: ({ rowIndex: index }) => {
      const filedName = `llpCheckAddRemoveItems.addedItems.${index}.componentModel` as Path<TFormData>
      return (
        <div className="w-full min-w-full text-xs">
          <EditableInputCellWithLabelWithLabel formControl={formControl} fieldName={filedName} />
        </div>
      )
    },
    cellClassName: 'basis-1/6',
    headerClassName: 'basis-1/6',
  },
  {
    accessor: 'componentModule',
    title: 'Module',
    customCell: ({ rowIndex: index }) => {
      const filedName = `llpCheckAddRemoveItems.addedItems.${index}.componentModule` as Path<TFormData>
      return (
        <div className="w-full min-w-full text-xs">
          <EditableInputCellWithLabelWithLabel formControl={formControl} fieldName={filedName} />
        </div>
      )
    },
    cellClassName: 'basis-1/6',
    headerClassName: 'basis-1/6',
  },
  {
    accessor: 'unit',
    title: 'Unit',
    valueGetter: (value) => UtilizationUnitsDisplay(value as UtilizationUnits),
    cellClassName: 'basis-1/6',
    headerClassName: 'basis-1/6',
  },
  {
    accessor: 'limit',
    title: 'Limit',
    customCell: ({ rowIndex: index }) => {
      const filedName = `llpCheckAddRemoveItems.addedItems.${index}.matureLimit` as Path<TFormData>
      return (
        <div className="w-full min-w-full text-xs">
          <EditableInputCellWithLabelWithLabel inputType={InputType.NaturalNumber} formControl={formControl} fieldName={filedName} />
        </div>
      )
    },
    cellClassName: 'basis-1/6',
    headerClassName: 'basis-1/6',
  },
  {
    accessor: 'cost',
    title: 'CLP',
    customCell: ({ rowIndex: index }) => {
      const filedName = `llpCheckAddRemoveItems.addedItems.${index}.baseCost` as Path<TFormData>
      return (
        <div className="w-full min-w-full text-xs">
          <EditableInputCellWithLabelWithLabel inputType={InputType.Currency} formControl={formControl} fieldName={filedName} />
        </div>
      )
    },
    cellClassName: 'basis-1/6',
    headerClassName: 'basis-1/6',
  },
  {
    accessor: 'costPerCycle',
    title: 'Cost/Cycle',
    valueGetter: (value) => <NumberDisplay displayType={NumberDisplayType.Currency} currencyDecimals={2} value={value} />,
    cellClassName: 'basis-1/6',
    headerClassName: 'basis-1/6',
  },
]
