import { type IconVariant } from '@flyward/platform/components'
import { NavMenu } from './NavMenu'
import { useAppSelector } from '@flyward/platform/store/configureHooks'
import { selectSupportFilteredTenantId } from '@flyward/platform/store/slices/features/auth/authSelectors'
import { cn, ErrorBoundary } from '@flyward/platform'
import { pageContentStyles } from './layout'

interface IMasterLayoutProps {
  children?: React.ReactNode
  routes: Array<{ path: string; label: string; icon: IconVariant }>
}

/**
 * This wraps the protected routes that have a side-nav and a header
 */
export const MasterLayout = ({ children, routes }: IMasterLayoutProps): React.ReactElement<IMasterLayoutProps> => {
  const supportFilteredTenantId = useAppSelector(selectSupportFilteredTenantId)

  return (
    <div className="h-full w-full bg-background-light">
      <div className="flex h-full">
        <NavMenu routes={routes} />
        <main className={cn('h-full flex-1 overflow-auto', pageContentStyles)} key={supportFilteredTenantId ?? 'tenant-data'}>
          <ErrorBoundary>{children}</ErrorBoundary>
        </main>
      </div>
    </div>
  )
}
