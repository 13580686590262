import { CnFormControl, CnFormField, CnFormItem, CnFormMessage } from '@flyward/platform/components/Form'
import { cn } from '@flyward/platform/components/utils'
import { type FieldValues, type Control, type Path, type PathValue, type UseFormSetValue } from 'react-hook-form'
import { isEmpty, isNil } from 'lodash'
import { dateToIsoString } from '@flyward/platform/helpers/dateHelpers'
import { DatePicker as MUIDatePicker } from '@mui/x-date-pickers/DatePicker'

interface IEditableDateCellWithLabelWithLabel<TFormData extends FieldValues> {
  label: string
  className?: string
  labelClassName?: string
  formControl: Control<TFormData, unknown>
  fieldName: Path<TFormData>
  setFormValue: UseFormSetValue<TFormData>
  allowClear?: boolean
}

const EditableDateCellWithLabelWithLabel = <TFormData extends FieldValues>({
  label,
  className = '',
  formControl,
  fieldName,
  setFormValue,
  labelClassName = '',
  allowClear = true,
}: IEditableDateCellWithLabelWithLabel<TFormData>) => {
  return (
    <div className={(cn('flex flex-col justify-center gap-y-1'), className)}>
      {!isEmpty(label) && <em className={cn('block text-xs font-semibold capitalize not-italic text-text-3', labelClassName)}>{label}</em>}
      <CnFormField
        control={formControl}
        name={fieldName}
        render={({ field }) => {
          return (
            <CnFormItem className="space-y-0">
              <CnFormControl>
                <MUIDatePicker
                  className="MuiCustom-date-picker-small"
                  value={!isNil(field.value) && !isEmpty(field.value) ? new Date(field.value) : undefined}
                  onChange={(value?: Date) => {
                    const isoFormat = isNil(value) ? undefined : dateToIsoString(value)
                    const isoFormatDisplay = isoFormat ?? ''
                    const valuePath = isoFormat as PathValue<TFormData, Path<TFormData>>
                    field.value = valuePath
                    setFormValue(fieldName, valuePath)
                    field.onChange(isoFormatDisplay)
                  }}
                  slotProps={{
                    field: {
                      clearable: allowClear,
                      onClear: () => {
                        field.onChange(undefined)
                      },
                    },
                  }}
                />
              </CnFormControl>
              <CnFormMessage />
            </CnFormItem>
          )
        }}
      />
    </div>
  )
}

export { EditableDateCellWithLabelWithLabel }
export type { IEditableDateCellWithLabelWithLabel }
