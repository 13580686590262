import { useSelector, useDispatch } from 'react-redux'
import type { TRootState } from '@flyward/platform/store/configureReducers'
import { Box, Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import { NumberDisplay, NumberDisplayType, EnvironmentalImpactTypeDisplay } from '@flyward/platform'
import { ComponentType } from '@flyward/platform/models/enums/ComponentType'
import type { EnvironmentalImpactType } from '@flyward/platform'
import { Label } from '../common/Label'
import { useCreateEngineMaintenanceProgramMutation } from '@flyward/knowledgeBase/store'
import { type CreateEngineProgramDto } from '@flyward/knowledgeBase/models/DTOs/Create/Engine'
import { isNil } from 'lodash'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '@flyward/main-app/providers/routes'
import { showError, showSuccess } from '@flyward/platform/services'
import { formatAxiosErrorMessage } from '@flyward/platform/helpers/ErrorHelpers'
import { resetWizard } from '../../store/wizardSlice'

interface ReviewAndPublishProps {
  onNext: () => void
  onBack: () => void
  stepTitle: string
}

export const ReviewAndPublish: React.FC<ReviewAndPublishProps> = ({ onBack, stepTitle }) => {
  const wizardState = useSelector((state: TRootState) => state.wizard[ComponentType.EngineUnit])
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [createEngineMaintenanceProgram] = useCreateEngineMaintenanceProgramMutation()

  const onPublish = () => {
    const programToCreate: CreateEngineProgramDto = {
      programName: wizardState.general.name,
      manufacturer: wizardState.general.manufacturer,
      componentModel: wizardState.general.model,
      baseYear: wizardState.general.baseYear,
      basicAssumptions: {
        downtimeMonths: wizardState.basicAssumptions.checkDowntime,
        monthsDelayInPaymentOnClaims:
          isNil(wizardState.basicAssumptions.delayInPayment) || wizardState.basicAssumptions.delayInPayment === '' ? 0 : wizardState.basicAssumptions.delayInPayment,
      },
      eprCheck: {
        firstRunLimit: wizardState.perfLLP.firstRunLimit,
        firstRunCost: wizardState.perfLLP.firstRunCost,
        matureRunLimit: wizardState.perfLLP.lastRunLimit,
        matureRunCost: wizardState.perfLLP.lastRunCost,
      },
      llpChecks: wizardState.perfLLP.llpReplacements.map((llp: { description: string; module?: string; limit: number; clp: number }) => ({
        componentModel: llp.description,
        componentModule: llp.module,
        limit: llp.limit,
        cost: llp.clp,
      })),
      eprEscalationPct: wizardState.adjustCostGrowth.pr,
      llpEscalationPct: wizardState.adjustCostGrowth.llp,
      derateRatios: wizardState.adjustCostGrowth.derate?.map((derate: { impact: number; limit: number }) => ({
        deratePct: derate.impact,
        limitAdjustmentPct: derate.limit,
      })),
      envImpacts: wizardState.adjustCostGrowth.envImpact?.map((env: { environment: EnvironmentalImpactType; limit: number }) => ({
        impactType: Number(env.environment),
        limitAdjustmentPct: env.limit,
      })),
      fhFcRatios: wizardState.adjustCostGrowth.fhfc?.map((fhfc: { ratio: number; limit: number }) => ({
        fhFcRatio: fhfc.ratio,
        limitAdjustmentPct: fhfc.limit,
      })),
    }

    createEngineMaintenanceProgram({
      program: programToCreate,
    })
      .then(() => {
        dispatch(resetWizard())
        navigate(ROUTES.MAINTENANCE_PROGRAMS.EMP)
        showSuccess(<span>The maintenance programme was successfully added.</span>)
      })
      .catch((error) => {
        showError(formatAxiosErrorMessage(JSON.stringify(error?.response)))
      })
  }

  const flexBoxStyles = {
    display: 'flex',
    flexDirection: 'row',
    gap: 2,
    flexWrap: 'wrap',
    '& > *': {
      flex: '1 1 calc(25% - 16px)',
      minWidth: '200px',
    },
  }

  return (
    <div className="shadow-sm rounded-lg bg-white p-6">
      <Typography variant="h6" sx={{ mb: 3 }}>
        {stepTitle}
      </Typography>

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, mb: 4 }}>
        <div className="mb-2 text-lg font-semibold">1. General</div>
        <Box sx={flexBoxStyles}>
          <Label label="Programme Name" value={wizardState.general.name} />
          <Label label="Manufacturer" value={wizardState.general.manufacturer} />
          <Label label="Model" value={wizardState.general.model} />
          <Label label="Base Year" value={wizardState.general.baseYear?.toString() ?? 'N/A'} />
        </Box>
      </Box>

      {/* Performance & LLP Section */}
      <div className="mb-2 mt-4 text-lg font-semibold">2. Performance & LLP</div>
      <Typography variant="subtitle2" sx={{ mb: 1 }}>
        Performance Summary
      </Typography>
      <TableContainer component={Paper} sx={{ mb: 4 }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: 600, whiteSpace: 'nowrap' }}>Event Type</TableCell>
              <TableCell sx={{ fontWeight: 600, whiteSpace: 'nowrap' }}>Unit</TableCell>
              <TableCell sx={{ fontWeight: 600, textAlign: 'right', whiteSpace: 'nowrap' }}>First Run Limit (Hours)</TableCell>
              <TableCell sx={{ fontWeight: 600, textAlign: 'right', whiteSpace: 'nowrap' }}>First Run Cost</TableCell>
              <TableCell sx={{ fontWeight: 600, textAlign: 'right', whiteSpace: 'nowrap' }}>Mature Limit (Hours)</TableCell>
              <TableCell sx={{ fontWeight: 600, textAlign: 'right', whiteSpace: 'nowrap' }}>Mature Cost</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>EPR</TableCell>
              <TableCell>Flight Hours</TableCell>
              <TableCell sx={{ textAlign: 'right' }}>
                <NumberDisplay value={wizardState.perfLLP.firstRunLimit} displayType={NumberDisplayType.Integer} />
              </TableCell>
              <TableCell sx={{ textAlign: 'right' }}>
                <NumberDisplay value={wizardState.perfLLP.firstRunCost} displayType={NumberDisplayType.CurrencyRounded} />
              </TableCell>
              <TableCell sx={{ textAlign: 'right' }}>
                <NumberDisplay value={wizardState.perfLLP.lastRunLimit} displayType={NumberDisplayType.Integer} />
              </TableCell>
              <TableCell sx={{ textAlign: 'right' }}>
                <NumberDisplay value={wizardState.perfLLP.lastRunCost} displayType={NumberDisplayType.CurrencyRounded} />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      {wizardState.perfLLP.llpReplacements.length > 0 && (
        <>
          <Typography variant="subtitle2" sx={{ mb: 1 }}>
            LLP Replacements
          </Typography>
          <TableContainer component={Paper} sx={{ mb: 4 }}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: 600 }}>Description</TableCell>
                  <TableCell sx={{ fontWeight: 600 }}>Module</TableCell>
                  <TableCell sx={{ fontWeight: 600 }}>Unit</TableCell>
                  <TableCell sx={{ fontWeight: 600, textAlign: 'right' }}>Limit</TableCell>
                  <TableCell sx={{ fontWeight: 600, textAlign: 'right' }}>CLP</TableCell>
                  <TableCell sx={{ fontWeight: 600, textAlign: 'right' }}>Cost/Cycle</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {wizardState.perfLLP.llpReplacements.map(
                  (
                    {
                      description,
                      module,
                      limit,
                      clp,
                    }: {
                      description: string
                      module: string | null
                      limit: number
                      clp: number
                    },
                    index: number,
                  ) => (
                    <TableRow key={index}>
                      <TableCell>{description}</TableCell>
                      <TableCell>{module ?? '-'}</TableCell>
                      <TableCell>{'Flight Cycle'}</TableCell>
                      <TableCell sx={{ textAlign: 'right' }}>
                        <NumberDisplay value={limit} displayType={NumberDisplayType.Integer} />
                      </TableCell>
                      <TableCell sx={{ textAlign: 'right' }}>
                        <NumberDisplay value={clp} displayType={NumberDisplayType.CurrencyRounded} />
                      </TableCell>
                      <TableCell sx={{ textAlign: 'right' }}>
                        $<NumberDisplay value={clp / limit} displayType={NumberDisplayType.Decimal} decimals={2} />
                      </TableCell>
                    </TableRow>
                  ),
                )}
                <TableRow className="bg-background-light">
                  <TableCell sx={{ fontWeight: 600 }}>Total</TableCell>
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell sx={{ fontWeight: 600, textAlign: 'right' }}>
                    <NumberDisplay
                      value={wizardState.perfLLP.llpReplacements.reduce((sum: number, { clp }: { clp: number }) => sum + (clp || 0), 0)}
                      displayType={NumberDisplayType.CurrencyRounded}
                    />
                  </TableCell>
                  <TableCell sx={{ fontWeight: 600, textAlign: 'right' }}>
                    $
                    <NumberDisplay
                      value={wizardState.perfLLP.llpReplacements.reduce((sum: number, { clp, limit }: { clp: number; limit: number }) => sum + (clp || 0) / (limit || 1), 0)}
                      displayType={NumberDisplayType.Decimal}
                      decimals={2}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}

      {/* Adjustments & Cost Growth Section */}
      <div className="mb-2 mt-4 text-lg font-semibold">
        3. Adjustments & Cost Growth <span className="font-normal text-gray-400">(Optional)</span>
      </div>
      <Typography variant="subtitle2" sx={{ mb: 1 }}>
        Escalation
      </Typography>
      <Box sx={flexBoxStyles} className="mb-4">
        <Label label="PR Percentage" value={`${wizardState.adjustCostGrowth.pr ?? 0}%`} />
        <Label label="LLP Percentage" value={`${wizardState.adjustCostGrowth.llp ?? 0}%`} />
        <span></span>
        <span></span>
      </Box>

      <div className="flex flex-row gap-4">
        {wizardState.adjustCostGrowth.fhfc && wizardState.adjustCostGrowth.fhfc.length > 0 && (
          <div className="flex-1">
            <Typography variant="subtitle2" sx={{ mb: 1 }}>
              FH/FC Ratio
            </Typography>
            <TableContainer component={Paper}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: 600, textAlign: 'right', whiteSpace: 'nowrap' }}>FH/FC Ratio</TableCell>
                    <TableCell sx={{ fontWeight: 600, textAlign: 'right', whiteSpace: 'nowrap' }}>EPR Limit Adjustment</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {wizardState.adjustCostGrowth.fhfc.map(({ ratio, limit }: { ratio: number; limit: number }, index: number) => (
                    <TableRow key={index}>
                      <TableCell sx={{ textAlign: 'right' }}>{ratio}</TableCell>
                      <TableCell sx={{ textAlign: 'right' }}>{limit}%</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        )}

        {wizardState.adjustCostGrowth.derate && wizardState.adjustCostGrowth.derate.length > 0 && (
          <div className="flex-1">
            <Typography variant="subtitle2" sx={{ mb: 1 }}>
              Derate Ratio
            </Typography>
            <TableContainer component={Paper}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: 600, textAlign: 'right', whiteSpace: 'nowrap' }}>Derate Impact</TableCell>
                    <TableCell sx={{ fontWeight: 600, textAlign: 'right', whiteSpace: 'nowrap' }}>EPR Limit Adjustment</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {wizardState.adjustCostGrowth.derate.map(({ impact, limit }: { impact: number; limit: number }, index: number) => (
                    <TableRow key={index}>
                      <TableCell sx={{ textAlign: 'right' }}>{impact}%</TableCell>
                      <TableCell sx={{ textAlign: 'right' }}>{limit}%</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        )}

        {wizardState.adjustCostGrowth.envImpact && wizardState.adjustCostGrowth.envImpact.length > 0 && (
          <div className="flex-1">
            <Typography variant="subtitle2" sx={{ mb: 1 }}>
              Environmental Impact
            </Typography>
            <TableContainer component={Paper}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: 600, whiteSpace: 'nowrap' }}>Environment</TableCell>
                    <TableCell sx={{ fontWeight: 600, textAlign: 'right', whiteSpace: 'nowrap' }}>EPR Limit Adjustment</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {wizardState.adjustCostGrowth.envImpact.map(({ environment, limit }: { environment: EnvironmentalImpactType; limit: number }, index: number) => (
                    <TableRow key={index}>
                      <TableCell>{EnvironmentalImpactTypeDisplay(Number(environment))}</TableCell>
                      <TableCell sx={{ textAlign: 'right' }}>{limit}%</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        )}
      </div>

      {/* Basic Assumptions Section */}
      <div className="mb-2 mt-6 text-lg font-semibold">
        4. Basic Assumptions <span className="font-normal text-gray-400">(Optional)</span>
      </div>
      <Box sx={flexBoxStyles}>
        <Label label="Check Downtime (months)" value={wizardState.basicAssumptions.checkDowntime} />
        <Label label="Delay in Payment (months)" value={wizardState.basicAssumptions.delayInPayment || 0} />
        <span></span>
        <span></span>
      </Box>

      <Box sx={{ mt: 4 }}>
        <Button
          variant="contained"
          onClick={() => {
            onPublish()
          }}
          sx={{ mr: 1 }}
        >
          Publish
        </Button>
        <Button variant="text" onClick={onBack}>
          Back
        </Button>
      </Box>
    </div>
  )
}
