/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { ActionDialog, Input, InputType, ReportDisplayType, errorMessages, AssetType, FlyToType } from '@flyward/platform'
import { useAppDispatch, useAppSelector } from '@flyward/platform/store/configureHooks'
import { selectUnsortedReportAssets, selectReportDisplayType, selectSuccessfulReportId } from '@flyward/platform/store/slices/features/flyForward/flyForwardSelectors'
import { isEmpty, isNil } from 'lodash'
import { useEffect, useState } from 'react'
import { useComputeSchedule } from './useComputeSchedule'
import { setShowAdditionalParams, setSuccessfulReportId } from '@flyward/platform/store/slices'
import { isValidEndDate } from '@flyward/forecasts/helpers/flyForwardHelpers'
import { isWithinFiftyYears } from '@flyward/platform/helpers/dateHelpers'
import { ReportExcelExtract, ReportItemCustomExcelExtractBtn, ReportItemExcelExtract } from '@flyward/forecasts'
import { showError, showSuccess } from '@flyward/platform/services'
import { useSaveReportWithNameMutation } from '@flyward/platform/store'
import { Button as MuiButton, Typography } from '@mui/material'
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff'
import SaveIcon from '@mui/icons-material/Save'

const FlyForwardPageHeader = () => {
  const reportAssets = useAppSelector(selectUnsortedReportAssets)
  const reportDisplayType = useAppSelector(selectReportDisplayType)
  const reportId = useAppSelector(selectSuccessfulReportId)
  const dispatch = useAppDispatch()

  const [canFlyForward, setCanFlyForward] = useState<boolean>(false)
  const { computeSchedule } = useComputeSchedule()

  // Save dialog state
  const [reportName, setReportName] = useState('')
  const [isReportNameValid, setIsReportNameValid] = useState(false)
  const [saveReportWithName] = useSaveReportWithNameMutation()
  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false)

  const handleSave = async () => {
    if (reportId === undefined) {
      return
    }
    const saveResponse = await saveReportWithName({ id: reportId, name: reportName.trim() })
    if (!isNil(saveResponse.error)) {
      showError(errorMessages.forecasting.reportSaveError)
    } else {
      showSuccess(
        <span>
          Your forecast report <strong>{reportName.trim()}</strong> has been successfully saved!
        </span>,
      )
    }
    setIsSaveModalOpen(false)
  }

  const handleFly = () => {
    dispatch(setSuccessfulReportId({ reportId: '' }))
    dispatch(setShowAdditionalParams(false))
    computeSchedule(reportAssets).then((reportId) => {
      if (!isNil(reportId)) {
        dispatch(setSuccessfulReportId({ reportId }))
      }
    })
  }

  const reportItemIds = () => {
    return reportAssets
      .map((asset) => ({
        reportItemId: asset.reportItemId,
        assetSerialNumber: asset.assetSerialNumber,
      }))
      .filter((item): item is { reportItemId: string; assetSerialNumber: string } => item.reportItemId !== undefined)
  }

  useEffect(() => {
    setCanFlyForward(false)

    if (reportAssets.length === 0) {
      setCanFlyForward(false)
      return
    }

    const canFlyForward = reportAssets.every((reportAsset) => {
      const techSpecDate: string = reportAsset.flyForwardParameters?.commonAssetDetails?.assetDetailsSnapshot.dateOfLastTechSpec ?? ''

      const isEndDateValid =
        (!isEmpty(techSpecDate) && reportAsset.flyForwardParameters.flyToType === FlyToType.Redelivery) ||
        (isValidEndDate(reportAsset.flyForwardParameters.endDate, techSpecDate) && isWithinFiftyYears(reportAsset.flyForwardParameters.endDate))

      const isAvgFlightHoursValid = reportAsset.flyForwardParameters.averageMonthlyFlightHours > 0
      const isAvgFlightCyclesValid = reportAsset.flyForwardParameters.averageMonthlyFlightCycles > 0
      const isAvgApuHoursValid = reportAsset.flyForwardParameters.assetType === AssetType.StandaloneEngine || reportAsset.flyForwardParameters.averageMonthlyAPUHours > 0

      return isEndDateValid && isAvgFlightHoursValid && isAvgFlightCyclesValid && isAvgApuHoursValid
    })

    setCanFlyForward(canFlyForward)
  }, [reportAssets])

  return (
    <>
      <div className="flex h-20 min-h-[5rem] w-full items-center justify-between px-2">
        <Typography sx={{ fontSize: '1.25rem', fontWeight: 600, color: 'var(--text-1)' }}>Fly Forward</Typography>
        <div className="flex h-full items-center gap-4">
          <MuiButton variant="contained" color="primary" startIcon={<FlightTakeoffIcon />} disabled={!canFlyForward} onClick={handleFly}>
            Fly
          </MuiButton>

          <MuiButton variant="contained" color="primary" startIcon={<SaveIcon />} disabled={isEmpty(reportId) || isNil(reportId)} onClick={() => setIsSaveModalOpen(true)}>
            Save
          </MuiButton>

          {reportDisplayType === ReportDisplayType.AssetReport ? (
            <>
              <ReportItemExcelExtract reportId={reportId} reportItems={reportItemIds()} disabled={isEmpty(reportId) || isNil(reportId)} />
              <ReportItemCustomExcelExtractBtn reportId={reportId} reportItems={reportAssets} disabled={isEmpty(reportId) || isNil(reportId)} />
            </>
          ) : (
            <ReportExcelExtract reportId={reportId} reportName={reportName} disabled={isEmpty(reportId) || isNil(reportId)} />
          )}
        </div>
      </div>

      <ActionDialog
        isValid={reportName.trim().length >= 3 && isReportNameValid}
        confirmBtnLabel="Save"
        dialogHeader="Save report"
        key={`save-${reportId}`}
        onConfirm={handleSave}
        onCancel={() => {
          setReportName('')
          setIsSaveModalOpen(false)
        }}
        onSuccess={() => {
          setReportName('')
          setIsSaveModalOpen(false)
        }}
        isOpen={isSaveModalOpen}
        setIsOpen={setIsSaveModalOpen}
        dialogContent={
          <Input
            label="Report name"
            labelClassName="text-semibold text-text-1"
            setValueAfterValidation={(value: string) => {
              setReportName(value.trimStart())
            }}
            type={InputType.SafeText}
            inputClassName="w-full mt-2"
            setIsValid={setIsReportNameValid}
          />
        }
      />
    </>
  )
}

export { FlyForwardPageHeader }
