import { ProgressStep } from '@flyward/platform'

export const DOCUMENT_PROCESSING_STEPS: ProgressStep[] = [
  { message: 'Initializing… Preparing your file for analysis.', progressStart: 0, duration: 3 },
  { message: 'Locating and extracting technical specifications from document…', progressStart: 3, duration: 5 },
  { message: 'Reviewing aircraft details…', progressStart: 10, duration: 7 },
  { message: 'Collecting Engines & LLP details…', progressStart: 25, duration: 15 },
  { message: 'Gathering Airframe information…', progressStart: 40, duration: 5 },
  { message: 'Analyzing Landing Gear and APU data…', progressStart: 55, duration: 8 },
  { message: 'Reviewing all components and cross-checking their details…', progressStart: 70, duration: 10 },
  { message: 'Almost done — final checks in progress…', progressStart: 82, duration: 6 },
  { message: 'Almost done — final checks in progress…', progressStart: 88, duration: 12 },
]
