/**
 * Formats input string to ensure it only contains numbers and a single decimal point,
 * limiting to 2 decimal places
 * @param value - The input string to format
 * @returns Formatted string containing only numbers and up to 2 decimal places
 */
export const handleNumericInput = (value: string): string => {
  return value === ''
    ? ''
    : value
        .replace(/[^\d.]/g, '') // Remove all non-numeric characters except decimal point
        .replace(/(\..*)\./g, '$1') // Ensure only one decimal point
        .replace(/^(\d*\.?\d{0,2}).*$/, '$1') // Limit to 2 decimal places
}
