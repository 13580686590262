import { type AircraftEngineDto, type ContractualFlightHoursToFlightCyclesPerformanceRestorationReserveDto } from '@flyward/assets/models'
import { getCoreRowModel, type Row, useReactTable } from '@tanstack/react-table'
import { contractualReserveEditableColumns } from './constants'
import { GenericTable } from '@flyward/platform/components/Table/GenericTable'
import { type UseFormUnregister, type Control, type FormState, type UseFormRegister, type UseFormSetValue } from 'react-hook-form'
import { BooleanCellWithLabel, EditableInputCellWithLabelWithLabel, InputType, TextCellWithLabel, UtilizationUnitsDisplay } from '@flyward/platform'
import { useState, useEffect } from 'react'
import { getLabelContent } from '@flyward/assets/helpers/assetHelpers'

interface IContractualReserveEditableTabProps {
  component: AircraftEngineDto
  performanceRestorationReserves: ContractualFlightHoursToFlightCyclesPerformanceRestorationReserveDto[]
  registerFormField: UseFormRegister<AircraftEngineDto>
  unRegisterFormField: UseFormUnregister<AircraftEngineDto>
  setFormValue: UseFormSetValue<AircraftEngineDto>
  formControl: Control<AircraftEngineDto, unknown>
  formState: FormState<AircraftEngineDto>
}

const ContractualReserveEditableTab = ({
  component,
  performanceRestorationReserves,
  formControl,
  setFormValue,
  registerFormField,
  unRegisterFormField,
}: IContractualReserveEditableTabProps) => {
  const maintenanceReserve = component.engineContract.engineUnitMaintenanceReserveContract

  const contractualReserveTable = useReactTable<ContractualFlightHoursToFlightCyclesPerformanceRestorationReserveDto>({
    data: performanceRestorationReserves,
    columns: contractualReserveEditableColumns(formControl),
    getRowCanExpand: () => true,
    getCoreRowModel: getCoreRowModel(),
    enableMultiRowSelection: false,
  })

  const [dynamicLabel, setDynamicLabel] = useState<string>('')

  useEffect(() => {
    setDynamicLabel(getLabelContent(maintenanceReserve.isEolActive, maintenanceReserve.isMaintenanceReserveActive))
  }, [maintenanceReserve.isEolActive, maintenanceReserve.isMaintenanceReserveActive])

  const onAddFcFhRatio = () => {
    const newRow: ContractualFlightHoursToFlightCyclesPerformanceRestorationReserveDto = {
      flightHoursToFlightCyclesRatio: 0,
      reserveAmount: 0,
    }
    const updatedData = [...performanceRestorationReserves, newRow]
    registerFormField(`engineContract.contractualFlightHoursToFlightCyclesPerformanceRestorationReserves.${updatedData.length - 1}`)
    setFormValue(`engineContract.contractualFlightHoursToFlightCyclesPerformanceRestorationReserves.${updatedData.length - 1}`, newRow, {
      shouldDirty: true,
    })
    setFormValue('engineContract.contractualFlightHoursToFlightCyclesPerformanceRestorationReserves', updatedData, { shouldDirty: true })
  }

  const onRemoveFcFhRatio = (row: Row<ContractualFlightHoursToFlightCyclesPerformanceRestorationReserveDto>) => {
    const updatedData = performanceRestorationReserves.filter((_data, index) => index !== row.index)
    unRegisterFormField(`engineContract.contractualFlightHoursToFlightCyclesPerformanceRestorationReserves.${row.index}`)
    setFormValue('engineContract.contractualFlightHoursToFlightCyclesPerformanceRestorationReserves', updatedData, { shouldDirty: true })
  }

  return (
    <>
      <div className="flex flex-col gap-y-2">
        <div className="flex gap-x-6">
          <BooleanCellWithLabel
            className="basis-1/6"
            label={`${dynamicLabel} Flag First Run`}
            info={component.engineContract.engineUnitMaintenanceReserveContract.isFirstRun}
            preventInfo={!component.engineContract.engineUnitMaintenanceReserveContract.isMaintenanceReserveActive && !maintenanceReserve.isEolActive}
          />
          <EditableInputCellWithLabelWithLabel
            inputType={InputType.Percentage}
            className="basis-1/6"
            label={`PR ${dynamicLabel} Agreed Escalation`}
            formControl={formControl}
            fieldName={`engineContract.escalations.eprEscalation.maintenanceReserveAgreedEscalationPercentage`}
            preventInfo={!maintenanceReserve.isMaintenanceReserveActive && !maintenanceReserve.isEolActive}
          />
          <EditableInputCellWithLabelWithLabel
            inputType={InputType.Currency}
            className="basis-1/6"
            label={`PR ${dynamicLabel} rate`}
            formControl={formControl}
            fieldName={`engineContract.engineUnitMaintenanceReserveContract.enginePerformanceRestorationMaintenanceRate.rateAmount`}
            preventInfo={!maintenanceReserve.isMaintenanceReserveActive && !maintenanceReserve.isEolActive}
          />
          <TextCellWithLabel
            className="basis-1/6"
            label={`PR ${dynamicLabel} rate type`}
            info={UtilizationUnitsDisplay(
              component.engineContract.engineUnitMaintenanceReserveContract.enginePerformanceRestorationMaintenanceRate?.utilizationUnit,
            )}
            preventInfo={!maintenanceReserve.isMaintenanceReserveActive && !maintenanceReserve.isEolActive}
          />
          <EditableInputCellWithLabelWithLabel
            inputType={InputType.IntegerCurrency}
            className="basis-1/6"
            label={`Current PR ${dynamicLabel} fund`}
            formControl={formControl}
            fieldName={`engineContract.engineUnitMaintenanceReserveContract.enginePerformanceRestorationMaintenanceRate.currentFund`}
            isReadOnly={!maintenanceReserve.isMaintenanceReserveActive && !maintenanceReserve.isEolActive}
          />
          <div className="basis-1/6" />
        </div>
      </div>
      <div className="w-52 pt-2">
        <GenericTable tableInstance={contractualReserveTable} onAddRow={onAddFcFhRatio} onRemoveRow={onRemoveFcFhRatio} />
      </div>
    </>
  )
}

export { ContractualReserveEditableTab }
export type { IContractualReserveEditableTabProps }
