import { useAssetComponentsForms } from '@flyward/assets/context'
import { useVerifyAssetQuery } from '@flyward/assets/store'
import { isEmpty, isNil } from 'lodash'
import { useState, useCallback } from 'react'
import { Button, Dialog } from '@mui/material'
import CheckIcon from '@mui/icons-material/Check'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { ActionDialog, DialogSize } from '@flyward/platform'
import { AssetAlignmentWizard } from './Align/AssetAlignmentWizard'

const DialogContent = ({ msn, assetId, alignmentData, selectedAirframeProgramId, selectedEngineProgramId, setIsAssetVerificationModalOpen, onClose }) => {
  return (
    <div className="w-full p-4">
      <AssetAlignmentWizard
        msn={msn}
        assetId={assetId}
        alignmentData={alignmentData}
        selectedAirframeProgramId={selectedAirframeProgramId}
        selectedEngineProgramId={selectedEngineProgramId}
        setIsAssetVerificationModalOpen={setIsAssetVerificationModalOpen}
      />
    </div>
  )
}

interface IAssetVerificationProps {
  msn: string
  assetId: string
  isLoading?: boolean
  selectedAirframeProgramId?: string
  selectedEngineProgramId?: string
  readOnly?: boolean
}

const AssetVerification = ({ msn, assetId, isLoading = true, selectedAirframeProgramId, selectedEngineProgramId, readOnly = false }: IAssetVerificationProps) => {
  const { hasAnyChanges } = useAssetComponentsForms()
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [key, setKey] = useState(0)

  const isEnabled = !hasAnyChanges && !isLoading

  const { data: verifyAssetData } = useVerifyAssetQuery(
    {
      assetId,
      airframeProgramId: selectedAirframeProgramId,
      engineProgramId: selectedEngineProgramId,
    },
    {
      skip: !isEnabled || (isEmpty(selectedAirframeProgramId) && isEmpty(selectedEngineProgramId)),
    },
  )

  const noProgramAssigned = isEmpty(selectedAirframeProgramId) && isEmpty(selectedEngineProgramId)

  const handleDialogOpen = useCallback(() => {
    setIsDialogOpen(true)
    setKey((prev) => prev + 1)
  }, [])

  const handleDialogClose = useCallback(() => {
    setIsDialogOpen(false)
  }, [])

  return (
    <>
      <Button variant="outlined" disabled={!isEnabled || readOnly} onClick={handleDialogOpen} style={{ minWidth: '5.4rem' }}>
        Align{' '}
        {!isNil(verifyAssetData) &&
          (verifyAssetData?.isSuccess ? (
            <CheckIcon
              sx={{
                ml: 0.2,
                color: '#22c55e',
              }}
              fontSize="small"
            />
          ) : (
            <ErrorOutlineIcon
              sx={{
                ml: 0.2,
                color: '#ef4444',
              }}
              fontSize="small"
            />
          ))}
      </Button>

      {noProgramAssigned ? (
        <ActionDialog
          size={DialogSize.Small}
          confirmBtnLabel="OK"
          isValid={true}
          dialogHeader={<p>Align Asset</p>}
          hideCancelBtn
          onConfirm={async () => {}}
          onCancel={() => {}}
          onSuccess={() => {}}
          isOpen={isDialogOpen}
          setIsOpen={setIsDialogOpen}
          dialogContent={<p>No maintenance program assigned</p>}
        />
      ) : (
        <Dialog key={key} fullScreen open={isDialogOpen} onClose={handleDialogClose}>
          <DialogContent
            msn={msn}
            assetId={assetId}
            alignmentData={verifyAssetData}
            selectedAirframeProgramId={selectedAirframeProgramId}
            selectedEngineProgramId={selectedEngineProgramId}
            setIsAssetVerificationModalOpen={setIsDialogOpen}
            onClose={handleDialogClose}
          />
        </Dialog>
      )}
    </>
  )
}

export { AssetVerification }
